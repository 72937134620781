import { graphql } from "babel-plugin-relay/macro";

export const ADD_CONFIG_MUTATION = graphql`
	mutation addCanIfUnlockedStartContentConfig_AddCanIfUnlockedStartContentConfigMutation(
		$input: AddCanIfUnlockedStartContentConfigInput!
	) {
		Admin {
			Tree {
				addCanIfUnlockedStartContentConfig(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
