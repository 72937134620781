import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { LimitedCartDiscountEditRoutes } from "@screens/limited-cart-discount-edit";
import { LimitedCartDiscountScreen } from "./limited-cart-discounts.screen";

export const LimitedCartDiscountsRoutes: RouteDefinition[] = [
	{
		path: Paths.cartDiscounts.path,
		element: <LimitedCartDiscountScreen />,
		requiredPermissions: ["UserInAccountPermission_LimitedCartDiscountAdmin_Read"],
	},
	...LimitedCartDiscountEditRoutes,
];
