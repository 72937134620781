import { Button } from "primereact/button";
import { CreateChildTagProps } from "./create-child-tag.types";
import { useMutation } from "react-relay";
import { CREATE_AND_APPEND_CHILD_TAG_MUTATION } from "./create-child-tag.graphql";
import { createChildTag_CreateAndAppendChildTagMutation } from "../../../../__generated__/createChildTag_CreateAndAppendChildTagMutation.graphql";
import { useState } from "react";
import { AcademiesTagDialog } from "../academies-tag-dialog";
import { AcademiesTagFormState } from "../academies-tag-form/academies-tag-form.types";

export const CreateChildTag = ({ parentId, onComplete }: CreateChildTagProps) => {
	const [isOpen, setIsOpen] = useState(false);

	const [createAndAppendChildTag, isCreatingandAppendingChildTag] =
		useMutation<createChildTag_CreateAndAppendChildTagMutation>(
			CREATE_AND_APPEND_CHILD_TAG_MUTATION,
		);

	const handleOnClick = () => setIsOpen(true);

	const handleOnHide = () => setIsOpen(false);

	const handleOnSubmit = (value: AcademiesTagFormState) => {
		createAndAppendChildTag({
			variables: {
				input: {
					data: {
						name: value.name,
						isClickable: value.isClickable,
						isTopic: value.isTopic,
					},
					parentId: parentId,
				},
			},
			onCompleted: onComplete,
		});
	};

	return (
		<>
			<Button
				icon="pi pi-plus-circle"
				onClick={handleOnClick}
				disabled={isCreatingandAppendingChildTag}
			/>
			<AcademiesTagDialog isOpen={isOpen} onHide={handleOnHide} onSubmit={handleOnSubmit} />
		</>
	);
};
