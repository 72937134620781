import { createContext, Dispatch, PropsWithChildren, useState } from "react";

export type IMessageDispatchContext = {
	connectionId: string;
	setConnectionId: Dispatch<string>;
};

export const MessageDispatchContext = createContext<IMessageDispatchContext>({
	connectionId: "",
	setConnectionId: () => {},
});

export const MessageDispatchContextProvider = ({ children }: PropsWithChildren) => {
	const [connectionId, setConnectionId] = useState("");
	const value: IMessageDispatchContext = {
		connectionId,
		setConnectionId,
	};
	return (
		<MessageDispatchContext.Provider value={value}>{children}</MessageDispatchContext.Provider>
	);
};
