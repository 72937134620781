import graphql from "babel-plugin-relay/macro";

export const PRIVATE_BILLING_DETAILS_FRAGMENT = graphql`
	fragment privateBillingDetailsForm_BillingDetailsFragment on PrivateBillingDetails {
		invoiceAddress {
			firstName
			lastName
			postalCode
			city
			country
			houseNumber
			street
			companyDetails
			companyName
		}
		dateOfBirth
		invoiceEmail
		houseNumber
		salutation
		postalCode
		firstName
		lastName
		country
		street
		city
	}
`;
