import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import { htmlTagsInTextRemover } from "../../../util/htmlTagsInTextRemover";

export function AttachmentItem(props: {
	attachmentTitle?: string | null;
	isPresentational: boolean;
	canEdit: boolean;
	editDialog?: (props: any) => React.ReactElement;
	onDelete?: () => void;
	isLoading?: boolean;
}) {
	const [displayMaximizable, setDisplayMaximizable] = useState(false);

	const showEditDialog = () => {
		setDisplayMaximizable(true);
	};

	const onHide = () => {
		setDisplayMaximizable(false);
	};

	return (
		<>
			<div className={"flex align-items-center mb-2"}>
				<div className="w-full">
					{props.attachmentTitle ? htmlTagsInTextRemover(props.attachmentTitle) : ""}
				</div>
				{props.isPresentational && (
					<Button
						disabled={props.isLoading}
						className="ml-2"
						icon="pi pi-trash"
						onClick={() => {
							props.onDelete!();
						}}
					/>
				)}

				{props.canEdit && (
					<Button
						className="ml-2"
						icon="pi pi-pencil"
						onClick={() => {
							showEditDialog();
						}}
					/>
				)}
			</div>

			{props.canEdit && (
				<Dialog
					header="Bearbeiten"
					visible={displayMaximizable}
					maximizable
					modal
					style={{ width: "50vw" }}
					onHide={() => {
						onHide();
					}}
				>
					{props.editDialog!({ onBack: onHide })}
				</Dialog>
			)}
		</>
	);
}
