import { graphql } from "babel-plugin-relay/macro";

export const TREE_NODE_FRAGMENT = graphql`
	fragment createIhkCertificateRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

export const ADD_IHK_CERTIFICATE_REWARD_MUTATION = graphql`
	mutation createIhkCertificateRewardButton_AddIHKCertificateRewardMutation(
		$input: AddIHKCertificateRewardInput!
	) {
		Admin {
			Tree {
				addIHKCertificateReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
