import { graphql } from "babel-plugin-relay/macro";

export const REMOVE_ROW_FROM_MATRIX_ELEMENT_MUTATION = graphql`
	mutation removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation(
		$input: RemoveRowFromMatrixElementInput!
	) {
		Admin {
			ElearningV2 {
				removeRowFromMatrixElement(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
