import { graphql } from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useMutation } from "react-relay";
import { type VideoSourceField_GetVideoDataV2Mutation } from "../../../__generated__/VideoSourceField_GetVideoDataV2Mutation.graphql";

const GET_VIDEO_DATA_MUTATION = graphql`
	mutation VideoSourceField_GetVideoDataV2Mutation($input: GetVideoDataV2Input!) {
		Admin {
			ElearningV2 {
				getVideoData(input: $input) {
					videoData {
						name
						duration
						thumbnailUrl
					}
				}
			}
		}
	}
`;

type VideoDataState = {
	name?: string;
	duration?: number;
	thumbnailUrl?: string;
};

type OwnProps = {
	vimeoId?: string;
	onUpdate: (vimeoId?: string) => void;
	onChange?: () => void;
};

export const VideoSourceField = ({ vimeoId, onUpdate, onChange }: OwnProps) => {
	const [getVideoData, isGettingVideoData] =
		useMutation<VideoSourceField_GetVideoDataV2Mutation>(GET_VIDEO_DATA_MUTATION);

	const [state, setState] = useState<VideoDataState | undefined>();

	return (
		<div>
			<div className="flex align-items-center">
				<div className="p-field flex-grow-1 mr-2">
					<InputText
						placeholder={"Vimeo-ID"}
						id="vimeoId"
						className="p-d-block"
						value={vimeoId}
						onChange={(e) => {
							onUpdate(e.target.value);
							if (onChange) {
								onChange();
							}
						}}
					/>
				</div>
				<Button
					icon="pi pi-refresh"
					disabled={isGettingVideoData}
					onClick={() => {
						getVideoData({
							variables: {
								input: {
									vimeoId: vimeoId || "",
								},
							},
							onCompleted: (response) => {
								setState(
									response.Admin.ElearningV2.getVideoData
										? {
												...response.Admin.ElearningV2.getVideoData
													?.videoData,
										  }
										: undefined,
								);
							},
						});
					}}
				/>
			</div>
			{state && (
				<div className="p-fluid p-3">
					<div className="field grid">
						<label
							htmlFor="firstname3"
							className="p-col-fixed"
							style={{ width: "70px" }}
						>
							Name:
						</label>
						<div className="p-col">{state.name}</div>
					</div>
					<div className="field grid">
						<label
							htmlFor="firstname3"
							className="p-col-fixed"
							style={{ width: "70px" }}
						>
							Dauer:
						</label>
						<div className="p-col">{state.duration} Sekunden</div>
					</div>

					<div>
						{state.thumbnailUrl && (
							<img height={200} alt={state.name} src={state.thumbnailUrl} />
						)}
					</div>
				</div>
			)}
		</div>
	);
};
