import {
	DefaultStyledInputCalendarComponents,
	type InputBaseProps,
	type StyledInputCalendarComponents,
} from "@thekeytechnology/framework-react-components";
import { Calendar as PrCalendar, type CalendarProps as PrCalendarProps } from "primereact/calendar";
import styled, { createGlobalStyle } from "styled-components";
import { DefaultInputStyles } from "@corestyle/component-theme/common/input.styles";

export const tkaAdminInputCalendarStyles: StyledInputCalendarComponents = {
	InputCalendar: {
		...DefaultStyledInputCalendarComponents.InputCalendar,
		StyledCalendar: styled(PrCalendar)<InputBaseProps & PrCalendarProps>`
			> input {
				${DefaultInputStyles()}
			}
		`,
		CalendarGlobalStyles: createGlobalStyle`
		`,
	},
};
