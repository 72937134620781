/**
 * @generated SignedSource<<287511a7cd8f879e05961d53db986b6b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ContentKind = "Async" | "ELearning";
export type TypeDefinitionType = "branch" | "content";
import { FragmentRefs } from "relay-runtime";
export type contentCard_TreeNodeFragment$data = {
  readonly typeDefinition: {
    readonly contentKind?: ContentKind;
    readonly definitionType: TypeDefinitionType;
  };
  readonly " $fragmentSpreads": FragmentRefs<"asyncContentEditor_TreeNodeFragment" | "elearningContentEditor_TreeNodeFragment">;
  readonly " $fragmentType": "contentCard_TreeNodeFragment";
};
export type contentCard_TreeNodeFragment$key = {
  readonly " $data"?: contentCard_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"contentCard_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "contentCard_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "definitionType",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "contentKind",
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "elearningContentEditor_TreeNodeFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "asyncContentEditor_TreeNodeFragment"
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "8dde5dbd009534e587bf292d2922a33c";

export default node;
