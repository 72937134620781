import { Button } from "primereact/button";
import { useFragment } from "react-relay";
import {
	type rewardForm_RewardFragment$key,
	type RewardKind,
} from "@relay/rewardForm_RewardFragment.graphql";
import { type rewardForm_TreeNodeFragment$key } from "@relay/rewardForm_TreeNodeFragment.graphql";
import { REWARD_CONFIGURATION_FRAGMENT, TREE_NODE_FRAGMENT } from "./reward-form.graphql";
import { type RewardFormProps } from "./reward-form.types";
import { selectArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedSelector } from "../../../../store/store.redux";
import { ExternalLicenseRewardForm } from "../external-license-reward-form";

export const RewardForm = ({
	treeNodeFragmentRef,
	rewardConfigurationFragmentRef,
	onBack: handleOnClick,
}: RewardFormProps) => {
	const node = useFragment<rewardForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const reward = useFragment<rewardForm_RewardFragment$key>(
		REWARD_CONFIGURATION_FRAGMENT,
		rewardConfigurationFragmentRef,
	);
	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);

	const Form = (() => {
		switch (reward.kind) {
			case "ExternalLicense":
				return (
					<ExternalLicenseRewardForm
						contentNodeId={node.id}
						externalLicenseRewardFragmentRef={reward}
						onBack={handleOnClick}
					/>
				);

			default:
				return null;
		}
	})();

	const TRANSLATIONS: Record<RewardKind, string> = {
		ExternalLicense: "Externe Lizenz",
		GamificationPoints: "Schlüssel",
		IHKCertificate: "IHK Zertifikat",
		// WordPackage: "Wort-Paket",
		// WordProficiencies: "Wort-Fähigkeiten",
		CrmTreeHeadUpdater: "Hubspot-Lektionsstatus-Aktualisierung",
		// ParticipationCertificate: "Teilnahmezertifikat",
	};
	return (
		<div>
			<div className="flex align-items-center">
				<h2>{TRANSLATIONS[reward.kind]}</h2>
				<Button
					disabled={arrayOfEditedForm.length > 0}
					className="ml-auto"
					icon="pi pi-times"
					onClick={handleOnClick}
				/>
			</div>
			{Form}
		</div>
	);
};
