import { graphql } from "babel-plugin-relay/macro";

export const ADD_ROW_TO_MATRIX_ELEMENT_MUTATION = graphql`
	mutation addRowToMatrixElementButton_AddRowToMatrixElementMutation(
		$input: AddRowToMatrixElementInput!
	) {
		Admin {
			ElearningV2 {
				addRowToMatrixElement(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
