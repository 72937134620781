import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { DiscountCodeScreen } from "./discount-code.screen";

export const DiscountCodeRoutes: RouteDefinition[] = [
	{
		path: Paths.discountActions.withIdPlaceholder().edit.discountCode.withIdPlaceholder().path,
		element: <DiscountCodeScreen />,
		requiredPermissions: ["UserInAccountPermission_DiscountAdmin_Read"],
	},
];
