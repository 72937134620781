import graphql from "babel-plugin-relay/macro";

export const RESET_STAGING_USER_PROGRESS_MUTATION = graphql`
	mutation resetStagingUserProgressButton_resetStagingUserProgressMutation {
		Admin {
			Staging {
				cleanUpUserInputRelatedToStaging(input: {}) {
					clientMutationId
				}
			}
		}
	}
`;
