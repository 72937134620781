import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Paths } from "@routes/paths";
import { RESET_ALL_STAGING_DATA_MUTATION } from "./reset-all-staging-data-button.graphql";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../translations/tree";

export const ResetAllStagingDataButton = () => {
	const navigate = useNavigate();
	const [resetAllStagingData, isResettingAllStagingData] = useMutation(
		RESET_ALL_STAGING_DATA_MUTATION,
	);

	const handleOnClick = () => {
		resetAllStagingData({
			variables: {},
			onCompleted: () => {
				toast.success(`Staging ${TREE_I18N_MAP(TREE_I18N_KEY.treePlural)} resettet`);
				navigate(
					Paths.educationalOffer.withId(btoa("TreeNode:regular-staging-course-root-id"))
						.path,
				);
			},
		});
	};

	return (
		<Button
			className={"mb-2"}
			label={`Staging ${TREE_I18N_MAP(TREE_I18N_KEY.treePlural)} resetten`}
			disabled={isResettingAllStagingData}
			onClick={handleOnClick}
		/>
	);
};
