import graphql from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import {
	type EditOrderItemButtonProps,
	type EditOrderItemState,
} from "./edit-order-item-button.interface";
import { type EditOrderItemButton_EditOrderItemMutation } from "../../../../__generated__/EditOrderItemButton_EditOrderItemMutation.graphql";
import { type EditOrderItemButton_OrderItemFragment$key } from "../../../../__generated__/EditOrderItemButton_OrderItemFragment.graphql";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";

const ORDER_ITEM_FRAGMENT = graphql`
	fragment EditOrderItemButton_OrderItemFragment on OrderItem {
		id
		text
	}
`;

const EDIT_ORDER_ITEM_MUTATION = graphql`
	mutation EditOrderItemButton_EditOrderItemMutation($input: EditOrderItemInput!) {
		Admin {
			ElearningV2 {
				editOrderItem(input: $input) {
					orderElement {
						...OrderElementForm_OrderElementFragment
					}
				}
			}
		}
	}
`;

export const EditOrderItemButton = ({
	orderItemFragmentRef,
	orderElementId,
}: EditOrderItemButtonProps) => {
	const orderItem = useFragment<EditOrderItemButton_OrderItemFragment$key>(
		ORDER_ITEM_FRAGMENT,
		orderItemFragmentRef,
	);
	const [editOrderItem] =
		useMutation<EditOrderItemButton_EditOrderItemMutation>(EDIT_ORDER_ITEM_MUTATION);
	const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
	const dispatch = useTypedDispatch();

	const formik = useFormik<EditOrderItemState>({
		initialValues: {
			text: orderItem.text,
		},
		onSubmit: (values) => {
			editOrderItem({
				variables: {
					input: {
						orderElementId,
						orderItemId: orderItem.id,
						newText: values.text,
					},
				},
				onCompleted: () => {
					setEditModalOpen(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	const formId = `EditOrderItem:${orderElementId}`;

	return editModalOpen ? (
		<Dialog
			onHide={() => {
				setEditModalOpen(false);
			}}
			visible={true}
		>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditOrderItemState, string>
					name={"text"}
					label={"Text"}
					className="mr-2 flex-grow-1 w-auto"
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button type="submit" label={"Speichern"} />
			</form>
		</Dialog>
	) : (
		<Button
			icon="pi pi-pencil"
			className="ml-2"
			onClick={() => {
				setEditModalOpen(true);
			}}
		/>
	);
};
