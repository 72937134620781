import { Dialog } from "primereact/dialog";
import { AcademiesTagDialogProps } from "./academies-tag-dialog.types";
import { AcademiesTagForm } from "../academies-tag-form";

export const AcademiesTagDialog = ({
	isOpen,
	onHide,
	isLoading,
	onSubmit,
	initialValues,
}: AcademiesTagDialogProps) => {
	return (
		<Dialog visible={isOpen} onHide={onHide}>
			<AcademiesTagForm
				isLoading={isLoading}
				initialValues={initialValues}
				onSubmit={onSubmit}
			/>
		</Dialog>
	);
};
