import { Toast } from "primereact/toast";
import { useCallback, useRef } from "react";
import { useMutation } from "react-relay";
import { type registerUser_CreateUserInAccountMutation } from "@relay/registerUser_CreateUserInAccountMutation.graphql";
import { ADD_USER_IN_ACCOUNT_MUTATION } from "./register-user.graphql";
import { type RegisterUserProps } from "./register-user.interface";
import { RegistrationForm } from "../registration-form/registration-form.component";
import { type RegistrationFormState } from "../registration-form/registration-form.interface";

export const RegisterUser = ({ onUserRegistered, accountId, connections }: RegisterUserProps) => {
	const [addUserInAccount] = useMutation<registerUser_CreateUserInAccountMutation>(
		ADD_USER_IN_ACCOUNT_MUTATION,
	);
	const toastRef = useRef<Toast>(null);

	const handleOnUserSubmitted = useCallback(
		(user: RegistrationFormState, onSuccess?: () => void) => {
			addUserInAccount({
				variables: {
					input: {
						email: user.email,
						firstName: user.firstName,
						lastName: user.lastName,
						password: user.password,
						accountId,
						adsOptIn: false,
					},
					connections: connections || [],
				},
				onCompleted: (response) => {
					const id = response.Admin.Auth.createUserInAccount?.userInAccount.node.user.id;
					if (id) {
						onUserRegistered(id);
					} else {
						toastRef.current?.show({
							severity: "error",
							summary: "Error",
							detail: "Benutzer konnte nicht erstellt werden.",
						});
					}
					onSuccess?.();
				},
			});
		},
		[],
	);
	return (
		<>
			<RegistrationForm onUserSubmitted={handleOnUserSubmitted} />
			<Toast ref={toastRef} />
		</>
	);
};
