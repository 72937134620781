import React from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { ProductIsHiddenColumn_ProductFragment$key } from "@relay/ProductIsHiddenColumn_ProductFragment.graphql";
import { Tag } from "primereact/tag";

const PRODUCT_FRAGMENT = graphql`
	fragment ProductIsHiddenColumn_ProductFragment on Product {
		isHidden
	}
`;

interface OwnProps {
	productFragmentRef: ProductIsHiddenColumn_ProductFragment$key;
}

export const ProductIsHiddenColumn = ({ productFragmentRef }: OwnProps) => {
	const product = useFragment<ProductIsHiddenColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	return (
		<Tag
			value={product.isHidden ? "Ja" : "Nein"}
			severity={product.isHidden ? "success" : "info"}
		/>
	);
};
