import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type discountActionsScreen_Query } from "@relay/discountActionsScreen_Query.graphql";
import { DiscountActionsTable } from "./parts/DiscountActionsTable";
import { DiscountActionSearchContainer } from "./parts/DiscountActionSearchContext";
import { NoAccess } from "../../components/no-access";

const QUERY = graphql`
	query discountActionsScreen_Query(
		$after: String
		$before: String
		$filterByDiscountTypeKind: DiscountTypeKindInput
		$filterByActionTitle: String
		$filterByCode: String
		$first: Int
		$last: Int
	) {
		...DiscountActionsTable_QueryFragment
			@arguments(
				first: $first
				last: $last
				before: $before
				after: $after
				filterByDiscountTypeKind: $filterByDiscountTypeKind
				filterByActionTitle: $filterByActionTitle
				filterByCode: $filterByCode
			)
	}
`;
export const DiscountActionsScreen = () => {
	const query = useLazyLoadQuery<discountActionsScreen_Query>(QUERY, { first: 20 });

	const canRead = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Read"]);

	const canModify = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Modify"]);

	if (!canRead) return <NoAccess />;

	return (
		<div>
			<h2>Discount Aktionen</h2>
			<DiscountActionSearchContainer>
				<DiscountActionsTable
					discountActionsQueryFragment={query}
					canModifyDiscountActions={canModify}
				/>
			</DiscountActionSearchContainer>
		</div>
	);
};
