import { useFormik } from "formik";
import React, { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { inAppNotificationForm_InAppNotificationFragment$key } from "@relay/inAppNotificationForm_InAppNotificationFragment.graphql";
import { IN_APP_NOTIFICATION_FRAGMENT } from "@screens/message-definition-edit/parts/in-app-notification-form/in-app-notification-form.graphql";
import {
	InAppNotificationFormProps,
	InAppNotificationFormRef,
	InAppNotificationFormState,
} from "./in-app-notification-form.types";

export const InAppNotificationForm = forwardRef<
	InAppNotificationFormRef,
	InAppNotificationFormProps
>(function InAppNotificationForm({ messageDefinitionRef, onSubmit }, ref) {
	const data = useFragment<inAppNotificationForm_InAppNotificationFragment$key>(
		IN_APP_NOTIFICATION_FRAGMENT,
		messageDefinitionRef ?? null,
	);
	const initialData = {
		title: data?.inAppNotification?.title ?? "",
		content: data?.inAppNotification?.content ?? "",
	};

	const formik = useFormik<InAppNotificationFormState>({
		initialValues: initialData,
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: formik.submitForm,
		validate: () => formik.validateForm().then((errors) => !errors),
	}));

	return (
		<>
			<ValidatedField<InAppNotificationFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<InAppNotificationFormState, string>
				name={"content"}
				label={"Inhalt"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
		</>
	);
});
