import { readInlineData, usePaginationFragment } from "react-relay";

import {
	type selectExternalLicensePoolField_ExternalLicensePoolFragment$data,
	type selectExternalLicensePoolField_ExternalLicensePoolFragment$key,
} from "@relay/selectExternalLicensePoolField_ExternalLicensePoolFragment.graphql";
import { type selectExternalLicensePoolField_QueryFragment$key } from "@relay/selectExternalLicensePoolField_QueryFragment.graphql";
import { type selectExternalLicensePoolField_Refetch } from "@relay/selectExternalLicensePoolField_Refetch.graphql";
import {
	EXTERNAL_LICENSE_POOL_FRAGMENT,
	QUERY_FRAGMENT,
} from "./select-external-license-pool-field.graphql";
import {
	type SelectExternalLicensePoolFieldProps,
	type SelectExternalLicensePoolFieldState,
} from "./select-external-license-pool-field.types";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";
import { SelectComplexDataField } from "../../../components/select-complex-data-field";

export const SelectExternalLicensePoolField = ({
	queryFragmentRef,
	...props
}: SelectExternalLicensePoolFieldProps) => {
	const {
		data: {
			Admin: {
				Billing: { GetExternalLicensePools },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectExternalLicensePoolField_Refetch,
		selectExternalLicensePoolField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ nameMatchRegex: search });
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectExternalLicensePoolField_ExternalLicensePoolFragment$data>,
	) => {
		props.updateField({ id: event.value.id, poolName: event.value.data.name });
	};

	const values =
		GetExternalLicensePools.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<selectExternalLicensePoolField_ExternalLicensePoolFragment$key>(
					EXTERNAL_LICENSE_POOL_FRAGMENT,
					e!.node,
				),
			) ?? [];

	return (
		<SelectComplexDataField<SelectExternalLicensePoolFieldState>
			valueField="poolName"
			valuePlaceholder="Keinen externen Lizenzpool ausgewählt"
			actionButtonLabel="Externen Lizenzpool auswählen"
			headerLabel="Wähle einen externen Lizenzpool aus"
			{...props}
		>
			<SearchableTable
				selectionMode="single"
				selection={props.fieldValue}
				dataKey="id"
				value={values}
				hasPrevious={hasPrevious}
				hasNext={hasNext}
				loadPrevious={handleLoadPrevious}
				loadNext={handleLoadNext}
				onSearch={handleOnSearch}
				onSelectionChange={handleOnSelectionChange}
			>
				{(Column) => (
					<>
						<Column header="Name" field="data.name" />
					</>
				)}
			</SearchableTable>
		</SelectComplexDataField>
	);
};
