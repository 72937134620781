/**
 * @generated SignedSource<<5a211353ea80f504b782fb0e172a3068>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateMessageDispatchInput = {
  clientMutationId?: string | null;
  messageDefinitionId: string;
};
export type createMessageDispatchButton_CreateMessageDispatchMutation$variables = {
  input: CreateMessageDispatchInput;
};
export type createMessageDispatchButton_CreateMessageDispatchMutation$data = {
  readonly Admin: {
    readonly Message: {
      readonly createMessageDispatch: {
        readonly messageDispatch: {
          readonly node: {
            readonly id: string;
          };
        };
      } | null;
    };
  };
};
export type createMessageDispatchButton_CreateMessageDispatchMutation = {
  response: createMessageDispatchButton_CreateMessageDispatchMutation$data;
  variables: createMessageDispatchButton_CreateMessageDispatchMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Message",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "CreateMessageDispatchPayload",
            "kind": "LinkedField",
            "name": "createMessageDispatch",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageDispatchesEdge",
                "kind": "LinkedField",
                "name": "messageDispatch",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDispatch",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createMessageDispatchButton_CreateMessageDispatchMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createMessageDispatchButton_CreateMessageDispatchMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c46adc96c7bd7025a987f80ee7bbd0c5",
    "id": null,
    "metadata": {},
    "name": "createMessageDispatchButton_CreateMessageDispatchMutation",
    "operationKind": "mutation",
    "text": "mutation createMessageDispatchButton_CreateMessageDispatchMutation(\n  $input: CreateMessageDispatchInput!\n) {\n  Admin {\n    Message {\n      createMessageDispatch(input: $input) {\n        messageDispatch {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8223dccfca3c1cf996f91d8286d2bcaf";

export default node;
