import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query rewardEditor_Query {
		Admin {
			Billing {
				GetExternalLicensePools {
					...createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment
				}
			}
		}
	}
`;

export const TREE_NODE_FRAGMENT = graphql`
	fragment rewardEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				rewards {
					id
					kind
					... on ExternalLicenseReward {
						data {
							pool {
								data {
									name
								}
							}
						}
					}
					...rewardForm_RewardFragment
				}
			}
		}
		...rewardForm_TreeNodeFragment
		...createExternalLicenseRewardButton_TreeNodeFragment
		...createIhkCertificateRewardButton_TreeNodeFragment
	}
`;

export const REMOVE_REWARD_MUTATION = graphql`
	mutation rewardEditor_DeleteRewardMutation($input: DeleteRewardInput!) {
		Admin {
			Tree {
				deleteReward(input: $input) {
					contentNode {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
