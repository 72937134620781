import { Button } from "primereact/button";
import { OrderList } from "primereact/orderlist";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { type elearningContentEditor_DeleteElementV2Mutation } from "@relay/elearningContentEditor_DeleteElementV2Mutation.graphql";
import { type elearningContentEditor_RearrangeElementsMutation } from "@relay/elearningContentEditor_RearrangeElementsMutation.graphql";
import { type elementForm_ElementV2Fragment$key } from "@relay/elementForm_ElementV2Fragment.graphql";
import { CreateTextElementButton } from "@screens/educational-offer/parts/create-text-element-button";
import { ButtonSetClass } from "@screens/educational-offer/parts/elearning-content-editor/elearning-content-editor.styles";
import {
	DELETE_ELEMENT_MUTATION,
	REARRANGE_ELEMENTS_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./elearning-content-editor.graphql";
import { type ELearningContentEditorProps } from "./elearning-content-editor.types";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { selectArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedSelector } from "../../../../store/store.redux";
import { ELEMENT_TYPE_TRANSLATIONS } from "../../../../translations/element-type";
import { htmlTagsInTextRemover } from "../../../../util/htmlTagsInTextRemover";
import { BaseContentEditor } from "../BaseContentEditor";
import { CreateClozeTextElementButton } from "../create-cloze-text-element-button";
import { CreateEnhancedTextElementButton } from "../create-enhanced-text-element-button/CreateEnhancedTextElementButton.component";
import { CreateFileElementButton } from "../create-file-element-button";
import { CreateMatrixElementButton } from "../create-matrix-element-button";
import { CreateOrderElementButton } from "../create-order-element-button";
import { CreatePodcastWithTimestampElementButton } from "../create-podcast-with-timestamp-element-button";
import { CreateMarkMistakeElementButton } from "../CreateMarkMistakeElementButton";
import { CreateMultipleChoiceElementButton } from "../CreateMultipleChoiceElementButton";
import { CreateVideoElementButton } from "../CreateVideoElementButton";
import { ElementForm } from "../element-form";

export const ELearningContentEditor = ({ treeNodeFragmentRef }: ELearningContentEditorProps) => {
	const node = useFragment(TREE_NODE_FRAGMENT, treeNodeFragmentRef);
	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);
	const [rearrangeElements, isRearrangingElements] =
		useMutation<elearningContentEditor_RearrangeElementsMutation>(REARRANGE_ELEMENTS_MUTATION);
	const [deleteElement, isDeletingElement] =
		useMutation<elearningContentEditor_DeleteElementV2Mutation>(DELETE_ELEMENT_MUTATION);
	const [showDialog, setShowDialog] = useState(false);
	const [currentlyEditingElement, setCurrentlyEditingElement] = useState<
		elementForm_ElementV2Fragment$key | undefined
	>();

	return currentlyEditingElement ? (
		<>
			{showDialog && (
				<WarningUnsavedChangesDialog
					setShowDialog={setShowDialog}
					callback={setCurrentlyEditingElement}
					value={undefined}
				/>
			)}
			<ElementForm
				onBack={() => {
					if (arrayOfEditedForm.length > 0) {
						setShowDialog(true);
					} else {
						setCurrentlyEditingElement(undefined);
					}
				}}
				elementFragmentRef={currentlyEditingElement}
			/>
		</>
	) : (
		<BaseContentEditor>
			<div className={ButtonSetClass}>
				<CreateTextElementButton eLearningContentNodeId={node.id} />
				<CreateFileElementButton eLearningContentNodeId={node.id} />
				<CreateVideoElementButton eLearningContentNodeId={node.id} />
				<CreateMultipleChoiceElementButton eLearningContentNodeId={node.id} />
				<CreateMarkMistakeElementButton eLearningContentNodeId={node.id} />
				<CreatePodcastWithTimestampElementButton eLearningContentId={node.id} />
				<CreateMatrixElementButton eLearningContentNodeId={node.id} />
				<CreateEnhancedTextElementButton eLearningContentNodeId={node.id} />
				<CreateOrderElementButton eLearningContentId={node.id} />
				<CreateClozeTextElementButton eLearningContentNodeId={node.id} />
			</div>
			<OrderList
				dragdrop={false}
				onChange={(e) =>
					rearrangeElements({
						variables: {
							input: {
								eLearningContentNodeId: node.id,
								newElementIds: e.value.map((e: any) => e.id!),
							},
						},
					})
				}
				itemTemplate={(item) => (
					<div
						className="flex align-items-center"
						onDoubleClick={() => {
							setCurrentlyEditingElement(item);
						}}
					>
						{ELEMENT_TYPE_TRANSLATIONS[item.elementType]}:{" "}
						{htmlTagsInTextRemover(item.title)}
						<Button
							disabled={isDeletingElement}
							className="ml-auto"
							icon="pi pi-trash"
							onClick={() => {
								deleteElement({
									variables: {
										input: {
											elementId: item.id,
										},
									},
								});
							}}
						/>
						<Button
							disabled={isRearrangingElements || isDeletingElement}
							className="ml-2"
							icon="pi pi-pencil"
							onClick={() => {
								setCurrentlyEditingElement(item);
							}}
						/>
					</div>
				)}
				value={[...(node.typeDefinition.elements || [])]}
			/>
		</BaseContentEditor>
	);
};
