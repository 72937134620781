import { useFormik } from "formik";
import React, { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { FileSelectionField, FileV2 } from "@features/files/file-selection-field";
import { desktopNotificationForm_DesktopNotificationFragment$key } from "@relay/desktopNotificationForm_DesktopNotificationFragment.graphql";
import { DESKTOP_NOTIFICATION_FRAGMENT } from "@screens/message-definition-edit/parts/desktop-notification-form/desktop-notification-form.graphql";
import {
	DesktopNotificationFormProps,
	DesktopNotificationFormRef,
	DesktopNotificationFormState,
} from "@screens/message-definition-edit/parts/desktop-notification-form/desktop-notification-form.types";

export const DesktopNotificationForm = forwardRef<
	DesktopNotificationFormRef,
	DesktopNotificationFormProps
>(function InAppNotificationForm({ messageDefinitionRef, onSubmit }, ref) {
	const data = useFragment<desktopNotificationForm_DesktopNotificationFragment$key>(
		DESKTOP_NOTIFICATION_FRAGMENT,
		messageDefinitionRef ?? null,
	);
	const initialData = {
		title: data?.desktopNotification?.title ?? "",
		content: data?.desktopNotification?.content ?? "",
		url: data?.desktopNotification?.url ?? "",
		icon: {
			id: data?.desktopNotification?.icon.id ?? "",
			name: data?.desktopNotification?.icon.name ?? "",
		},
	};

	const formik = useFormik<DesktopNotificationFormState>({
		initialValues: initialData,
		onSubmit: (values) => {
			onSubmit?.(values);
		},
	});

	useImperativeHandle(ref, () => ({
		submit: formik.submitForm,
		validate: () => formik.validateForm().then((errors) => !errors),
	}));

	return (
		<>
			<ValidatedField<DesktopNotificationFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<DesktopNotificationFormState, string>
				name={"content"}
				label={"Inhalt"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<DesktopNotificationFormState, string>
				name={"url"}
				label={"URL"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<DesktopNotificationFormState, FileV2>
				name={"icon"}
				label={"Icon"}
				required
				component={({ fieldValue, updateField }) => {
					return (
						<FileSelectionField
							name={"media"}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
							canUploadFiles={true}
							canDeleteFiles={true}
						/>
					);
				}}
				formikConfig={formik}
			/>
		</>
	);
});
