import { graphql } from "babel-plugin-relay/macro";

export const PUBLISHINGV2_FRAGMENT = graphql`
	fragment educationalOfferPublishing_PublishingV2Fragment on PublishingV2 {
		id
		initiatedAt
		finishedAt
		messages {
			timestamp
			message
			additionalMessage
		}
		status
		canBeAcknowledged
	}
`;

export const ACKNOWLEDGE_PUBLISHINGV2_MUTATION = graphql`
	mutation educationalOfferPublishing_AcknowledgePublishingV2Mutation(
		$input: AcknowledgePublishingV2Input!
	) {
		Admin {
			PublishingV2 {
				acknowledgePublishingV2(input: $input) {
					publishing {
						...educationalOfferPublishing_PublishingV2Fragment
					}
				}
			}
		}
	}
`;
