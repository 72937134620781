import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { DataTableSelectionChangeParams } from "@components/data-table";
import { SearchableTable } from "@components/searchable-table";
import { SelectComplexDataField } from "@components/select-complex-data-field";
import {
	SelectSingleAccountFieldProps,
	SelectSingleAccountFieldState,
} from "@features/accounts/select-single-account-field/select-single-account-field.types";
import {
	selectSingleAccountField_AccountFragment$data,
	selectSingleAccountField_AccountFragment$key,
} from "@relay/selectSingleAccountField_AccountFragment.graphql";
import { selectSingleAccountField_QueryFragment$key } from "@relay/selectSingleAccountField_QueryFragment.graphql";
import { selectSingleAccountField_Refetch } from "@relay/selectSingleAccountField_Refetch.graphql";
import { ACCOUNT_FRAGMENT, QUERY_FRAGMENT } from "./select-single-account-field.graphql";

export const SelectSingleAccountField = ({
	queryFragmentRef,
	...props
}: SelectSingleAccountFieldProps) => {
	const {
		data: {
			Admin: {
				Auth: { SelectAccounts },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectSingleAccountField_Refetch,
		selectSingleAccountField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ nameOpt: search });
	};

	const handleOnSingleSelectionChange = (
		event: DataTableSelectionChangeParams<selectSingleAccountField_AccountFragment$data>,
	) => {
		props.updateField({ id: event.value.id, name: event.value.name });
	};

	const values =
		SelectAccounts.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<selectSingleAccountField_AccountFragment$key>(
					ACCOUNT_FRAGMENT,
					e!.node,
				),
			) || [];

	return (
		<SelectComplexDataField<SelectSingleAccountFieldState>
			valueField="name"
			valuePlaceholder="Keine Konto ausgewählt"
			actionButtonLabel="Konto auswählen"
			headerLabel="Wähle ein Konto aus"
			{...props}
		>
			<SearchableTable
				selectionMode="single"
				selection={props.fieldValue}
				dataKey="id"
				value={values}
				hasPrevious={hasPrevious}
				hasNext={hasNext}
				loadPrevious={handleLoadPrevious}
				loadNext={handleLoadNext}
				onSearch={handleOnSearch}
				onSelectionChange={handleOnSingleSelectionChange}
			>
				{(Column) => (
					<>
						<Column header="Name" field="name" />
					</>
				)}
			</SearchableTable>
		</SelectComplexDataField>
	);
};
