import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation } from "@relay/deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation.graphql";
import { DELETE_LIMITED_CART_DISCOUNT_MUTATION } from "./delete-limited-cart-discount-button.graphql";
import { type DeleteLimitedCartDiscountButtonProps } from "./delete-limited-cart-discount-button.interface";
import { useDialogLogic } from "../../../hooks/use-dialog-logic/use-dialog-logic.hook";

export const DeleteLimitedCartDiscountButton = ({
	connectionId,
	limitedCartDiscountId,
}: DeleteLimitedCartDiscountButtonProps) => {
	const [deleteLimitedCartDiscount, isDeletingLimitedCartDiscount] =
		useMutation<deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation>(
			DELETE_LIMITED_CART_DISCOUNT_MUTATION,
		);

	const { showDialog, dialogComponent } = useDialogLogic();

	const handleOnClick = () => {
		showDialog({
			title: "Warenkorb-Rabatt löschen",
			content: "Möchten Sie dieses Warenkorb-Rabatt wirklich löschen?",
			dialogCallback: (result) => {
				if (result === "Accept") {
					deleteLimitedCartDiscount({
						variables: {
							input: {
								ids: [limitedCartDiscountId],
							},
							connections: [connectionId],
						},
					});
				}
			},
		});
	};

	return (
		<>
			{dialogComponent}
			<Button
				icon={"pi pi-trash"}
				onClick={handleOnClick}
				className="ml-auto"
				disabled={isDeletingLimitedCartDiscount}
			/>
		</>
	);
};
