import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useLazyLoadQuery } from "react-relay";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import { SelectMultipleRootNodesField } from "@features/educational-offer/select-multiple-root-nodes-field";
import { SelectMultipleRootNodesFieldState } from "@features/educational-offer/select-multiple-root-nodes-field/select-multiple-root-nodes-field.types";

import { inputFieldClass } from "@features/educational-offer/select-multiple-root-nodes-form/select-multiple-root-nodes-form.styles";
import { selectMultipleRootNodesForm_PublishedRootQuery } from "@relay/selectMultipleRootNodesForm_PublishedRootQuery.graphql";
import { QUERY } from "./select-multiple-root-nodes-form.graphql";
import {
	SelectMultipleRootNodesFormProps,
	SelectMultipleRootNodesFormState,
} from "./select-multiple-root-nodes-form.types";

export const SelectMultipleRootNodesForm = ({
	onRootNodesSelected,
	disabled,
}: SelectMultipleRootNodesFormProps) => {
	const data = useLazyLoadQuery<selectMultipleRootNodesForm_PublishedRootQuery>(
		QUERY,
		{},
		{ fetchPolicy: "store-or-network" },
	);

	const formik = useFormik<SelectMultipleRootNodesFormState>({
		initialValues: {},
		validationSchema: Yup.object().shape({
			publishedRoots: Yup.array()
				.of(
					Yup.object().shape({
						id: Yup.string(),
						title: Yup.string(),
					}),
				)
				.min(1, "Mindestens ein Kurs wird benötigt.")
				.required("Bitte wähle mindestens einen Kurs aus."),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onRootNodesSelected(data.publishedRoots!.map((account) => account.id!));
			setSubmitting(false);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<ValidatedField<SelectMultipleRootNodesFormState, SelectMultipleRootNodesFieldState>
				className={inputFieldClass}
				name={"publishedRoots"}
				label={"Wähle eine Weiterbildung aus"}
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectMultipleRootNodesField {...renderConfig} queryFragmentRef={data} />
				)}
			/>
			<Button
				disabled={!formik.isValid || disabled}
				label="Weiterbildung hinzufügen"
				type="submit"
			/>
		</form>
	);
};
