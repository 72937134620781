import { Button } from "primereact/button";
import { useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type settingsScreen_Query } from "@relay/settingsScreen_Query.graphql";
import { type settingsScreen_SetGlobalDiscountCodeMutation } from "@relay/settingsScreen_SetGlobalDiscountCodeMutation.graphql";
import { QUERY, SET_GLOBAL_DISCOUNT_CODE_MUTATION } from "./settings.graphql";
import { NoAccess } from "../../components/no-access";
import { SelectDiscountCodeField } from "../../features/discount-codes/select-discount-code-field";
import { type DiscountCode } from "../../features/discount-codes/select-discount-code-field/select-discount-code-field.interface";
import { BaseScreen } from "../BaseScreen";
export const SettingsScreen = () => {
	const query = useLazyLoadQuery<settingsScreen_Query>(QUERY, {});

	const [globalDiscountCode, setGlobalDiscountCode] = useState<DiscountCode | undefined>(
		query.Admin.Settings.Settings?.globalDiscountCode as DiscountCode,
	);

	const isRoot = useHasPermissions("onlyOwnerOfRoot");

	const [dispatchSetGlobalDiscountCodeId] =
		useMutation<settingsScreen_SetGlobalDiscountCodeMutation>(
			SET_GLOBAL_DISCOUNT_CODE_MUTATION,
		);

	const handleSubmit = () => {
		dispatchSetGlobalDiscountCodeId({
			variables: {
				input: {
					discountCodeId: globalDiscountCode?.id,
				},
			},
			onCompleted: () => {
				toast.info("Globaler Rabatt gespeichert.");
			},
		});
	};

	const handleOnChange = (discountCode?: DiscountCode) => {
		setGlobalDiscountCode(discountCode);
	};

	if (!isRoot) return <NoAccess />;

	return (
		<BaseScreen>
			<h1>Settings</h1>

			<div className="w-full">
				<div className="col-12 col-sm-2 pl-0">
					<label htmlFor="value" className="col-form-label">
						{"Globaler Rabatt"}
					</label>
				</div>
				<SelectDiscountCodeField
					fieldValue={globalDiscountCode}
					updateField={handleOnChange}
					fieldName={"discount-code"}
					required={false}
					isValid={true}
					disabled={false}
				/>

				<Button disabled={false} type="button" onClick={handleSubmit} icon="pi pi-save" />
			</div>
		</BaseScreen>
	);
};
