import { type PathParams } from "@thekeytechnology/epic-ui";
import { Accordion, AccordionTab } from "primereact/accordion";
import { BreadCrumb } from "primereact/breadcrumb";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type discountActionEditScreen_Query } from "@relay/discountActionEditScreen_Query.graphql";
import { Paths } from "@routes/paths";
import { type DiscountActionsPath } from "@screens/discount-actions";
import { QUERY } from "./discount-action-edit.graphql";
import { DiscountActionForm } from "./parts/discount-action-form";
import { DiscountCodeTable } from "./parts/DiscountCodeTable";
import { NoAccess } from "../../components/no-access";

export const DiscountActionEditScreen = () => {
	const [activeIndex, setActiveIndex] = useState(0);

	const { discountActionId } = useParams<PathParams<typeof DiscountActionsPath>>();

	const query = useLazyLoadQuery<discountActionEditScreen_Query>(QUERY, {
		id: discountActionId!,
	});

	const canRead = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Read"]);

	const canModify = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Modify"]);

	const items: Array<{ label?: string; url: string }> = [
		{ label: "Alle Aktionen", url: Paths.discountActions.path },
	];

	if (canModify)
		items.push({
			label: query.node?.title,
			url: Paths.discountActions.withId(discountActionId!).edit.path,
		});

	if (!canRead) return <NoAccess />;

	return (
		query.node && (
			<div>
				<BreadCrumb className="mb-5" model={items} />
				<Accordion
					activeIndex={activeIndex}
					onTabChange={(e) => {
						setActiveIndex(e.index as number);
					}}
				>
					{canModify && (
						<AccordionTab header="Discount-Aktion" tabIndex={0}>
							<DiscountActionForm discountActionFragmentRef={query.node} />
						</AccordionTab>
					)}
					<AccordionTab header="Codes" tabIndex={1}>
						<DiscountCodeTable
							query={query}
							discountActionId={discountActionId!}
							canModifyDiscountCodes={canModify}
						/>
					</AccordionTab>
				</Accordion>
			</div>
		)
	);
};
