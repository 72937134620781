import styled from "styled-components";

export const PaymentMethodIcon = styled.i`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 25px;
	margin-right: 10px;
`;

export const PaymentMethodImage = styled.img`
	width: 45px;
	height: 25px;
	margin-right: 10px;
`;
