import { Paths } from "@routes/paths";
import { TaskEditRoutes } from "@screens/task-edit";
import { TasksScreen } from "./tasks.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const TasksRoutes: RouteDefinition[] = [
	{
		path: Paths.tasks.path,
		element: <TasksScreen />,
		requiredPermissions: ["UserInAccountPermission_AsyncElementTaskAdmin_Read"],
	},
	...TaskEditRoutes,
];
