import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import {
	type LimitedCartDiscountTableFilterProps,
	type LimitedCartDiscountTableFilterState,
} from "./limited-cart-discount-table-filter.interface";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";

export const LimitedCartDiscountTableFilter = ({
	refetch,
}: LimitedCartDiscountTableFilterProps) => {
	const initialValues = {
		title: undefined,
	};

	const formik = useFormik<LimitedCartDiscountTableFilterState>({
		initialValues,
		onSubmit: (values: LimitedCartDiscountTableFilterState, { setSubmitting }) => {
			refetch(values.title);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<LimitedCartDiscountTableFilterState, string>
					name="title"
					label="Titel"
					className="mr-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={clearForm}
					icon="pi pi-times"
					className="h-3rem ml-auto"
				/>
				<Button
					disabled={false}
					type="submit"
					icon="pi pi-search"
					className="h-3rem ml-2"
				/>
			</div>
		</form>
	);
};
