/**
 * @generated SignedSource<<1be6fb3c7f1125f5a7bb20fba1938987>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AnonymizeAccountInput = {
  clientMutationId?: string | null;
  id: string;
};
export type accountsTable_AnonymizeAccountAdminMutation$variables = {
  connections: ReadonlyArray<string>;
  input: AnonymizeAccountInput;
};
export type accountsTable_AnonymizeAccountAdminMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly anonymizeAccountAdmin: {
        readonly deletedIds: ReadonlyArray<string>;
      } | null;
    };
  };
};
export type accountsTable_AnonymizeAccountAdminMutation = {
  response: accountsTable_AnonymizeAccountAdminMutation$data;
  variables: accountsTable_AnonymizeAccountAdminMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "accountsTable_AnonymizeAccountAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AnonymizeAccountPayload",
                "kind": "LinkedField",
                "name": "anonymizeAccountAdmin",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "accountsTable_AnonymizeAccountAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "AnonymizeAccountPayload",
                "kind": "LinkedField",
                "name": "anonymizeAccountAdmin",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "276c8c10fb4c44557325e85360d7a184",
    "id": null,
    "metadata": {},
    "name": "accountsTable_AnonymizeAccountAdminMutation",
    "operationKind": "mutation",
    "text": "mutation accountsTable_AnonymizeAccountAdminMutation(\n  $input: AnonymizeAccountInput!\n) {\n  Admin {\n    Auth {\n      anonymizeAccountAdmin(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4bf0ac6bcf769f7c752044d69487deaf";

export default node;
