import { graphql } from "babel-plugin-relay/macro";

export const ENHANCED_TEXT_ELEMENT_FRAGMENT = graphql`
	fragment enhancedTextElementForm_EnhancedTextElementFragment on EnhancedTextElement {
		id
		title
		text
		readMore
		readMoreButtonText
	}
`;

export const EDIT_ENHANCED_TEXT_ELEMENT_MUTATION = graphql`
	mutation enhancedTextElementForm_EditEnhancedTextElementMutation(
		$input: EditEnhancedTextElementInput!
	) {
		Admin {
			ElearningV2 {
				editEnhancedTextElement(input: $input) {
					enhancedTextElement {
						...enhancedTextElementForm_EnhancedTextElementFragment
					}
				}
			}
		}
	}
`;
