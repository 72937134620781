/**
 * @generated SignedSource<<5c155e141b6d2491f94fd4dd8338d939>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OrderItemForm_OrderItemFragment$data = {
  readonly id: string;
  readonly text: string;
  readonly " $fragmentSpreads": FragmentRefs<"EditOrderItemButton_OrderItemFragment" | "RemoveOrderItemButton_OrderItemFragment">;
  readonly " $fragmentType": "OrderItemForm_OrderItemFragment";
};
export type OrderItemForm_OrderItemFragment$key = {
  readonly " $data"?: OrderItemForm_OrderItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"OrderItemForm_OrderItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OrderItemForm_OrderItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditOrderItemButton_OrderItemFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RemoveOrderItemButton_OrderItemFragment"
    }
  ],
  "type": "OrderItem",
  "abstractKey": null
};

(node as any).hash = "a472bf3c43a5215b55656f6252287e8e";

export default node;
