import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { type AcademiesTagFormProps, type AcademiesTagFormState } from "./academies-tag-form.types";
import {
	DefaultSwitchComponent,
	DefaultTextFieldComponent,
} from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { addEditedFormToEditedFormsArray } from "../../../../store/slices/CoreSlice";

export const AcademiesTagForm = ({ onSubmit, initialValues, isLoading }: AcademiesTagFormProps) => {
	const dispatch = useDispatch();

	const formId = "AcademiesTagForm";
	const formik = useFormik<AcademiesTagFormState>({
		initialValues: {
			name: initialValues?.name || "",
			isTopic: initialValues?.isTopic || false,
			isClickable: initialValues?.isClickable || false,
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
			isClickable: Yup.boolean().required("ist klickbar wird benötigt"),
			isTopic: Yup.boolean().required("ist Thema wird benötigt"),
		}),
		onSubmit,
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<AcademiesTagFormState, string>
				name={"name"}
				label={"Name"}
				component={DefaultTextFieldComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<ValidatedField<AcademiesTagFormState, boolean>
				name={"isClickable"}
				label={"ist Klickbar"}
				component={DefaultSwitchComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<ValidatedField<AcademiesTagFormState, boolean>
				name={"isTopic"}
				label={"ist Thema"}
				component={DefaultSwitchComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<Button disabled={isLoading} type="submit" label="Speichern" className="p-mt-2" />
		</form>
	);
};
