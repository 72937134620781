import { Button } from "primereact/button";
import { Suspense } from "react";
import { useNavigate } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { Paths } from "@routes/paths";
import { AccountsTable } from "./parts/accounts-table";
import { Card } from "../../components/card";
import { NoAccess } from "../../components/no-access";
import { BaseScreen } from "../BaseScreen";
export const AccountsScreen = () => {
	const navigate = useNavigate();

	const handleCreateAccountOnClick = () => {
		navigate(Paths.accounts.withId("new").edit.path);
	};

	const canModify = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	const canRead = useHasPermissions(["UserInAccountPermission_AuthAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<BaseScreen>
			<Card
				header={
					<div className="flex p-3 align-items-center justify-content-between card-flat">
						<h1 className="mt-0 mr-3 mb-0 ml-0">Konten</h1>
						{canModify && (
							<Button label="Konto erstellen" onClick={handleCreateAccountOnClick} />
						)}
					</div>
				}
			>
				<Suspense fallback={<div>Lade...</div>}>
					<AccountsTable />
				</Suspense>
			</Card>
		</BaseScreen>
	);
};
