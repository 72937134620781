import graphql from "babel-plugin-relay/macro";

export const BUSINESS_BILLING_DETAILS_FRAGMENT = graphql`
	fragment businessBillingDetailsForm_BillingDetailsFragment on BusinessBillingDetails {
		firstName
		lastName
		street
		houseNumber
		postalCode
		city
		companyDetails
		country
		salutation
		invoiceEmail
		company
		companyType
	}
`;
