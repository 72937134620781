import { useContext, useRef, useState } from "react";
import { readInlineData, useFragment, useMutation } from "react-relay";
import { Card } from "@components/card";
import { ConfirmWithTextDialog } from "@components/confirm-with-text-dialog";
import { SortableTree, type TreeItem } from "@components/sortable-tree";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type educationalOfferTree_MoveChildNodeMutation } from "@relay/educationalOfferTree_MoveChildNodeMutation.graphql";
import { type educationalOfferTree_RemoveNodeFromTreeMutation } from "@relay/educationalOfferTree_RemoveNodeFromTreeMutation.graphql";
import {
	type educationalOfferTree_TreeNodeFragment$data,
	type educationalOfferTree_TreeNodeFragment$key,
} from "@relay/educationalOfferTree_TreeNodeFragment.graphql";
import {
	DELETE_CHILD_NODE_MUTATION,
	MOVE_CHILD_NODE_MUTATION,
	PUBLISHINGV2_FRAGMENT,
	TREE_FRAGMENT,
	TREE_NODE_FRAGMENT,
} from "./educational-offer-tree.graphql";
import { type EducationalOfferTreeProps } from "./educational-offer-tree.types";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { selectArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedSelector } from "../../../../store/store.redux";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../translations/tree";
import { AddNodeButton } from "../add-node-button/add-node-button.component";
import { ElementNode } from "../ElementNode";
import { Node } from "../Node";
import { StartPublishingButton } from "../StartPublishingButton";
import { TreeSelectionContext } from "../TreeSelectionContext";

export const EducationalOfferTree = ({
	treeFragmentRef,
	publishingV2FragmentRef,
}: EducationalOfferTreeProps) => {
	const { selectedNodeId, setSelectedNodeId } = useContext(TreeSelectionContext);
	const tree = useFragment(TREE_FRAGMENT, treeFragmentRef);
	const publishing = useFragment(PUBLISHINGV2_FRAGMENT, publishingV2FragmentRef);

	const [moveChildNode] =
		useMutation<educationalOfferTree_MoveChildNodeMutation>(MOVE_CHILD_NODE_MUTATION);
	const [deleteChildNode] = useMutation<educationalOfferTree_RemoveNodeFromTreeMutation>(
		DELETE_CHILD_NODE_MUTATION,
	);

	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const nodes: educationalOfferTree_TreeNodeFragment$data[] = tree.nodes.map((node) =>
		readInlineData<educationalOfferTree_TreeNodeFragment$key>(TREE_NODE_FRAGMENT, node),
	);

	const selectedNode = nodes.find((n) => n.id === selectedNodeId);

	const treeExpandedKeys = selectedNode ? determineExpandedKeys(selectedNode, nodes) : [];

	const [expandedKeys, setExpandedKeys] = useState<string[]>(treeExpandedKeys);
	const [selectedId, setSelectedId] = useState("");
	const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);
	const [showConfirmMoveDialog, setShowConfirmMoveDialog] = useState(false);
	const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
	const [textToConfirm, setTextToConfirm] = useState("");
	const allowNodeMoveRef = useRef<(allow?: boolean) => void>();

	const rootNode = nodes.find((node) => node.id === tree.rootNode?.id);

	const canPublish = useHasPermissions(["UserInAccountPermission_Nodes_PublishNodes"]);
	const canCreate = useHasPermissions(["UserInAccountPermission_Nodes_CreateNodes"]);
	const canUpdate = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);
	const canDelete = useHasPermissions(["UserInAccountPermission_Nodes_DeleteNodes"]);

	if (!rootNode) return null;
	const newTree = [buildTree(nodes, rootNode, selectedNode)];

	const hasBeenPublishedOnce = rootNode.hasBeenPublishedOnce;
	const hasPublishingAndBeenAcknowledged = publishing?.status
		? publishing.status === "acknowledged"
		: true;

	const handleNodeClick = (id: string) => {
		if (arrayOfEditedForms.length > 0) {
			setSelectedId(id);
			setShowUnsavedChangesDialog(true);
		} else {
			setSelectedNodeId(id);
		}
	};

	const moveNode = (id: string, parentId: string, previousSiblingId?: string) => {
		moveChildNode({
			variables: {
				input: {
					childToBeMovedId: id,
					newParentId: parentId,
					newPreviousSiblingId: previousSiblingId ?? undefined,
				},
			},
		});
	};

	const handleNodeMove = (id: string, parentId: string, previousSiblingId?: string) => {
		moveNode(id, parentId, previousSiblingId);
		allowNodeMoveRef.current = undefined;
	};

	const handleShouldDropBeAllowed = async (id: string) => {
		return await new Promise<boolean>((resolve) => {
			if (!canUpdate) {
				resolve(false);
				return;
			}
			const needsConfirmMoveDialog = hasBeenPublishedOnce;

			if (needsConfirmMoveDialog) {
				const nodeToMove = nodes.find((n) => n.id === id);
				setTextToConfirm(nodeToMove?.structureDefinition.title ?? "");
				setShowConfirmMoveDialog(true);
				setSelectedId(id);
				allowNodeMoveRef.current = (allow = true) => {
					resolve(allow);
				};
				return;
			}

			resolve(true);
		});
	};

	const removeNode = (id: string) => {
		if (id === selectedNodeId) {
			const nodeToRemove = nodes.find((n) => n.id === id);
			setSelectedNodeId(nodeToRemove?.parentId ?? undefined);
		}

		deleteChildNode({
			variables: {
				input: {
					nodeId: id,
				},
			},
		});
	};

	const handleNodeRemove = (id: string) => {
		if (!canDelete) return;
		const needsConfirmDeleteDialog = hasBeenPublishedOnce;

		if (needsConfirmDeleteDialog) {
			const nodeToRemove = nodes.find((n) => n.id === id);
			setTextToConfirm(nodeToRemove?.structureDefinition.title ?? "");
			setShowConfirmDeleteDialog(true);
			setSelectedId(id);
			return;
		}

		removeNode(id);
	};

	return (
		<>
			{showUnsavedChangesDialog && (
				<WarningUnsavedChangesDialog
					setShowDialog={setShowUnsavedChangesDialog}
					callback={setSelectedNodeId}
					value={selectedId}
				/>
			)}
			{showConfirmDeleteDialog && (
				<ConfirmWithTextDialog
					title={`Sind Sie sicher, dass Sie "${textToConfirm}" löschen möchten?`}
					content={
						<div>
							Sie löschen damit Fortschritte welche von Nutzern in diesem Modul
							gemacht wurden.
						</div>
					}
					setShowDialog={setShowConfirmDeleteDialog}
					onCancel={() => {
						setShowConfirmDeleteDialog(false);
					}}
					onConfirm={() => {
						removeNode(selectedId);
					}}
					textToConfirm={textToConfirm}
				/>
			)}
			{showConfirmMoveDialog && (
				<ConfirmWithTextDialog
					title={`Sind Sie sicher, dass Sie "${textToConfirm}" verschieben möchten?`}
					content={
						<div>
							Sie löschen damit Fortschritte welche von Nutzern in diesem Modul
							gemacht wurden.
						</div>
					}
					setShowDialog={setShowConfirmMoveDialog}
					onCancel={() => {
						setShowConfirmMoveDialog(false);
						allowNodeMoveRef.current?.(false);
					}}
					onConfirm={() => {
						allowNodeMoveRef.current?.(true);
					}}
					textToConfirm={textToConfirm}
				/>
			)}
			<Card>
				<div className="flex flex-column justify-content-end mb-2 align-items-start">
					<h2 className="mr-auto">
						{TREE_I18N_MAP(TREE_I18N_KEY.tree)} {rootNode.structureDefinition.title}
					</h2>
					{canCreate && (
						<AddNodeButton
							parentBranchId={
								selectedNode?.typeDefinition.definitionType === "branch"
									? selectedNodeId
									: undefined
							}
							onSuccessful={(newId) => {
								if (selectedNodeId) {
									setExpandedKeys((prevExpandedKeys) => [
										...prevExpandedKeys,
										selectedNodeId,
									]);
								}

								setSelectedNodeId(newId);
							}}
						/>
					)}
					<div className="flex flex-1 mt-2 mb-2">
						{canPublish && (
							<StartPublishingButton className="mr-2" rootNodeId={rootNode.id} />
						)}
					</div>
				</div>
				<SortableTree
					expandedKeys={expandedKeys}
					onExpand={(keys) => {
						setExpandedKeys(keys);
					}}
					defaultItems={newTree}
					removable={canDelete && hasPublishingAndBeenAcknowledged}
					dragable={canUpdate && hasPublishingAndBeenAcknowledged}
					onNodeClick={handleNodeClick}
					onNodeMove={handleNodeMove}
					onRemove={handleNodeRemove}
					shouldDropBeAllowed={handleShouldDropBeAllowed}
					nodeTemplate={(node) => {
						if (node.elementType) {
							return {
								node: <ElementNode key={node.key} elementFragmentRef={node} />,
								type: "element",
							};
						}
						return {
							node: <Node key={node.key} treeNodeFragmentRef={node} />,
						};
					}}
				/>
			</Card>
		</>
	);
};

const buildTree = (
	nodes: readonly educationalOfferTree_TreeNodeFragment$data[],
	currentNode: educationalOfferTree_TreeNodeFragment$data,
	selectedNode?: educationalOfferTree_TreeNodeFragment$data,
	depth = 0,
): TreeItem => {
	let children: TreeItem[] = [];

	switch (currentNode.typeDefinition.definitionType) {
		case "branch":
			children =
				currentNode.typeDefinition.childRefs?.map((childId) =>
					buildTree(
						nodes,
						nodes.find((node) => childId === node.id)!,
						selectedNode,
						(depth = depth + 1),
					),
				) ?? [];
			break;
		case "content":
			switch (currentNode.typeDefinition.contentKind) {
				case "ELearning":
					children =
						currentNode.typeDefinition.elements?.map((el) => ({
							id: el.id,
							children: [],
							data: el,
						})) ?? [];
					break;
				case "Async":
					children =
						currentNode.typeDefinition.elements?.map((el) => ({
							id: el.id,
							children: [],
							data: el,
						})) ?? [];
					break;
				default:
					children = [];
					break;
			}
			break;
		default:
			children = [];
			break;
	}

	return {
		id: currentNode.id,
		children,
		data: currentNode,
	};
};

function determineExpandedKeys(
	selectedNode: educationalOfferTree_TreeNodeFragment$data,
	tree: readonly educationalOfferTree_TreeNodeFragment$data[],
) {
	const initiallyOpenedContainers = [];

	let node: educationalOfferTree_TreeNodeFragment$data | undefined = selectedNode;
	while (node) {
		initiallyOpenedContainers.push(node.id);
		node = tree.find((c) => c.id === node?.structureDefinition.coordinates.parentRef);
	}
	return initiallyOpenedContainers;
}
