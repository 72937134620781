import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { CurrencyDisplay } from "@components/CurrencyDisplay";
import { type RootCostInputFieldProps } from "./root-cost-input-field.interface";
import { RootCostForm, type RootCostFormState } from "../root-cost-form";

export const RootCostInputField = ({
	fieldValue,
	updateField,
	disabled,
	onChange,
}: RootCostInputFieldProps) => {
	const [isDialogVisible, setIsDialogVisible] = useState(false);

	const setValues = (values?: RootCostFormState) => {
		updateField(values);
		if (onChange) {
			onChange();
		}
	};

	const handleDialogOnHide = () => {
		setIsDialogVisible(false);
	};

	const handleOnSubmit = (values: RootCostFormState) => {
		setIsDialogVisible(false);
		updateField(values);
		if (onChange) {
			onChange();
		}
	};

	const handleClearOnClick = () => {
		setValues();
	};

	const handleEditOnClick = () => {
		setIsDialogVisible(true);
	};

	const hasValues = fieldValue?.supplier !== undefined && fieldValue?.price !== undefined;

	const inputValuePreview = hasValues ? (
		<>
			{fieldValue?.supplier}:&nbsp;
			<CurrencyDisplay value={fieldValue?.price} />
		</>
	) : (
		"Keine Kosten festgelegt"
	);
	return (
		<>
			<div className="flex">
				<div className="flex-grow-1 flex bg-gray-100 border-gray-300 border-1 border-round  px-3 py-2">
					{inputValuePreview}
				</div>
				<Button
					className="w-auto p-button-secondary p-2 ml-2"
					type={"button"}
					disabled={!hasValues || disabled}
					label={""}
					icon="pi pi-times"
					onClick={handleClearOnClick}
				/>
				<Button
					className="w-auto ml-2"
					type={"button"}
					disabled={disabled}
					onClick={handleEditOnClick}
				>
					Kosten festlegen
				</Button>
			</div>
			<Dialog header="Kosten" onHide={handleDialogOnHide} visible={isDialogVisible}>
				<RootCostForm
					initialValues={{
						price: fieldValue?.price ?? 0,
						percentageShare: fieldValue?.percentageShare ?? 0,
						supplier: fieldValue?.supplier ?? "",
					}}
					onSubmit={handleOnSubmit}
				/>
			</Dialog>
		</>
	);
};
