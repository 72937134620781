import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useMutation } from "react-relay";
import { type createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation } from "@relay/createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation.graphql";
import { CREATE_PERCENTAGE_DISCOUNT_ACTION_MUTATION } from "./create-percentage-discount-action-button.graphql";
import { type CreatePercentageDiscountActionButtonProps } from "./create-percentage-discount-action-button.types";

export const CreatePercentageDiscountActionButton = ({
	connectionId,
	className,
}: CreatePercentageDiscountActionButtonProps) => {
	const [createPercentageDiscountAction, isCreatingPercentageDiscountAction] =
		useMutation<createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation>(
			CREATE_PERCENTAGE_DISCOUNT_ACTION_MUTATION,
		);

	const overlayPanel = useRef<OverlayPanel>(null);
	const nameOfAction = useRef<any>(null);
	const toast = useRef<any>(null);

	const handleOnClick = () => {
		createPercentageDiscountAction({
			variables: {
				input: {
					title: nameOfAction.current.value || "Neu",
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Aktion erstellt",
					detail: "Discountaktion wurde erfolgreich erstellt",
				});
			},
		});
	};

	return (
		<>
			<Toast ref={toast} />
			<Button
				label="Neu"
				loading={isCreatingPercentageDiscountAction}
				disabled={isCreatingPercentageDiscountAction}
				className={className}
				onClick={(e) => overlayPanel.current?.toggle(e)}
			/>
			<OverlayPanel ref={overlayPanel}>
				<span className="p-float-label mb-2 mt-3">
					<InputText id="nameOfAction" ref={nameOfAction} />
					<label htmlFor="nameOfAction">Name der Aktion</label>
				</span>
				<Button
					label="Aktion erstellen"
					loading={isCreatingPercentageDiscountAction}
					disabled={isCreatingPercentageDiscountAction}
					onClick={handleOnClick}
				/>
			</OverlayPanel>
		</>
	);
};
