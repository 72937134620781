import { graphql } from "babel-plugin-relay/macro";

export const MULTIPLE_CHOICE_ELEMENTV2_FRAGMENT = graphql`
	fragment multipleChoiceElementForm_MultipleChoiceElementV2Fragment on MultipleChoiceElementV2 {
		id
		title
		question
		image {
			id
			name
		}
		answeringType
		answeringTypeText
		answerOptions {
			answer
			isCorrect
			image {
				id
				name
			}
		}
	}
`;

export const EDIT_MULTIPLE_CHOICE_ELEMENT_MUTATION = graphql`
	mutation multipleChoiceElementForm_EditMultipleChoiceElementMutation(
		$input: EditMultipleChoiceElementV2Input!
	) {
		Admin {
			ElearningV2 {
				editMultipleChoiceElement(input: $input) {
					multipleChoiceElement {
						...multipleChoiceElementForm_MultipleChoiceElementV2Fragment
					}
				}
			}
		}
	}
`;
