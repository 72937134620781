export enum ButtonVariant {
	Default = "default",
	Strong = "strong",
	Tertiary = "tertiary",
	Outline = "outline",
	Error = "error",
	Success = "success",
	Textlink = "textlink",
}

export type ButtonVariantKeys = { [key in ButtonVariant]: unknown };
