import { H2Span } from "@thekeytechnology/epic-ui";
import React, { useCallback } from "react";
import { useRefetchableFragment } from "react-relay";

import { dispatchExclusions_MessageDispatchFragment$key } from "@relay/dispatchExclusions_MessageDispatchFragment.graphql";
import { dispatchExclusions_Refetch } from "@relay/dispatchExclusions_Refetch.graphql";
import { DispatchConditionDropdown } from "@screens/message-dispatch-edit/parts/dispatch-condition-dropdown";
import { DispatchConditionPreview } from "@screens/message-dispatch-edit/parts/dispatch-conditions-preview";
import { MESSAGE_DISPATCH_FRAGMENT } from "@screens/message-dispatch-edit/parts/dispatch-exclusions/dispatch-exclusions.graphql";
import { DispatchExclusionsProps } from "@screens/message-dispatch-edit/parts/dispatch-exclusions/dispatch-exclusions.types";

export const DispatchExclusions = ({ messageDispatchFragmentRef }: DispatchExclusionsProps) => {
	const [messageDispatch, refetch] = useRefetchableFragment<
		dispatchExclusions_Refetch,
		dispatchExclusions_MessageDispatchFragment$key
	>(MESSAGE_DISPATCH_FRAGMENT, messageDispatchFragmentRef ?? null);

	const refresh = useCallback(() => {
		refetch({}, { fetchPolicy: "network-only" });
	}, [refetch]);

	return (
		<>
			<H2Span>Ausschlüsse</H2Span>
			<DispatchConditionDropdown
				isInclusion={false}
				messageDispatchFragmentRef={messageDispatch}
				onChange={refresh}
			/>
			<DispatchConditionPreview
				messageDispatchFragmentRef={messageDispatch}
				onChange={refresh}
				isInclusion={false}
			/>
		</>
	);
};
