import { type FormikState } from "formik";
import { classNames } from "primereact/utils";
import React, { type ReactNode, useCallback } from "react";

export type RenderConfig<FieldType> = {
	fieldValue: FieldType | undefined;
	updateField: (newValue: FieldType | undefined) => void;
	fieldName: string;
	required: boolean;
	isValid: boolean;
	disabled: boolean;
	placeholder?: string;
	noLineBreak?: boolean;
	onChange?: () => void;
	canUploadFiles?: boolean;
	canDeleteFiles?: boolean;
};

type ValidatedFieldPropsV2<State, FieldType> = {
	label?: string;
	name: keyof State & string;
	required?: boolean;
	disabled?: boolean;
	placeholder?: string;

	formikConfig: FormikState<State> & {
		setFieldTouched: (
			field: string,
			touched?: boolean,
			shouldValidate?: boolean | undefined,
		) => any;
		setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
	};

	iconClass?: string;
	className?: string;
	helpText?: ReactNode;
	onChange?: () => void;
	component: (renderConfig: RenderConfig<FieldType>) => ReactNode;
};

export function ValidatedField<State, FieldType>({
	className,
	iconClass,
	name,
	label,
	formikConfig,
	helpText,
	component,
	required,
	disabled,
	placeholder,
	onChange,
}: ValidatedFieldPropsV2<State, FieldType>) {
	const error = formikConfig.errors[name];
	const hasError = error && formikConfig.touched[name];
	const value = formikConfig.values[name] as unknown as FieldType;

	const updateValue = useCallback(
		(updatedValue: FieldType | undefined) => {
			formikConfig.setFieldValue(name, updatedValue);
			formikConfig.setFieldTouched(name, true, false);
		},
		[name, formikConfig.setFieldTouched, formikConfig.setFieldValue],
	);

	const FieldContent = (
		<>
			{iconClass && <i className={`pi ${iconClass}`} />}

			{component({
				fieldValue: value,
				isValid: !hasError,
				fieldName: name,
				updateField: updateValue,
				required: required ?? false,
				disabled: disabled ?? false,
				placeholder,
				onChange: onChange ?? undefined,
			})}
		</>
	);

	const displayError = () => {
		if (typeof error === "object" && error !== null) {
			return Object.values(error).map((error, index) => (
				<div key={index}>{error as any}</div>
			));
		} else {
			return <div>{error}</div>;
		}
	};
	return (
		<div className={`field flex flex-column ${className ?? ""}`}>
			{label ? (
				<label htmlFor={name} className={classNames({ "p-error": hasError })}>
					{label} {required ? "*" : ""}
				</label>
			) : null}

			{iconClass ? <span className="p-input-icon-right">{FieldContent}</span> : FieldContent}

			{helpText ? <div className="text-xs mt-1">{helpText}</div> : null}

			{hasError ? <div className="p-error text-xs mt-1">{displayError()}</div> : null}
		</div>
	);
}
