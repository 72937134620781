import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { pageButtonKindOptions } from "@components/page-button-kind-dropdown/page-button-kind-dropdown.consts";
import { type RenderConfig } from "@components/ValidatedField";
import { ButtonKind } from "@relay/pageButtonForm_ButtonFragment.graphql";

export function PageButtonKindDropdown({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
	isValid,
	placeholder,
	required,
}: RenderConfig<ButtonKind>) {
	const checkIfDropdownFieldChanged = (e: any) => {
		if (e.value !== fieldValue) {
			onChange?.();
		}
	};

	const handleOnChange = (dropdownChangeEvent: DropdownChangeEvent) => {
		updateField(dropdownChangeEvent.value);
		checkIfDropdownFieldChanged(dropdownChangeEvent.value);
	};

	return (
		<Dropdown
			options={pageButtonKindOptions}
			name={fieldName}
			disabled={disabled}
			required={required}
			placeholder={placeholder}
			onChange={handleOnChange}
			value={fieldValue}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}
