import { MultiSelect, type MultiSelectChangeEvent } from "primereact/multiselect";
// import { useMemo } from "react";
// import { useLazyLoadQuery } from "react-relay";
// import { type selectAccountGroups_Query } from "@relay/selectAccountGroups_Query.graphql";
// import { QUERY } from "./select-account-groups.graphql";
import { type SelectAccountGroupsProps } from "./select-account-groups.interface";
// import { convertGroupName } from "./select-account-groups.util";

export const SelectAccountGroups = ({
	fieldValue,
	fieldName,
	updateField,
	placeholder, // accountId,
}: SelectAccountGroupsProps) => {
	// const {
	// 	Admin: {
	// 		Auth: { GetUserInAccountGroups: groups },
	// 	},
	// } = useLazyLoadQuery<selectAccountGroups_Query>(QUERY, { id: accountId });

	// const options = useMemo(
	// 	() =>
	// 		groups.map((g) => ({
	// 			label: convertGroupName(g.name),
	// 			value: g.id,
	// 		})),
	// 	[groups],
	// );

	const handleOnChange = (event: MultiSelectChangeEvent) => {
		updateField(event.value);
	};

	return (
		<MultiSelect
			name={fieldName}
			placeholder={placeholder}
			value={fieldValue}
			options={[]}
			onChange={handleOnChange}
			filter={true}
			filterBy={"label"}
		/>
	);
};
