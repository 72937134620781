import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation } from "@relay/displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation.graphql";
import { type displayMatrixElementCellForm_SetMatrixElementRowTitleMutation } from "@relay/displayMatrixElementCellForm_SetMatrixElementRowTitleMutation.graphql";
import { resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import {
	DISPLAY_MATRIX_ELEMENT_CELL_FRAGMENT,
	SET_MATRIX_ELEMENT_COLUMN_TITLE_MUTATION,
	SET_MATRIX_ELEMENT_ROW_TITLE_MUTATION,
} from "./display-matrix-element-cell-form.graphql";
import {
	type DisplayMatrixElementCellFormFormState,
	type DisplayMatrixElementCellFormProps,
} from "./display-matrix-element-cell-form.types";
import { RemoveColumnFromMatrixElementButton } from "../remove-column-from-matrix-element-button";
import { RemoveRowFromMatrixElementButton } from "../remove-row-from-matrix-element-button";

export const DisplayMatrixElementCellForm = ({
	academiesDisplayMatrixElementCellFragmentRef,
	matrixElementId,
}: DisplayMatrixElementCellFormProps) => {
	const displayCell = useFragment(
		DISPLAY_MATRIX_ELEMENT_CELL_FRAGMENT,
		academiesDisplayMatrixElementCellFragmentRef,
	);

	const [setMatrixElementColumnTitle] =
		useMutation<displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation>(
			SET_MATRIX_ELEMENT_COLUMN_TITLE_MUTATION,
		);

	const [setMatrixElementRowTitle] =
		useMutation<displayMatrixElementCellForm_SetMatrixElementRowTitleMutation>(
			SET_MATRIX_ELEMENT_ROW_TITLE_MUTATION,
		);

	const dispatch = useTypedDispatch();
	const formik = useFormik<DisplayMatrixElementCellFormFormState>({
		initialValues: {
			content: displayCell.content,
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			if (displayCell.yIdx === 0) {
				setMatrixElementColumnTitle({
					variables: {
						input: {
							matrixElementId,
							idx: displayCell.xIdx,
							newTitle: values.content,
						},
					},
					onCompleted: () => {
						dispatch(resetArrayOfEditedForms());
					},
				});
			} else {
				setMatrixElementRowTitle({
					variables: {
						input: {
							matrixElementId,
							idx: displayCell.yIdx,
							newTitle: values.content,
						},
					},
					onCompleted: () => {
						dispatch(resetArrayOfEditedForms());
					},
				});
			}
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="flex m-0" style={{ height: "45px" }}>
			<ValidatedField<DisplayMatrixElementCellFormFormState, string>
				name={"content"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				className="m-1"
			/>
			<Button
				disabled={Object.entries(formik.touched).length === 0}
				type="submit"
				icon="pi pi-save"
				className="m-1"
			/>
			{displayCell.yIdx === 0 ? (
				<RemoveColumnFromMatrixElementButton
					matrixElementId={matrixElementId}
					idx={displayCell.xIdx}
				/>
			) : (
				<RemoveRowFromMatrixElementButton
					matrixElementId={matrixElementId}
					idx={displayCell.yIdx}
				/>
			)}
		</form>
	);
};
