import { graphql } from "babel-plugin-relay/macro";

export const EXTERNAL_LICENSES_FRAGMENT = graphql`
	fragment externalLicensesTable_ExternalLicensesFragment on Query
	@refetchable(queryName: "externalLicensesTable_ExternalLicensesFragmentRefetch")
	@argumentDefinitions(
		name: { type: "String" }
		isIssued: { type: "Boolean" }
		externalLicensePoolIdOpt: { type: "ID" }
		first: { type: "Int!" }
		after: { type: "String" }
	) {
		Admin {
			Billing {
				GetExternalLicenses(
					pool: $externalLicensePoolIdOpt
					name: $name
					isIssued: $isIssued
					first: $first
					after: $after
				) @connection(key: "ExternalLicensesTable_GetExternalLicenses") {
					__id
					edges {
						node {
							...externalLicensesTable_ExternalLicenseFragment
						}
					}
				}
			}
		}
	}
`;

export const EXTERNAL_LICENSE_FRAGMENT = graphql`
	fragment externalLicensesTable_ExternalLicenseFragment on ExternalLicense @inline {
		id
		data {
			name
			code
		}
		pool {
			data {
				usageInformation
			}
		}
		issuingInfo {
			hasBeenIssued
			... on ExternalLicenseIssuedToUserIssuingInfoType {
				issuedAt
				validUntil
				issuedTo {
					email
				}
			}
		}
	}
`;

export const CREATE_EXTERNAL_LICENSE_MUTATION = graphql`
	mutation externalLicensesTable_CreateExternalLicenseMutation(
		$input: CreateExternalLicenseInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createExternalLicense(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							...externalLicensesTable_ExternalLicenseFragment
						}
					}
				}
			}
		}
	}
`;
