import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query filesTableV2_Query($filterByFileTypes: [String!]) {
		...filesTableV2_FilesListFragment @arguments(filterByFileTypes: $filterByFileTypes)
	}
`;

export const FILES_FRAGMENT = graphql`
	fragment filesTableV2_FilesListFragment on Query
	@refetchable(queryName: "FilesTableV2_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		filterByName: { type: "String" }
		filterByFileTypes: { type: "[String!]" }
		filterByFromDateTimeInclusive: { type: "ZonedDateTIme" }
		filterByToDateTimeInclusive: { type: "ZonedDateTIme" }
		filterFileTagsIncluded: { type: "[String!]" }
		# DefaultValue is here the CertificateTag (relay compiler does not allow us to use it directly here)
		filterFileTagsExcluded: { type: "[String!]", defaultValue: ["IssuedCertificate"] }
	) {
		Admin {
			Files {
				Files(
					first: $first
					after: $after
					name: $filterByName
					fileType: $filterByFileTypes
					fromDateTimeInclusive: $filterByFromDateTimeInclusive
					toDateTimeInclusive: $filterByToDateTimeInclusive
					tagsIncluded: $filterFileTagsIncluded
					tagsExcluded: $filterFileTagsExcluded
				) @connection(key: "FilesTable_Files") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							name
							fileType
							accessType
							uploadDateTime
							thumbnail
							url
						}
					}
				}
			}
		}
	}
`;
