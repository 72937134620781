import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query externalLicensePoolEditScreen_ExternalLicensePoolQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on ExternalLicensePool {
				id
				data {
					name
				}
				...editExternalLicensePoolForm_ExternalLicensePoolFragment
			}
		}
	}
`;
