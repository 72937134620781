import { TabsOrder } from "./node-part.consts";

export const getTabIndex = (path?: string) => {
	const index = path ? TabsOrder.indexOf(path as any) : 0;
	return index < 0 ? 0 : index;
};

export const getPathForTabIndex = (index = 0) => {
	return TabsOrder[index];
};
