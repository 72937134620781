import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ExternalLicensePoolRoutes } from "@screens/external-license-pool";
import { ExternalLicensePoolEditRoutes } from "@screens/external-license-pool-edit";
import { ExternalLicensePoolsScreen } from "./external-license-pools.screen";

export const ExternalLicensePoolsRoutes: RouteDefinition[] = [
	{
		path: Paths.externalLicensePools.path,
		element: <ExternalLicensePoolsScreen />,
		requiredPermissions: ["UserInAccountPermission_ExternalLicenseAdmin_Read"],
	},
	...ExternalLicensePoolRoutes,
	...ExternalLicensePoolEditRoutes,
];
