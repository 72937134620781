import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { type DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key } from "@relay/DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment.graphql";
import { DiscountUsageFilter } from "./DiscountUsageFilter";
import { DiscountUsagePriceDisplay } from "./DiscountUsagePriceDisplay";
import { DownloadUsageCsvButton } from "./DownloadUsageCsvButton";

const SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT = graphql`
	fragment DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment on SearchDiscountCodeUsagesResult {
		...DiscountUsagePriceDisplay_SearchDiscountUsageResultFragment
		...DownloadUsageCsvButton_SearchDiscountUsageResultFragment
	}
`;

type OwnProps = {
	searchDiscountUsageResultFragmentRef: DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key;
};

export const DiscountCodeUsageTableHeader = ({
	searchDiscountUsageResultFragmentRef,
}: OwnProps) => {
	const searchDiscountUsageResult =
		useFragment<DiscountCodeUsageTableHeader_SearchDiscountUsageResultFragment$key>(
			SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT,
			searchDiscountUsageResultFragmentRef,
		);

	return (
		<div className="w-full flex flex-row justify-content-between align-items-center flex-wrap">
			<DiscountUsagePriceDisplay
				searchDiscountUsageResultFragmentRef={searchDiscountUsageResult}
			/>
			<DownloadUsageCsvButton
				searchDiscountUsageResultFragmentRef={searchDiscountUsageResult}
			/>
			<DiscountUsageFilter />
		</div>
	);
};
