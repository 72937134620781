import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { useContext } from "react";
import { type DiscountTypeKind } from "@relay/ActionTypeColumn_DiscountActionFragment.graphql";
import { DiscountActionSearchContext } from "./DiscountActionSearchContext";

export const DiscountActionFilter = () => {
	const { filters, setFilters, clearFilters } = useContext(DiscountActionSearchContext);

	const options: Array<{ label: string; value: DiscountTypeKind }> = [
		{ label: "System", value: "system" },
		{ label: "Nutzer", value: "user" },
	];

	return (
		<div className="flex flex-wrap align-items-center">
			<div className="mr-2">
				<Dropdown
					value={filters.filterByDiscountTypeKind}
					options={options}
					placeholder="Discount-Typ"
					onChange={(e) => {
						setFilters({
							...filters,
							filterByDiscountTypeKind: e.target.value,
						});
					}}
				/>
			</div>
			<div className="mr-2">
				<InputText
					value={filters.filterByActionTitle}
					placeholder="Aktionstitel"
					onChange={(e) => {
						setFilters({
							...filters,
							filterByActionTitle: e.target.value,
						});
					}}
				/>
			</div>
			<div className="mr-2">
				<InputText
					value={filters.filterByCode}
					placeholder="Code"
					onChange={(e) => {
						setFilters({
							...filters,
							filterByCode: e.target.value,
						});
					}}
				/>
			</div>
			<Button
				className="h-2rem"
				tooltip={"Zurücksetzen"}
				icon="pi pi-times"
				onClick={() => {
					clearFilters();
				}}
			/>
		</div>
	);
};
