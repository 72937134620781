import { graphql } from "babel-plugin-relay/macro";

export const PAGE_FRAGMENT = graphql`
	fragment messagePageForm_PageFragment on MessageDefinitionPage {
		id
		internalTitle
		data {
			kind
			... on ImagePage {
				element {
					image {
						name
						id
						fileType
					}
				}
			}
			... on ImageWithButtonPage {
				...pageButtonForm_ButtonFragment
				button {
					kind
					... on ConfirmButton {
						text
					}
					... on TextCopyButton {
						text
					}
					... on LinkButton {
						text
						url
					}
				}
				element {
					image {
						name
						id
						fileType
					}
				}
			}
		}
	}
`;
