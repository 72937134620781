import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query usersTable_Query($first: Int) {
		...usersTable_UsersListFragment @arguments(first: $first)
	}
`;

export const USERS_LIST_FRAGMENT = graphql`
	fragment usersTable_UsersListFragment on Query
	@refetchable(queryName: "UsersTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int", defaultValue: 20 }
		after: { type: "String" }
		nameOrEmail: { type: "String" }
	) {
		Admin {
			Auth {
				Users(first: $first, after: $after, filterByNameOrEmail: $nameOrEmail)
					@connection(key: "UsersTable_Users") {
					__id
					edges {
						node {
							id
							name
							email
							activated
							groupAssociations {
								account {
									name
								}
								group {
									id
									name
								}
							}
						}
					}
				}
			}
		}
	}
`;

export const ANONYMIZE_USER_MUTATION = graphql`
	mutation usersTable_AnonymizeUserMutation($input: DeleteUserInput!, $connections: [ID!]!) {
		Admin {
			Auth {
				anonymizeUser(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
