import styled, { css } from "styled-components";
import { Typography } from "./typography";
import { accent, accent65 } from "../color";
import { spacing4 } from "../spacing";

// h1

export const baseTypography = new Typography(css`
	a {
		color: ${accent.rgbaValue()};
		margin-left: ${spacing4.rem()};
		margin-right: ${spacing4.rem()};

		&:hover {
			color: ${accent65.rgbaValue()};
		}
	}

	strong {
		font-weight: 600;
	}
`);

export const heading2Xl = new Typography(css`
	${baseTypography.getCSS()};
	font-family: sans-serif;
	font-size: 64px;
	font-style: normal;
	font-weight: 300;
	line-height: 125%;
`);

export const heading2XlSpan = styled.span`
	${heading2Xl.getCSS()}
`;

export const headingXl = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 40px;
	font-style: normal;
	font-weight: 300;
	line-height: 125%;
`);

export const headingXlSpan = styled.span`
	${headingXl.getCSS()}
`;

export const headingLg = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 36px;
	font-style: normal;
	font-weight: 300;
	line-height: 175%;
`);

export const headingLgSpan = styled.span`
	${headingLg.getCSS()}
`;

export const headingMdStrong = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 800;
	line-height: 175%;
`);

export const headingMdStrongSpan = styled.span`
	${headingMdStrong.getCSS()}
`;

export const headingMd = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 150%;
`);

export const headingMdSpan = styled.span`
	${headingMd.getCSS()}
`;

export const headingMdUppercase = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 175%; /* 31.5px */
	letter-spacing: 0.54px;
	text-transform: uppercase;
`);

export const headingMdUppercaseSpan = styled.span`
	${headingMdUppercase.getCSS()}
`;

export const headingSm = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 150%;
`);

export const headingSmSpan = styled.span`
	${headingSm.getCSS()}
`;

export const headingXs = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 800;
	line-height: 150%;
`);

export const headingXsSpan = styled.span`
	${headingXs.getCSS()}
`;

export const headingXsUppercase = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 150%; /* 21px */
	letter-spacing: 0.7px;
	text-transform: uppercase;
`);

export const headingXsUppercaseSpan = styled.span`
	${headingXsUppercase.getCSS()}
`;

export const heading2XsUppercase = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 150%; /* 18px */
	letter-spacing: 0.72px;
	text-transform: uppercase;
`);

export const heading2XsUppercaseSpan = styled.span`
	${heading2XsUppercase.getCSS()}
`;

export const paragraphXlTypography = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 22px;
	font-style: normal;
	font-weight: 300;
	line-height: 150%;
`);

export const paragraphXlSpan = styled.span`
	${paragraphXlTypography.getCSS()}
`;

export const paragraphLgTypography = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 300;
	line-height: 165%;
`);

export const paragraphLgSpan = styled.span`
	${paragraphLgTypography.getCSS()}
`;

export const paragraphMdTypography = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 165%;
`);

export const paragraphMdSpan = styled.span`
	${paragraphMdTypography.getCSS()}
`;

export const paragraphSmTypography = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 150%;
`);

export const paragraphSmSpan = styled.span`
	${paragraphSmTypography.getCSS()}
`;

export const linkButtonLgTypography = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: 175%;
`);

export const linkButtonLgSpan = styled.span`
	${linkButtonLgTypography.getCSS()}
`;

export const linkButtonMdTypography = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 175%;
`);

export const linkButtonMdSpan = styled.span`
	${linkButtonMdTypography.getCSS()}
`;

export const linkButtonSmTypography = new Typography(css`
	${baseTypography.getCSS()};

	font-family: sans-serif;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 175%;
`);

export const linkButtonSmSpan = styled.span`
	${linkButtonSmTypography.getCSS()}
`;
