import { commitMutation, Environment } from "react-relay";

import { ADD_CONFIG_MUTATION } from "./add-can-if-unlocked-start-content-config.graphql";
import {
	addCanIfUnlockedStartContentConfig_AddCanIfUnlockedStartContentConfigMutation,
	AddCanIfUnlockedStartContentConfigInput,
} from "../../../../__generated__/addCanIfUnlockedStartContentConfig_AddCanIfUnlockedStartContentConfigMutation.graphql";

export const AddCanIfIsDemoStartContentConfigMutation = (
	environment: Environment,
	input: AddCanIfUnlockedStartContentConfigInput,
) => {
	commitMutation<addCanIfUnlockedStartContentConfig_AddCanIfUnlockedStartContentConfigMutation>(
		environment,
		{
			mutation: ADD_CONFIG_MUTATION,
			variables: { input },
			onCompleted: () => {},
			onError: () => {},
		},
	);
};
