import graphql from "babel-plugin-relay/macro";
export const GENERATE_CART_LINK_MUTATION = graphql`
	mutation generateCartLinkForm_GenerateCartLinkMutation($input: GenerateAbsoluteCartLinkInput!) {
		Admin {
			Billing {
				generateAbsoluteCartLink(input: $input) {
					link
				}
			}
		}
	}
`;
