/**
 * @generated SignedSource<<486fa63aeb4495ec77efa9f9038f79de>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ProductsSelect_QueryFragmentRefetchQuery$variables = {
  after?: string | null;
  first?: number | null;
  productIds: ReadonlyArray<string>;
  title?: string | null;
};
export type ProductsSelect_QueryFragmentRefetchQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProductsSelect_QueryFragment">;
};
export type ProductsSelect_QueryFragmentRefetchQuery = {
  response: ProductsSelect_QueryFragmentRefetchQuery$data;
  variables: ProductsSelect_QueryFragmentRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "productIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "title"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "titleOpt",
    "variableName": "title"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProductsSelect_QueryFragmentRefetchQuery",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "productIds",
            "variableName": "productIds"
          },
          {
            "kind": "Variable",
            "name": "title",
            "variableName": "title"
          }
        ],
        "kind": "FragmentSpread",
        "name": "ProductsSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProductsSelect_QueryFragmentRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ProductsConnection",
                "kind": "LinkedField",
                "name": "SearchProducts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v5/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "titleOpt"
                ],
                "handle": "connection",
                "key": "ProductsSelect_SearchProducts",
                "kind": "LinkedHandle",
                "name": "SearchProducts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "ids",
            "variableName": "productIds"
          }
        ],
        "concreteType": null,
        "kind": "LinkedField",
        "name": "nodes",
        "plural": true,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v6/*: any*/)
            ],
            "type": "Product",
            "abstractKey": "__isProduct"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0ca073fef6725b15bcd8ed55dc1029e2",
    "id": null,
    "metadata": {},
    "name": "ProductsSelect_QueryFragmentRefetchQuery",
    "operationKind": "query",
    "text": "query ProductsSelect_QueryFragmentRefetchQuery(\n  $after: String = null\n  $first: Int\n  $productIds: [ID!]!\n  $title: String = null\n) {\n  ...ProductsSelect_QueryFragment_15KeLQ\n}\n\nfragment ProductsSelect_QueryFragment_15KeLQ on Query {\n  Admin {\n    Billing {\n      SearchProducts(first: $first, after: $after, titleOpt: $title) {\n        edges {\n          cursor\n          node {\n            __typename\n            id\n            title\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n  nodes(ids: $productIds) {\n    __typename\n    ... on Product {\n      __isProduct: __typename\n      id\n      title\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "ff117ac4e826abe120e57c1b4b61ae1b";

export default node;
