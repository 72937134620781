import { Dialog } from "@thekeytechnology/epic-ui";
import React from "react";
import { SelectMultipleRootNodesForm } from "@features/educational-offer/select-multiple-root-nodes-form";
import { SelectNewsletterOptInForm } from "@features/newsletter";
import { DispatchInputSelectionModalProps } from "@screens/message-dispatch-edit/parts/dispatch-input-selection-modal/dispatch-input-selection-modal.types";
import { SelectMultipleAccountsForm } from "src/features/accounts/select-multiple-accounts-form";
import { SelectMultipleUsersForm } from "src/features/users/select-multiple-users-form";

export const DispatchInputSelectionModal = ({
	isInclusion = true,
	onHide,
	onInputSelected,
	inputType,
}: DispatchInputSelectionModalProps) => {
	let formComponent = null;
	const titleBase = isInclusion ? "Einzuschließende" : "Auszuschließende";
	let title = "";
	const handleUserSelection = (selectedUsers: string[]) => {
		onInputSelected(selectedUsers);
		onHide();
	};
	const handleAccountSelection = (selectedAccounts: string[]) => {
		onInputSelected(selectedAccounts);
		onHide();
	};
	const handleRootNodeSelection = (selectedRoots: string[]) => {
		onInputSelected(selectedRoots);
		onHide();
	};
	const handleNewsletterOptInSelection = (selectedOptIn: boolean) => {
		onInputSelected(selectedOptIn);
		onHide();
	};

	switch (inputType) {
		case "user":
			title = `${titleBase} Benutzer auswählen`;
			formComponent = <SelectMultipleUsersForm onUsersSelected={handleUserSelection} />;
			break;

		case "account":
			title = `${titleBase} Accounts auswählen`;
			formComponent = (
				<SelectMultipleAccountsForm onAccountSelected={handleAccountSelection} />
			);
			break;

		case "rootNode":
			title = `${titleBase} Kurse auswählen`;
			formComponent = (
				<SelectMultipleRootNodesForm onRootNodesSelected={handleRootNodeSelection} />
			);
			break;

		case "newsletterOptIn":
			title = `Newsletter-Abonnement-Status auswählen`;
			formComponent = (
				<SelectNewsletterOptInForm onOptInSelected={handleNewsletterOptInSelection} />
			);
			break;

		default:
			return null;
	}

	return (
		<Dialog visible={true} title={title} onHide={onHide}>
			{formComponent}
		</Dialog>
	);
};
