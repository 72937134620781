import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import * as Yup from "yup";
import { type externalLicenseRewardForm_EditExternalLicenseRewardMutation } from "@relay/externalLicenseRewardForm_EditExternalLicenseRewardMutation.graphql";
import { type externalLicenseRewardForm_ExternalLicenseRewardFragment$key } from "@relay/externalLicenseRewardForm_ExternalLicenseRewardFragment.graphql";
import { type externalLicenseRewardForm_Query } from "@relay/externalLicenseRewardForm_Query.graphql";
import {
	EDIT_EXTERNAL_LICENSE_REWARD_MUTATION,
	EXTERNAL_LICENSE_REWARD_FRAGMENT,
	QUERY,
} from "./external-license-reward-form.graphql";
import {
	type ExternalLicenseRewardFormFormState,
	type ExternalLicenseRewardFormProps,
} from "./external-license-reward-form.types";
import { ValidatedField } from "../../../../components/ValidatedField";
import { SelectExternalLicensePoolField } from "../../../../features/external-licenses/select-external-license-pool-field/select-external-license-pool-field.component";
import { type SelectExternalLicensePoolFieldState } from "../../../../features/external-licenses/select-external-license-pool-field/select-external-license-pool-field.types";
import { resetArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";

export const ExternalLicenseRewardForm = ({
	contentNodeId,
	externalLicenseRewardFragmentRef,
	onBack: handleOnClick,
}: ExternalLicenseRewardFormProps) => {
	const query = useLazyLoadQuery<externalLicenseRewardForm_Query>(QUERY, {});
	const reward = useFragment<externalLicenseRewardForm_ExternalLicenseRewardFragment$key>(
		EXTERNAL_LICENSE_REWARD_FRAGMENT,
		externalLicenseRewardFragmentRef,
	);
	const [editReward, isEditingReward] =
		useMutation<externalLicenseRewardForm_EditExternalLicenseRewardMutation>(
			EDIT_EXTERNAL_LICENSE_REWARD_MUTATION,
		);
	const dispatch = useTypedDispatch();
	const formik = useFormik<ExternalLicenseRewardFormFormState>({
		initialValues: {
			externalLicensePool: {
				id: reward.data.pool!.id!,
				poolName: reward.data.pool!.data.name,
			},
		},
		validationSchema: Yup.object().shape({
			externalLicensePool: Yup.object().required("Externer Lizenzpool wird benötigt"),
		}),
		onSubmit: (values, formikHelpers) => {
			if (!values.externalLicensePool?.id) return;
			editReward({
				variables: {
					input: {
						contentId: contentNodeId,
						rewardId: reward.id!,
						newData: { pool: values.externalLicensePool!.id },
					},
				},
				onCompleted: () => {
					formikHelpers.setTouched({});
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});
	const isDisabled = Object.entries(formik.touched).length === 0 || isEditingReward;

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<ExternalLicenseRewardFormFormState, SelectExternalLicensePoolFieldState>
				required
				name={"externalLicensePool"}
				label="Externer Lizenzpool"
				placeholder="Externer Lizenzpool auswählen..."
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectExternalLicensePoolField {...renderConfig} queryFragmentRef={query} />
				)}
			/>

			<Button disabled={isDisabled} type="submit" label="Speichern" className="mt-2" />

			<Button
				type="button"
				onClick={handleOnClick}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
