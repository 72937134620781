import { AsyncElementTaskStatus } from "@relay/tasksTable_TasksFragment.graphql";

export const convertTaskStatus = (taskStatus: AsyncElementTaskStatus) => {
	switch (taskStatus) {
		case "Fresh":
			return "Bereit zur Bewertung";
		case "Evaluated":
			return "Bewertet";
		default:
			return taskStatus;
	}
};
