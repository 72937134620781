/**
 * @generated SignedSource<<34a93194ca1b708a677bcfd0a9d7f935>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type settingsScreen_Query$variables = {};
export type settingsScreen_Query$data = {
  readonly Admin: {
    readonly Settings: {
      readonly Settings: {
        readonly globalDiscountCode: {
          readonly code: string;
          readonly id: string;
        } | null;
        readonly id: string;
      };
    };
  };
  readonly " $fragmentSpreads": FragmentRefs<"discountCodeSelect_QueryFragment">;
};
export type settingsScreen_Query = {
  response: settingsScreen_Query$data;
  variables: settingsScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "SettingsAdminSchema",
  "kind": "LinkedField",
  "name": "Settings",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Settings",
      "kind": "LinkedField",
      "name": "Settings",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DiscountCode",
          "kind": "LinkedField",
          "name": "globalDiscountCode",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "ids",
    "value": ([]/*: any*/)
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "settingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      },
      {
        "args": (v3/*: any*/),
        "kind": "FragmentSpread",
        "name": "discountCodeSelect_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "settingsScreen_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "DiscountCodesV2Connection",
                "kind": "LinkedField",
                "name": "DiscountsMultiselect",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountCodesV2Edge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountCode",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "DiscountsMultiselect(ids:[])"
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "codeOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "DiscountCodeSelect_DiscountsMultiselect",
                "kind": "LinkedHandle",
                "name": "DiscountsMultiselect"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "62a8fdd7ffa8f65babb8b229b1ed5618",
    "id": null,
    "metadata": {},
    "name": "settingsScreen_Query",
    "operationKind": "query",
    "text": "query settingsScreen_Query {\n  Admin {\n    Settings {\n      Settings {\n        globalDiscountCode {\n          id\n          code\n        }\n        id\n      }\n    }\n  }\n  ...discountCodeSelect_QueryFragment_4vS6aX\n}\n\nfragment discountCodeSelect_QueryFragment_4vS6aX on Query {\n  Admin {\n    Billing {\n      DiscountsMultiselect(ids: []) {\n        edges {\n          cursor\n          node {\n            id\n            code\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ef8211461908b107b66f69af0912734c";

export default node;
