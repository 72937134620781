import { Button } from "primereact/button";
import { type DataTableSelectionSingleChangeEvent } from "primereact/datatable";
import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { useCallback, useEffect, useState } from "react";
import { usePaginationFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { type selectProductField_Query } from "@relay/selectProductField_Query.graphql";
import { type selectProductField_QueryFragment$key } from "@relay/selectProductField_QueryFragment.graphql";
import { type SelectProductField_Refetch } from "@relay/SelectProductField_Refetch.graphql";
import { QUERY, QUERY_FRAGMENT } from "./select-product-field.graphql";
import {
	type SelectProductFieldWithPreloadedQueryProps,
	type SelectProductFieldProps,
	type SelectProductFieldState,
} from "./select-product-field.interface";
import { ProductsTable } from "../products-table";

export const SelectProductFieldWithPreloadedQuery = ({
	updateField,
	fieldValue: product,
	queryRef,
}: SelectProductFieldWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<selectProductField_Query>(QUERY, queryRef);

	const {
		data: {
			Admin: {
				Billing: { SearchProducts: products },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<SelectProductField_Refetch, selectProductField_QueryFragment$key>(
		QUERY_FRAGMENT,
		query,
	);

	const [filterState, setFilterState] = useState<SelectProductFieldState>({
		titleOpt: undefined,
		isHiddenOpt: undefined,
	});

	const updateProductsNodes = useCallback((selectProductFieldState: SelectProductFieldState) => {
		refetch({
			titleOpt: selectProductFieldState.titleOpt,
			isHiddenOpt: selectProductFieldState.isHiddenOpt,
		});
	}, []);

	const handleOnSubmit = useCallback(() => {
		updateProductsNodes(filterState);
	}, [filterState, updateProductsNodes]);

	useEffect(handleOnSubmit, []);

	useEffect(() => {
		if (product?.id && !products.edges?.find((e) => e?.node.id === product?.id)) {
			updateField(undefined);
		}
	}, [products]);

	const isHiddenItems = [
		{ label: "Ja", value: true },
		{ label: "Nein", value: false },
	];

	const handleClearFormOnClick = () => {
		setFilterState({
			titleOpt: undefined,
			isHiddenOpt: undefined,
		});
		updateProductsNodes({});
	};

	const handleFilterTitleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setFilterState({ ...filterState, titleOpt: e.target.value });
	}, []);

	const handleFilterIsHiddenOnChange = useCallback((e: DropdownChangeEvent) => {
		setFilterState({ ...filterState, isHiddenOpt: e.value });
	}, []);

	const handleRootSelected = useCallback((event: DataTableSelectionSingleChangeEvent<any>) => {
		updateField({
			id: event.value.id,
			title: event.value.title,
		});
	}, []);

	return (
		<>
			<div className="flex flex-row align-items-center">
				<InputText
					value={filterState.titleOpt}
					placeholder={"Titel"}
					onChange={handleFilterTitleOnChange}
					className="mr-2"
				/>
				<Dropdown
					value={filterState.isHiddenOpt}
					options={isHiddenItems}
					placeholder={"Versteckt"}
					onChange={handleFilterIsHiddenOnChange}
					className="mr-2"
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={handleClearFormOnClick}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					onClick={handleOnSubmit}
					type="button"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
			<ProductsTable
				productsConnectionFragmentRef={products}
				hasPrevious={hasPrevious}
				loadPrevious={loadPrevious}
				hasNext={hasNext}
				loadNext={loadNext}
				selectionMode="single"
				onProductSelected={handleRootSelected}
				selectedId={product?.id}
			/>
		</>
	);
};

export const SelectProductField = ({ ...props }: SelectProductFieldProps) => {
	const [queryReference, loadQuery] = useQueryLoader<selectProductField_Query>(QUERY);

	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery]);

	return queryReference ? (
		<SelectProductFieldWithPreloadedQuery {...props} queryRef={queryReference} />
	) : null;
};
