import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { type ProductPriceColumn_ProductFragment$key } from "@relay/ProductPriceColumn_ProductFragment.graphql";
import { CurrencyDisplay } from "../../../components/CurrencyDisplay";
import { Taxrate } from "../../../components/taxrate";

const PRODUCT_FRAGMENT = graphql`
	fragment ProductPriceColumn_ProductFragment on Product {
		price {
			netPrice
			grossPrice
			taxRatePercentage
		}
	}
`;

type OwnProps = {
	productFragmentRef: ProductPriceColumn_ProductFragment$key;
};

export const ProductPriceColumn = ({ productFragmentRef }: OwnProps) => {
	const product = useFragment<ProductPriceColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	return (
		<div>
			<CurrencyDisplay value={product.price.grossPrice} />
			<CurrencyDisplay value={product.price.netPrice} />
			<Taxrate taxrate={product.price.taxRatePercentage} />
		</div>
	);
};
