export enum RichTextEditorToolbar {
	Default = "Default",
	WithLists = "WithLists",
	UndoRedo = "UndoRedo",
	NoImage = "NoImage",
	WithTextColor = "WithTextColor",
}

export const RichTextEditorToolbarsConfig = {
	[RichTextEditorToolbar.Default]: {
		toolbar: ["undo redo | bold italic underline | blocks | image link "],
		plugins: ["image", "autolink", "link"],
		paste_as_text: true,
	},
	[RichTextEditorToolbar.WithLists]: {
		toolbar: ["undo redo | bold italic underline | numlist bullist | blocks link "],
		plugins: ["lists", "autolink", "link"],
		paste_as_text: true,
	},
	[RichTextEditorToolbar.UndoRedo]: {
		toolbar: ["undo redo link"],
		plugins: ["image", "autolink", "link"],
		paste_as_text: true,
	},
	[RichTextEditorToolbar.NoImage]: {
		toolbar: ["undo redo | bold italic underline | blocks link"],
		plugins: ["image", "autolink", "link"],
		paste_as_text: true,
	},
	[RichTextEditorToolbar.WithTextColor]: {
		toolbar: ["undo redo | bold italic underline | blocks | image link | forecolor backcolor "],
		plugins: ["image", "autolink", "link"],
		paste_as_text: true,
	},
};
