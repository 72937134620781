import graphql from "babel-plugin-relay/macro";

export const PRODUCTS_CONNECTION_FRAGMENT = graphql`
	fragment productsTable_ProductsConnectionFragment on ProductsConnection {
		edges {
			node {
				... on LicenseProduct {
					...productsTable_LicenseProductFragment
				}
			}
		}
	}
`;

export const LICENSE_PRODUCT_FRAGMENT = graphql`
	fragment productsTable_LicenseProductFragment on LicenseProduct @inline {
		id
		title
	}
`;
