/**
 * @generated SignedSource<<d44ddb88471e2dcca79cc39410bfe00a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateInstructorImplInput = {
  clientMutationId?: string | null;
  data: InstructorImplInput;
  id: string;
};
export type InstructorImplInput = {
  clientMutationId?: string | null;
  description?: string | null;
  imageId?: string | null;
  name: string;
  position?: string | null;
  shortDescription?: string | null;
};
export type instructorEditScreen_UpdateMutation$variables = {
  input: UpdateInstructorImplInput;
};
export type instructorEditScreen_UpdateMutation$data = {
  readonly Admin: {
    readonly Instructor: {
      readonly updateInstructorImpl: {
        readonly data: {
          readonly node: {
            readonly id: string;
            readonly name: string;
            readonly superId: string;
            readonly " $fragmentSpreads": FragmentRefs<"instructorEditScreen_InstructorFragment">;
          };
        };
      } | null;
    };
  };
};
export type instructorEditScreen_UpdateMutation = {
  response: instructorEditScreen_UpdateMutation$data;
  variables: instructorEditScreen_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "superId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "instructorEditScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateInstructorImplPayload",
                "kind": "LinkedField",
                "name": "updateInstructorImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorImplsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorImpl",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "instructorEditScreen_InstructorFragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "instructorEditScreen_UpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "InstructorAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Instructor",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "UpdateInstructorImplPayload",
                "kind": "LinkedField",
                "name": "updateInstructorImpl",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "InstructorImplsEdge",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "InstructorImpl",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "position",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "shortDescription",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "File",
                            "kind": "LinkedField",
                            "name": "image",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "url",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c00088cc5f3da4768e07afe63ae69859",
    "id": null,
    "metadata": {},
    "name": "instructorEditScreen_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation instructorEditScreen_UpdateMutation(\n  $input: UpdateInstructorImplInput!\n) {\n  Admin {\n    Instructor {\n      updateInstructorImpl(input: $input) {\n        data {\n          node {\n            id\n            superId\n            name\n            ...instructorEditScreen_InstructorFragment\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment instructorEditScreen_InstructorFragment on InstructorImpl {\n  id\n  superId\n  name\n  position\n  shortDescription\n  description\n  image {\n    id\n    name\n    url\n  }\n}\n"
  }
};
})();

(node as any).hash = "d99eb3fa6bfddc070607558d35e5a2f3";

export default node;
