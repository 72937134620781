import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "@routes/paths";

type TreeSelectionContextProps = {
	treeId: string;
	selectedNodeId?: string;
	setSelectedNodeId: (c: string | undefined) => void;
};

export const TreeSelectionContext = React.createContext<TreeSelectionContextProps>({
	treeId: "",
	selectedNodeId: undefined,
	setSelectedNodeId: () => {},
});

type OwnProps = {
	treeId: string;
	initiallySelectedNodeId?: string;
	children: any;
};

export const TreeSelectionContainer = ({ children, treeId, initiallySelectedNodeId }: OwnProps) => {
	const [selectedNodeId, setSelectedNodeId] = useState<string | undefined>(
		initiallySelectedNodeId,
	);

	const navigate = useNavigate();

	return (
		<TreeSelectionContext.Provider
			value={{
				treeId,
				setSelectedNodeId: (newId) => {
					if (newId !== treeId && newId) {
						navigate(Paths.educationalOffer.withId(treeId).node.withId(newId).path);
					} else {
						navigate(Paths.educationalOffer.withId(treeId).path);
					}

					setSelectedNodeId(newId);
				},
				selectedNodeId,
			}}
		>
			{children}
		</TreeSelectionContext.Provider>
	);
};
