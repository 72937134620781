import {
	AccountType,
	type AccountFormState,
	type BusinessBaseDataSubformState,
	type PrivateBaseDataSubformState,
} from "./account-edit-base-data.types";

export const isBusinessForm = (
	form: AccountFormState,
): form is Required<BusinessBaseDataSubformState> => {
	return (form as AccountFormState).accountType === AccountType.Business && !!form.subform;
};

export const isPrivateForm = (
	form: AccountFormState,
): form is Required<PrivateBaseDataSubformState> => {
	return (form as AccountFormState).accountType === AccountType.Private && !!form.subform;
};
