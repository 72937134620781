import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import { type asyncContentEditor_CreateUploadAsyncElementMutation } from "@relay/asyncContentEditor_CreateUploadAsyncElementMutation.graphql";
import { type AsyncElementKind } from "@relay/asyncContentEditor_TreeNodeFragment.graphql";
import { AsyncElementForm } from "@screens/educational-offer/parts/async-element-form";
import { AsyncElementsDropdownOptions } from "./async-content-editor.consts";
import {
	CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./async-content-editor.graphql";
import { type AsnycContentEditorProps } from "./async-content-editor.types";
import { BaseContentEditor } from "../BaseContentEditor";

export const AsyncContentEditor = ({ treeNodeFragmentRef }: AsnycContentEditorProps) => {
	const node = useFragment(TREE_NODE_FRAGMENT, treeNodeFragmentRef);

	const [createUploadAsyncElement] =
		useMutation<asyncContentEditor_CreateUploadAsyncElementMutation>(
			CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
		);

	const kind = node.typeDefinition.element?.kind;

	const createAsyncElement = useCallback(
		(newKind: AsyncElementKind) => {
			switch (newKind) {
				case "Upload":
					createUploadAsyncElement({
						variables: {
							input: {
								asyncContentId: node.id,
								title: "",
							},
						},
					});
			}
		},
		[createUploadAsyncElement, node.id],
	);

	const handleDropDownElementChange = useCallback(
		(event: DropdownChangeEvent) => {
			createAsyncElement(event.value.typeDefinitionType);
		},
		[createAsyncElement],
	);

	return (
		<>
			<BaseContentEditor>
				<Dropdown
					options={AsyncElementsDropdownOptions}
					className="static w-full"
					onChange={handleDropDownElementChange}
					value={AsyncElementsDropdownOptions.find((e) => e.typeDefinitionType === kind)}
					optionLabel={"name"}
				/>
			</BaseContentEditor>
			{node.typeDefinition.element && (
				<AsyncElementForm asyncElementFragmentRef={node.typeDefinition.element} />
			)}
		</>
	);
};
