import { type PathParams } from "@thekeytechnology/epic-ui";
import graphql from "babel-plugin-relay/macro";
import { BreadCrumb } from "primereact/breadcrumb";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type discountCodeScreen_Query } from "@relay/discountCodeScreen_Query.graphql";
import { Paths } from "@routes/paths";
import { type DiscountActionsPath } from "@screens/discount-actions";
import { type DiscountCodePath } from "./discount-code.paths";
import { DiscountCodeForm } from "./parts/DiscountCodeForm";
import { DiscountCodeUsageTable } from "./parts/DiscountCodeUsageTable";
import { DiscountUsageSearchContainer } from "./parts/DiscountUsageSearchContext";
import { NoAccess } from "../../components/no-access";

export type Account = {
	id: string;
	name: string;
};

const QUERY = graphql`
	query discountCodeScreen_Query(
		$id: ID!
		$filterByUsedAtFrom: ZonedDateTIme
		$filterByUsedToFrom: ZonedDateTIme
	) {
		node(id: $id) {
			... on DiscountCode {
				code
				...DiscountCodeForm_DiscountCodeFragment
			}
		}
		...DiscountCodeUsageTable_DiscountCodeFragment
			@arguments(
				first: 10
				after: null
				id: $id
				filterByUsedAtFrom: $filterByUsedAtFrom
				filterByUsedToFrom: $filterByUsedToFrom
			)
	}
`;

export const DiscountCodeScreen = () => {
	const { discountActionId, discountCodeId } =
		useParams<PathParams<typeof DiscountActionsPath | typeof DiscountCodePath>>();

	const query = useLazyLoadQuery<discountCodeScreen_Query>(QUERY, {
		id: discountCodeId!,
	});

	const items: Array<{ label?: string; url: string }> = [
		{ label: "Alle Aktionen", url: Paths.discountActions.path },
	];

	const canRead = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Read"]);

	const canModify = useHasPermissions(["UserInAccountPermission_DiscountAdmin_Modify"]);

	if (canModify) {
		items.push({
			label: "Aktion",
			url: Paths.discountActions.withId(discountActionId!).edit.path,
		});
	}

	if (canModify) {
		items.push({
			label: query.node?.code,
			url: Paths.discountActions
				.withId(discountActionId!)
				.edit.discountCode.withId(discountCodeId!).path,
		});
	}

	if (!canRead) return <NoAccess />;

	return (
		query.node && (
			<DiscountUsageSearchContainer>
				<BreadCrumb className="mb-5" model={items} />
				{canModify && <DiscountCodeForm discountCodeFragmentRef={query.node} />}
				<DiscountCodeUsageTable query={query} />
			</DiscountUsageSearchContainer>
		)
	);
};
