import { NavLink } from "react-router-dom";
import { Card } from "primereact/card";
import { ValidatedField } from "../../../../components/ValidatedField";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import React from "react";
import { useDispatch } from "react-redux";
import { useMutation } from "react-relay";
import { FormContainer } from "./login-form.styles";
import { LoginFormState } from "./login-form.interface";
import { useFormik } from "formik";
import { LOGIN_MUTATION } from "./login-form.graphql";
import * as Yup from "yup";
import { loginForm_LoginMutation } from "@relay/loginForm_LoginMutation.graphql";
import { JwtTokenData, setLoggedIn } from "../../../../store/slices/AuthSlice";

export const LoginForm = () => {
	const dispatch = useDispatch();
	const [login, isLoggingIn] = useMutation<loginForm_LoginMutation>(LOGIN_MUTATION);

	const formik = useFormik<LoginFormState>({
		initialValues: {
			email: "",
			password: "",
		},
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.email("Please enter a valid e-mail address.")
				.required("E-Mail is a required field."),
			password: Yup.string()
				.min(8, "A password needs at least 8 characters.")
				.required("Password is a required field."),
		}),
		onSubmit: (data) => {
			login({
				variables: {
					input: {
						email: data.email,
						password: data.password,
					},
				},
				onCompleted: (response) => {
					if (response.Auth.loginJwt?.loginResult?.jwtTokens) {
						dispatch(
							setLoggedIn({
								tokenData: response.Auth.loginJwt?.loginResult
									?.jwtTokens as JwtTokenData,
							}),
						);
					}
				},
			});
		},
	});

	return (
		<div className="flex justify-content-center align-items-center p-sidebar-full">
			<Card>
				<h1 className="text-center">Login</h1>
				<FormContainer onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<LoginFormState, string>
						name={"email"}
						label={"E-Mail"}
						iconClass={"pi-envelope"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<InputText
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<ValidatedField<LoginFormState, string>
						name={"password"}
						label={"Passwort"}
						formikConfig={formik}
						component={({ fieldValue, updateField, fieldName, isValid }) => {
							return (
								<Password
									id={fieldName}
									name={fieldName}
									value={fieldValue}
									onChange={(e) => updateField(e.target.value)}
									toggleMask
									feedback={false}
									className={classNames({ "p-invalid": !isValid })}
								/>
							);
						}}
					/>
					<Button
						disabled={isLoggingIn}
						type="submit"
						label="Einloggen"
						className="p-mt-2"
					/>
				</FormContainer>
			</Card>
		</div>
	);
};
