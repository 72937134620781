import { Paths } from "@routes/paths";
import { TaskEditScreen } from "./task-edit.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const TaskEditRoutes: RouteDefinition[] = [
	{
		path: Paths.tasks.withIdPlaceholder().edit.path,
		element: <TaskEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_AsyncElementTaskAdmin_Read",
			"UserInAccountPermission_AsyncElementTaskAdmin_Modify",
		],
	},
];
