import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { useFragment, useMutation } from "react-relay";
import { type educationalOfferPublishing_AcknowledgePublishingV2Mutation } from "@relay/educationalOfferPublishing_AcknowledgePublishingV2Mutation.graphql";
import {
	ACKNOWLEDGE_PUBLISHINGV2_MUTATION,
	PUBLISHINGV2_FRAGMENT,
} from "./educational-offer-publishing.graphql";
import { type EducationalOfferPublishingProps } from "./educational-offer-publishing.types";
import { DateTimeDisplay } from "../../../../components/DateTimeDisplay";
import { BaseScreenNoBackground } from "../../../BaseScreen";

export const EducationalOfferPublishing = ({
	publishingV2FragmentRef,
}: EducationalOfferPublishingProps) => {
	const publishing = useFragment(PUBLISHINGV2_FRAGMENT, publishingV2FragmentRef);

	const [acknowledgePublishingV2, isAcknowledgingPublishingV2] =
		useMutation<educationalOfferPublishing_AcknowledgePublishingV2Mutation>(
			ACKNOWLEDGE_PUBLISHINGV2_MUTATION,
		);

	if (!publishing) return null;

	return (
		<BaseScreenNoBackground>
			<Card>
				<h2>Veröffentlichung</h2>
				<p>Status: {publishing.status}</p>
				<p>
					gestartet <DateTimeDisplay value={publishing.initiatedAt} hideTimezone={true} />
				</p>
				{publishing.finishedAt && (
					<p>
						abgeschlossen{" "}
						<DateTimeDisplay value={publishing.initiatedAt} hideTimezone={true} />
					</p>
				)}

				{publishing.canBeAcknowledged && (
					<Button
						disabled={isAcknowledgingPublishingV2}
						label={"Als gelesen markieren"}
						onClick={() => {
							acknowledgePublishingV2({
								variables: { input: { publishingId: publishing.id } },
								onCompleted: () => {
									window.location.reload();
								},
							});
						}}
					/>
				)}

				<h3>Nachrichten</h3>
				<DataTable emptyMessage={"Noch keine Nachrichten"} value={[...publishing.messages]}>
					<Column
						header="Zeit"
						body={(item) => <DateTimeDisplay value={item.timestamp} />}
					/>
					<Column field="message" header="Nachricht" />
					<Column field="additionalMessage" header="Weitere Informationen" />
				</DataTable>
			</Card>
		</BaseScreenNoBackground>
	);
};
