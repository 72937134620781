import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query taskEditScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on AsyncElementTask {
				...uploadTaskEditScreen_AsyncElementTaskFragment
				elementKind
			}
		}
	}
`;
