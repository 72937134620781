import { Button } from "primereact/button";
import { useState } from "react";
import { useMutation } from "react-relay";
import { type addExternalLicenseButton_CreateExternalLicenseMutation } from "@relay/addExternalLicenseButton_CreateExternalLicenseMutation.graphql";
import { ADD_EXTERNAL_LICENSE_MUTATION } from "./add-external-license-button.graphql";
import { type AddExternalLicenseButtonProps } from "./add-external-license-button.interface";
import { AddExternalLicenseForm } from "../add-external-license-form/add-external-license-form.component";
import { type AddExternalLicenseFormState } from "../add-external-license-form/add-external-license-form.interface";

export const AddExternalLicenseButton = ({
	externalLicensePoolId,
	externalLicensesConnection,
}: AddExternalLicenseButtonProps) => {
	const [showDialog, setShowDialog] = useState(false);

	const [addExternalLicense, isAddingExternalLicense] =
		useMutation<addExternalLicenseButton_CreateExternalLicenseMutation>(
			ADD_EXTERNAL_LICENSE_MUTATION,
		);

	const handleExternalLicenseFormOnSubmit = (values: AddExternalLicenseFormState) =>
		addExternalLicense({
			variables: {
				input: {
					poolId: externalLicensePoolId,
					data: {
						name: values.name,
						code: values.code,
					},
				},
				connections: externalLicensesConnection,
			},
			onCompleted: () => {
				setShowDialog(false);
			},
		});
	return (
		<>
			<Button
				label={"Lizenz hinzufügen"}
				className={"m-2"}
				disabled={isAddingExternalLicense}
				onClick={() => {
					setShowDialog(true);
				}}
			/>

			<AddExternalLicenseForm
				addExternalLicenseDialogVisible={showDialog}
				onHideToggle={setShowDialog}
				onSubmitCallback={(formValues: AddExternalLicenseFormState) => {
					handleExternalLicenseFormOnSubmit(formValues);
				}}
			/>
		</>
	);
};
