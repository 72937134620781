import { Paths } from "@routes/paths";
import { InstructorEditScreen } from "./instructor-edit.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const InstructorEditRoutes: RouteDefinition[] = [
	{
		path: Paths.instructors.withIdPlaceholder().edit.path,
		element: <InstructorEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_Instructors_ReadInstructors",
			"UserInAccountPermission_Instructors_UpdateInstructors",
			"UserInAccountPermission_Instructors_CreateInstructors",
		],
	},
];
