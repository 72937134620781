/**
 * @generated SignedSource<<6e99da6a1ee9cdcbbe2dbb2093fb9bfb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CompanyType = "Ag" | "Einzelunternehmen" | "Ev" | "Gbr" | "Gmbh" | "GmbhCoKg" | "Kg" | "OeffentlicheEinrichtung" | "Ohg" | "PartmbH" | "Ug" | "Unknown";
export type SalutationType = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type businessBillingDetailsForm_BillingDetailsFragment$data = {
  readonly city: string;
  readonly company: string;
  readonly companyDetails: string | null;
  readonly companyType: CompanyType;
  readonly country: string;
  readonly firstName: string;
  readonly houseNumber: string;
  readonly invoiceEmail: string;
  readonly lastName: string;
  readonly postalCode: string;
  readonly salutation: SalutationType;
  readonly street: string;
  readonly " $fragmentType": "businessBillingDetailsForm_BillingDetailsFragment";
};
export type businessBillingDetailsForm_BillingDetailsFragment$key = {
  readonly " $data"?: businessBillingDetailsForm_BillingDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"businessBillingDetailsForm_BillingDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "businessBillingDetailsForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyDetails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "country",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "invoiceEmail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "company",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "companyType",
      "storageKey": null
    }
  ],
  "type": "BusinessBillingDetails",
  "abstractKey": null
};

(node as any).hash = "82cf3885c4d77184652cd8174328614d";

export default node;
