import {
	type SetIHKFlowPassContentConfigInput,
	type passContentConfigEditor_SetIHKFlowPassContentConfigMutation,
} from "@relay/passContentConfigEditor_SetIHKFlowPassContentConfigMutation.graphql";
import { Card } from "primereact/card";
import { type DropdownChangeEvent } from "primereact/dropdown";
import React from "react";
import { useFragment, useMutation } from "react-relay";

import {
	type SetAlwaysPassContentConfigInput,
	type passContentConfigEditor_SetAlwaysPassContentConfigMutation,
} from "@relay/passContentConfigEditor_SetAlwaysPassContentConfigMutation.graphql";
import { type PassContentConfigType } from "@relay/passContentConfigEditor_TreeNodeFragment.graphql";
import {
	SET_ALWAYS_PASS_CONTENT_CONFIG_MUTATION,
	SET_IHK_FLOW_PASS_CONTENT_CONFIG_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./pass-content-config-editor.graphql";
import { type PassContentConfigEditorProps } from "./pass-content-config-editor.types";
import { type Config } from "../Config.interfaces";
import { type ConfigDropdownOptions } from "../ConfigDropdown";
import { ConfigItem } from "../ConfigItem";

export const PassContentConfigEditor = ({ treeNodeFragmentRef }: PassContentConfigEditorProps) => {
	const treeNode = useFragment(TREE_NODE_FRAGMENT, treeNodeFragmentRef);
	const [setAlwaysPassContentConfig] =
		useMutation<passContentConfigEditor_SetAlwaysPassContentConfigMutation>(
			SET_ALWAYS_PASS_CONTENT_CONFIG_MUTATION,
		);

	const [setIHKFlowPassContentConfig] =
		useMutation<passContentConfigEditor_SetIHKFlowPassContentConfigMutation>(
			SET_IHK_FLOW_PASS_CONTENT_CONFIG_MUTATION,
		);

	const configType: PassContentConfigType = treeNode.typeDefinition.passConfig
		?.configType as PassContentConfigType;

	const alwaysPass: Config<PassContentConfigType, SetAlwaysPassContentConfigInput> = {
		configKey: "PassContent_Always",
		addMutation: (input: SetAlwaysPassContentConfigInput) => {
			setAlwaysPassContentConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const onIHKFlow: Config<PassContentConfigType, SetIHKFlowPassContentConfigInput> = {
		configKey: "PassContent_IHKFlow",
		addMutation: (input: SetIHKFlowPassContentConfigInput) => {
			setIHKFlowPassContentConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const configs: Array<Config<PassContentConfigType, any | never>> = [alwaysPass, onIHKFlow];

	const options: Array<ConfigDropdownOptions<PassContentConfigType>> = configs.map((c) => {
		return {
			label: c.configKey,
			value: c.configKey,
		};
	});

	const canEdit = configs.find(
		(c: Config<PassContentConfigType, any | never>) =>
			c.configKey === treeNode.typeDefinition.passConfig?.configType,
	)?.editable as boolean;

	const handleOnChange = (e: DropdownChangeEvent) => {
		const selectedConfig = configs.find((c) => c.configKey === e.value);

		if (selectedConfig) {
			selectedConfig.addMutation({
				contentNodeId: treeNode.id,
				...selectedConfig.addMutationPayload,
			});
		}
	};

	return (
		<Card className="mb-2">
			<h2>Bestehen</h2>

			<ConfigItem<PassContentConfigType, string>
				isPresentational={false}
				configType={configType}
				canEdit={canEdit}
				configOptions={options}
				onChange={handleOnChange}
			/>
		</Card>
	);
};
