import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { AccountEditRoutes } from "@screens/account-edit";
import { AccountsScreen } from "./accounts.screen";

export const AccountsRoutes: RouteDefinition[] = [
	{
		path: Paths.accounts.path,
		element: <AccountsScreen />,
		requiredPermissions: ["UserInAccountPermission_Auth_Read"],
	},
	...AccountEditRoutes,
];
