import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { DefaultTextFieldComponent } from "../../components/DefaultTextInput";
import { ValidatedField } from "../../components/ValidatedField";

type FilterState = {
	username?: string;
	email?: string;
};

type OwnProps = {
	refetch: (username?: string, email?: string) => void;
};

export const OrdersTableFilters = ({ refetch }: OwnProps) => {
	const initialValues = {
		username: undefined,
		email: undefined,
	};

	const formik = useFormik<FilterState>({
		initialValues,
		onSubmit: (values: FilterState, { setSubmitting }) => {
			refetch(values.username, values.email);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<FilterState, string>
					name="username"
					label="Vor-/Nachname"
					className="mr-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<FilterState, string>
					name="email"
					label="Email"
					className="mr-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={clearForm}
					icon="pi pi-times"
					className="h-3rem ml-auto"
				/>
				<Button
					disabled={false}
					type="submit"
					icon="pi pi-search"
					className="h-3rem ml-2"
				/>
			</div>
		</form>
	);
};
