import type React from "react";
import { createContext } from "react";

export interface ILicenseDefinitionsScreenContext {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
}

export const LicenseDefinitionsScreenContext = createContext<ILicenseDefinitionsScreenContext>({
	connectionId: "",
	setConnectionId: () => {},
});
