import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment academiesTagsTable_QueryFragment on Query
	@refetchable(queryName: "academiesTagsTable_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String" }
		last: { type: "Int" }
		before: { type: "String" }
		name: { type: "String" }
		isClickable: { type: "Boolean" }
		isTopic: { type: "Boolean" }
	) {
		Admin {
			AcademiesTag {
				RootTags(
					first: $first
					after: $after
					last: $last
					before: $before
					name: $name
					isClickable: $isClickable
					isTopic: $isTopic
				) @connection(key: "academiesTagsTable_RootTags") {
					__id
					edges {
						node {
							...academiesTagsTable_AcademiesTagFragment
						}
					}
				}
			}
		}
	}
`;

export const ACADEMIES_TAG_FRAGMENT = graphql`
	fragment academiesTagsTable_AcademiesTagFragment on AcademiesTag @inline {
		id
		... on AcademiesRootTag {
			kind
			canBeDeleted
			data {
				name
				isClickable
				isTopic
			}
			children {
				edges {
					node {
						id
						data {
							name
							isTopic
							isClickable
						}
						canBeDeleted
						subId
						kind
						... on AcademiesChildTag {
							...editAcademiesTagButton_AcademiesTagFragment
						}
					}
				}
			}
		}
		...editAcademiesTagButton_AcademiesTagFragment
	}
`;
