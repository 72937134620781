import { useFormik } from "formik";
import { Button } from "primereact/button";
import * as Yup from "yup";
import { BranchDropdownComponent } from "@components/branch-dropdown/branch-dropdown.component";
import { DefaultSwitchComponent, DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { TeamSizeDropdownComponent } from "@components/team-size-dropdown";
import { ValidatedField } from "@components/ValidatedField";
import { WarningUnsavedChangesDialog } from "@components/WarningUnsavedChangesDialog";
import { FileSelectionField, type FileV2 } from "@features/files/file-selection-field";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { useCallbackPrompt } from "@hooks/UseCallBackPrompt";
import { type Branch, type TeamSize } from "@relay/userEditScreen_EditMutation.graphql";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "@store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "@store/store.redux";
import { type EditUserFormProps, type EditUserFormState } from "./edit-user-form.interface";

export const EditUserForm = ({ loading, initialValues, onSubmit }: EditUserFormProps) => {
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const isEditing = arrayOfEditedForms.length > 0;
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] =
		useCallbackPrompt(isEditing);

	const formId = "EditUserForm";
	const formik = useFormik<EditUserFormState>({
		initialValues: {
			firstName: initialValues?.firstName ?? "",
			lastName: initialValues?.lastName ?? "",
			email: initialValues?.email ?? "",
			activated: initialValues?.activated ?? false,
			branch: initialValues?.branch,
			teamSize: initialValues?.teamSize,
			position: initialValues?.position,
			adsOptIn: initialValues?.adsOptIn ?? false,
			image: initialValues?.image as FileV2,
		},
		validationSchema: Yup.object().shape({
			firstName: Yup.string().required("Vorname wird benötigt"),
			lastName: Yup.string().required("Nachname wird benötigt"),
			email: Yup.string().email().required("Email wird benötigt"),
			activated: Yup.bool().required("Aktivierungsstatus wird benötigt"),
			branch: Yup.string().optional(),
			teamSize: Yup.string().optional(),
			position: Yup.string().optional(),
			adsOptIn: Yup.bool().optional(),
			imageId: Yup.string().optional(),
		}),
		onSubmit: (values, { setSubmitting }) => {
			const { image: _ = {}, ...newValues } = values;
			onSubmit({
				...newValues,
				imageId: values.image?.id,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	const canUpload = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid w-30rem">
				<ValidatedField<EditUserFormState, string>
					name={"firstName"}
					label={"Vornames"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, string>
					name={"lastName"}
					label={"Nachname"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, string>
					name={"email"}
					label={"E-Mail"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, Branch>
					name={"branch"}
					label={"Branche"}
					component={BranchDropdownComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, TeamSize>
					name={"teamSize"}
					label={"Teamgröße"}
					component={TeamSizeDropdownComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, string>
					name={"position"}
					label={"Position"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, boolean>
					name={"activated"}
					label={"Aktiviert"}
					component={DefaultSwitchComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, boolean>
					name={"adsOptIn"}
					label={"Werbung"}
					component={DefaultSwitchComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditUserFormState, FileV2>
					name={"image"}
					label={"Profilbild"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
					component={({ fieldName, fieldValue, updateField, onChange }) => {
						return (
							<FileSelectionField
								name={fieldName}
								selectedFile={fieldValue}
								setSelectedFile={updateField}
								filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
								onChange={onChange}
								canUploadFiles={canUpload}
								canDeleteFiles={canDelete}
							/>
						);
					}}
				/>

				<Button
					disabled={loading || !isEditing}
					type="submit"
					label="Speichern"
					className="p-mt-2"
				/>
			</form>
		</>
	);
};
