/**
 * @generated SignedSource<<a4fae7e7a5872b9563f36c947daf18bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRootNodePartInput = {
  clientMutationId?: string | null;
  firstReleasedAt?: string | null;
  iconId?: string | null;
  rootNodeId: string;
  searchWords?: string | null;
  tagIds: ReadonlyArray<string>;
};
export type EditRootNodePartForm_EditRootNodePartMutation$variables = {
  input: EditRootNodePartInput;
};
export type EditRootNodePartForm_EditRootNodePartMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly editRootNodePart: {
        readonly editedNode: {
          readonly " $fragmentSpreads": FragmentRefs<"EditRootNodePartForm_TreeNodeFragment" | "Node_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type EditRootNodePartForm_EditRootNodePartMutation = {
  response: EditRootNodePartForm_EditRootNodePartMutation$data;
  variables: EditRootNodePartForm_EditRootNodePartMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EditRootNodePartForm_EditRootNodePartMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditRootNodePartPayload",
                "kind": "LinkedField",
                "name": "editRootNodePart",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "editedNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "EditRootNodePartForm_TreeNodeFragment"
                      },
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "Node_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EditRootNodePartForm_EditRootNodePartMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditRootNodePartPayload",
                "kind": "LinkedField",
                "name": "editRootNodePart",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "editedNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "structureDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "firstReleasedAt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "icon",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "searchWords",
                                "storageKey": null
                              }
                            ],
                            "type": "RootStructureDefinition",
                            "abstractKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "contentKind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "rewards",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "kind",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "childRefs",
                                "storageKey": null
                              }
                            ],
                            "type": "BranchTypeDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Versioning",
                        "kind": "LinkedField",
                        "name": "versioning",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "draftVersion",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "releaseVersion",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c208ce7bcede07a3198f3dd8ac2aca58",
    "id": null,
    "metadata": {},
    "name": "EditRootNodePartForm_EditRootNodePartMutation",
    "operationKind": "mutation",
    "text": "mutation EditRootNodePartForm_EditRootNodePartMutation(\n  $input: EditRootNodePartInput!\n) {\n  Admin {\n    Tree {\n      editRootNodePart(input: $input) {\n        editedNode {\n          ...EditRootNodePartForm_TreeNodeFragment\n          ...Node_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment EditRootNodePartForm_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    ... on RootStructureDefinition {\n      firstReleasedAt\n      icon {\n        id\n        name\n      }\n      searchWords\n    }\n  }\n}\n\nfragment NodeConfigurationBadges_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    definitionType\n  }\n  typeDefinition {\n    __typename\n    definitionType\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      rewards {\n        __typename\n        kind\n        id\n      }\n    }\n  }\n  versioning {\n    draftVersion\n    releaseVersion\n  }\n}\n\nfragment Node_TreeNodeFragment on TreeNode {\n  id\n  structureDefinition {\n    __typename\n    title\n  }\n  typeDefinition {\n    __typename\n    definitionType\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      contentKind\n    }\n    ... on BranchTypeDefinition {\n      childRefs\n    }\n  }\n  ...NodeConfigurationBadges_TreeNodeFragment\n}\n"
  }
};
})();

(node as any).hash = "31778ce3bf78ece7914ead7b515d3b7a";

export default node;
