import React from "react";
import { useFragment } from "react-relay";
import { ORDER_FRAGMENT } from "./payment-provider-link.graphql";
import { type PaymentProviderLinkProps } from "./payment-provider-link.interface";
import { type paymentProviderLink_OrderFragment$key } from "../../../__generated__/paymentProviderLink_OrderFragment.graphql";
import { PaymentMethod } from "../../../translations/PaymentMethod";

export const PaymentProviderLink = ({ orderFragmentRef }: PaymentProviderLinkProps) => {
	const order = useFragment<paymentProviderLink_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);
	const isProduction = process.env.APP_ENVIRONMENT === "prod";

	if (order.status === "PaymentReceived" || order.status === "Purchased") {
		const paymentString = PaymentMethod[order.paymentData?.paymentMethod || ""];
		const paymentIntentId = order.paymentData?.paymentIntentId;

		switch (order.paymentData?.dataType) {
			case "Stripe":
			case "StripeSepa":
				if (isProduction && paymentIntentId) {
					return (
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={`https://dashboard.stripe.com/payments/${paymentIntentId}`}
						>
							{paymentString}
						</a>
					);
				}
				if (paymentIntentId) {
					return (
						<a
							rel="noopener noreferrer"
							target="_blank"
							href={`https://dashboard.stripe.com/test/payments/${paymentIntentId}`}
						>
							{paymentString}
						</a>
					);
				} else return <>{paymentString}</>;
			default:
				return <>{paymentString}</>;
		}
	} else {
		return <>Unbezahlt</>;
	}
};
