import { Button, ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import { inputFieldClass } from "@features/users/select-single-user-form/select-single-user-form.styles";
import { SelectUserField } from "@features/users/select-user-field";
import {
	SelectMultipleUsersFormProps,
	SelectMultipleUsersFormState,
} from "./select-multiple-users-form.types";

export const SelectMultipleUsersForm = ({
	onUsersSelected,
	disabled,
	accountId,
}: SelectMultipleUsersFormProps) => {
	const formik = useFormik<SelectMultipleUsersFormState>({
		initialValues: {},
		isInitialValid: false,
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			userIds: Yup.array()
				.of(Yup.string())
				.min(1, "Mindestens ein Benutzer wird benötigt.")
				.required("Bitte wähle mindestens einen Benutzer aus."),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onUsersSelected(data.userIds!);
			setSubmitting(false);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="w-12 p-fluid mb-5">
			<ValidatedField<SelectMultipleUsersFormState, string[]>
				className={inputFieldClass}
				name={"userIds"}
				label={"Wähle Benutzer aus"}
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectUserField accountId={accountId} {...renderConfig} isMultipleSelection />
				)}
			/>
			<Button
				disabled={!formik.isValid || disabled}
				label="Benutzer hinzufügen"
				type={ButtonType.Submit}
			/>
		</form>
	);
};
