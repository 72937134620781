import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type removeWrongAnswerFromMatrixElementCellButton_RemoveWrongAnswerFromMatrixElementCellMutation } from "@relay/removeWrongAnswerFromMatrixElementCellButton_RemoveWrongAnswerFromMatrixElementCellMutation.graphql";
import { REMOVE_WRONG_ANSWER_FROM_MATRIX_ELEMENT_CELL_MUTATION } from "./remove-wrong-answer-from-matrix-element-cell-button.graphql";
import { type RemoveWrongAnswerFromMatrixElementCellButtonProps } from "./remove-wrong-answer-from-matrix-element-cell-button.types";

export const RemoveWrongAnswerFromMatrixElementCellButton = ({
	matrixElementId,
	wrongAnswerId,
	matrixCellId,
}: RemoveWrongAnswerFromMatrixElementCellButtonProps) => {
	const [removeWrongAnswerFromMatrixElementCell] =
		useMutation<removeWrongAnswerFromMatrixElementCellButton_RemoveWrongAnswerFromMatrixElementCellMutation>(
			REMOVE_WRONG_ANSWER_FROM_MATRIX_ELEMENT_CELL_MUTATION,
		);

	return (
		<Button
			type="button"
			icon="pi pi-trash"
			className="m-1"
			onClick={() => {
				removeWrongAnswerFromMatrixElementCell({
					variables: {
						input: {
							matrixElementId,
							wrongAnswerId,
							matrixCellId,
						},
					},
				});
			}}
		/>
	);
};
