import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { OrderEditRoutes } from "@screens/order-edit";
import { OrdersScreen } from "./orders.screen";

export const OrdersRoutes: RouteDefinition[] = [
	{
		path: Paths.orders.path,
		element: <OrdersScreen />,
		requiredPermissions: ["UserInAccountPermission_OrderAdmin_Read"],
	},
	...OrderEditRoutes,
];
