/**
 * @generated SignedSource<<687fde5f6aebd6313ba34944090ecef3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type instructorEditScreen_InstructorFragment$data = {
  readonly description: string | null;
  readonly id: string;
  readonly image: {
    readonly id: string;
    readonly name: string;
    readonly url: string | null;
  } | null;
  readonly name: string;
  readonly position: string | null;
  readonly shortDescription: string | null;
  readonly superId: string;
  readonly " $fragmentType": "instructorEditScreen_InstructorFragment";
};
export type instructorEditScreen_InstructorFragment$key = {
  readonly " $data"?: instructorEditScreen_InstructorFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"instructorEditScreen_InstructorFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "instructorEditScreen_InstructorFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "superId",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "position",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "InstructorImpl",
  "abstractKey": null
};
})();

(node as any).hash = "152e931d9b35fccb556b8d8b277d21c4";

export default node;
