import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { BlockPermanentlyRestartIfFailedContentConfigForm } from "./BlockPermanentlyRestartIfFailedContentConfigForm";
import { BlockTemporarilyRestartIfFailedContentConfigForm } from "./BlockTemporarilyRestartIfFailedContentConfigForm";
import { type Config, type ConfigurationGraphqlInterface } from "./Config.interfaces";
import { ConfigDropdown, type ConfigDropdownOptions } from "./ConfigDropdown";
import { ConfigItem } from "./ConfigItem";
import { ConfigPreview } from "./ConfigPreview";
import { type BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key } from "../../../__generated__/BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment.graphql";
import { type BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key } from "../../../__generated__/BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment.graphql";
import {
	type AddBlockPermanentlyRestartIfFailedContentConfigInput,
	type RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation,
} from "../../../__generated__/RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation.graphql";
import {
	type AddBlockTemporarilyRestartIfFailedContentConfigInput,
	type RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation,
} from "../../../__generated__/RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation.graphql";
import {
	type AddCanNotRestartIfFailedContentConfigInput,
	type RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation,
} from "../../../__generated__/RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation.graphql";
import { type RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation } from "../../../__generated__/RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation.graphql";
import {
	type RestartIfFailedContentConfigEditor_TreeNodeFragment$key,
	type RestartIfFailedContentConfigType,
} from "../../../__generated__/RestartIfFailedContentConfigEditor_TreeNodeFragment.graphql";

const TREE_NODE_FRAGMENT = graphql`
	fragment RestartIfFailedContentConfigEditor_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ContentTypeDefinition {
				restartIfFailedConfigs {
					id
					configType
					... on BlockTemporarilyRestartIfFailedContentConfig {
						daysToBlock
					}
					... on BlockPermanentlyRestartIfFailedContentConfig {
						maximumTries
					}
					...BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment
					...BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment
				}
			}
		}
		...BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment
		...BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment
	}
`;

const ADD_CAN_NOT_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation(
		$input: AddCanNotRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				addCanNotRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_BLOCK_TEMPORARILY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation(
		$input: AddBlockTemporarilyRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				addBlockTemporarilyRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const ADD_BLOCK_PERMANENTLY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation(
		$input: AddBlockPermanentlyRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				addBlockPermanentlyRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

const REMOVE_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION = graphql`
	mutation RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation(
		$input: RemoveRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				removeRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type Props = {
	treeNodeFragmentRef: RestartIfFailedContentConfigEditor_TreeNodeFragment$key;
};

export const RestartIfFailedContentConfigEditor = ({ treeNodeFragmentRef }: Props) => {
	const treeNode = useFragment<RestartIfFailedContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const [
		removeNonLogicalRestartIfFailedContentConfig,
		isRemovingNonLogicalRestartIfFailedContentConfig,
	] =
		useMutation<RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation>(
			REMOVE_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addCanNotRestartIfFAiledRestartIfFailedCConfig] =
		useMutation<RestartIfFailedContentConfigEditor_AddCanNotRestartIfFailedContentConfigMutation>(
			ADD_CAN_NOT_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addBlockTemorarilyRestartIfFailedCConfig] =
		useMutation<RestartIfFailedContentConfigEditor_AddBlockTemporarilyRestartIfFailedContentConfigMutation>(
			ADD_BLOCK_TEMPORARILY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);
	const [addBlockPermanentlyRestartIfFailedContentConfig] =
		useMutation<RestartIfFailedContentConfigEditor_AddBlockPermanentlyRestartIfFailedContentConfigMutation>(
			ADD_BLOCK_PERMANENTLY_RESTART_IF_FAILED_CONTENT_CONDITION_CONFIGURATION_MUTATION,
		);

	const canNotRestartIfFailed: Config<
		RestartIfFailedContentConfigType,
		AddCanNotRestartIfFailedContentConfigInput
	> = {
		configKey: "RestartIfFailedContent_CanNot",
		addMutation: (input: AddCanNotRestartIfFailedContentConfigInput) => {
			addCanNotRestartIfFAiledRestartIfFailedCConfig({
				variables: {
					input,
				},
			});
		},
		editable: false,
	};

	const blockTemporarly: Config<
		RestartIfFailedContentConfigType,
		AddBlockTemporarilyRestartIfFailedContentConfigInput
	> = {
		configKey: "RestartIfFailedContent_BlockTemporarily",
		addMutation: (input: AddBlockTemporarilyRestartIfFailedContentConfigInput) => {
			addBlockTemorarilyRestartIfFailedCConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			daysToBlock: 30,
		},
		editable: true,
	};

	const blockPermanently: Config<
		RestartIfFailedContentConfigType,
		AddBlockPermanentlyRestartIfFailedContentConfigInput
	> = {
		configKey: "RestartIfFailedContent_BlockPermanently",
		addMutation: (input: AddBlockPermanentlyRestartIfFailedContentConfigInput) => {
			addBlockPermanentlyRestartIfFailedContentConfig({
				variables: {
					input,
				},
			});
		},
		addMutationPayload: {
			maximumTries: 3,
		},
		editable: true,
	};

	const configs: Array<Config<RestartIfFailedContentConfigType, any | never>> = [
		canNotRestartIfFailed,
		blockTemporarly,
		blockPermanently,
	];

	const options: Array<ConfigDropdownOptions<RestartIfFailedContentConfigType>> = configs.map(
		(c) => {
			return {
				label: c.configKey,
				value: c.configKey,
			};
		},
	);

	const previewOptions: Array<ConfigDropdownOptions<RestartIfFailedContentConfigType>> =
		configs.map((c) => {
			const selectedConfig = treeNode.typeDefinition.restartIfFailedConfigs?.find(
				(r) => r.configType === c.configKey,
			);

			if (selectedConfig && c.configKey === "RestartIfFailedContent_BlockTemporarily") {
				return {
					label: `(${selectedConfig?.daysToBlock})`,
					value: c.configKey,
				};
			} else if (
				selectedConfig &&
				c.configKey === "RestartIfFailedContent_BlockPermanently"
			) {
				return {
					label: `(${selectedConfig?.maximumTries})`,
					value: c.configKey,
				};
			} else {
				return {
					label: c.configKey,
					value: c.configKey,
				};
			}
		});

	return (
		<Card className="mb-2">
			<h2>Wiederholen falls noch nicht bestanden</h2>

			<ConfigDropdown<RestartIfFailedContentConfigType>
				configOptions={options}
				onChange={(e) => {
					const selectedConfig = configs.find((c) => c.configKey === e.value);

					if (selectedConfig) {
						selectedConfig.addMutation({
							contentNodeId: treeNode.id,
							...selectedConfig.addMutationPayload,
						});
					}
				}}
				isPresentational={false}
			/>

			<ConfigPreview<ConfigurationGraphqlInterface<RestartIfFailedContentConfigType>>
				selectedConfigs={treeNode.typeDefinition.restartIfFailedConfigs as any[]}
				template={(
					configuration: ConfigurationGraphqlInterface<RestartIfFailedContentConfigType>,
				) => (
					<>
						<ConfigItem<RestartIfFailedContentConfigType, string>
							isPresentational={true}
							isLoading={isRemovingNonLogicalRestartIfFailedContentConfig}
							configType={configuration.configType}
							canEdit={
								configs.find((c) => c.configKey === configuration.configType)
									?.editable as boolean
							}
							configOptions={previewOptions}
							onDelete={() => {
								removeNonLogicalRestartIfFailedContentConfig({
									variables: {
										input: {
											contentNodeId: treeNode.id,
											configId: configuration.id,
										},
									},
								});
							}}
							editDialog={() => (
								<>
									{configuration.configType ===
										"RestartIfFailedContent_BlockTemporarily" && (
										<BlockTemporarilyRestartIfFailedContentConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key
											}
										/>
									)}

									{configuration.configType ===
										"RestartIfFailedContent_BlockPermanently" && (
										<BlockPermanentlyRestartIfFailedContentConfigForm
											treeNodeFragmentRef={treeNode}
											configurationFragmentRef={
												configuration as unknown as BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key
											}
										/>
									)}
								</>
							)}
						/>
					</>
				)}
			/>
		</Card>
	);
};
