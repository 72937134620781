import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { EmailTemplateEditScreen } from "./email-template-edit.screen";

export const EmailTemplateEditRoutes: RouteDefinition[] = [
	{
		path: Paths.emailTemplates.withIdPlaceholder().edit.path,
		element: <EmailTemplateEditScreen />,
		requiredPermissions: ["UserInAccountPermission_Email_Templates"],
	},
];
