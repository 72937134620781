/**
 * @generated SignedSource<<021f75e3ca24427a2e8852228314ce81>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageDefinitionForm_MessageDefinitionFragment$data = {
  readonly node: {
    readonly desktopNotification?: {
      readonly content: string;
      readonly icon: {
        readonly id: string;
        readonly name: string;
      };
      readonly title: string;
      readonly url: string;
    } | null;
    readonly inAppNotification?: {
      readonly content: string;
      readonly title: string;
    } | null;
    readonly internalTitle?: string;
    readonly showUntil?: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"desktopNotificationForm_DesktopNotificationFragment" | "inAppNotificationForm_InAppNotificationFragment" | "messagePagesEditor_PagesFragment">;
  } | null;
  readonly " $fragmentType": "messageDefinitionForm_MessageDefinitionFragment";
};
export type messageDefinitionForm_MessageDefinitionFragment$key = {
  readonly " $data"?: messageDefinitionForm_MessageDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageDefinitionForm_MessageDefinitionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "content",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./messageDefinition_Refetch.graphql')
    }
  },
  "name": "messageDefinitionForm_MessageDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "internalTitle",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "showUntil",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "InAppNotification",
              "kind": "LinkedField",
              "name": "inAppNotification",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "DesktopNotification",
              "kind": "LinkedField",
              "name": "desktopNotification",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "File",
                  "kind": "LinkedField",
                  "name": "icon",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "id",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "inAppNotificationForm_InAppNotificationFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "desktopNotificationForm_DesktopNotificationFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "messagePagesEditor_PagesFragment"
            }
          ],
          "type": "MessageDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "1350a737515df694a84cca8a394e8703";

export default node;
