import graphql from "babel-plugin-relay/macro";

export const ORDER_HISTORY_FRAGMENT = graphql`
	fragment orderHistoryEvent_OrderHistoryFragment on OrderHistory {
		event {
			kind
			... on InvoiceSentUpdate {
				invoiceId
				invoiceNumber
			}
			... on CreditNoteSentUpdate {
				creditNoteId
				creditNoteNumber
			}
		}
	}
`;
