import { type BranchDropDownType } from "@components/branch-dropdown/branch-dropdown.types";

export const branchOptions: BranchDropDownType[] = [
	{
		value: "adviceAndConsulting",
		label: "Beratung / Consulting",
	},
	{
		value: "analysisAndStatistic",
		label: "Analyse und Statistik",
	},
	{
		value: "financeAccountingAndControlling",
		label: "Finanzen, Rechnungswesen und Controlling",
	},
	{
		value: "graphicsAndDesign",
		label: "Grafik, Design",
	},
	{
		value: "healthMedicineAndSocialAffairs",
		label: "Gesundheit, Medizin, Soziales",
	},
	{
		value: "personnelAndHr",
		label: "Personalwesen und HR",
	},
	{
		value: "processPlanningAndQualityAssurance",
		label: "Prozessplanung und Qualitätssicherung",
	},
	{
		value: "itAndSoftwareDevelopment",
		label: "IT und Softwareentwicklung",
	},
	{
		value: "legalServices",
		label: "Recht",
	},
	{
		value: "productManagement",
		label: "Produktmanagement",
	},
	{
		value: "marketingAndAdvertising",
		label: "Marketing und Werbung",
	},
	{
		value: "otherFieldsOfActivity",
		label: "Sonstige Tätigkeitsfelder",
	},
];
