import { graphql } from "babel-plugin-relay/macro";

export const EXTERNAL_LICENSE_POOL_CONNECTION_FRAGMENT = graphql`
	fragment createExternalLicenseRewardButton_ExternalLicensePoolsConnectionFragment on ExternalLicensePoolsConnection {
		edges {
			node {
				id
			}
		}
	}
`;

export const TREE_NODE_FRAGMENT = graphql`
	fragment createExternalLicenseRewardButton_TreeNodeFragment on TreeNode {
		id
		typeDefinition {
			... on ELearningContentTypeDefinition {
				rewards {
					id
					kind
				}
			}
		}
	}
`;

export const ADD_EXTERNAL_LICENSE_REWARD_MUTATION = graphql`
	mutation createExternalLicenseRewardButton_AddExternalLicenseRewardMutation(
		$input: AddExternalLicenseRewardInput!
	) {
		Admin {
			Tree {
				addExternalLicenseReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
