import { type PathParams } from "@thekeytechnology/epic-ui";
import { Button } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { RadioButton, type RadioButtonChangeEvent } from "primereact/radiobutton";
import { Divider } from "primereact/divider";
import { useRef, useState } from "react";
import { useLazyLoadQuery, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type accountEditBaseDataScreen_EditBusinessBaseDataMutation } from "@relay/accountEditBaseDataScreen_EditBusinessBaseDataMutation.graphql";
import { type accountEditBaseDataScreen_EditPrivateBaseDataMutation } from "@relay/accountEditBaseDataScreen_EditPrivateBaseDataMutation.graphql";
import { type accountEditBaseDataScreen_Query } from "@relay/accountEditBaseDataScreen_Query.graphql";
import { type AccountsPath } from "@screens/accounts";
import { BaseScreen } from "@screens/BaseScreen";
import {
	QUERY,
	EDIT_BUSINESS_BASE_DATA_MUTATION,
	EDIT_PRIVATE_BASE_DATA_MUTATION,
} from "./account-edit-base-data.graphql";
import {
	AccountType,
	type AccountFormState,
	type BusinessBaseDataSubformState,
	type PrivateBaseDataSubformState,
} from "./account-edit-base-data.types";
import { isBusinessForm, isPrivateForm } from "./account-edit-base-data.utils";
import { BusinessBaseDataForm } from "./parts/business-base-data-form";
import type {
	BusinessBaseDataFormState,
	BusinessBaseDataRef,
} from "./parts/business-base-data-form/business-base-data-form.types";
import { PrivateBaseDataForm } from "./parts/private-base-data-form/private-base-data-form.component";
import type {
	PrivateBaseDataFormState,
	PrivateBaseDataRef,
} from "./parts/private-base-data-form/private-base-data-form.types";

export const AccountEditBaseData = () => {
	const { accountId } = useParams<PathParams<typeof AccountsPath>>();
	const [isLoading, setIsLoading] = useState(false);

	const query = useLazyLoadQuery<accountEditBaseDataScreen_Query>(
		QUERY,
		{
			id: accountId ?? "",
		},
		{ fetchPolicy: "network-only" },
	);
	const [editPrivateBaseData] =
		useMutation<accountEditBaseDataScreen_EditPrivateBaseDataMutation>(
			EDIT_PRIVATE_BASE_DATA_MUTATION,
		);
	const [editBusinessBaseData] =
		useMutation<accountEditBaseDataScreen_EditBusinessBaseDataMutation>(
			EDIT_BUSINESS_BASE_DATA_MUTATION,
		);

	const handleOnCompleted = () => {
		setIsLoading(false);
		toast.success("Stammdaten erfolgreich gespeichert");
	};

	const handleSaveBusinessBaseData = (values: Required<BusinessBaseDataSubformState>) => {
		setIsLoading(true);
		editBusinessBaseData({
			variables: {
				accountId: accountId!,
				baseData: {
					// accountName: values.accountName,
					companyName: values.subform.companyName,
					billingOffice: values.subform.billingOffice,
					companyLegalForm: values.subform.companyLegalForm!,
					invoiceEmail: values.subform.invoiceEmail,
					street: values.subform.street,
					houseNumber: values.subform.houseNumber,
					postalCode: values.subform.postalCode,
					city: values.subform.city,
					countryCode: values.subform.countryCode,
					phoneNumber: values.subform.phoneNumber,
					taxData: {
						taxNumber: values.subform.taxNumber,
						taxIdentificationNumber: values.subform.taxIdentificationNumber,
					},
					accountData: {
						iban: values.subform.iban,
						bic: values.subform.bic,
					},
				},
			},
			onCompleted: handleOnCompleted,
		});
	};

	const handleSavePrivateBaseData = (values: Required<PrivateBaseDataSubformState>) => {
		setIsLoading(true);
		editPrivateBaseData({
			variables: {
				accountId: accountId!,
				baseData: {
					// accountName: values.accountName,
					salutation: values.subform.salutation!,
					title: values.subform.title,
					firstName: values.subform.firstName,
					lastName: values.subform.lastName,
					street: values.subform.street,
					houseNumber: values.subform.houseNumber,
					postalCode: values.subform.postalCode,
					city: values.subform.city,
					countryCode: values.subform.countryCode,
					phoneNumber: values.subform.phoneNumber,
					// accountData: {
					// 	iban: values.subform.iban,
					// 	bic: values.subform.bic,
					// },
				},
			},
			onCompleted: handleOnCompleted,
		});
	};

	const businessAccountFormRef = useRef<BusinessBaseDataRef>(null);
	const privateAccountFormRef = useRef<PrivateBaseDataRef>(null);

	const form = useFormik<AccountFormState>({
		initialValues: {
			accountType:
				query?.Admin.AccountBaseData.AccountBaseData.__typename === "BusinessBaseData"
					? AccountType.Business
					: AccountType.Private,
			accountName: "", // query?.Admin.AccountBaseData.AccountBaseData.accountName ?? "",
			subform: undefined,
		},
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			accountName: Yup.string().required("Bitte gib einen Kontonamen ein."),
			accountType: Yup.mixed<AccountType>()
				.oneOf(Object.values(AccountType))
				.required("Bitte wähle einen Kontotyp aus."),
			subform: Yup.mixed().required(),
		}),
		onSubmit: (values) => {
			if (isBusinessForm(values)) {
				handleSaveBusinessBaseData(values);
			}
			if (isPrivateForm(values)) {
				handleSavePrivateBaseData(values);
			}
		},
	});

	const isBusinessAccount = form.values.accountType === AccountType.Business;

	const handleOnSave = () => {
		void form
			.setFieldValue("subform", undefined)
			.then(form.submitForm)
			.then(
				isBusinessAccount
					? businessAccountFormRef.current?.submit
					: privateAccountFormRef.current?.submit,
			);
	};

	const handlePrivateAccountOnChange = (event: RadioButtonChangeEvent) => {
		if (event.checked) {
			void form.setFieldValue("accountType", AccountType.Private);
		}
	};

	const handleBusinessAccountOnChange = (event: RadioButtonChangeEvent) => {
		if (event.checked) {
			void form.setFieldValue("accountType", AccountType.Business);
		}
	};

	const handleSubformOnSubmit = (
		values: BusinessBaseDataFormState | PrivateBaseDataFormState,
	) => {
		void form.setFieldValue("subform", values).then(form.submitForm);
	};

	return (
		<BaseScreen>
			<h1>Stammdaten bearbeiten</h1>

			<div className="flex flex-row gap-3">
				<div className="flex align-items-center">
					<RadioButton
						inputId="private"
						checked={!isBusinessAccount}
						onChange={handlePrivateAccountOnChange}
					/>
					<label htmlFor="private" className="ml-2">
						Privatkonto
					</label>
				</div>
				<div className="flex align-items-center">
					<RadioButton
						inputId="business"
						checked={isBusinessAccount}
						onChange={handleBusinessAccountOnChange}
					/>
					<label htmlFor="business" className="ml-2">
						Geschäftskonto
					</label>
				</div>
			</div>
			<div className="mt-4">
				<ValidatedField<AccountFormState, string>
					formikConfig={form}
					name="accountName"
					label="Kontoname*"
					placeholder="..."
					component={DefaultTextFieldComponent}
				/>
			</div>
			<Divider />
			{isBusinessAccount ? (
				<BusinessBaseDataForm
					ref={businessAccountFormRef}
					baseDataFragmentRef={query?.Admin.AccountBaseData.AccountBaseData}
					onSubmit={handleSubformOnSubmit}
				/>
			) : (
				<PrivateBaseDataForm
					ref={privateAccountFormRef}
					baseDataFragmentRef={query?.Admin.AccountBaseData.AccountBaseData}
					onSubmit={handleSubformOnSubmit}
				/>
			)}
			<Button
				loading={isLoading}
				label="Speichern"
				onClick={() => {
					handleOnSave();
				}}
			/>
		</BaseScreen>
	);
};
