import { Path, PathBase } from "@thekeytechnology/epic-ui";

export class AccountEditBaseDataPath extends PathBase {
	static readonly pathKey = "basedata";
}

export class AccountEditPath extends Path<typeof AccountEditPath> {
	static readonly pathKey = "edit";
	static readonly childPaths = [AccountEditBaseDataPath];
}
