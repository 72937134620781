import { graphql } from "babel-plugin-relay/macro";

export const CREATE_IMAGE_PAGE_MUTATION = graphql`
	mutation useMessagePageMutations_CreateImagePageMutation($input: CreateImagePageInput!) {
		Admin {
			MessageDefinitions {
				createImagePage(input: $input) {
					pageId
					messageDefinition {
						node {
							id
						}
					}
				}
			}
		}
	}
`;
export const CREATE_IMAGE_BUTTON_PAGE_MUTATION = graphql`
	mutation useMessagePageMutations_CreateImageButtonPageMutation(
		$input: CreateImageWithButtonPageInput!
	) {
		Admin {
			MessageDefinitions {
				createImageWithButtonPage(input: $input) {
					pageId
					messageDefinition {
						node {
							id
						}
					}
				}
			}
		}
	}
`;

export const EDIT_IMAGE_PAGE_MUTATION = graphql`
	mutation useMessagePageMutations_EditImagePageMutation($input: EditImagePageInput!) {
		Admin {
			MessageDefinitions {
				editImagePage(input: $input) {
					messageDefinition {
						node {
							id
						}
					}
				}
			}
		}
	}
`;
export const EDIT_IMAGE_BUTTON_PAGE_MUTATION = graphql`
	mutation useMessagePageMutations_EditImageButtonPageMutation(
		$input: EditImageWithButtonPageInput!
	) {
		Admin {
			MessageDefinitions {
				editImageWithButtonPage(input: $input) {
					messageDefinition {
						node {
							id
						}
					}
				}
			}
		}
	}
`;

export const DELETE_PAGE_MUTATION = graphql`
	mutation useMessagePageMutations_DeletePageMutation($input: DeletePageInput!) {
		Admin {
			MessageDefinitions {
				deletePage(input: $input) {
					messageDefinition {
						id
					}
				}
			}
		}
	}
`;
export const REARRANGE_PAGES_MUTATION = graphql`
	mutation useMessagePageMutations_RearrangePagesMutation($input: RearrangePagesInput!) {
		Admin {
			MessageDefinitions {
				rearrangePages(input: $input) {
					messageDefinition {
						id
					}
				}
			}
		}
	}
`;
