/**
 * @generated SignedSource<<139edd6e71ef2b39427632a20c7e27e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licensesTable_Refetch$variables = {
  after?: string | null;
  first?: number | null;
  nameOpt?: string | null;
};
export type licensesTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"licensesTable_LicensesFragment">;
};
export type licensesTable_Refetch = {
  response: licensesTable_Refetch$data;
  variables: licensesTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "nameOpt"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = {
  "kind": "Variable",
  "name": "nameOpt",
  "variableName": "nameOpt"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "ids",
    "value": ([]/*: any*/)
  },
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "licensesTable_Refetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "licensesTable_LicensesFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "licensesTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "AccountConnection",
                "kind": "LinkedField",
                "name": "SelectAccounts",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Account",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "extension",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": (v8/*: any*/),
                                    "concreteType": "LicenseConnection",
                                    "kind": "LinkedField",
                                    "name": "licenses",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "LicenseEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "License",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v5/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": null,
                                                "kind": "LinkedField",
                                                "name": "creationInfo",
                                                "plural": false,
                                                "selections": [
                                                  (v7/*: any*/),
                                                  (v9/*: any*/),
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "createdAt",
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "LicenseDefinition",
                                                    "kind": "LinkedField",
                                                    "name": "licenseDefinition",
                                                    "plural": false,
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": null,
                                                        "kind": "LinkedField",
                                                        "name": "data",
                                                        "plural": false,
                                                        "selections": [
                                                          (v7/*: any*/),
                                                          (v6/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      },
                                                      (v5/*: any*/)
                                                    ],
                                                    "storageKey": null
                                                  },
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "orderId",
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "LicenseCreatedByOrder",
                                                    "abstractKey": null
                                                  },
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "creatorId",
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "LicenseCreatedByAdmin",
                                                    "abstractKey": null
                                                  },
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "userId",
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "LicenseCreatedByRegistration",
                                                    "abstractKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              },
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": null,
                                                "kind": "LinkedField",
                                                "name": "usage",
                                                "plural": false,
                                                "selections": [
                                                  (v7/*: any*/),
                                                  (v9/*: any*/),
                                                  {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "kind": "ScalarField",
                                                        "name": "at",
                                                        "storageKey": null
                                                      },
                                                      {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "User",
                                                        "kind": "LinkedField",
                                                        "name": "forUser",
                                                        "plural": false,
                                                        "selections": [
                                                          {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "email",
                                                            "storageKey": null
                                                          },
                                                          (v5/*: any*/)
                                                        ],
                                                        "storageKey": null
                                                      }
                                                    ],
                                                    "type": "LicenseUsageUsedForTree",
                                                    "abstractKey": null
                                                  }
                                                ],
                                                "storageKey": null
                                              },
                                              (v7/*: any*/)
                                            ],
                                            "storageKey": null
                                          },
                                          (v10/*: any*/)
                                        ],
                                        "storageKey": null
                                      },
                                      (v11/*: any*/),
                                      {
                                        "kind": "ClientExtension",
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__id",
                                            "storageKey": null
                                          }
                                        ]
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": (v8/*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "licensesTable_licenses",
                                    "kind": "LinkedHandle",
                                    "name": "licenses"
                                  }
                                ],
                                "type": "AccountExtensionImpl",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v11/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v4/*: any*/),
                "filters": [
                  "nameOpt",
                  "ids"
                ],
                "handle": "connection",
                "key": "licensesTable_SelectAccounts",
                "kind": "LinkedHandle",
                "name": "SelectAccounts"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6a8c3c9f7823105b98e8bad012d114ba",
    "id": null,
    "metadata": {},
    "name": "licensesTable_Refetch",
    "operationKind": "query",
    "text": "query licensesTable_Refetch(\n  $after: String\n  $first: Int\n  $nameOpt: String\n) {\n  ...licensesTable_LicensesFragment_2HHA3u\n}\n\nfragment licensesTable_AccountFragment_2HEEH6 on Account {\n  id\n  name\n  extension {\n    __typename\n    ... on AccountExtensionImpl {\n      licenses(first: $first, after: $after) {\n        edges {\n          node {\n            ...licensesTable_LicenseFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment licensesTable_LicenseFragment on License {\n  id\n  creationInfo {\n    __typename\n    kind\n    createdAt\n    licenseDefinition {\n      data {\n        __typename\n        name\n      }\n      id\n    }\n    ... on LicenseCreatedByOrder {\n      orderId\n    }\n    ... on LicenseCreatedByAdmin {\n      creatorId\n    }\n    ... on LicenseCreatedByRegistration {\n      userId\n    }\n  }\n  usage {\n    __typename\n    kind\n    ... on LicenseUsageUsedForTree {\n      at\n      forUser {\n        email\n        id\n      }\n    }\n  }\n}\n\nfragment licensesTable_LicensesFragment_2HHA3u on Query {\n  Admin {\n    Auth {\n      SelectAccounts(first: $first, after: $after, nameOpt: $nameOpt, ids: []) {\n        edges {\n          node {\n            ...licensesTable_AccountFragment_2HEEH6\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "9380f49a3bf1961bcb40db8c13a8de2b";

export default node;
