import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query settingsScreen_Query {
		Admin {
			Settings {
				Settings {
					globalDiscountCode {
						id
						code
					}
					id
				}
			}
		}
		...discountCodeSelect_QueryFragment @arguments(ids: [])
	}
`;

export const SET_GLOBAL_DISCOUNT_CODE_MUTATION = graphql`
	mutation settingsScreen_SetGlobalDiscountCodeMutation($input: SetGlobalDiscountCodeIdInput!) {
		Admin {
			Settings {
				setGlobalDiscountCodeId(input: $input) {
					settings {
						id
						globalDiscountCode {
							id
							code
						}
					}
					clientMutationId
				}
			}
		}
	}
`;
