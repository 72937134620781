import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type addRowToMatrixElementButton_AddRowToMatrixElementMutation } from "@relay/addRowToMatrixElementButton_AddRowToMatrixElementMutation.graphql";
import { ADD_ROW_TO_MATRIX_ELEMENT_MUTATION } from "./add-row-to-matrix-element-button.graphql";
import { type AddRowToMatrixElementButtonProps } from "./add-row-to-matrix-element-button.types";

export const AddRowToMatrixElementButton = ({
	matrixElementId,
}: AddRowToMatrixElementButtonProps) => {
	const [addRowToMatrixElement] =
		useMutation<addRowToMatrixElementButton_AddRowToMatrixElementMutation>(
			ADD_ROW_TO_MATRIX_ELEMENT_MUTATION,
		);

	return (
		<Button
			type="button"
			className="m-2 flex flex-grow-1"
			label={"Neue Zeile"}
			onClick={() => {
				addRowToMatrixElement({
					variables: {
						input: {
							matrixElementId,
							rowTitle: "Neue Zeile",
							correctAnswerContent: "korrekte Antwort",
						},
					},
				});
			}}
		/>
	);
};
