import { SalutationTypeOptions } from "./salutation-dropdown.consts";
import { type SalutationType, type SalutationTypeOptionsRecord } from "./salutation-dropdown.types";

// TODO: add-translations
export const getSalutationOptions = () => {
	const translations: SalutationTypeOptionsRecord = {
		[SalutationTypeOptions.Mr]: "Herr",
		[SalutationTypeOptions.Mrs]: "Frau",
		[SalutationTypeOptions.Divers]: "Divers",
		[SalutationTypeOptions.Unknown]: "Unbekannt",
	};
	return Object.keys(translations)
		.sort()
		.map((key) => ({
			value: key,
			label: translations[key as unknown as SalutationTypeOptions],
		}));
};

export const getAvailableSalutationType = (salutationType?: string): SalutationType | undefined => {
	if (
		salutationType &&
		Object.values(SalutationTypeOptions).includes(salutationType as SalutationTypeOptions)
	)
		return salutationType as SalutationType;
	return undefined;
};
