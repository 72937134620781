import { DispatchConditionKind } from "@relay/dispatchConditionDropdown_MessageDispatchFragment.graphql";

export const DISPATCHES_TRANSLATIONS: Record<DispatchConditionKind, string> = {
	AccountDispatchCondition: "Einzelne Accounts",
	AllUserDispatchCondition: "Alle Nutzer",
	UserDispatchCondition: "Einzelne Nutzer",
	OptInDispatchCondition: "Newsletter abonniert",
	TreeFinishedDispatchCondition: "Kurs abgeschlossen",
	TreeStartedDispatchCondition: "Kurs gestartet, aber nicht freigeschaltet",
	TreeUnlockedDispatchCondition: "Kurs freigeschaltet",
};
