import { TabPanel, TabView } from "primereact/tabview";
import { Toast } from "primereact/toast";
import { useCallback, useRef } from "react";
import { useMutation } from "react-relay";
import { type addUserToAccount_AddUserToAccountMutation } from "@relay/addUserToAccount_AddUserToAccountMutation.graphql";
import { SelectSingleUserForm } from "src/features/users/select-single-user-form";
import { ADD_USER_TO_ACCOUNT_MUTATION } from "./add-user-to-account.graphql";
import { type AddUserToAccountProps } from "./add-user-to-account.interface";
import { RegisterUser } from "../register-user";

export const AddUserToAccount = ({
	onUserAdded,
	accountId,
	connections,
}: AddUserToAccountProps) => {
	const [addUserToAccount, isAddingUserToAccount] =
		useMutation<addUserToAccount_AddUserToAccountMutation>(ADD_USER_TO_ACCOUNT_MUTATION);

	const toastRef = useRef<Toast>(null);

	const handleOnUsersSelected = useCallback(
		(userIds: string | string[]) => {
			const userId = Array.isArray(userIds) ? userIds[0] : userIds;

			addUserToAccount({
				variables: {
					input: {
						userId,
						accountId,
					},
					connections: connections || [],
				},
				onCompleted: () => {
					onUserAdded(userId);
				},
				onError: () => {
					toastRef.current?.show({
						severity: "error",
						summary: "Error",
						detail: "Benutzer konnte nicht hinzugefügt werden.",
					});
				},
			});
		},
		[addUserToAccount, accountId, toastRef.current],
	);

	return (
		<TabView>
			<TabPanel header="Benutzer erstellen">
				<RegisterUser
					accountId={accountId}
					connections={connections}
					onUserRegistered={onUserAdded}
				/>
			</TabPanel>
			<TabPanel header="Benutzer auswählen">
				<SelectSingleUserForm
					disabled={isAddingUserToAccount}
					onUserSelected={handleOnUsersSelected}
				/>
			</TabPanel>
			<Toast ref={toastRef} />
		</TabView>
	);
};
