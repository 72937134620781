import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ProductEditScreen } from "./product-edit.screen";

export const ProductEditRoutes: RouteDefinition[] = [
	{
		path: Paths.products.withIdPlaceholder().edit.path,
		element: <ProductEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_ProductAdmin_Read",
			"UserInAccountPermission_ProductAdmin_Modify",
		],
	},
];
