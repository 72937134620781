import { Button } from "primereact/button";
import React from "react";
import { useFragment } from "react-relay";
import { type creditNoteDataForm_OrderFragment$key } from "@relay/creditNoteDataForm_OrderFragment.graphql";
import { ORDER_FRAGMENT } from "./credit-note-data-form.graphql";
import { type CreditNoteDataFormProps } from "./credit-note-data-form.interface";
import { TextDisplayField } from "../../../../components/text-display-field/text-display-field.component";

export const CreditNoteDataForm = ({ orderFragmentRef }: CreditNoteDataFormProps) => {
	const order = useFragment<creditNoteDataForm_OrderFragment$key>(
		ORDER_FRAGMENT,
		orderFragmentRef,
	);

	const downloadCreditNotePDF = () => {
		if (order.creditNoteData?.creditNoteFile?.url) {
			const link = document.createElement("a");
			link.href =
				"data:application/octet-stream;base64," +
				order.creditNoteData?.creditNoteFile?.url;
			link.download = order.creditNoteData?.creditNoteNumber + ".pdf";
			link.click();
		}
	};

	return order.creditNoteData ? (
		<div>
			<div className="formgrid grid">
				<TextDisplayField
					label="Gutschrifts-ID"
					value={order.creditNoteData.creditNoteId}
				/>
				<TextDisplayField
					label="Gutschriftsnummer"
					value={order.creditNoteData.creditNoteId}
				/>
			</div>
			{order.creditNoteData.creditNoteFile?.url && (
				<Button
					onClick={downloadCreditNotePDF}
					label="PDF Download"
					icon="pi pi-download"
				/>
			)}
		</div>
	) : (
		<h4>Keine Gutschriftsdaten verfügbar</h4>
	);
};
