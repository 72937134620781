/**
 * @generated SignedSource<<4b4a0bb9029f9cc531e75f9312a2bfe0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type LicenseCreationInfoKind = "Admin" | "Order" | "Registration";
export type LicenseUsageKind = "Free" | "UsedForTree";
import { FragmentRefs } from "relay-runtime";
export type licensesTable_LicenseFragment$data = {
  readonly creationInfo: {
    readonly createdAt: string;
    readonly creatorId?: string;
    readonly kind: LicenseCreationInfoKind;
    readonly licenseDefinition: {
      readonly data: {
        readonly name: string;
      };
    } | null;
    readonly orderId?: string;
    readonly userId?: string;
  };
  readonly id: string;
  readonly usage: {
    readonly at?: string;
    readonly forUser?: {
      readonly email: string;
    } | null;
    readonly kind: LicenseUsageKind;
  };
  readonly " $fragmentType": "licensesTable_LicenseFragment";
};
export type licensesTable_LicenseFragment$key = {
  readonly " $data"?: licensesTable_LicenseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licensesTable_LicenseFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "licensesTable_LicenseFragment"
};

(node as any).hash = "1e0f29b439373f15c3bc79cc3518d086";

export default node;
