import { type PathParams } from "@thekeytechnology/epic-ui";
import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { useLazyLoadQuery, useFragment, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { Card } from "@components/card";
import { NoAccess } from "@components/no-access";
import { type FileV2 } from "@features/files/file-selection-field";
import { OrdersTable } from "@features/orders/orders-table/orders-table.component";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type userEditScreen_EditMutation } from "@relay/userEditScreen_EditMutation.graphql";
import { type userEditScreen_Query } from "@relay/userEditScreen_Query.graphql";
import { type userEditScreen_TriggerResendActivationMutation } from "@relay/userEditScreen_TriggerResendActivationMutation.graphql";
import { type userEditScreen_TriggerResetPasswordMutation } from "@relay/userEditScreen_TriggerResetPasswordMutation.graphql";
import { type userEditScreen_UserFragment$key } from "@relay/userEditScreen_UserFragment.graphql";
import { type UsersPath } from "@screens/users";
import { EditUserForm } from "./parts/edit-user-form";
import { type EditUserFormState } from "./parts/edit-user-form/edit-user-form.interface";
import {
	USER_QUERY,
	USER_FRAGMENT,
	EDIT_USER_MUTATION,
	TRIGGER_RESEND_ACTIVATION_MUTATION,
	TRIGGER_RESET_PASSWORD_MUTATION,
} from "./user-edit.graphql";
import { BaseScreen } from "../BaseScreen";

export const UserEditScreen = () => {
	const { userId } = useParams<PathParams<typeof UsersPath>>();
	const query = useLazyLoadQuery<userEditScreen_Query>(USER_QUERY, {
		id: userId || "",
		skip: !userId,
	});

	const user = useFragment<userEditScreen_UserFragment$key>(USER_FRAGMENT, query.node || null);

	const [updateUser, isUpdatingUser] =
		useMutation<userEditScreen_EditMutation>(EDIT_USER_MUTATION);

	const [triggerResendActivation, isResendingActivation] =
		useMutation<userEditScreen_TriggerResendActivationMutation>(
			TRIGGER_RESEND_ACTIVATION_MUTATION,
		);

	const [triggerResetPassword, isResetingPassword] =
		useMutation<userEditScreen_TriggerResetPasswordMutation>(TRIGGER_RESET_PASSWORD_MUTATION);

	const toastRef = useRef<Toast>(null);
	const handleOnUpdateUser = (form: EditUserFormState) => {
		updateUser({
			variables: {
				input: {
					userId: userId!,
					...form,
				},
			},
		});
	};

	const handleTriggerResendActivationOnClick = () => {
		confirmDialog({
			header: "Aktivierungs-E-Mail erneut versenden",
			message: "Bist du sicher, dass du die Aktivierungs-E-Mail erneut versenden möchtest?",
			icon: "pi pi-info-circle",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				triggerResendActivation({
					variables: {
						input: {
							userId: userId!,
						},
					},
					onCompleted: () => {
						toastRef.current?.show({
							severity: "success",
							summary: "Aktivierungs-E-Mail versandt",
							detail: "Die Aktivierungs-E-Mail wurde erfolgreich versandt.",
						});
					},
				}),
		});
	};

	const handleTriggerResetPasswordOnClick = () => {
		confirmDialog({
			header: "Passwort zurücksetzen",
			message: "Bist du sicher, dass du das Passwort zurücksetzen möchtest?",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				triggerResetPassword({
					variables: {
						input: {
							userId: userId!,
						},
					},
					onCompleted: () => {
						toastRef.current?.show({
							severity: "success",
							summary: "Passwort zurückgesetzt",
							detail: "Die E-Mail mit einem Reset-Link wurde erfolgreich versandt.",
						});
					},
				}),
		});
	};

	const canReadOrders = useHasPermissions(["UserInAccountPermission_OrderAdmin_Read"]);

	const canModifyOrders = useHasPermissions(["UserInAccountPermission_OrderAdmin_Modify"]);

	const canReadAuth = useHasPermissions(["UserInAccountPermission_AuthAdmin_Read"]);

	const canModifyAuth = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	if (!canReadAuth || !canModifyAuth) return <NoAccess />;

	return (
		<BaseScreen>
			<div className="flex flex-column align-items-start">
				<h1 className="mb-3">Benutzer bearbeiten</h1>
				<Card>
					<EditUserForm
						initialValues={{
							firstName: user?.extension.firstName ?? "",
							lastName: user?.extension.lastName ?? "",
							email: user?.email || "",
							activated: user?.activated || false,
							branch: user?.extension.branch ?? undefined,
							teamSize: user?.extension.teamSize ?? undefined,
							position: user?.extension.position ?? undefined,
							adsOptIn: user?.extension.adsOptIn ?? false,
							image: user?.extension.avatar as FileV2,
						}}
						loading={isUpdatingUser}
						onSubmit={handleOnUpdateUser}
					/>
				</Card>
				<Card className="mt-5" header={<h2 className="ml-3">Benutzerfunktionen</h2>}>
					<div className="flex flex-column align-items-start">
						<Button
							label="Aktivierungs-E-Mail erneut versenden"
							disabled={isResendingActivation}
							onClick={handleTriggerResendActivationOnClick}
						/>
						<Button
							className="mt-4"
							label="Passwort zurücksetzen"
							disabled={isResetingPassword}
							onClick={handleTriggerResetPasswordOnClick}
						/>
					</div>
				</Card>
				{canReadOrders && (
					<Card className="mt-6 w-full">
						<h2 className="mb-3">Bestellungen</h2>
						{
							<OrdersTable
								ordersFragmentRef={query}
								ordersPerPage={10}
								enableFilter={false}
								canModifyOrders={canModifyOrders}
							/>
						}
					</Card>
				)}
			</div>
			<ConfirmDialog />
			<Toast ref={toastRef} />
		</BaseScreen>
	);
};
