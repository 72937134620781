import React from "react";

import { DeleteMessageDispatchButtonProps } from "@screens/message-dispatches/delete-message-dispatch-button/delete-message-dispatch-button.types";

export const DeleteMessageDispatchButton = ({
	messageDispatchFragmentRef,
}: DeleteMessageDispatchButtonProps) => {
	// TODO implement Backend

	// const { connectionId } = useContext(MessageDispatchContext);
	// const toast = useRef<Toast>(null);
	//
	// const { showDialog, dialogComponent } = useDialogLogic();
	//
	// const messageDispatch = useFragment<deleteMessageDispatchButton_MessageDispatchFragment$key>(
	// 	MESSAGE_DISPATCH_FRAGMENT,
	// 	messageDispatchFragmentRef ?? null,
	// );
	//
	// const [deleteMessageDispatch, isDeletingMessageDispatch] =
	// 	useMutation<deleteMessageDefinitionButton_DeleteMessageDefinitionMutation>(
	// 		DELETE_MESSAGE_DISPATCH_MUTATION,
	// 	);
	// const handleOnDeleteClick = () => {
	// 	showDialog({
	// 		title: "Nachricht löschen",
	// 		content:
	// 			"Soll diese Nachrichten wirklich gelöscht werden? Das kann nicht rückgängig gemacht werden.",
	// 		dialogCallback: (result) => {
	// 			if (result === "Accept") {
	// 				deleteMessageDispatch({
	// 					variables: {
	// 						input: {
	// 							messageDispatchId: messageDispatch.id,
	// 						},
	// 						connections: [connectionId],
	// 					},
	// 					onCompleted: () => {
	// 						toast.current?.show({
	// 							severity: "success",
	// 							life: 3000,
	// 							summary: "Nachrichten gelöscht",
	// 							detail: "Nachrichten wurde gelöscht",
	// 						});
	// 					},
	// 					onError: () => {
	// 						toast.current?.show({
	// 							severity: "error",
	// 							life: 3000,
	// 							summary: "Nachrichten nicht gelöscht",
	// 							detail: "Nachrichten konnte nicht gelöscht werden",
	// 						});
	// 					},
	// 				};
	// 			}
	// 		},
	// 	});
	// };

	return (
		<>
			{/*<Toast ref={toast}></Toast>*/}
			{/*{dialogComponent}*/}
			{/*<Button*/}
			{/*	disabled={isDeletingMessageDispatch}*/}
			{/*	icon={EpicIcons.TRASH}*/}
			{/*	variant={ButtonVariant.Error}*/}
			{/*	onClick={handleOnDeleteClick}*/}
			{/*/>*/}
		</>
	);
};
