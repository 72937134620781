import { graphql } from "babel-plugin-relay/macro";

export const USERS_QUERY = graphql`
	query selectUserField_UsersQuery($nameOrEmail: String, $accountId: ID) {
		Admin {
			Auth {
				SelectUsers(filterByNameOrEmail: $nameOrEmail, filterByAccountId: $accountId) {
					edges {
						node {
							id
							name
							email
							activated
						}
					}
				}
			}
		}
	}
`;

export const EDITORS_QUERY = graphql`
	query selectUserField_EditorsQuery($nameOrEmail: String) {
		Admin {
			Auth {
				# TODO: Make it SearchEditors(filterByNameOrEmail: $nameOrEmail) { again
				SelectUsers(filterByNameOrEmail: $nameOrEmail) {
					edges {
						node {
							id
							name
							email
							activated
						}
					}
				}
			}
		}
	}
`;
