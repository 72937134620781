import React from "react";
import { useFragment } from "react-relay";
import { type billingDetailsForm_BillingDetailsFragment$key } from "@relay/billingDetailsForm_BillingDetailsFragment.graphql";
import { BILLING_DETAILS_FRAGMENT } from "./billing-details-form.graphql";
import { type BillingDetailsFormProps } from "./billing-details-form.interface";
import { BusinessBillingDetailsForm } from "../business-billing-details-form/business-billing-details-form.component";
import { PrivateBillingDetailsForm } from "../private-billing-details-form/private-billing-details-form.component";

export const BillingDetailsForm = ({ billingDetailsFragmentRef }: BillingDetailsFormProps) => {
	const billingDetails = useFragment<billingDetailsForm_BillingDetailsFragment$key>(
		BILLING_DETAILS_FRAGMENT,
		billingDetailsFragmentRef,
	);

	switch (billingDetails.customerType) {
		case "Private":
			return <PrivateBillingDetailsForm privateBillingDetailsFragmentRef={billingDetails} />;
		case "Business":
			return (
				<BusinessBillingDetailsForm businessBillingDetailsFragmentRef={billingDetails} />
			);
		default:
			return <div>Keine Daten vorhanden</div>;
	}
};
