import React, {useState} from "react";
import {IProductsContext, ProductsScreenContext} from "@screens/products/products.context";
import {ProductsTableFiltersState} from "@screens/products/parts/products-table-filters/products-table-filters.types";

export function ProductsContextProvider<T>({ children }: React.PropsWithChildren<T>) {
    const [connectionId, setConnectionId] = useState("");
    const [filters, setFilters] = useState<ProductsTableFiltersState>({
        titleOpt: undefined,
        isHiddenOpt: undefined
    });
    const value: IProductsContext = {
        connectionId,
        setConnectionId,
        filters,
        setFilters,
    };
    return <ProductsScreenContext.Provider value={value}>{children}</ProductsScreenContext.Provider>;
}