/**
 * @generated SignedSource<<f6ebeb3024383d83e55c627eafb24169>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type minimizedDiscountCodeTable_DiscountCodeFragment$data = {
  readonly code: string;
  readonly id: string;
  readonly " $fragmentType": "minimizedDiscountCodeTable_DiscountCodeFragment";
};
export type minimizedDiscountCodeTable_DiscountCodeFragment$key = {
  readonly " $data"?: minimizedDiscountCodeTable_DiscountCodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"minimizedDiscountCodeTable_DiscountCodeFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "minimizedDiscountCodeTable_DiscountCodeFragment"
};

(node as any).hash = "6efb7ec549afa2e770640aba1edc9ee5";

export default node;
