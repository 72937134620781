import { useFormik } from "formik";
import React, { forwardRef, useImperativeHandle } from "react";
import { useFragment } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { PageButtonKindDropdown } from "@components/page-button-kind-dropdown";
import { ValidatedField } from "@components/ValidatedField";

import {
	ButtonKind,
	pageButtonForm_ButtonFragment$key,
} from "@relay/pageButtonForm_ButtonFragment.graphql";
import { PAGE_BUTTON_FRAGMENT } from "@screens/message-definition-edit/parts/page-button-form/page-button-form.graphql";

import {
	PageButtonFormProps,
	PageButtonFormRef,
	PageButtonFormSchema,
	PageButtonFormState,
} from "@screens/message-definition-edit/parts/page-button-form/page-button-form.types";

export const PageButtonForm = forwardRef<PageButtonFormRef, PageButtonFormProps>(
	function PageButtonForm({ buttonFragmentRef, onSubmit }, ref) {
		const data = useFragment<pageButtonForm_ButtonFragment$key>(
			PAGE_BUTTON_FRAGMENT,
			buttonFragmentRef ?? null,
		);

		const initialData = {
			text: data?.button?.text ?? "",
			kind: data?.button?.kind ?? "",
			url: data?.button?.url ?? "",
		};

		const formik = useFormik<PageButtonFormState>({
			initialValues: initialData,
			validationSchema: PageButtonFormSchema,
			onSubmit: (values) => {
				onSubmit?.(values);
			},
		});
		useImperativeHandle(ref, () => ({
			submit: formik.submitForm,
			validate: () =>
				formik.validateForm().then((errors) => Object.keys(errors).length === 0),
			values: formik.values,
		}));

		return (
			<>
				<ValidatedField<PageButtonFormState, ButtonKind>
					name={"kind"}
					label={"Button"}
					required
					component={PageButtonKindDropdown}
					formikConfig={formik}
				/>
				<ValidatedField<PageButtonFormState, string>
					name="text"
					label="Text"
					required
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				{formik.values.kind === "link" && (
					<ValidatedField<PageButtonFormState, string>
						name="url"
						required
						label="URL"
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
				)}
			</>
		);
	},
);
