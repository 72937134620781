import { graphql } from "babel-plugin-relay/macro";

export const REMOVE_WRONG_ANSWER_FROM_MATRIX_ELEMENT_CELL_MUTATION = graphql`
	mutation removeWrongAnswerFromMatrixElementCellButton_RemoveWrongAnswerFromMatrixElementCellMutation(
		$input: RemoveWrongAnswerFromMatrixElementCellInput!
	) {
		Admin {
			ElearningV2 {
				removeWrongAnswerFromMatrixElementCell(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
