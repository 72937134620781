import graphql from "babel-plugin-relay/macro";

export const CREATE_MESSAGE_DEFINITION_MUTATION = graphql`
	mutation createMessageDefinitionButton_CreateMessageDefinitionMutation(
		$input: CreateMessageDefinitionInput!
		$connections: [ID!]!
	) {
		Admin {
			MessageDefinitions {
				createMessageDefinition(input: $input) {
					messageDefinition @appendEdge(connections: $connections) {
						node {
							id
							internalTitle
							createdAt
							showUntil
						}
					}
				}
			}
		}
	}
`;
