import { graphql } from "babel-plugin-relay/macro";

export const ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT = graphql`
	fragment editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment on AnswerMatrixElementCell {
		id
	}
`;

export const EDIT_WRONG_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION = graphql`
	mutation editWrongAnswerInMatrixElementCellForm_EditWrongAnswerInMatrixElementCellMutation(
		$input: EditWrongAnswerInMatrixElementCellInput!
	) {
		Admin {
			ElearningV2 {
				editWrongAnswerInMatrixElementCell(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
