import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import * as Yup from "yup";
import {
	type Product,
	type SelectProductFieldDialogProps,
	type SelectProductFieldDialogState,
} from "./select-product-field-dialog.interface";
import { ValidatedField } from "../../../components/ValidatedField";
import { SelectProductField } from "../select-product-field";

export const SelectProductFieldDialog = ({
	updateField,
	fieldValue: selectedProduct,
	disabled,
	onChange,
}: SelectProductFieldDialogProps) => {
	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	const formik = useFormik<SelectProductFieldDialogState>({
		initialValues: {
			product: selectedProduct,
		},
		validationSchema: Yup.object().shape({}),
		onSubmit: (values) => {
			updateField({
				id: values.product?.id,
				title: values.product?.title,
			});
			setModalOpen(false);
			onChange?.();
		},
	});

	const handleDialogOnHide = () => {
		setModalOpen(false);
	};

	const handleSelectProductOnClick = () => {
		setModalOpen(true);
	};

	const handleUnselectProductOnClick = () => {
		updateField(undefined);
	};

	const name = selectedProduct?.id ? selectedProduct?.title : "Kein Lizenz-Produkt ausgewählt";

	return (
		<>
			<div className="flex">
				<div className="flex-grow-1 flex bg-gray-100 border-gray-300 border-1 border-round  px-3 py-2">
					{name}
				</div>
				<Button
					className="w-auto p-button-secondary p-2 ml-2"
					type={"button"}
					disabled={!selectedProduct?.id || disabled}
					tooltip={"löschen"}
					icon="pi pi-times"
					onClick={handleUnselectProductOnClick}
				/>
				<Button
					className="w-auto ml-2"
					type={"button"}
					disabled={disabled}
					onClick={handleSelectProductOnClick}
					label="Lizenz-Produkt auswählen"
				/>
			</div>
			<Dialog
				header="Lizenz-Produkt auswählen"
				onHide={handleDialogOnHide}
				className="w-10"
				visible={isModalOpen}
			>
				<form onSubmit={formik.handleSubmit}>
					<ValidatedField<SelectProductFieldDialogState, Product>
						className="mb-4"
						name={"product"}
						label={"Wähle ein Lizenz-Produkt aus"}
						formikConfig={{
							...formik,
							errors: { ...formik.errors, product: undefined },
						}}
						component={SelectProductField}
					/>
					<Button label={"Auswählen"} icon="pi pi-check" type="submit" autoFocus />
				</form>
			</Dialog>
		</>
	);
};
