import graphql from "babel-plugin-relay/macro";

export const ADD_INCLUDE_ACCOUNT_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddIncludeAccountDispatchConditionMutation(
		$input: AddIncludeAccountDispatchConditionInput!
	) {
		Admin {
			Message {
				addIncludeAccountDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_EXCLUDE_ACCOUNT_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddExcludeAccountDispatchConditionMutation(
		$input: AddExcludeAccountDispatchConditionInput!
	) {
		Admin {
			Message {
				addExcludeAccountDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_INCLUDE_ALL_USER_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddIncludeAllUsersDispatchConditionMutation(
		$input: AddIncludeAllUserDispatchConditionInput!
	) {
		Admin {
			Message {
				addIncludeAllUserDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_INCLUDE_USER_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddIncludeUserDispatchConditionMutation(
		$input: AddIncludeUserDispatchConditionInput!
	) {
		Admin {
			Message {
				addIncludeUserDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_EXCLUDE_USER_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddExcludeUserDispatchConditionMutation(
		$input: AddExcludeUserDispatchConditionInput!
	) {
		Admin {
			Message {
				addExcludeUserDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;

export const ADD_INCLUDE_OPT_IN_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddIncludeOptInDispatchConditionMutation(
		$input: AddIncludeOptInDispatchConditionInput!
	) {
		Admin {
			Message {
				addIncludeOptInDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_EXCLUDE_OPT_IN_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddExcludeOptInDispatchConditionMutation(
		$input: AddExcludeOptInDispatchConditionInput!
	) {
		Admin {
			Message {
				addExcludeOptInDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_INCLUDE_TREE_FINISHED_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation(
		$input: AddIncludeTreeFinishedDispatchConditionInput!
	) {
		Admin {
			Message {
				addIncludeTreeFinishedDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_EXCLUDE_TREE_FINISHED_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddExcludeTreeFinishedDispatchConditionMutation(
		$input: AddExcludeTreeFinishedDispatchConditionInput!
	) {
		Admin {
			Message {
				addExcludeTreeFinishedDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_INCLUDE_TREE_STARTED_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddIncludeTreeStartedDispatchConditionMutation(
		$input: AddIncludeTreeStartedDispatchConditionInput!
	) {
		Admin {
			Message {
				addIncludeTreeStartedDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_EXCLUDE_TREE_STARTED_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddExcludeTreeStartedDispatchConditionMutation(
		$input: AddExcludeTreeStartedDispatchConditionInput!
	) {
		Admin {
			Message {
				addExcludeTreeStartedDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_INCLUDE_TREE_UNLOCKED_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddIncludeTreeUnlockedDispatchConditionMutation(
		$input: AddIncludeTreeUnlockedDispatchConditionInput!
	) {
		Admin {
			Message {
				addIncludeTreeUnlockedDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
export const ADD_EXCLUDE_TREE_UNLOCKED_DISPATCH_CONDITION = graphql`
	mutation useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation(
		$input: AddExcludeTreeUnlockedDispatchConditionInput!
	) {
		Admin {
			Message {
				addExcludeTreeUnlockedDispatchCondition(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
