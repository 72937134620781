import { Paths } from "@routes/paths";
import { UserEditScreen } from "./user-edit.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const UserEditRoutes: RouteDefinition[] = [
	{
		path: Paths.users.withIdPlaceholder().edit.path,
		element: <UserEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_AuthAdmin_Read",
			"UserInAccountPermission_AuthAdmin_Modify",
		],
	},
];
