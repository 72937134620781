import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "../../../components/ValidatedField";
import { DefaultIntegerFieldComponent } from "../../../components/DefaultTextInput";
import { ProductSelection, ProductSelectionFormProps } from "./product-selection-form.interface";
import { Button } from "primereact/button";
import React from "react";
import { SelectProductFieldDialog } from "../select-product-field-dialog";
import { Product } from "../select-product-field-dialog/select-product-field-dialog.interface";

export const ProductSelectionForm = ({
	onBack,
	onSubmit,
	isLoading,
	initialValues,
}: ProductSelectionFormProps) => {
	const formik = useFormik<ProductSelection>({
		initialValues: initialValues,
		enableReinitialize: true,
		validationSchema: Yup.object().shape({
			product: Yup.object().required("Es wird ein Produkt benötigt"),
			amount: Yup.number().min(1).required("Es wird eine Anzahl benötigt"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				product: values.product,
				amount: values.amount,
			});
			setSubmitting(false);
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<ProductSelection, Product>
					name={"product"}
					label={"Produkt"}
					component={SelectProductFieldDialog}
					required
					formikConfig={formik}
				/>
				<ValidatedField<ProductSelection, number>
					name={"amount"}
					label={"Anzahl"}
					required
					component={DefaultIntegerFieldComponent}
					formikConfig={formik}
				/>

				<Button disabled={isLoading} type="submit" label="Speichern" className="mt-2" />
				<Button
					disabled={isLoading}
					label="Zurück"
					type="button"
					onClick={onBack}
					className="mt-2"
				/>
			</form>
		</>
	);
};
