import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { EducationalOfferScreen } from "./educational-offer.screen";

export const EducationalOfferRoutes: RouteDefinition[] = [
	{
		path: `${Paths.educationalOffer.withIdPlaceholder().path}/*`,
		element: <EducationalOfferScreen />,
		requiredPermissions: [
			"UserInAccountPermission_Nodes_ReadNodes",
			"UserInAccountPermission_Nodes_UpdateNodes",
		],
	},
	{
		path: `${Paths.educationalOffer.withIdPlaceholder().node.withIdPlaceholder().path}/*`,
		element: <EducationalOfferScreen />,
		requiredPermissions: [
			"UserInAccountPermission_Nodes_ReadNodes",
			"UserInAccountPermission_Nodes_UpdateNodes",
		],
	},
];
