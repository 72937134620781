import graphql from "babel-plugin-relay/macro";

export const ACCOUNT_QUERY = graphql`
	query accountEditScreen_Query($id: ID!, $nameOpt: String, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Account {
				...accountEditScreen_AccountFragment
			}
		}
		...ordersTable_OrdersFragment @arguments(accountId: $id) @skip(if: $skip)
		...licensesTable_LicensesFragment @arguments(nameOpt: $nameOpt)
	}
`;

export const ACCOUNT_FRAGMENT = graphql`
	fragment accountEditScreen_AccountFragment on Account
	@refetchable(queryName: "AccountEditScreen_Refetch")
	@argumentDefinitions(first: { type: "Int", defaultValue: 20 }, after: { type: "String" }) {
		name
		id
		registeredAt
		isDeleted
		groupAssociations {
			group {
				id
				name
			}
		}
		extension {
			... on AccountExtensionImpl {
				users(first: $first, after: $after) @connection(key: "AccountEditScreen_users") {
					__id
					edges {
						node {
							id
						}
					}
					...usersInAccountTable_UserInAccountConnectionFragment
				}
			}
		}
		...editAccountGroupsButton_AccountFragment
	}
`;

export const CREATE_MUTATION = graphql`
	mutation accountEditScreen_CreateMutation($input: CreateAccountInput!) {
		Admin {
			Auth {
				createAccount(input: $input) {
					edge {
						node {
							...accountEditScreen_AccountFragment
							id
						}
					}
				}
			}
		}
	}
`;

export const UPDATE_MUTATION = graphql`
	mutation accountEditScreen_UpdateMutation($input: SetAccountNameInput!) {
		Admin {
			Auth {
				setAccountName(input: $input) {
					account {
						...accountEditScreen_AccountFragment
					}
				}
			}
		}
	}
`;

export const ACCOUNT_GROUP_QUERY = graphql`
	query accountEditScreen_AccountGroupsQuery {
		Admin {
			Management {
				AccountGroups {
					edges {
						node {
							name
							...editAccountGroupsButton_AccountGroupFragment
						}
					}
				}
			}
		}
	}
`;
