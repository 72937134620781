/**
 * @generated SignedSource<<35d62799b8c84d1eaa97c242a0b6420e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddCanAfterStartingTreeStartContentConfigInput = {
  clientMutationId?: string | null;
  contentId: string;
};
export type startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation$variables = {
  input: AddCanAfterStartingTreeStartContentConfigInput;
};
export type startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly addCanAfterStartingTreeStartContentConfig: {
        readonly clientMutationId: string | null;
        readonly content: {
          readonly " $fragmentSpreads": FragmentRefs<"startContentConfigEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation = {
  response: startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation$data;
  variables: startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "startConfigs",
    "plural": true,
    "selections": [
      (v4/*: any*/),
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "configType",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddCanAfterStartingTreeStartContentConfigPayload",
                "kind": "LinkedField",
                "name": "addCanAfterStartingTreeStartContentConfig",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "startContentConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddCanAfterStartingTreeStartContentConfigPayload",
                "kind": "LinkedField",
                "name": "addCanAfterStartingTreeStartContentConfig",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v5/*: any*/),
                            "type": "ELearningContentTypeDefinition",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v5/*: any*/),
                            "type": "AsyncContentTypeDefinition",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0706173a02f1e240eb37fe02e104f8cd",
    "id": null,
    "metadata": {},
    "name": "startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation",
    "operationKind": "mutation",
    "text": "mutation startContentConfigEditor_addCanAfterStartingTreeStartContentConfigAdminMutation(\n  $input: AddCanAfterStartingTreeStartContentConfigInput!\n) {\n  Admin {\n    Tree {\n      addCanAfterStartingTreeStartContentConfig(input: $input) {\n        clientMutationId\n        content {\n          ...startContentConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment startContentConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ELearningContentTypeDefinition {\n      startConfigs {\n        __typename\n        id\n        configType\n      }\n    }\n    ... on AsyncContentTypeDefinition {\n      startConfigs {\n        __typename\n        id\n        configType\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "656c0b8c4a2271bfd14073548eb1e86b";

export default node;
