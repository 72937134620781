/**
 * @generated SignedSource<<3fd03776de325700405d6c34db7b5e1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ordersTable_OrdersFragmentRefetch$variables = {
  accountId?: string | null;
  after?: string | null;
  email?: string | null;
  first?: number | null;
  userId?: string | null;
  username?: string | null;
};
export type ordersTable_OrdersFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ordersTable_OrdersFragment">;
};
export type ordersTable_OrdersFragmentRefetch = {
  response: ordersTable_OrdersFragmentRefetch$data;
  variables: ordersTable_OrdersFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "accountId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "email"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "accountId",
    "variableName": "accountId"
  },
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "email",
    "variableName": "email"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "userId",
    "variableName": "userId"
  },
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "netPrice",
    "storageKey": null
  }
],
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "paymentIntentId",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ordersTable_OrdersFragmentRefetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "ordersTable_OrdersFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ordersTable_OrdersFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "OrdersConnection",
                "kind": "LinkedField",
                "name": "Orders",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrdersEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Order",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "status",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "selectedPaymentMethod",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "selectedPaymentMethodType",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "MonthlyOption",
                                    "kind": "LinkedField",
                                    "name": "chosenOption",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "Price",
                                        "kind": "LinkedField",
                                        "name": "monthlyRate",
                                        "plural": false,
                                        "selections": (v3/*: any*/),
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "MonthlySelectedPaymentMethod",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Cart",
                            "kind": "LinkedField",
                            "name": "cart",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "CartTotals",
                                "kind": "LinkedField",
                                "name": "totals",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Price",
                                    "kind": "LinkedField",
                                    "name": "includingAllDiscounts",
                                    "plural": false,
                                    "selections": (v3/*: any*/),
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "InvoiceData",
                            "kind": "LinkedField",
                            "name": "invoiceData",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceId",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "invoiceNumber",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "paymentData",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "paymentMethod",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "dataType",
                                "storageKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v4/*: any*/),
                                "type": "StripeData",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v4/*: any*/),
                                "type": "StripeSepaData",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "username",
                  "email",
                  "accountId",
                  "userId"
                ],
                "handle": "connection",
                "key": "OrdersTable_Orders",
                "kind": "LinkedHandle",
                "name": "Orders"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "efcc93b8c6be4fe02830e9ae7f277b23",
    "id": null,
    "metadata": {},
    "name": "ordersTable_OrdersFragmentRefetch",
    "operationKind": "query",
    "text": "query ordersTable_OrdersFragmentRefetch(\n  $accountId: ID\n  $after: String\n  $email: String\n  $first: Int\n  $userId: ID\n  $username: String\n) {\n  ...ordersTable_OrdersFragment_20VWmC\n}\n\nfragment invoiceProviderLink_InvoiceDataFragment on InvoiceData {\n  invoiceId\n  invoiceNumber\n}\n\nfragment orderPriceDisplay_OrderFragment on Order {\n  status\n  cart {\n    totals {\n      includingAllDiscounts {\n        netPrice\n      }\n    }\n  }\n  selectedPaymentMethod {\n    __typename\n    ... on MonthlySelectedPaymentMethod {\n      chosenOption {\n        monthlyRate {\n          netPrice\n        }\n      }\n    }\n  }\n  invoiceData {\n    ...invoiceProviderLink_InvoiceDataFragment\n  }\n}\n\nfragment ordersTable_OrderFragment on Order {\n  id\n  createdAt\n  status\n  selectedPaymentMethod {\n    __typename\n    selectedPaymentMethodType\n  }\n  ...orderPriceDisplay_OrderFragment\n  ...paymentProviderLink_OrderFragment\n}\n\nfragment ordersTable_OrdersFragment_20VWmC on Query {\n  Admin {\n    Billing {\n      Orders(first: $first, after: $after, username: $username, email: $email, accountId: $accountId, userId: $userId) {\n        edges {\n          node {\n            ...ordersTable_OrderFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n\nfragment paymentProviderLink_OrderFragment on Order {\n  paymentData {\n    __typename\n    paymentMethod\n    dataType\n    ... on StripeData {\n      paymentIntentId\n    }\n    ... on StripeSepaData {\n      paymentIntentId\n    }\n  }\n  status\n}\n"
  }
};
})();

(node as any).hash = "ec528d99d3b3d851a7899e6d37e1925b";

export default node;
