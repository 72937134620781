import React, { useRef } from "react";
import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment } from "react-relay";
import { Toast } from "primereact/toast";
import { DownloadUsageCsvButton_SearchDiscountUsageResultFragment$key } from "@relay/DownloadUsageCsvButton_SearchDiscountUsageResultFragment.graphql";

const SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT = graphql`
	fragment DownloadUsageCsvButton_SearchDiscountUsageResultFragment on SearchDiscountCodeUsagesResult {
		csv
	}
`;

interface OwnProps {
	searchDiscountUsageResultFragmentRef: DownloadUsageCsvButton_SearchDiscountUsageResultFragment$key;
}

export const DownloadUsageCsvButton = ({ searchDiscountUsageResultFragmentRef }: OwnProps) => {
	const searchDiscountUsageResult =
		useFragment<DownloadUsageCsvButton_SearchDiscountUsageResultFragment$key>(
			SEARCH_DISCOUNT_USAGE_RESULT_FRAGMENT,
			searchDiscountUsageResultFragmentRef,
		);
	const toast = useRef<any>(null);
	const downloadCsv = () => {
		if (searchDiscountUsageResult.csv) {
			const link = document.createElement("a");
			link.href = "data:text/csv;charset=utf-8," + searchDiscountUsageResult.csv;
			link.download = "Verwendungen.csv";
			link.click();
		} else {
			toast.current?.show({
				severity: "success",
				life: 3000,
				summary: "CSV erstellt",
				detail: "Dein CSV wurde erfolgreich erstellt und heruntergeladen",
			});
		}
	};

	return (
		<>
			<Toast ref={toast} />
			<Button label="CSV" icon="pi pi-download" className="h-3rem" onClick={downloadCsv} />
		</>
	);
};
