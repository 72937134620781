import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { DefaultTextFieldComponent } from "../../../components/DefaultTextInput";
import { ValidatedField } from "../../../components/ValidatedField";

export const ExternalLicenseFilters = ({ refetch }: ExternalLicenseFilterProps) => {
	const initialValues = {
		name: undefined,
		isIssued: undefined,
	};

	const formik = useFormik<ExternalLicenseFilterState>({
		initialValues,
		onSubmit: (values: ExternalLicenseFilterState, { setSubmitting }) => {
			refetch(values.isIssued, values.name);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	const dropdownOptions = [
		{ label: "Ja", value: true },
		{ label: "Nein", value: false },
		{ label: "Alle", value: null },
	];

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<ExternalLicenseFilterState, string>
					name="name"
					label="Name"
					className="mr-2 mt-2"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<div className="flex flex-column">
					<label>Ist Vergeben</label>
					<Dropdown
						className="m-1"
						options={dropdownOptions.map((o) => ({
							...o,
							label: `Ist Vergeben: ${o.label}`,
						}))}
						value={formik.values.isIssued}
						placeholder={"Ist Vergeben"}
						onChange={(e) => {
							formik.setFieldValue("isIssued", e.value);
						}}
					/>
				</div>
				<div className="mt-2">
					<Button
						disabled={false}
						type="reset"
						onClick={clearForm}
						icon="pi pi-times"
						className="h-3rem ml-auto"
					/>
					<Button
						disabled={false}
						type="submit"
						icon="pi pi-search"
						className="h-3rem ml-2"
					/>
				</div>
			</div>
		</form>
	);
};
