import { Button } from "@thekeytechnology/epic-ui";
import { useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { type createEmailTemplateButton_AvailableTemplatesFragment$key } from "@relay/createEmailTemplateButton_AvailableTemplatesFragment.graphql";
import { type createEmailTemplateButton_CreateMutation } from "@relay/createEmailTemplateButton_CreateMutation.graphql";
import { Paths } from "@routes/paths";
import { EMAIL_TEMPLATE_KEYS } from "src/translations/email";
import {
	AVAILABLE_EMAIL_TEMPLATES_FRAGMENT,
	CREATE_EMAIL_TEMPLATE_MUTATION,
} from "./create-email-template-button.graphql";
import { Dropdown, Wrapper } from "./create-email-template-button.styles";
import { type CreateEmailTemplateButtonProps } from "./create-email-template-button.types";

export const CreateEmailTemplateButton = ({
	availableTemplatesFragmentRef,
}: CreateEmailTemplateButtonProps) => {
	const navigate = useNavigate();
	const [create, isCreating] = useMutation<createEmailTemplateButton_CreateMutation>(
		CREATE_EMAIL_TEMPLATE_MUTATION,
	);
	const [selectedTemplate, setSelectedTemplate] = useState<string | undefined>(undefined);

	const availableTemplates =
		useFragment<createEmailTemplateButton_AvailableTemplatesFragment$key>(
			AVAILABLE_EMAIL_TEMPLATES_FRAGMENT,
			availableTemplatesFragmentRef,
		);

	const options = availableTemplates.Admin.Email.AvailableSystemTemplates.map((t) => ({
		value: t.key,
		label: EMAIL_TEMPLATE_KEYS[t.key] ? EMAIL_TEMPLATE_KEYS[t.key] : t.key,
	}));

	const isDisabled =
		availableTemplates.Admin.Email.AvailableSystemTemplates.length === 0 ||
		isCreating ||
		!selectedTemplate;

	const handleOnChange = (value: string) => {
		setSelectedTemplate(value);
	};

	const handleOnClick = () => {
		const selected = availableTemplates.Admin.Email.AvailableSystemTemplates.find(
			(t) => t.key === selectedTemplate,
		)!;
		create({
			variables: {
				input: {
					data: { ...selected, variables: [...selected.variables] },
				},
			},
			onCompleted: (response) => {
				const templateId =
					response.Admin.Email.createPersonalizedEmailTemplate?.data.node.id;
				templateId && navigate(Paths.emailTemplates.withId(templateId).edit.path);
			},
		});
	};

	return (
		<Wrapper>
			<Dropdown
				placeholder="Bitte Template auswählen"
				value={selectedTemplate}
				onChange={handleOnChange}
				options={options}
			/>
			<Button onClick={handleOnClick} disabled={isDisabled} label={"Erstellen"} />
		</Wrapper>
	);
};
