import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { type DiscountCodeCodesTableHeader_DiscountActionFragment$key } from "@relay/DiscountCodeCodesTableHeader_DiscountActionFragment.graphql";
import { DownloadCodesCsvButton } from "./DownloadCodesCsvButton";
import { CreateRandomCodesButton } from "../../../features/discount-codes/CreateRandomCodesButton";
import { CreateSingleCodeButton } from "../../../features/discount-codes/CreateSingleCodeButton";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment DiscountCodeCodesTableHeader_DiscountActionFragment on DiscountAction {
		...CreateRandomCodesButton_DiscountActionFragment
		...CreateSingleCodeButton_DiscountActionFragment
		...DownloadCodesCsvButton_DiscountActionFragment
	}
`;

type OwnProps = {
	connectionId: string;
	discountActionFragmentRef: DiscountCodeCodesTableHeader_DiscountActionFragment$key;
	canModifyDiscountCodes: boolean;
};

export const DiscountCodeCodesTableHeader = ({
	connectionId,
	discountActionFragmentRef,
	canModifyDiscountCodes,
}: OwnProps) => {
	const discountAction = useFragment<DiscountCodeCodesTableHeader_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return (
		<div className="w-full flex flex-row justify-content-between">
			<DownloadCodesCsvButton
				className="ml-auto mr-2"
				discountActionFragmentRef={discountAction}
			/>
			{canModifyDiscountCodes && (
				<>
					<CreateRandomCodesButton
						className="mr-2"
						connectionId={connectionId}
						discountActionFragmentRef={discountAction}
					/>
					<CreateSingleCodeButton
						connectionId={connectionId}
						discountActionFragmentRef={discountAction}
					/>
				</>
			)}
		</div>
	);
};
