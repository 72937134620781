import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import {
	type limitedCartDiscountTable_LimitedCartDiscountFragment$data,
	type limitedCartDiscountTable_LimitedCartDiscountFragment$key,
} from "@relay/limitedCartDiscountTable_LimitedCartDiscountFragment.graphql";
import { type limitedCartDiscountTable_LimitedCartDiscountsFragment$key } from "@relay/limitedCartDiscountTable_LimitedCartDiscountsFragment.graphql";
import { type limitedCartDiscountTable_Refetch } from "@relay/limitedCartDiscountTable_Refetch.graphql";
import {
	LIMITED_CART_DISCOUNT_FRAGMENT,
	LIMITED_CART_DISCOUNTS_FRAGMENT,
} from "./limited-cart-discount-table.graphql";
import { type LimitedCartDiscountTableProps } from "./limited-cart-discount-table.interface";
import { Text } from "./limited-cart-discount-table.styles";
import { DateTimeDisplay } from "../../../components/datetime-display/datetime-display.component";
import { Pagination } from "../../../components/Pagination";
import { numItemsPerPage } from "../../../screens/limited-cart-discounts/limited-cart-discounts.constants";
import { CreateLimitedCartDiscountButton } from "../create-limited-cart-discount-button";
import { DeleteLimitedCartDiscountButton } from "../delete-limited-cart-discount-button";
import { EditLimitedCartDiscountButton } from "../edit-limited-cart-discount-button";
import { LimitedCartDiscountTableFilter } from "../limited-cart-discount-table-filter";

export const LimitedCartDiscountTable = ({
	limitedCartDiscountsFragmentRef,
	limitedCartDiscountsPerPage,
	enableFilter,
	enableActions,
}: LimitedCartDiscountTableProps) => {
	const {
		data: limitedCartDiscounts,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<
		limitedCartDiscountTable_Refetch,
		limitedCartDiscountTable_LimitedCartDiscountsFragment$key
	>(LIMITED_CART_DISCOUNTS_FRAGMENT, limitedCartDiscountsFragmentRef);

	const limitedCartDiscountsConnection =
		limitedCartDiscounts.Admin.Billing.LimitedCartDiscounts.edges?.map((edge) =>
			readInlineData<limitedCartDiscountTable_LimitedCartDiscountFragment$key>(
				LIMITED_CART_DISCOUNT_FRAGMENT,
				edge?.node!,
			),
		);

	const connectionId = limitedCartDiscounts.Admin.Billing.LimitedCartDiscounts.__id;

	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Keine Warenkorb-Rabatte"}
				className="p-mb-2"
				header={
					<div className="w-full flex flex-column">
						{enableActions && (
							<CreateLimitedCartDiscountButton connectionId={connectionId} />
						)}
						{enableFilter && (
							<LimitedCartDiscountTableFilter
								refetch={(title?: string) =>
									refetch({
										title,
										first: numItemsPerPage,
									})
								}
							/>
						)}
					</div>
				}
				value={limitedCartDiscountsConnection}
			>
				<Column
					header={"ID"}
					body={(
						limitedCartDiscount: limitedCartDiscountTable_LimitedCartDiscountFragment$data,
					) => (
						<div
							className="cursor-pointer"
							onClick={async () => {
								await navigator.clipboard.writeText(limitedCartDiscount.id);
							}}
						>
							<Text hasZeroConditions={limitedCartDiscount.conditions.length == 0}>
								ID<i className="pi pi-copy"></i>
							</Text>
						</div>
					)}
				/>
				<Column
					header={"Erstellungsdatum"}
					body={(
						limitedCartDiscount: limitedCartDiscountTable_LimitedCartDiscountFragment$data,
					) => (
						<Text hasZeroConditions={limitedCartDiscount.conditions.length == 0}>
							<DateTimeDisplay value={limitedCartDiscount.createdAt} hideTimezone />
						</Text>
					)}
				/>
				<Column
					header={"Titel"}
					body={(
						limitedCartDiscount: limitedCartDiscountTable_LimitedCartDiscountFragment$data,
					) => (
						<Text hasZeroConditions={limitedCartDiscount.conditions.length == 0}>
							{limitedCartDiscount.title}
						</Text>
					)}
				/>
				<Column
					header={"Rabatt"}
					body={(
						limitedCartDiscount: limitedCartDiscountTable_LimitedCartDiscountFragment$data,
					) => (
						<Text hasZeroConditions={limitedCartDiscount.conditions.length == 0}>
							{limitedCartDiscount.productSelectionConfig.value} %
						</Text>
					)}
				/>
				<Column
					header={"Anzahl Bedingungen"}
					body={(
						limitedCartDiscount: limitedCartDiscountTable_LimitedCartDiscountFragment$data,
					) => (
						<Text hasZeroConditions={limitedCartDiscount.conditions.length == 0}>
							{limitedCartDiscount.conditions.length == 0
								? "Keine Bedingungen hinterlegt"
								: limitedCartDiscount.conditions.length}
						</Text>
					)}
				/>
				{enableActions && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(
							limitedCartDiscount: limitedCartDiscountTable_LimitedCartDiscountFragment$data,
						) => (
							<>
								<EditLimitedCartDiscountButton
									className="mr-2"
									limitedCartDiscountId={limitedCartDiscount.id}
								/>
								<DeleteLimitedCartDiscountButton
									connectionId={connectionId}
									limitedCartDiscountId={limitedCartDiscount.id}
								/>
							</>
						)}
					/>
				)}
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(limitedCartDiscountsPerPage)} />
		</>
	);
};
