import { Divider } from "primereact/divider";
import React from "react";
import { useFragment } from "react-relay";
import {
	type paymentDataForm_OrderFragment$key,
	type PaymentMethodType,
} from "@relay/paymentDataForm_OrderFragment.graphql";
import { ORDER_FRAGMENT } from "./payment-data-form.graphql";
import { type PaymentDataFormProps } from "./payment-data-form.interface";
import { PaymentMethodDisplay } from "../payment-method-display/payment-method-display.component";

export const PaymentDataForm = ({ orderFragmentRef }: PaymentDataFormProps) => {
	const order = useFragment<paymentDataForm_OrderFragment$key>(ORDER_FRAGMENT, orderFragmentRef);
	return (
		<div className="flex flex-wrap flex-row">
			<div>
				<h3>Erlaubte Zahlmethoden</h3>
				{order.allowedPaymentMethods.map(
					(paymentMethod: PaymentMethodType, index: number) => (
						<PaymentMethodDisplay key={index} paymentMethod={paymentMethod} />
					),
				)}
			</div>
			<Divider layout="vertical" />
			<div>
				{order.selectedPaymentMethod?.paymentMethodId && (
					<>
						<h3>Gewählte Zahlmethode</h3>
						<PaymentMethodDisplay
							paymentMethod={order.selectedPaymentMethod?.paymentMethodId}
						/>
					</>
				)}
			</div>
		</div>
	);
};
