import { EpicIcons } from "@thekeytechnology/epic-ui";
import { useNavigate } from "react-router-dom";
import { Button } from "@components/button";
import { Paths } from "@routes/paths";
import { type ViewReportButtonProps } from "@screens/reports/parts/view-report-button/view-report-button.types";

export const ViewReportButton = ({ reportId }: ViewReportButtonProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon={EpicIcons.FILE}
			tooltip="Reports-Details einsehen"
			onClick={() => {
				navigate(Paths.reports.withId(reportId).details.path);
			}}
		/>
	);
};
