/**
 * @generated SignedSource<<a3709dfbb1dbbf5adf9742180ee84037>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "podcast" | "podcastWithTimestamp" | "text" | "video";
import { FragmentRefs } from "relay-runtime";
export type ElementNode_ElementV2Fragment$data = {
  readonly elementType: ElementTypeV2;
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "ElementNode_ElementV2Fragment";
};
export type ElementNode_ElementV2Fragment$key = {
  readonly " $data"?: ElementNode_ElementV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElementNode_ElementV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElementNode_ElementV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "elementType",
      "storageKey": null
    }
  ],
  "type": "ElementV2",
  "abstractKey": "__isElementV2"
};

(node as any).hash = "ab324d00d58ed7814d1ae2993767b710";

export default node;
