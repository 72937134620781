/**
 * @generated SignedSource<<bbaa634272cbf25fe9c5e3c25693a038>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type searchablePublishedRootTable_LearnOpportunityV2Fragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly title: string;
  };
  readonly " $fragmentType": "searchablePublishedRootTable_LearnOpportunityV2Fragment";
};
export type searchablePublishedRootTable_LearnOpportunityV2Fragment$key = {
  readonly " $data"?: searchablePublishedRootTable_LearnOpportunityV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"searchablePublishedRootTable_LearnOpportunityV2Fragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "searchablePublishedRootTable_LearnOpportunityV2Fragment"
};

(node as any).hash = "f882e8e162f6930ab7189f8f46fc3175";

export default node;
