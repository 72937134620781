import graphql from "babel-plugin-relay/macro";

export const ORDER_FRAGMENT = graphql`
	fragment paymentDataForm_OrderFragment on Order {
		allowedPaymentMethods
		selectedPaymentMethod {
			paymentMethodId
			selectedPaymentMethodType
		}
	}
`;
