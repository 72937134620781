import { useMutation } from "react-relay";
import { DELETE_EXTERNAL_LICENSE_MUTATION } from "./delete-external-license-button.graphql";
import { Button } from "primereact/button";
import { deleteExternalLicenseButton_DeleteExternalLicenseMutation } from "@relay/deleteExternalLicenseButton_DeleteExternalLicenseMutation.graphql";

export const DeleteExternalLicenseButton = (props: DeleteExternalLicenseButtonProps) => {
	const [deleteExternalLicense, isDeleting] =
		useMutation<deleteExternalLicenseButton_DeleteExternalLicenseMutation>(
			DELETE_EXTERNAL_LICENSE_MUTATION,
		);

	const onClick = () =>
		deleteExternalLicense({
			variables: {
				input: {
					ids: [props.externalLicenseId],
				},
				connections: props.connections,
			},
		});

	return <Button onClick={onClick} icon="pi pi-trash" />;
};
