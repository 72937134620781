import { Button } from "primereact/button";
import React from "react";
import { v4 } from "uuid";
import { type CreateTimestampWithImageButtonProps } from "./create-timestamp-with-image-button.interface";
import { secondToTimestamp } from "../../../../util/TimestampConverter";

export const CreateTimestampWithImageButton = ({
	fieldValue,
	updateField,
}: CreateTimestampWithImageButtonProps) => {
	return (
		<Button
			onClick={() => {
				updateField([
					...(fieldValue || []),
					{
						tempId: v4(),
						start: secondToTimestamp(0),
						end: secondToTimestamp(0),
						imageFileId: undefined,
					},
				]);
			}}
			className="mr-2"
			icon="pi pi-plus"
		/>
	);
};
