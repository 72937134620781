/**
 * @generated SignedSource<<3bc16b3e7d57613068288dd2e2bea4c1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetAccountGroupsInput = {
  clientMutationId?: string | null;
  groupIds: ReadonlyArray<string>;
  id: string;
};
export type editAccountGroupsForm_UpdateAccountGroupsMutation$variables = {
  input: SetAccountGroupsInput;
};
export type editAccountGroupsForm_UpdateAccountGroupsMutation$data = {
  readonly Admin: {
    readonly Auth: {
      readonly setAccountGroups: {
        readonly account: {
          readonly " $fragmentSpreads": FragmentRefs<"editAccountGroupsForm_AccountFragment">;
        };
      } | null;
    };
  };
};
export type editAccountGroupsForm_UpdateAccountGroupsMutation = {
  response: editAccountGroupsForm_UpdateAccountGroupsMutation$data;
  variables: editAccountGroupsForm_UpdateAccountGroupsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "editAccountGroupsForm_UpdateAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetAccountGroupsPayload",
                "kind": "LinkedField",
                "name": "setAccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "editAccountGroupsForm_AccountFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "editAccountGroupsForm_UpdateAccountGroupsMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AuthAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Auth",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetAccountGroupsPayload",
                "kind": "LinkedField",
                "name": "setAccountGroups",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Account",
                    "kind": "LinkedField",
                    "name": "account",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AccountGroupAssociation",
                        "kind": "LinkedField",
                        "name": "groupAssociations",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AccountGroup",
                            "kind": "LinkedField",
                            "name": "group",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40243036980cb812a36c3fc9f18c8977",
    "id": null,
    "metadata": {},
    "name": "editAccountGroupsForm_UpdateAccountGroupsMutation",
    "operationKind": "mutation",
    "text": "mutation editAccountGroupsForm_UpdateAccountGroupsMutation(\n  $input: SetAccountGroupsInput!\n) {\n  Admin {\n    Auth {\n      setAccountGroups(input: $input) {\n        account {\n          ...editAccountGroupsForm_AccountFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment editAccountGroupsForm_AccountFragment on Account {\n  id\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f0a53aa453fb3fb41f08d512c787c79d";

export default node;
