import { Card } from "primereact/card";
import { type DropdownChangeEvent } from "primereact/dropdown";
import { useFragment, useMutation } from "react-relay";

import {
	type flowAndCurrentElementContentConfigEditor_SetDirectFeedbackFlowELearningContentConfigMutation,
	type SetDirectFeedbackFlowELearningContentConfigInput,
} from "@relay/flowAndCurrentElementContentConfigEditor_SetDirectFeedbackFlowELearningContentConfigMutation.graphql";
import {
	type flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation,
	type SetSimplyContinueFlowELearningContentConfigInput,
} from "@relay/flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation.graphql";
import {
	type flowAndCurrentElementContentConfigEditor_TreeNodeFragment$key,
	type FlowELearningContentConfigType,
} from "@relay/flowAndCurrentElementContentConfigEditor_TreeNodeFragment.graphql";
import { type setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment$key } from "@relay/setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment.graphql";
import {
	SET_DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION,
	SET_SIMPLY_CONTINUE_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./flow-and-current-element-content-config-editor.graphql";
import { type FlowAndCurrentElementContentConfigEditorProps } from "./flow-and-current-element-content-config-editor.types";
import { type Config } from "../Config.interfaces";
import { type ConfigDropdownOptions } from "../ConfigDropdown";
import { ConfigItem } from "../ConfigItem";
import { SetDirectFeedbackFlowConfigForm } from "../set-direct-feedback-flow-config-form/set-direct-feedback-flow-config-form.component";

export const FlowAndCurrentElementContentConfigEditor = ({
	treeNodeFragmentRef,
}: FlowAndCurrentElementContentConfigEditorProps) => {
	const treeNode = useFragment<flowAndCurrentElementContentConfigEditor_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);

	const [setDirectFeedbackFlowELearningContentConfig] =
		useMutation<flowAndCurrentElementContentConfigEditor_SetDirectFeedbackFlowELearningContentConfigMutation>(
			SET_DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION,
		);

	const [setSimplyContinueFlowELearningContentConfig] =
		useMutation<flowAndCurrentElementContentConfigEditor_SetSimplyContinueFlowELearningContentConfigMutation>(
			SET_SIMPLY_CONTINUE_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION,
		);

	const flowConfigType: FlowELearningContentConfigType = treeNode.typeDefinition.flowConfig
		?.configType as FlowELearningContentConfigType;

	const flowConfigs: Array<Config<FlowELearningContentConfigType, any | never>> = [
		{
			configKey: "FlowELearningContent_SimplyContinue",
			addMutation: (input: SetSimplyContinueFlowELearningContentConfigInput) => {
				setSimplyContinueFlowELearningContentConfig({
					variables: {
						input,
					},
				});
			},
			editable: false,
		},
		{
			configKey: "FlowELearningContent_DirectFeedback",
			addMutation: (input: SetDirectFeedbackFlowELearningContentConfigInput) => {
				setDirectFeedbackFlowELearningContentConfig({
					variables: {
						input,
					},
				});
			},
			addMutationPayload: {
				minNumTriesTillShowAnswer: 3,
			},
			editable: true,
		},
	];

	const options: Array<ConfigDropdownOptions<FlowELearningContentConfigType>> = flowConfigs.map(
		(c) => {
			const selectedConfig = treeNode.typeDefinition.flowConfig;

			if (
				selectedConfig?.configType === c.configKey &&
				c.configKey === "FlowELearningContent_DirectFeedback"
			) {
				return {
					label: `(${selectedConfig?.minNumTriesTillShowAnswer})`,
					value: c.configKey,
				};
			} else {
				return {
					label: c.configKey,
					value: c.configKey,
				};
			}
		},
	);

	const handleAndTriggerMutationPairs = (e: DropdownChangeEvent) => {
		const selectedConfig = flowConfigs.find((c) => c.configKey === e.value);
		if (selectedConfig) {
			selectedConfig.addMutation({
				contentId: treeNode.id,
				...selectedConfig.addMutationPayload,
			});
		}
	};

	return (
		<Card className="mb-3">
			<h3>Flow-Feedback</h3>
			<ConfigItem<FlowELearningContentConfigType, string>
				isPresentational={false}
				configType={flowConfigType}
				canEdit={
					flowConfigs.find(
						(c) => c.configKey === treeNode.typeDefinition.flowConfig?.configType,
					)?.editable as boolean
				}
				configOptions={options}
				onChange={handleAndTriggerMutationPairs}
				editDialog={(props: any) => (
					<>
						{flowConfigType === "FlowELearningContent_DirectFeedback" && (
							<SetDirectFeedbackFlowConfigForm
								{...props}
								treeNodeFragmentRef={treeNode}
								configurationFragmentRef={
									treeNode.typeDefinition
										.flowConfig as unknown as setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment$key
								}
							/>
						)}
					</>
				)}
			/>
		</Card>
	);
};
