import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { AcademiesTagsScreen } from ".";

export const AcademiesTagsRoutes: RouteDefinition[] = [
	{
		path: Paths.tags.path,
		element: <AcademiesTagsScreen />,
		requiredPermissions: "onlyOwnerOfRoot",
	},
];
