import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type limitedCartDiscountsScreen_Query } from "@relay/limitedCartDiscountsScreen_Query.graphql";
import { numItemsPerPage } from "./limited-cart-discounts.constants";

import { QUERY } from "./limited-cart-discounts.graphql";
import { NoAccess } from "../../components/no-access";
import { LimitedCartDiscountTable } from "../../features/cart-discounts/limited-cart-discount-table";

export const LimitedCartDiscountScreen = () => {
	const canRead = useHasPermissions(["UserInAccountPermission_LimitedCartDiscountAdmin_Read"]);

	const limitedCartDiscounts = useLazyLoadQuery<limitedCartDiscountsScreen_Query>(QUERY, {
		numItemsPerPage,
	});

	if (!canRead) return <NoAccess />;

	return (
		<Suspense fallback={<ProgressSpinner />}>
			<h2 className="mb-3 text-center">Warenkorb-Rabatte</h2>
			<LimitedCartDiscountTable
				enableFilter={true}
				enableActions={true}
				limitedCartDiscountsPerPage={numItemsPerPage}
				limitedCartDiscountsFragmentRef={limitedCartDiscounts}
			/>
		</Suspense>
	);
};
