import {
	epicBorderRadius4,
	epicSpacing0,
	EpicP2Typography,
	type ButtonVariantProps,
	ButtonShape,
	type ButtonVariantMap as EpicButtonVariantMap,
} from "@thekeytechnology/epic-ui";
import {
	brandMain100,
	brandMain80,
	brandStrong100,
	brandStrong20,
	brandStrong40,
	brandStrong80,
	error0,
	error100,
	error20,
	info100,
	onBrandMain,
	onBrandStrong,
	shade100,
	shade20,
	shade5,
	success0,
	success100,
	success20,
	transparent,
} from "@themes/colors";
import { borderRadius100 } from "@themes/radii";
import { ButtonVariant } from "./button.types";

const DefaultButtonVariant: ButtonVariantProps = {
	default: {
		contentColor: onBrandMain,
		backgroundColor: brandMain100,
	},
	hover: {
		backgroundColor: brandMain80,
	},
	active: {
		backgroundColor: brandMain80,
	},
	focus: {
		borderColor: info100,
	},
	disabled: {
		backgroundColor: shade5,
		contentColor: shade20,
	},
	shape: ButtonShape.Filled,
	borderRadius: borderRadius100,
};

const StrongButtonVariant: ButtonVariantProps = {
	default: {
		contentColor: onBrandStrong,
		backgroundColor: brandStrong100,
	},
	hover: {
		backgroundColor: brandStrong80,
	},
	active: {
		backgroundColor: brandStrong80,
	},
	focus: {
		borderColor: info100,
	},
	disabled: {
		backgroundColor: brandStrong40,
		contentColor: onBrandStrong.withAlpha(0.2),
	},
	shape: ButtonShape.Filled,
	borderRadius: borderRadius100,
};

const OutlineButtonVariant: ButtonVariantProps = {
	default: {
		contentColor: shade100,
		borderColor: brandMain100,
	},
	hover: {
		borderColor: brandMain80,
	},
	active: {
		borderColor: brandMain80,
	},
	focus: {
		borderColor: info100,
	},
	disabled: {
		borderColor: shade20,
		contentColor: shade20,
	},
	shape: ButtonShape.Outlined,
	borderRadius: borderRadius100,
};

const TertiaryButtonVariant: ButtonVariantProps = {
	default: {
		contentColor: brandStrong100,
		backgroundColor: transparent,
	},
	hover: {
		backgroundColor: brandStrong20,
	},
	active: {
		backgroundColor: brandStrong20,
	},
	focus: {
		borderColor: info100,
	},
	disabled: {
		contentColor: brandStrong20,
		backgroundColor: transparent,
	},
	shape: ButtonShape.Filled,
	borderRadius: borderRadius100,
};

const ErrorButtonVariant: ButtonVariantProps = {
	default: {
		contentColor: error100,
		backgroundColor: error20,
	},
	hover: {
		backgroundColor: error0,
	},
	active: {
		backgroundColor: error0,
	},
	focus: {
		borderColor: info100,
	},
	disabled: {
		backgroundColor: error0,
		contentColor: error100.withAlpha(0.2),
	},
	shape: ButtonShape.Filled,
	borderRadius: borderRadius100,
};

const SuccessButtonVariant: ButtonVariantProps = {
	default: {
		contentColor: success100,
		backgroundColor: success20,
	},
	hover: {
		backgroundColor: success0,
	},
	active: {
		backgroundColor: success0,
	},
	focus: {
		borderColor: info100,
	},
	disabled: {
		backgroundColor: success0,
		contentColor: success100.withAlpha(0.2),
	},
	shape: ButtonShape.Filled,
	borderRadius: borderRadius100,
};

const TextlinkButtonVariant: ButtonVariantProps = {
	default: {
		contentColor: brandStrong100,
	},
	hover: {
		contentColor: brandStrong100.withAlpha(0.8),
	},
	active: {
		contentColor: brandStrong100.withAlpha(0.8),
	},
	focus: {
		borderColor: info100,
	},
	disabled: {
		contentColor: brandStrong20,
	},
	borderRadius: epicBorderRadius4,
	paddingX: epicSpacing0,
	paddingY: epicSpacing0,
	shape: ButtonShape.Text,
	typography: EpicP2Typography,
};

export const ButtonVariantMap: EpicButtonVariantMap<ButtonVariant> = {
	[ButtonVariant.Default]: DefaultButtonVariant,
	[ButtonVariant.Strong]: StrongButtonVariant,
	[ButtonVariant.Outline]: OutlineButtonVariant,
	[ButtonVariant.Tertiary]: TertiaryButtonVariant,
	[ButtonVariant.Error]: ErrorButtonVariant,
	[ButtonVariant.Success]: SuccessButtonVariant,
	[ButtonVariant.Textlink]: TextlinkButtonVariant,
};
