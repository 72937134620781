/**
 * @generated SignedSource<<bfe951ef2f7f2f874d3a7afc0caeb278>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type HistoryEventType = "BillingDetailsUpdate" | "CartUpdate" | "CreditNoteSentUpdate" | "InvoiceSentUpdate" | "OrderCreationUpdate" | "PaymentInProcessUpdate" | "PaymentMethodUpdate" | "PurchaseUpdate";
import { FragmentRefs } from "relay-runtime";
export type orderHistoryEvent_OrderHistoryFragment$data = {
  readonly event: {
    readonly creditNoteId?: string;
    readonly creditNoteNumber?: string;
    readonly invoiceId?: string;
    readonly invoiceNumber?: string;
    readonly kind: HistoryEventType;
  };
  readonly " $fragmentType": "orderHistoryEvent_OrderHistoryFragment";
};
export type orderHistoryEvent_OrderHistoryFragment$key = {
  readonly " $data"?: orderHistoryEvent_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderHistoryEvent_OrderHistoryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "orderHistoryEvent_OrderHistoryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "event",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceNumber",
              "storageKey": null
            }
          ],
          "type": "InvoiceSentUpdate",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creditNoteId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "creditNoteNumber",
              "storageKey": null
            }
          ],
          "type": "CreditNoteSentUpdate",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "OrderHistory",
  "abstractKey": null
};

(node as any).hash = "80dae01561b1ea9c9e744bc05da1aacc";

export default node;
