import { EpicIcons } from "@thekeytechnology/epic-ui";
import React from "react";
import { useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { type deleteEmailTemplateButton_DeletePersonalizedEmailTemplateMutation } from "@relay/deleteEmailTemplateButton_DeletePersonalizedEmailTemplateMutation.graphql";
import { DELETE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION } from "./delete-email-template-button.graphql";
import { type DeleteEmailTemplateButtonProps } from "./delete-email-template-button.types";
import { useDialogLogic } from "../../../../hooks/use-dialog-logic/use-dialog-logic.hook";

export const DeleteEmailTemplateButton = ({
	personalizedEmailTemplateId,
	connectionId,
}: DeleteEmailTemplateButtonProps) => {
	const { showDialog, dialogComponent } = useDialogLogic();
	const [deletePersonalizedEmailTemplate, isDeletingPersonalizedEmailTemplate] =
		useMutation<deleteEmailTemplateButton_DeletePersonalizedEmailTemplateMutation>(
			DELETE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION,
		);
	const handleOnDeleteClick = () => {
		showDialog({
			title: "E-Mail Vorlage löschen",
			content:
				"Möchten Sie diese E-Mail-Vorlage wirklich löschen? Das kann nicht rückgängig gemacht werden. Es wird dann die Systemvorlage verwendet, welche eventuell nicht ordentlich funktioniert.",
			dialogCallback: (result) => {
				if (result === "Accept") {
					deletePersonalizedEmailTemplate({
						variables: {
							input: {
								ids: [personalizedEmailTemplateId],
							},
							connections: [connectionId],
						},
					});
				}
			},
		});
	};

	return (
		<>
			{dialogComponent}
			<Button
				disabled={isDeletingPersonalizedEmailTemplate}
				icon={EpicIcons.TRASH}
				variant={ButtonVariant.Error}
				onClick={handleOnDeleteClick}
			/>
		</>
	);
};
