import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { type editExternalLicenseForm_EditExternalLicenseMutation } from "@relay/editExternalLicenseForm_EditExternalLicenseMutation.graphql";
import { type editExternalLicenseForm_ExternalLicenseFragment$key } from "@relay/editExternalLicenseForm_ExternalLicenseFragment.graphql";
import { Paths } from "@routes/paths";
import {
	EDIT_EXTERNAL_LICENSE_MUTATION,
	EXTERNAL_LICENSE_FRAGMENT,
} from "./edit-external-license-form.graphql";
import {
	type EditExternalLicenseFormFormState,
	type EditExternalLicenseFormProps,
} from "./edit-external-license-form.interface";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";

export const EditExternalLicenseForm = ({ loading, entityRef }: EditExternalLicenseFormProps) => {
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const [updateExternalLicense] =
		useMutation<editExternalLicenseForm_EditExternalLicenseMutation>(
			EDIT_EXTERNAL_LICENSE_MUTATION,
		);

	const externalLicense = useFragment<editExternalLicenseForm_ExternalLicenseFragment$key>(
		EXTERNAL_LICENSE_FRAGMENT,
		entityRef,
	);

	const onSubmit = (values: EditExternalLicenseFormFormState) => {
		updateExternalLicense({
			variables: {
				input: {
					externalLicenseId: externalLicense.id!,
					data: {
						name: values.name!,
						code: values.code!,
					},
				},
			},
		});
	};

	const formId = "EditExternalLicenseForm";
	const formik = useFormik<EditExternalLicenseFormFormState>({
		initialValues: {
			name: externalLicense.data.name ?? "Neue Lizenz ",
			code: externalLicense.data?.code ?? "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
			code: Yup.string().required("Code wird benötigt"),
			usageInformation: Yup.string().optional(),
			validUntil: Yup.string().optional(),
			daysOfValidityAfterIssuing: Yup.number().optional(),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				name: values.name,
				code: values.code,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditExternalLicenseFormFormState, string>
					name={"name"}
					label={"Name"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditExternalLicenseFormFormState, string>
					name={"code"}
					label={"Code"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<Button disabled={loading} type="submit" label="Speichern" className="mt-2" />
				<Button
					disabled={loading}
					label="Zurück"
					onClick={() => {
						navigate(Paths.externalLicensePools.withId(externalLicense.pool?.id!).path);
					}}
					className="mt-2"
				/>
			</form>
		</>
	);
};
