import graphql from "babel-plugin-relay/macro";

export const LOGIN_MUTATION = graphql`
	mutation loginForm_LoginMutation($input: LoginJwtInput!) {
		Auth {
			loginJwt(input: $input) {
				loginResult {
					jwtTokens {
						accessToken
						refreshToken
					}
				}
			}
		}
	}
`;
