import React from "react";
import { type OffersFiltersState } from "./parts/offers-filters/offers-filters.types";

export type IOffersContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
	filters: OffersFiltersState;
	setFilters: (e: OffersFiltersState) => void;
};

export const OffersScreenContext = React.createContext<IOffersContext>({
	connectionId: "",
	setConnectionId: () => {},
	filters: {
		tagIds: [],
		tags: [],
		kinds: ["Course"],
	},
	setFilters: () => {},
});
