import { LoginForm } from "./parts/login-form/login-form.component";

export const LoginScreen = () => {
	return (
		<div>
			<h1 className="text-center">Willkommen!</h1>
			<LoginForm />
		</div>
	);
};
