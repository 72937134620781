import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Paths } from "@routes/paths";

type OwnProps = {
	id: string;
};

export const EditDiscountActionButton = ({ id }: OwnProps) => {
	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(Paths.discountActions.withId(id).edit.path);
	};
	return <Button icon="pi pi-pencil" tooltip="Bearbeiten" onClick={handleOnClick} />;
};
