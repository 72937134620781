import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ReportDetailsRoutes } from "@screens/report-details/report-details.routes";
import { ReportsScreen } from "@screens/reports/reports.screen";

export const ReportsRoutes: RouteDefinition[] = [
	{
		path: Paths.reports.path,
		element: <ReportsScreen />,
		requiredPermissions: ["UserInAccountPermission_ReportAdmin_Read"],
	},
	...ReportDetailsRoutes,
];
