import { Button, Dialog, ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ButtonVariant } from "@components/button";
import { error100, shade0 } from "@themes/colors";
import { ButtonsWrapper } from "./confirm-with-text-dialog.styles";
import {
	type ConfirmWithTextDialogProps,
	type ConfirmWithTextFormState,
} from "./confirm-with-text-dialog.types";
import { DefaultTextFieldComponent } from "../DefaultTextInput";
import { ValidatedField } from "../ValidatedField";

// TODO: Add translations
export const ConfirmWithTextDialog = ({
	showDialog = true,
	setShowDialog,
	title,
	content,
	textToConfirm,
	onCancel,
	onConfirm,
}: ConfirmWithTextDialogProps) => {
	const form = useFormik<ConfirmWithTextFormState>({
		initialValues: {
			textToConfirm: "",
		},
		validationSchema: Yup.object().shape({
			textToConfirm: Yup.string()
				.equals(
					[textToConfirm],
					"Die Eingabe stimmt nicht mit dem zu bestätigenden Text überein.",
				)
				.required("Bitte bestätigen Sie den Löschvorgang."),
		}),
		onSubmit: () => {
			handleConfirm();
		},
	});

	const handleClose = () => {
		onCancel?.();
		setShowDialog?.(false);
	};

	const handleConfirm = () => {
		onConfirm?.();
		setShowDialog?.(false);
	};

	return (
		<Dialog visible={showDialog} onHide={handleClose} title={title}>
			{content}
			<form onSubmit={form.handleSubmit} className="mt-4">
				<ValidatedField<ConfirmWithTextFormState, string>
					formikConfig={form}
					name="textToConfirm"
					placeholder={textToConfirm}
					component={DefaultTextFieldComponent}
					helpText={`Schreiben Sie in das Feld „${textToConfirm}“, um den Prozess abzuschließen.`}
				/>
				<ButtonsWrapper>
					<Button onClick={handleClose} type={ButtonType.Button} label="Abbrechen" />
					<Button
						variant={ButtonVariant.Error}
						backgroundColor={error100}
						contentColor={shade0}
						type={ButtonType.Submit}
						label="Ja"
					/>
				</ButtonsWrapper>
			</form>
		</Dialog>
	);
};
