/**
 * @generated SignedSource<<ca6612f0212ffcc478ba344e9d2469ad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type displayMatrixElementCellForm_DisplayMatrixElementCellFragment$data = {
  readonly content: string;
  readonly id: string;
  readonly xIdx: number;
  readonly yIdx: number;
  readonly " $fragmentType": "displayMatrixElementCellForm_DisplayMatrixElementCellFragment";
};
export type displayMatrixElementCellForm_DisplayMatrixElementCellFragment$key = {
  readonly " $data"?: displayMatrixElementCellForm_DisplayMatrixElementCellFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"displayMatrixElementCellForm_DisplayMatrixElementCellFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "displayMatrixElementCellForm_DisplayMatrixElementCellFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "content",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xIdx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yIdx",
      "storageKey": null
    }
  ],
  "type": "DisplayMatrixElementCell",
  "abstractKey": null
};

(node as any).hash = "5454976960502e4995b4a7f0f976c60f";

export default node;
