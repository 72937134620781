import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query reportDetails_Query($reportId: ID!) {
		node(id: $reportId) {
			... on DSAReport {
				id
				data {
					problemType
					reportType
					extra
					lastName
					firstName
					email
					url
				}
				referenceNumber
				createdAt
			}
		}
	}
`;
