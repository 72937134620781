/**
 * @generated SignedSource<<5edeb9f21faaeb6aeebbb7e1c9d34bf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageDefinitionsTable_MessageDefinitionFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly internalTitle: string;
  readonly showUntil: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"createMessageDispatchButton_MessageDefinitionFragment">;
  readonly " $fragmentType": "messageDefinitionsTable_MessageDefinitionFragment";
};
export type messageDefinitionsTable_MessageDefinitionFragment$key = {
  readonly " $data"?: messageDefinitionsTable_MessageDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageDefinitionsTable_MessageDefinitionFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "messageDefinitionsTable_MessageDefinitionFragment"
};

(node as any).hash = "1df41d81aeb05f1e83e6bad23b24dbda";

export default node;
