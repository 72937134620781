import styled from "styled-components";
import { OrderList } from "primereact/orderlist";

export const OrderListWithoutControls = styled(OrderList)`
	.p-orderlist-controls {
		display: none;
	}

	.p-orderlist-list {
		.p-disabled {
			opacity: 1;
		}
	}
`;
export const CartLink = styled.div`
	margin: 2rem 0;
	display: flex;
	padding: 2rem;
	justify-content: center;
	border: 1px solid black;
	cursor: pointer;
	overflow-wrap: anywhere;
`;
