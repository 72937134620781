import graphql from "babel-plugin-relay/macro";

export const ORDER_FRAGMENT = graphql`
	fragment creditNoteDataForm_OrderFragment on Order {
		creditNoteData {
			creditNoteId
			creditNoteNumber
			creditNoteFile {
				url
			}
		}
	}
`;
