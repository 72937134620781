import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query externalLicenseRewardForm_Query {
		...selectExternalLicensePoolField_QueryFragment
	}
`;
export const EXTERNAL_LICENSE_REWARD_FRAGMENT = graphql`
	fragment externalLicenseRewardForm_ExternalLicenseRewardFragment on ExternalLicenseReward {
		... on Reward {
			id
		}
		superId
		data {
			pool {
				id
				data {
					name
				}
			}
		}
	}
`;

export const EDIT_EXTERNAL_LICENSE_REWARD_MUTATION = graphql`
	mutation externalLicenseRewardForm_EditExternalLicenseRewardMutation(
		$input: EditExternalLicenseRewardInput!
	) {
		Admin {
			Tree {
				editExternalLicenseReward(input: $input) {
					content {
						...rewardEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;
