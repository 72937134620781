import { Button } from "primereact/button";
import { type PaginationTableProps } from "./pagination-table.type";
import { DataTable } from "../data-table";

export const PaginationTable = <T,>({
	hasPrevious,
	loadPrevious,
	hasNext,
	loadNext,
	emptyMessage,
	...props
}: PaginationTableProps<T>) => {
	return (
		<>
			<DataTable emptyMessage={emptyMessage ?? "Keine Daten gefunden"} {...props} />
			<div className="flex justify-content-center align-items-center mt-5">
				<Button className="p-button-primary" disabled={!hasNext} onClick={loadNext}>
					Mehr laden
				</Button>
			</div>
		</>
	);
};
