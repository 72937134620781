export const enum TREE_I18N_KEY {
	tree = "tree",
	treePlural = "tree-plural",
	newTree = "new-tree",
	treeAttributiveGenitive = "tree-attributive-genitive",
	thisTree = "this-tree",
	treeLevel = "tree-level",
	contentNode = "content-node",
	contentNodeLevel = "content-node-level",
	thisContentNode = "this-content-node",
	inThisContentNode = "in-this-content-node",
	thisContentNodeUppercase = "this-content-node-uppercase",
	thisContentNodeAttributiveGenitive = "this-content-node-attributive-genitive",
	contentNodeAttributiveGenitive = "content-node-attributive-genitive",
	eLearningContentNode = "e-learning-content-node",
	newELearningContentNode = "new-e-learning-content-node",
	asyncContentNode = "async-content-node",
	newAsyncContentNode = "new-async-content-node",
	branchNode = "branch-node",
	newBranchNode = "new-branch-node",
	node = "node",
	newNode = "new-node",
}

export const enum E_LEARNING_I18N_KEY {
	file = "file",
	fileElement = "file-element",
	multipleChoice = "multiple-choice",
	multipleChoiceElement = "multiple-choice-element",
}

export const TREE_I18N_MAP = (key: TREE_I18N_KEY): string => {
	switch (key) {
		case TREE_I18N_KEY.tree:
			return "Modul";
		case TREE_I18N_KEY.treePlural:
			return "Module";
		case TREE_I18N_KEY.newTree:
			return "Neues Modul";
		case TREE_I18N_KEY.treeAttributiveGenitive:
			return "Moduls";
		case TREE_I18N_KEY.thisTree:
			return "dieses Modul";
		case TREE_I18N_KEY.treeLevel:
			return "Lernmodul-Ebene";
		case TREE_I18N_KEY.contentNode:
			return "Modul";
		case TREE_I18N_KEY.contentNodeLevel:
			return "Modul-Ebene";
		case TREE_I18N_KEY.thisContentNode:
			return "dieses Modul";
		case TREE_I18N_KEY.inThisContentNode:
			return "in diesem Modul";
		case TREE_I18N_KEY.thisContentNodeUppercase:
			return "Dieses Modul";
		case TREE_I18N_KEY.thisContentNodeAttributiveGenitive:
			return "dieses Moduls";
		case TREE_I18N_KEY.contentNodeAttributiveGenitive:
			return "Moduls";
		case TREE_I18N_KEY.eLearningContentNode:
			return "E-Learning-Modul";
		case TREE_I18N_KEY.newELearningContentNode:
			return "Neues E-Learning-Modul";
		case TREE_I18N_KEY.asyncContentNode:
			return "Async-Learning-Modul";
		case TREE_I18N_KEY.newAsyncContentNode:
			return "Neues Async-Learning-Modul";
		case TREE_I18N_KEY.branchNode:
			return "Modul";
		case TREE_I18N_KEY.newBranchNode:
			return "Neues Modul";
		case TREE_I18N_KEY.node:
			return "E-Learning-Modul/Modul";
		case TREE_I18N_KEY.newNode:
			return "Neues E-Learning-Modul/Modul";
		default:
			return "unbekannt";
	}
};

export const E_LEARNING_I18N_MAP = (key: E_LEARNING_I18N_KEY): string => {
	switch (key) {
		case E_LEARNING_I18N_KEY.file:
			return "Heft";
		case E_LEARNING_I18N_KEY.fileElement:
			return "Heft-Baustein";
		case E_LEARNING_I18N_KEY.multipleChoice:
			return "Fragen";
		case E_LEARNING_I18N_KEY.multipleChoiceElement:
			return "Fragen-Baustein";
		default:
			return "unbekannt";
	}
};
