/**
 * @generated SignedSource<<fd31a8b1e2c1f7aa8526f2d21f19a2ee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageDispatchForm_MessageDispatchFragment$data = {
  readonly node: {
    readonly id?: string;
    readonly includes?: ReadonlyArray<{
      readonly id: string;
    }>;
    readonly messageDefinition?: {
      readonly internalTitle: string;
    };
    readonly sendAt?: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"dispatchExclusions_MessageDispatchFragment" | "dispatchInclusions_MessageDispatchFragment">;
  } | null;
  readonly " $fragmentType": "messageDispatchForm_MessageDispatchFragment";
};
export type messageDispatchForm_MessageDispatchFragment$key = {
  readonly " $data"?: messageDispatchForm_MessageDispatchFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messageDispatchForm_MessageDispatchFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": require('./messageDispatch_Refetch.graphql')
    }
  },
  "name": "messageDispatchForm_MessageDispatchFragment",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "sendAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "MessageDefinition",
              "kind": "LinkedField",
              "name": "messageDefinition",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "internalTitle",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "includes",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "dispatchInclusions_MessageDispatchFragment"
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "dispatchExclusions_MessageDispatchFragment"
            }
          ],
          "type": "MessageDispatch",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "55d314eb7ea0bae67c2d3a4346b42e75";

export default node;
