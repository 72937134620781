import { Button, ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import { inputFieldClass } from "@features/users/select-single-user-form/select-single-user-form.styles";
import { SelectUserField } from "@features/users/select-user-field";
import {
	SelectSingleUserFormProps,
	SelectSingleUserFormState,
} from "./select-single-user-form.types";

export const SelectSingleUserForm = ({
	onUserSelected,
	disabled,
	accountId,
}: SelectSingleUserFormProps) => {
	const formik = useFormik<SelectSingleUserFormState>({
		initialValues: {},
		isInitialValid: false,
		validateOnChange: false,
		validateOnBlur: false,
		validationSchema: Yup.object().shape({
			userId: Yup.string().min(1).required("Ein Benutzer wird benötigt."),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onUserSelected(data.userId!);
			setSubmitting(false);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="w-12 p-fluid mb-5">
			<ValidatedField<SelectSingleUserFormState, string>
				className={inputFieldClass}
				name={"userId"}
				label={"Wähle einen Benutzer aus"}
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectUserField accountId={accountId} {...renderConfig} />
				)}
			/>
			<Button
				disabled={!formik.isValid || disabled}
				label="Benutzer hinzufügen"
				type={ButtonType.Submit}
			/>
		</form>
	);
};
