import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ExternalLicensePoolEditScreen } from "./external-license-pool-edit.screen";

export const ExternalLicensePoolEditRoutes: RouteDefinition[] = [
	{
		path: Paths.externalLicensePools.withIdPlaceholder().edit.path,
		element: <ExternalLicensePoolEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_ExternalLicenseAdmin_Read",
			"UserInAccountPermission_ExternalLicenseAdmin_Modify",
		],
	},
];
