import graphql from "babel-plugin-relay/macro";

export const RESET_STAGING_BILLING_MUTATION = graphql`
	mutation resetStagingBillingButton_ResetStagingBillingMutation {
		Admin {
			Staging {
				resetStagingBilling(input: {}) {
					clientMutationId
				}
			}
		}
	}
`;
