import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { type TreeConfigurationEditDialogProps } from "./Config.interfaces";
import { type BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key } from "../../../__generated__/BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment.graphql";
import { type BlockPermanentlyRestartIfFailedContentConfigForm_EditMutation } from "../../../__generated__/BlockPermanentlyRestartIfFailedContentConfigForm_EditMutation.graphql";
import { type BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment$key } from "../../../__generated__/BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment.graphql";
import { ValidatedField } from "../../../components/ValidatedField";
import { CONFIGS_TRANSLATIONS } from "../../../translations/educational-offer";

const CONFIGURATION_FRAGMENT = graphql`
	fragment BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment on BlockPermanentlyRestartIfFailedContentConfig {
		id
		configType
		maximumTries
	}
`;

const TREE_NODE_FRAGMENT = graphql`
	fragment BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment on TreeNode {
		id
	}
`;

const EDIT_CONFIGURATION_MUTATION = graphql`
	mutation BlockPermanentlyRestartIfFailedContentConfigForm_EditMutation(
		$input: EditBlockPermanentlyRestartIfFailedContentConfigInput!
	) {
		Admin {
			Tree {
				editBlockPermanentlyRestartIfFailedContentConfig(input: $input) {
					contentNode {
						...RestartIfFailedContentConfigEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type FormState = {
	maximumTries: number;
};

type Props = {
	configurationFragmentRef: BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key;
	treeNodeFragmentRef: BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment$key;
	onBack?: () => void;
} & TreeConfigurationEditDialogProps;

export const BlockPermanentlyRestartIfFailedContentConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: Props) => {
	const treeNode =
		useFragment<BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment$key>(
			TREE_NODE_FRAGMENT,
			treeNodeFragmentRef,
		);
	const configuration =
		useFragment<BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment$key>(
			CONFIGURATION_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] =
		useMutation<BlockPermanentlyRestartIfFailedContentConfigForm_EditMutation>(
			EDIT_CONFIGURATION_MUTATION,
		);

	const formik = useFormik<FormState>({
		enableReinitialize: true,
		initialValues: {
			maximumTries: configuration.maximumTries,
		},
		onSubmit: (values, { setSubmitting }) => {
			edit({
				variables: {
					input: {
						contentNodeId: treeNode.id,
						configId: configuration.id,
						newMaximumTries: values.maximumTries,
					},
				},
			});
			setSubmitting(false);
			onBack!();
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<FormState, number>
					name={"maximumTries"}
					label={CONFIGS_TRANSLATIONS[configuration.configType]}
					component={({ fieldName, updateField, fieldValue }) => (
						<InputNumber
							name={fieldName}
							value={fieldValue}
							onChange={(e) => {
								updateField(e.value || undefined);
							}}
						/>
					)}
					formikConfig={formik}
				/>

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditing}
					type="submit"
					label="Speichern"
					className="mt-2"
				/>

				<Button
					type="button"
					onClick={() => {
						onBack!();
					}}
					label="Zurück"
					className="p-button-secondary mt-2"
				/>
			</form>
		</>
	);
};
