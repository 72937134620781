import { DialogButton, EpicIcons } from "@thekeytechnology/epic-ui";
import { readInlineData, useFragment } from "react-relay";
import { type editAccountGroupsButton_AccountFragment$key } from "@relay/editAccountGroupsButton_AccountFragment.graphql";
import { type editAccountGroupsButton_AccountGroupFragment$key } from "@relay/editAccountGroupsButton_AccountGroupFragment.graphql";
import { ACCOUNT_FRAGMENT, ACCOUNT_GROUP_FRAGMENT } from "./edit-account-groups-button.graphql";
import { type EditAccountGroupsButtonProps } from "./edit-account-groups-button.types";
import { EditAccountGroupsForm } from "../edit-account-groups-form/edit-account-groups-form.component";
export const EditAccountGroupsButton = ({
	accountFragmentRef,
	accountGroupsFragmentsRef,
}: EditAccountGroupsButtonProps) => {
	const accountFragment = useFragment<editAccountGroupsButton_AccountFragment$key>(
		ACCOUNT_FRAGMENT,
		accountFragmentRef,
	);

	const accountGroupFragments = accountGroupsFragmentsRef.map((e) =>
		readInlineData<editAccountGroupsButton_AccountGroupFragment$key>(ACCOUNT_GROUP_FRAGMENT, e),
	);

	return (
		<DialogButton
			buttonIcon={EpicIcons.PENCIL}
			dialogTitle="Account Gruppen"
			tooltip="Account Gruppen"
		>
			{() => (
				<EditAccountGroupsForm
					accountFragmentRef={accountFragment}
					accountGroupsFragmentsRef={accountGroupFragments}
				/>
			)}
		</DialogButton>
	);
};
