/**
 * @generated SignedSource<<be728be57127b6ab428ab2111107d82b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditRootNodePartForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly firstReleasedAt?: string | null;
    readonly icon?: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly searchWords?: string | null;
  };
  readonly " $fragmentType": "EditRootNodePartForm_TreeNodeFragment";
};
export type EditRootNodePartForm_TreeNodeFragment$key = {
  readonly " $data"?: EditRootNodePartForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditRootNodePartForm_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditRootNodePartForm_TreeNodeFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "firstReleasedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "searchWords",
              "storageKey": null
            }
          ],
          "type": "RootStructureDefinition",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "a3e744dd200d4a035d74b3c2978cf0d3";

export default node;
