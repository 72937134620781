import graphql from "babel-plugin-relay/macro";

export const DISCOUNT_CODES_V2_CONNECTION_FRAGMENT = graphql`
	fragment minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment on DiscountCodesV2Connection {
		edges {
			node {
				... on DiscountCode {
					...minimizedDiscountCodeTable_DiscountCodeFragment
				}
			}
		}
	}
`;

export const DISCOUNT_CODE_FRAGMENT = graphql`
	fragment minimizedDiscountCodeTable_DiscountCodeFragment on DiscountCode @inline {
		id
		code
	}
`;
