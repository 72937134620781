import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { externalLicensePoolsPerPage } from "./external-license-pools.constants";
import { EXTERNAL_LICENSE_POOLS_QUERY } from "./external-license-pools.graphql";
import {
	type externalLicensePoolsScreen_Query,
	type externalLicensePoolsScreen_Query$data,
} from "../../__generated__/externalLicensePoolsScreen_Query.graphql";
import { NoAccess } from "../../components/no-access";
import { ExternalLicensePoolsTable } from "../../features/external-licenses/external-license-pools-table/external-license-pools-table.component";

export const ExternalLicensePoolsScreen = () => {
	const externalLicensePools: externalLicensePoolsScreen_Query$data =
		useLazyLoadQuery<externalLicensePoolsScreen_Query>(
			EXTERNAL_LICENSE_POOLS_QUERY,
			{
				numItemsPerPage: externalLicensePoolsPerPage,
			},
			{ fetchPolicy: "network-only" },
		);

	const canRead = useHasPermissions(["UserInAccountPermission_ExternalLicenseAdmin_Read"]);

	if (!canRead) return <NoAccess />;

	return (
		<Suspense fallback={<ProgressSpinner />}>
			<h2 className="mb-3 text-center">Externe Lizenz-Pools</h2>
			<ExternalLicensePoolsTable
				externalLicensePoolsFragmentRef={externalLicensePools}
				externalLicensePoolsPerPage={externalLicensePoolsPerPage}
			/>
		</Suspense>
	);
};
