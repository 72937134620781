/**
 * @generated SignedSource<<5cd4bfe1e3fdf7370d8f39bdfa209c32>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsTable_LicenseProductFragment$data = {
  readonly id: string;
  readonly title: string;
  readonly " $fragmentType": "productsTable_LicenseProductFragment";
};
export type productsTable_LicenseProductFragment$key = {
  readonly " $data"?: productsTable_LicenseProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_LicenseProductFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "productsTable_LicenseProductFragment"
};

(node as any).hash = "d4dc1571b3ac48e5f340a02fd8c5075c";

export default node;
