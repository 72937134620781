import * as Yup from "yup";
import { pageButtonForm_ButtonFragment$key } from "@relay/pageButtonForm_ButtonFragment.graphql";

export const PageButtonFormSchema = Yup.object({
	text: Yup.string()
		.required("Bitte gib einen Text ein.")
		.max(40, "Text zu lang (maximal 40 Zeichen)."),
	kind: Yup.string().required("Bitte gib eine Button-Art ein."),
	url: Yup.string()
		.url("Die URL muss in folgendem Format vorliegen: https://...")
		.when("kind", {
			is: (kind: any) => {
				return kind === "link";
			},
			then: (schema) => schema.required("URL wird benötigt"),
			otherwise: (schema) => schema.notRequired(),
		}),
});

export type PageButtonFormState = Yup.InferType<typeof PageButtonFormSchema>;

export type PageButtonFormRef = {
	submit: () => Promise<any>;
	validate: () => Promise<boolean>;
	values: PageButtonFormState;
};

export type PageButtonFormProps = {
	buttonFragmentRef: pageButtonForm_ButtonFragment$key | undefined;
	onSubmit?: (values: PageButtonFormState) => void;
};
