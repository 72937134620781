/**
 * @generated SignedSource<<1ec8d52623a1a09da9c14f419d0e1017>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAcademiesTagField_QueryFragment$data = {
  readonly Admin: {
    readonly AcademiesTag: {
      readonly SelectTags: {
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"selectAcademiesTagField_AcademiesTagFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "selectAcademiesTagField_QueryFragment";
};
export type selectAcademiesTagField_QueryFragment$key = {
  readonly " $data"?: selectAcademiesTagField_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAcademiesTagField_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "AcademiesTag",
  "SelectTags"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": 20,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isClickable"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isRoot"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isTopic"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "name"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./selectAcademiesTagField_Refetch.graphql')
    }
  },
  "name": "selectAcademiesTagField_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AcademiesTagAdminSchema",
          "kind": "LinkedField",
          "name": "AcademiesTag",
          "plural": false,
          "selections": [
            {
              "alias": "SelectTags",
              "args": [
                {
                  "kind": "Variable",
                  "name": "isClickable",
                  "variableName": "isClickable"
                },
                {
                  "kind": "Variable",
                  "name": "isRoot",
                  "variableName": "isRoot"
                },
                {
                  "kind": "Variable",
                  "name": "isTopic",
                  "variableName": "isTopic"
                },
                {
                  "kind": "Variable",
                  "name": "name",
                  "variableName": "name"
                }
              ],
              "concreteType": "AcademiesTagsConnection",
              "kind": "LinkedField",
              "name": "__selectAcadamiesTagField_SelectTags_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AcademiesTagsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "selectAcademiesTagField_AcademiesTagFragment",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "id",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "AcademiesTagData",
                              "kind": "LinkedField",
                              "name": "data",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "name",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isClickable",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "isTopic",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "9f33021b8fd95b39f1b4662f74e9f60d";

export default node;
