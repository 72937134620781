/**
 * @generated SignedSource<<465dba27eda25015a3d3c37801aceef9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CountryCode = "AT" | "CH" | "DE";
export type SalutationType = "Divers" | "Mr" | "Mrs" | "Unknown";
import { FragmentRefs } from "relay-runtime";
export type privateBaseDataForm_PrivateBaseDataFragment$data = {
  readonly __typename: "PrivateBaseData";
  readonly city: string | null;
  readonly countryCode: CountryCode | null;
  readonly firstName: string | null;
  readonly houseNumber: string | null;
  readonly lastName: string | null;
  readonly phoneNumber: string | null;
  readonly postalCode: string | null;
  readonly salutation: SalutationType | null;
  readonly street: string | null;
  readonly title: string | null;
  readonly " $fragmentType": "privateBaseDataForm_PrivateBaseDataFragment";
};
export type privateBaseDataForm_PrivateBaseDataFragment$key = {
  readonly " $data"?: privateBaseDataForm_PrivateBaseDataFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"privateBaseDataForm_PrivateBaseDataFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "privateBaseDataForm_PrivateBaseDataFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "salutation",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phoneNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "city",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "street",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "houseNumber",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "postalCode",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "countryCode",
      "storageKey": null
    }
  ],
  "type": "PrivateBaseData",
  "abstractKey": null
};

(node as any).hash = "7ea2c3a67c93aab8c4eb15e55c5cc333";

export default node;
