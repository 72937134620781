import { Tooltip } from "@thekeytechnology/epic-ui";
import React, { type PropsWithChildren, useId } from "react";
import { toast } from "react-toastify";
import { type CopyTableIdButtonProps } from "@components/copy-table-id-button/copy-table-id-button.types";

export const CopyTableIdButton = ({ id, children }: PropsWithChildren<CopyTableIdButtonProps>) => {
	const idToUse = useId().replace(":", "").replace(":", "");
	const handleOnClick = () => {
		void navigator.clipboard.writeText(id);
		toast.success("In die Zwischenablage kopiert.");
	};
	return (
		<>
			<Tooltip target={`#${idToUse}`} content={`Copy ${id}`} />
			<div className="cursor-pointer" onClick={handleOnClick}>
				<div>
					{children}
					<i id={idToUse} className="pi pi-copy"></i>
				</div>
			</div>
		</>
	);
};
