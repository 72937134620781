import { MultiSelect } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { readInlineData, useFragment, useMutation } from "react-relay";
import { type editAccountGroupsForm_AccountFragment$key } from "@relay/editAccountGroupsForm_AccountFragment.graphql";
import { type editAccountGroupsForm_AccountGroupFragment$key } from "@relay/editAccountGroupsForm_AccountGroupFragment.graphql";
import {
	ACCOUNT_FRAGMENT,
	ACCOUNT_GROUP_FRAGMENT,
	UPDATE_ACCOUNT_GROUPS_MUTATION,
} from "./edit-account-groups-form.graphql";
import {
	type EditAccountGroupsFormProps,
	type EditAccountGroupsFormState,
} from "./edit-account-groups-form.types";

export const EditAccountGroupsForm = ({
	accountFragmentRef,
	accountGroupsFragmentsRef,
}: EditAccountGroupsFormProps) => {
	const accountFragment = useFragment<editAccountGroupsForm_AccountFragment$key>(
		ACCOUNT_FRAGMENT,
		accountFragmentRef,
	);

	const groupAssociationsOptions = accountGroupsFragmentsRef.map((e) => {
		const data = readInlineData<editAccountGroupsForm_AccountGroupFragment$key>(
			ACCOUNT_GROUP_FRAGMENT,
			e,
		);
		return {
			label: data.name,
			value: data.id,
		};
	});

	const [setAccountGroups] = useMutation(UPDATE_ACCOUNT_GROUPS_MUTATION);

	const formik = useFormik<EditAccountGroupsFormState>({
		initialValues: {
			groupAssociations: accountFragment?.groupAssociations.map((e) => e.group?.id!),
		},
		onSubmit: (values) => {
			setAccountGroups({
				variables: {
					input: {
						id: accountFragment.id,
						groupIds: values.groupAssociations,
					},
				},
			});
		},
	});

	const handleGroupAssociationsChanged = useCallback(
		(updatedValue: string[] | undefined) => {
			void formik.setFieldValue("groupAssociations", updatedValue);
			void formik.setFieldTouched("groupAssociations", true, false);
		},
		[formik],
	);

	const filteredValues = formik.values.groupAssociations?.filter((value) =>
		groupAssociationsOptions.find((option) => option.value === value),
	);

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid p-flex">
			<MultiSelect
				placeholder={"Gruppen auswählen ..."}
				filter={true}
				options={groupAssociationsOptions}
				values={filteredValues}
				onChange={handleGroupAssociationsChanged}
			/>
			<Button type="submit" label={"Gruppen Speichern"} className="mt-4"></Button>
		</form>
	);
};
