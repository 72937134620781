import { CreatePercentageDiscountActionButton } from "./create-percentage-discount-action-button";
import { DiscountActionFilter } from "./DiscountActionFilter";

type OwnProps = {
	connectionId: string;
};

export const DiscountCodeActionsTableHeader = ({ connectionId }: OwnProps) => {
	return (
		<div className="w-full flex flex-row justify-content-between">
			<DiscountActionFilter />
			<CreatePercentageDiscountActionButton
				className="align-self-end"
				connectionId={connectionId}
			/>
		</div>
	);
};
