import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { DataTableSelectionChangeParams } from "@components/data-table";
import { SearchableTable } from "@components/searchable-table";
import { SelectComplexDataField } from "@components/select-complex-data-field";
import {
	ACCOUNT_FRAGMENT,
	QUERY_FRAGMENT,
} from "@features/accounts/select-multiple-accounts-field/select-multiple-accounts-field.graphql";
import {
	SelectMultipleAccountsFieldProps,
	SelectMultipleAccountsFieldState,
} from "@features/accounts/select-multiple-accounts-field/select-multiple-accounts-field.types";
import {
	selectMultipleAccountsField_AccountFragment$data,
	selectMultipleAccountsField_AccountFragment$key,
} from "@relay/selectMultipleAccountsField_AccountFragment.graphql";
import { selectMultipleAccountsField_QueryFragment$key } from "@relay/selectMultipleAccountsField_QueryFragment.graphql";
import { selectMultipleAccountsField_Refetch } from "@relay/selectMultipleAccountsField_Refetch.graphql";

export const SelectMultipleAccountsField = ({
	queryFragmentRef,
	...props
}: SelectMultipleAccountsFieldProps) => {
	const {
		data: {
			Admin: {
				Auth: { SelectAccounts },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectMultipleAccountsField_Refetch,
		selectMultipleAccountsField_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ nameOpt: search });
	};

	const handleOnMultipleSelectionChange = (
		event: DataTableSelectionChangeParams<selectMultipleAccountsField_AccountFragment$data[]>,
	) => {
		props.updateField(event.value);
	};

	const values =
		SelectAccounts.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<selectMultipleAccountsField_AccountFragment$key>(
					ACCOUNT_FRAGMENT,
					e!.node,
				),
			) || [];

	return (
		<SelectComplexDataField<SelectMultipleAccountsFieldState>
			valuePlaceholder="Keine Konten ausgewählt"
			actionButtonLabel="Konten auswählen"
			headerLabel="Wähle mindestens ein Konto aus"
			{...props}
		>
			<SearchableTable
				selectionMode={"multiple"}
				metaKeySelection={false}
				selection={props.fieldValue}
				dataKey="id"
				value={values}
				hasPrevious={hasPrevious}
				hasNext={hasNext}
				loadPrevious={handleLoadPrevious}
				loadNext={handleLoadNext}
				onSearch={handleOnSearch}
				onSelectionChange={handleOnMultipleSelectionChange}
			>
				{(Column) => (
					<>
						<Column header="Name" body={(data) => data.name} />
					</>
				)}
			</SearchableTable>
		</SelectComplexDataField>
	);
};
