/**
 * @generated SignedSource<<b28b73e9589a5a2155929a5b0e77e6db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectSingleAccountField_AccountFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "selectSingleAccountField_AccountFragment";
};
export type selectSingleAccountField_AccountFragment$key = {
  readonly " $data"?: selectSingleAccountField_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectSingleAccountField_AccountFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectSingleAccountField_AccountFragment"
};

(node as any).hash = "401a1fe9912f8ec2cd5d91b80bd56525";

export default node;
