import { graphql } from "babel-plugin-relay/macro";

export const ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT = graphql`
	fragment editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment on AnswerMatrixElementCell {
		id
		correctAnswer {
			content
		}
		wrongAnswers {
			content
			id
		}
		...editWrongAnswerInMatrixElementCellForm_AnswerMatrixElementCellFragment
	}
`;

export const SET_CORRECT_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION = graphql`
	mutation editAnswersMatrixElementCellDialogForm_SetCorrectAnswerInMatrixElementCellMutation(
		$input: SetCorrectAnswerInMatrixElementCellInput!
	) {
		Admin {
			ElearningV2 {
				setCorrectAnswerInMatrixElementCell(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;

export const ADD_WRONG_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION = graphql`
	mutation editAnswersMatrixElementCellDialogForm_AddWrongAnswerInMatrixElementCellMutation(
		$input: AddWrongAnswerInMatrixElementCellInput!
	) {
		Admin {
			ElearningV2 {
				addWrongAnswerInMatrixElementCell(input: $input) {
					matrixElement {
						...matrixElementForm_MatrixElementFragment
					}
				}
			}
		}
	}
`;
