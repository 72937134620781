import { graphql } from "babel-plugin-relay/macro";

export const TREE_FRAGMENT = graphql`
	fragment educationalOfferTree_TreeFragment on Tree {
		id
		rootNode {
			id
			hasBeenPublishedOnce
			structureDefinition {
				title
			}
			...educationalOfferTree_TreeNodeFragment
		}
		nodes {
			...educationalOfferTree_TreeNodeFragment
		}
	}
`;

export const PUBLISHINGV2_FRAGMENT = graphql`
	fragment educationalOfferTree_PublishingV2Fragment on PublishingV2 {
		status
	}
`;

export const TREE_NODE_FRAGMENT = graphql`
	fragment educationalOfferTree_TreeNodeFragment on TreeNode @inline {
		id
		hasBeenPublishedOnce
		parentId
		structureDefinition {
			coordinates {
				parentRef
			}
			definitionType
			title
		}
		typeDefinition {
			definitionType
			... on BranchTypeDefinition {
				childRefs
			}
			... on ContentTypeDefinition {
				contentKind
				... on ELearningContentTypeDefinition {
					elements {
						id
						elementType
						...ElementNode_ElementV2Fragment
					}
				}
			}
		}
		...Node_TreeNodeFragment
		...DeleteChildNodeButton_TreeNodeFragment
	}
`;

export const MOVE_CHILD_NODE_MUTATION = graphql`
	mutation educationalOfferTree_MoveChildNodeMutation($input: MoveChildNodeInput!) {
		Admin {
			Tree {
				moveChildNode(input: $input) {
					tree {
						...educationalOffer_TreeFragment
					}
				}
			}
		}
	}
`;

export const DELETE_CHILD_NODE_MUTATION = graphql`
	mutation educationalOfferTree_RemoveNodeFromTreeMutation($input: RemoveNodeFromTreeInput!) {
		Admin {
			Tree {
				removeNodeFromTree(input: $input) {
					tree {
						...educationalOffer_TreeFragment
					}
				}
			}
		}
	}
`;
