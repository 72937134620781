import { MessageDefinitionForm } from "@screens/message-definition-edit/parts/message-definition-form";

import { BaseScreen } from "../BaseScreen";

export const MessageDefinitionEditScreen = () => {
	return (
		<BaseScreen>
			<MessageDefinitionForm />
		</BaseScreen>
	);
};
