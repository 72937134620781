import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query offers_Query(
		$first: Int
		$after: String
		$before: String
		$last: Int
		$tagIds: [ID!]!
		$isVisible: Boolean
		$kinds: [LearnableKind!]!
		$text: String
	) {
		...offersTable_QueryFragment
			@arguments(
				first: $first
				after: $after
				before: $before
				last: $last
				tagIds: $tagIds
				isVisible: $isVisible
				kinds: $kinds
				text: $text
			)
		...offersFilters_QueryFragment
	}
`;
