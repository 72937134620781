import { graphql } from "babel-plugin-relay/macro";

export const ADD_EXTERNAL_LICENSE_MUTATION = graphql`
	mutation addExternalLicenseButton_CreateExternalLicenseMutation(
		$input: CreateExternalLicenseInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createExternalLicense(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							id
							data {
								name
								code
							}
							pool {
								data {
									usageInformation
									validNumDaysAfterIssuing
								}
							}
							issuingInfo {
								hasBeenIssued
							}
						}
					}
				}
			}
		}
	}
`;
