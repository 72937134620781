import { Suspense, useRef } from "react";
import { Toast } from "primereact/toast";
import { useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";

import { DefaultNumberFieldComponent } from "@thekeytechnology/framework-react-components";
import {
	LicenseProductFormProps,
	LicenseProductFormState,
} from "@screens/product-edit/parts/license-product-form/license-product-form.types";
import {
	EDIT_LICENSE_PRODUCT_MUTATION,
	LICENSE_PRODUCT_FRAGMENT,
	// QUERY_FRAGMENT,
} from "@screens/product-edit/parts/license-product-form/license-product-form.graphql";
// import { licenseProductForm_QueryFragment$key } from "@relay/licenseProductForm_QueryFragment.graphql";
import { licenseProductForm_LicenseProductFragment$key } from "@relay/licenseProductForm_LicenseProductFragment.graphql";
import { ProductForm } from "@screens/product-edit/parts/product-form/product-form.component";
import { ProgressSpinner } from "primereact/progressspinner";
import { licenseProductForm_EditLicenseProductMutation } from "@relay/licenseProductForm_EditLicenseProductMutation.graphql";

export const LicenseProductForm = ({
	productFragmentRef, // queryFragmentRef,
}: LicenseProductFormProps) => {
	const toast = useRef<Toast>(null);

	// const query = useFragment<licenseProductForm_QueryFragment$key>(
	// 	QUERY_FRAGMENT,
	// 	queryFragmentRef,
	// );

	const product = useFragment<licenseProductForm_LicenseProductFragment$key>(
		LICENSE_PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	const [editProduct] = useMutation<licenseProductForm_EditLicenseProductMutation>(
		EDIT_LICENSE_PRODUCT_MUTATION,
	);

	const formik = useFormik<LicenseProductFormState>({
		enableReinitialize: true,
		initialValues: {
			title: product.title,
			isHidden: product.isHidden,
			price: product.price,
			// licenseDefinition: product.licenseDefinition
			// 	? {
			// 			id: product.licenseDefinition.id,
			// 			name: product.licenseDefinition.data.name,
			// 	  }
			// 	: undefined,
			licenseDefinitionAmount: product.licenseDefinitionAmount ?? 1,
			costUnit: product.costUnit ?? "",
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Ein Titel wird benötigt."),
			price: Yup.object().shape({
				netPrice: Yup.number().required("Ein Nettopreis wird benötigt."),
				grossPrice: Yup.number().required("Ein Bruttopreis wird benötigt."),
				taxRatePercentage: Yup.number().required("Ein Steuersatz wird benötigt."),
			}),
			isHidden: Yup.boolean(),
			licenseDefinition: Yup.object()
				.default(undefined)
				.required("Eine Lizenz wird benötigt."),
			licenseDefinitionAmount: Yup.number()
				.min(1, "Produkt muss mindestens eine Lizenz beinhalten")
				.typeError("Anzahl an Lizenzen muss eine Zahl sein"),
			costUnit: Yup.string().optional(),
		}),
		onSubmit: (values: LicenseProductFormState, { setSubmitting, resetForm }) => {
			editProduct({
				variables: {
					input: {
						id: product.id,
						netPrice: values.price,
						title: values.title,
						isHidden: values.isHidden ?? true,
						isTaxFree: true,
						licenseDefinitionId: "", // values.licenseDefinition!.id,
						licenseDefinitionAmount: values.licenseDefinitionAmount,
						costUnit: values.costUnit,
					},
				},
			});
			setSubmitting(false);
		},
	});

	return (
		<>
			<Toast ref={toast} />
			<Suspense fallback={<ProgressSpinner />}>
				<ProductForm formik={formik} handleSubmit={formik.handleSubmit}>
					<ValidatedField<LicenseProductFormState, string>
						name={"costUnit"}
						label="Kostenstelle"
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
					<ValidatedField<LicenseProductFormState, number>
						name={"licenseDefinitionAmount"}
						label="Anzahl an enthaltener Lizenzen"
						component={DefaultNumberFieldComponent}
						formikConfig={formik}
					/>
				</ProductForm>
			</Suspense>
		</>
	);
};
