import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type externalLicensesTable_ExternalLicenseFragment$key } from "@relay/externalLicensesTable_ExternalLicenseFragment.graphql";
import { type externalLicensesTable_ExternalLicensesFragment$key } from "@relay/externalLicensesTable_ExternalLicensesFragment.graphql";
import { type externalLicensesTable_ExternalLicensesFragmentRefetch } from "@relay/externalLicensesTable_ExternalLicensesFragmentRefetch.graphql";
import {
	EXTERNAL_LICENSE_FRAGMENT,
	EXTERNAL_LICENSES_FRAGMENT,
} from "./external-licenses-table.graphql";
import { type ExternalLicensesTableProps } from "./external-licenses-table.interface";
import { DataTable } from "../../../../components/data-table";
import { DateTimeDisplay } from "../../../../components/datetime-display/datetime-display.component";
import { Pagination } from "../../../../components/Pagination";
import { DeleteExternalLicenseButton } from "../../../../features/external-licenses/delete-external-license-button/delete-external-license-button.component";
import { EditExternalLicenseButton } from "../../../../features/external-licenses/edit-external-license-button/edit-external-license-button.component";
import { ExternalLicenseFilters } from "../../../../features/external-licenses/external-license-table-filters/external-license-filters.component";
import { AddExternalLicenseButton } from "../add-external-license-button/add-external-license-button.component";
import { IssueExternalLicenseToUserButton } from "../issue-external-license-to-user-button/issue-external-license-to-user-button.component";

export const ExternalLicensesTable = ({
	externalLicensesFragmentRef,
	externalLicensesPerPage,
	externalLicensePoolId,
}: ExternalLicensesTableProps) => {
	const {
		data: externalLicenses,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<
		externalLicensesTable_ExternalLicensesFragmentRefetch,
		externalLicensesTable_ExternalLicensesFragment$key
	>(EXTERNAL_LICENSES_FRAGMENT, externalLicensesFragmentRef);

	const externalLicensesConnection =
		externalLicenses.Admin.Billing.GetExternalLicenses.edges?.map((edge) =>
			readInlineData<externalLicensesTable_ExternalLicenseFragment$key>(
				EXTERNAL_LICENSE_FRAGMENT,
				edge?.node!,
			),
		);
	const entitiesConnection = [externalLicenses.Admin.Billing.GetExternalLicenses.__id] || [];

	const refetchFilters = (isIssued?: boolean, name?: string) =>
		refetch({
			name,
			isIssued,
			externalLicensePoolIdOpt: externalLicensePoolId,
			first: externalLicensesPerPage,
		});

	const canModify = useHasPermissions(["UserInAccountPermission_ExternalLicenseAdmin_Modify"]);

	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Keine Externen Lizenzen"}
				className="p-mb-2"
				header={
					<div className="flex flex-row">
						<ExternalLicenseFilters refetch={refetchFilters} />
						<div className="flex flex-grow-1" />
						{canModify && (
							<AddExternalLicenseButton
								externalLicensePoolId={externalLicensePoolId}
								externalLicensesConnection={entitiesConnection}
							/>
						)}
					</div>
				}
				value={externalLicensesConnection}
			>
				{(Column) => (
					<>
						<Column
							header={"ID"}
							body={(externalLicense) => (
								<div
									className="cursor-pointer"
									onClick={async () => {
										await navigator.clipboard.writeText(externalLicense.id);
									}}
								>
									<b>
										ID<i className="pi pi-copy"></i>
									</b>
								</div>
							)}
						/>
						<Column header={"Name"} field={"data.name"} />
						<Column header={"Code"} field={"data.code"} />
						<Column
							header={"Ist Vergeben"}
							body={(externalLicense) =>
								externalLicense.issuingInfo.hasBeenIssued ? (
									<div>
										Ja an {externalLicense.issuingInfo.issuedTo?.email} um
										<DateTimeDisplay
											value={externalLicense.issuingInfo.issuedAt}
										/>
									</div>
								) : (
									<div>Nein</div>
								)
							}
						/>
						<Column
							header={"Gültig bis"}
							body={(externalLicense) =>
								externalLicense.issuingInfo.validUntil ? (
									<div>
										<DateTimeDisplay
											value={externalLicense.issuingInfo.validUntil}
										/>
									</div>
								) : (
									<div>Nicht vergeben</div>
								)
							}
						/>
						{canModify && (
							<Column
								header="Aktionen"
								style={{ width: "20%" }}
								body={(externalLicense) => (
									<div className="flex gap-2">
										{!externalLicense.issuingInfo.hasBeenIssued && (
											<IssueExternalLicenseToUserButton
												externalLicenseId={externalLicense.id}
												externalLicensesConnection={entitiesConnection}
											/>
										)}
										<EditExternalLicenseButton
											externalLicenseId={externalLicense.id}
										/>
										{!externalLicense.issuingInfo?.issuedTo?.email && (
											<DeleteExternalLicenseButton
												externalLicenseId={externalLicense.id}
												connections={entitiesConnection}
											/>
										)}
									</div>
								)}
							/>
						)}
					</>
				)}
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(externalLicensesPerPage)} />
		</>
	);
};
