/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { useBlocker } from "./UseBlocker";
import { selectArrayOfEditedForms } from "../store/slices/CoreSlice";
import { useTypedSelector } from "../store/store.redux";

export function useCallbackPrompt(when: boolean): Array<boolean | (() => void)> {
	const navigate = useNavigate();
	const location = useLocation();
	const [showPrompt, setShowPrompt] = useState(false);
	const [lastLocation, setLastLocation] = useState<any>(null);
	const [confirmedNavigation, setConfirmedNavigation] = useState(false);
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const cancelNavigation = useCallback(() => {
		setShowPrompt(false);
	}, []);

	// handle blocking when user click on another route prompt will be shown
	const handleBlockedNavigation = useCallback(
		(nextLocation: any) => {
			// in if condition we are checking next location and current location are equals or not
			if (!confirmedNavigation && nextLocation.location.pathname !== location.pathname) {
				setShowPrompt(true);
				setLastLocation(nextLocation);
				return false;
			} else {
				setShowPrompt(false);
			}
			return true;
		},
		[confirmedNavigation],
	);

	const confirmNavigation = useCallback(() => {
		setShowPrompt(false);
		setConfirmedNavigation(true);
	}, []);
	useEffect(() => {
		if (arrayOfEditedForms.length === 0) {
			setShowPrompt(false);
		}
	}, [arrayOfEditedForms]);

	useEffect(() => {
		if (confirmedNavigation && lastLocation) {
			navigate(lastLocation.location.pathname);
			setConfirmedNavigation(false);
		}
	}, [confirmedNavigation, lastLocation]);

	useBlocker(handleBlockedNavigation, when);

	return [showPrompt, confirmNavigation, cancelNavigation];
}
