import { graphql } from "babel-plugin-relay/macro";

export const DISPATCH_CONDITION_FRAGMENT = graphql`
	fragment removeDispatchConditionButton_DispatchConditionFragment on DispatchCondition {
		id
	}
`;

export const REMOVE_INCLUDE_DISPATCH_CONDITION_MUTATION = graphql`
	mutation removeDispatchConditionButton_RemoveIncludeDispatchConditionMutation(
		$input: RemoveIncludeDispatchConditionInput!
	) {
		Admin {
			Message {
				removeIncludeDispatchCondition(input: $input) {
					messageDispatch {
						id
					}
				}
			}
		}
	}
`;
export const REMOVE_EXCLUDE_DISPATCH_CONDITION_MUTATION = graphql`
	mutation removeDispatchConditionButton_RemoveExcludeDispatchConditionMutation(
		$input: RemoveExcludeDispatchConditionInput!
	) {
		Admin {
			Message {
				removeExcludeDispatchCondition(input: $input) {
					messageDispatch {
						id
					}
				}
			}
		}
	}
`;
