import { type DSAReportProblemType, type DSAReportType } from "@relay/reportDetails_Query.graphql";

/**
 * Get Translatiopn for ReportType Enum
 * @param type - ReportType
 * @returns {string} translation
 */
export const getReportTypeTranslations = (type?: DSAReportType): string => {
	const map: Record<DSAReportType, string> = {
		Other: "Sonstiges",
		Report: "DSA-Meldung",
		Complaint: "Beschwerde",
		Feedback: "Feedback",
	};
	if (!type) return "";
	return map[type] ?? "";
};

/**
 * Get Translatiopn for ReportProblemType Enum
 * @param type - ReportProblemType
 * @returns {string} translation
 */
export const getProblemTypeTranslations = (type?: DSAReportProblemType): string => {
	const map: Record<DSAReportProblemType, string> = {
		Accessibility: "Zugänglichkeit verbessern",
		ConsumerProtection: "Verbraucherschutz",
		Disinformation: "Desinformation",
		IllegalContents: "Illegale oder falsche Inhalte",
		Other: "Sonstige",
		PrivacyRequest: "Datenschutzanfragen",
		ProtectionOfMinors: "Schutz von Kindern und Jugendlichen",
		TransparencyForOnlineAds: "Transparenz bei Online-Werbung",
		UserRightsAndComplaints: "Nutzerrechte und Beschwerden",
	};
	if (!type) return "";
	return map[type] ?? "";
};
