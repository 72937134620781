/**
 * @generated SignedSource<<c8070a1abcdb0bc9bbf6ad766e9aefa7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FilesTableV2_Refetch$variables = {
  after?: string | null;
  filterByFileTypes?: ReadonlyArray<string> | null;
  filterByFromDateTimeInclusive?: string | null;
  filterByName?: string | null;
  filterByToDateTimeInclusive?: string | null;
  filterFileTagsExcluded?: ReadonlyArray<string> | null;
  filterFileTagsIncluded?: ReadonlyArray<string> | null;
  first?: number | null;
};
export type FilesTableV2_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"filesTableV2_FilesListFragment">;
};
export type FilesTableV2_Refetch = {
  response: FilesTableV2_Refetch$data;
  variables: FilesTableV2_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByFileTypes"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByFromDateTimeInclusive"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByName"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterByToDateTimeInclusive"
  },
  {
    "defaultValue": [
      "IssuedCertificate"
    ],
    "kind": "LocalArgument",
    "name": "filterFileTagsExcluded"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filterFileTagsIncluded"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "fileType",
    "variableName": "filterByFileTypes"
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "fromDateTimeInclusive",
    "variableName": "filterByFromDateTimeInclusive"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "filterByName"
  },
  {
    "kind": "Variable",
    "name": "tagsExcluded",
    "variableName": "filterFileTagsExcluded"
  },
  {
    "kind": "Variable",
    "name": "tagsIncluded",
    "variableName": "filterFileTagsIncluded"
  },
  {
    "kind": "Variable",
    "name": "toDateTimeInclusive",
    "variableName": "filterByToDateTimeInclusive"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FilesTableV2_Refetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          {
            "kind": "Variable",
            "name": "filterByFileTypes",
            "variableName": "filterByFileTypes"
          },
          {
            "kind": "Variable",
            "name": "filterByFromDateTimeInclusive",
            "variableName": "filterByFromDateTimeInclusive"
          },
          {
            "kind": "Variable",
            "name": "filterByName",
            "variableName": "filterByName"
          },
          {
            "kind": "Variable",
            "name": "filterByToDateTimeInclusive",
            "variableName": "filterByToDateTimeInclusive"
          },
          {
            "kind": "Variable",
            "name": "filterFileTagsExcluded",
            "variableName": "filterFileTagsExcluded"
          },
          {
            "kind": "Variable",
            "name": "filterFileTagsIncluded",
            "variableName": "filterFileTagsIncluded"
          },
          (v2/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "filesTableV2_FilesListFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FilesTableV2_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FilesAdminSchema",
            "kind": "LinkedField",
            "name": "Files",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "FilesConnection",
                "kind": "LinkedField",
                "name": "Files",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FilesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "File",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fileType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "accessType",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "uploadDateTime",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "thumbnail",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "name",
                  "fileType",
                  "fromDateTimeInclusive",
                  "toDateTimeInclusive",
                  "tagsIncluded",
                  "tagsExcluded"
                ],
                "handle": "connection",
                "key": "FilesTable_Files",
                "kind": "LinkedHandle",
                "name": "Files"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66da34f14976df1b3aafd78fb678e4a9",
    "id": null,
    "metadata": {},
    "name": "FilesTableV2_Refetch",
    "operationKind": "query",
    "text": "query FilesTableV2_Refetch(\n  $after: String\n  $filterByFileTypes: [String!]\n  $filterByFromDateTimeInclusive: ZonedDateTIme\n  $filterByName: String\n  $filterByToDateTimeInclusive: ZonedDateTIme\n  $filterFileTagsExcluded: [String!] = [\"IssuedCertificate\"]\n  $filterFileTagsIncluded: [String!]\n  $first: Int = 20\n) {\n  ...filesTableV2_FilesListFragment_1hDQWp\n}\n\nfragment filesTableV2_FilesListFragment_1hDQWp on Query {\n  Admin {\n    Files {\n      Files(first: $first, after: $after, name: $filterByName, fileType: $filterByFileTypes, fromDateTimeInclusive: $filterByFromDateTimeInclusive, toDateTimeInclusive: $filterByToDateTimeInclusive, tagsIncluded: $filterFileTagsIncluded, tagsExcluded: $filterFileTagsExcluded) {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            id\n            name\n            fileType\n            accessType\n            uploadDateTime\n            thumbnail\n            url\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "cb34281d29ba83716dbe7bce87222115";

export default node;
