import { Dropdown } from "@thekeytechnology/epic-ui";
import { useMemo } from "react";
import { type RenderConfig } from "@components/ValidatedField";
import { type CompanyType } from "@relay/accountEditBaseDataScreen_EditBusinessBaseDataMutation.graphql";
import { type BusinessType } from "./business-type-dropdown.types";
import { getBusinessTypeOptions } from "./business-type-dropdown.utils";

export const BusinessTypeDropdown = ({
	fieldName,
	fieldValue,
	updateField,
	onChange,
	placeholder,
}: RenderConfig<CompanyType>) => {
	const checkIfDropdownFieldChanged = (e: any) => {
		if (e.value !== fieldValue) {
			if (onChange) {
				onChange();
			}
		}
	};

	const businessTypeOptions = useMemo(() => getBusinessTypeOptions(), []);

	return (
		<Dropdown
			options={businessTypeOptions}
			id={fieldName}
			placeholder={placeholder}
			onChange={(value) => {
				updateField(value as BusinessType);
				checkIfDropdownFieldChanged(value);
			}}
			value={fieldValue}
		/>
	);
};
