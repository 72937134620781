import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment privateBaseDataForm_PrivateBaseDataFragment on PrivateBaseData {
		__typename
		salutation
		title
		firstName
		lastName
		phoneNumber
		city
		street
		houseNumber
		postalCode
		countryCode
		# accountData {
		# 	bic
		# 	iban
		# }
	}
`;
