import styled from "styled-components";
import tw from "twin.macro";
import { IdDisplayField as TkIdDisplayField } from "../../../components/id-display-field";

export const Spacing32 = styled.div`
	margin-bottom: 2rem;
`;

export const IdWrapper = styled.div`
	${tw`tw-flex`}
`;

export const IdDisplayField = styled(TkIdDisplayField)`
	${tw`tw-ml-4`}
`;
