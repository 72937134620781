import { graphql } from "babel-plugin-relay/macro";

import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { type ClozeTextElementForm_ClozeTextElementFragment$key } from "@relay/ClozeTextElementForm_ClozeTextElementFragment.graphql";
import { type ClozeTextElementForm_EditClozeTextElementMutation } from "@relay/ClozeTextElementForm_EditClozeTextElementMutation.graphql";
import { stripHtml } from "src/util/html.utils";
import {
	type ClozeTextElementFormProps,
	type ClozeTextElementFormState,
} from "./cloze-text-element-form.interface";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { MemoizedRichTextEditor } from "../../../../components/rich-text-editor";
import { RichTextEditorToolbar } from "../../../../components/rich-text-editor/rich-text-editor.const";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";

const CLOZE_TEXT_ELEMENT_FRAGMENT = graphql`
	fragment ClozeTextElementForm_ClozeTextElementFragment on ClozeTextElementV2 {
		id
		title
		text
		additionalWords
	}
`;

const EDIT_CLOZE_TEXT_ELEMENT_MUTATION = graphql`
	mutation ClozeTextElementForm_EditClozeTextElementMutation(
		$input: EditClozeTextElementV2Input!
	) {
		Admin {
			ElearningV2 {
				editClozeTextElement(input: $input) {
					clozeTextElement {
						...ClozeTextElementForm_ClozeTextElementFragment
					}
				}
			}
		}
	}
`;

export const ClozeTextElementForm = ({
	clozeTextElementFragmentRef,
	onBack,
}: ClozeTextElementFormProps) => {
	const element = useFragment<ClozeTextElementForm_ClozeTextElementFragment$key>(
		CLOZE_TEXT_ELEMENT_FRAGMENT,
		clozeTextElementFragmentRef,
	);
	const [editClozeTextElement, isEditingClozeTextElement] =
		useMutation<ClozeTextElementForm_EditClozeTextElementMutation>(
			EDIT_CLOZE_TEXT_ELEMENT_MUTATION,
		);

	const dispatch = useTypedDispatch();
	const formId = "ClozeTextElementForm";
	const formik = useFormik<ClozeTextElementFormState>({
		enableReinitialize: true,
		initialValues: {
			title: stripHtml(element.title),
			text: element.text,
			additionalWords: convertAdditionWordsToText(element.additionalWords),
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			text: Yup.string().required("Das Feld Text wird benötigt."),
			additionalWords: Yup.string()
				.required("Das Feld Ablenkwörter wird benötigt.")
				.test("", "message", (value, b) => {
					const additionalWordsLength = convertToAdditionalWords(value || "").length;
					if (additionalWordsLength === 0)
						return b.createError({
							path: "additionalWords",
							message: "Nicht genügend Ablenkwörter angegeben.",
						});
					return true;
				}),
		}),
		onSubmit: (values) => {
			editClozeTextElement({
				variables: {
					input: {
						clozeTextElementId: element.id,
						title: values.title,
						text: stripHtml(values.text),
						additionalWords: convertToAdditionalWords(values.additionalWords),
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const handleFormEdited = useCallback(
		() => dispatch(addEditedFormToEditedFormsArray({ form: formId })),
		[dispatch],
	);

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<ClozeTextElementFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				onChange={handleFormEdited}
				formikConfig={formik}
			/>

			<ValidatedField<ClozeTextElementFormState, string>
				name={"text"}
				label={
					'Text (bspw. "Dies ist ein [Lückentext] mit ein paar [Worten], die ich [ausfüllen] muss."'
				}
				component={(props) => {
					return (
						<MemoizedRichTextEditor
							{...props}
							toolbar={RichTextEditorToolbar.UndoRedo}
						/>
					);
				}}
				onChange={handleFormEdited}
				formikConfig={formik}
			/>
			<ValidatedField<ClozeTextElementFormState, string>
				name={"additionalWords"}
				label={'Ablenkwörter (getrennt durch ",")'}
				component={DefaultTextFieldComponent}
				onChange={handleFormEdited}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingClozeTextElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

const convertToAdditionalWords = (raw: string): string[] => {
	return raw.split(",").map((s) => s.trim());
};
const convertAdditionWordsToText = (additionalWords: readonly string[]): string => {
	return additionalWords ? additionalWords.join(", ") : "";
};
