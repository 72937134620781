import React from "react";
import { useFragment } from "react-relay";

import { TREE_I18N_KEY, TREE_I18N_MAP } from "src/translations/tree";
import { TREE_NODE_FRAGMENT } from "./content-config-editor.graphql";
import { type ContentConfigEditorProps } from "./content-config-editor.types";

import { FlowAndCurrentElementContentConfigEditor } from "../flow-and-current-element-content-config-editor/flow-and-current-element-content-config-editor.component";
import { PassContentConfigEditor } from "../pass-content-config-editor";
import { RestartIfFailedContentConfigEditor } from "../RestartIfFailedContentConfigEditor";
import { RestartIfPassedContentConfigEditor } from "../RestartIfPassedContentConfigEditor";
import { StartContentConfigEditor } from "../start-content-config-editor";

export const ContentConfigEditorComponent = ({ treeNodeFragmentRef }: ContentConfigEditorProps) => {
	const treeNodeFragment = useFragment(TREE_NODE_FRAGMENT, treeNodeFragmentRef);

	return (
		<>
			<h2>Auf {TREE_I18N_MAP(TREE_I18N_KEY.contentNodeLevel)}</h2>
			<StartContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<PassContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<RestartIfFailedContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			<RestartIfPassedContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			{treeNodeFragment.typeDefinition?.contentKind === "ELearning" && (
				<FlowAndCurrentElementContentConfigEditor treeNodeFragmentRef={treeNodeFragment} />
			)}
		</>
	);
};
