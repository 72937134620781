import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type deleteConditionButton_DeleteConditionMutation } from "@relay/deleteConditionButton_DeleteConditionMutation.graphql";
import { DELETE_CONDITION_MUTATION } from "./delete-condition-button.graphql";
import { type DeleteConditionButtonProps } from "./delete-condition-button.interface";
import { useDialogLogic } from "../../../../hooks/use-dialog-logic/use-dialog-logic.hook";

export const DeleteConditionButton = ({
	conditionId,
	limitedCartDiscountId,
}: DeleteConditionButtonProps) => {
	const [deleteCondition, isDeletingCondition] =
		useMutation<deleteConditionButton_DeleteConditionMutation>(DELETE_CONDITION_MUTATION);

	const { showDialog, dialogComponent } = useDialogLogic();

	const handleOnClick = () => {
		showDialog({
			title: "Warenkorb-Rabatt löschen",
			content: "Möchten Sie dieses Warenkorb-Rabatt wirklich löschen?",
			dialogCallback: (result) => {
				if (result === "Accept") {
					deleteCondition({
						variables: {
							input: {
								conditionId,
								limitedCartDiscountId,
							},
						},
					});
				}
			},
		});
	};

	return (
		<>
			{dialogComponent}
			<Button
				type="button"
				icon={"pi pi-trash"}
				onClick={handleOnClick}
				className="ml-auto"
				disabled={isDeletingCondition}
			/>
		</>
	);
};
