import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import { withSuspense } from "../../../components/withSuspense";
import {
	type FileSelectionFieldProps,
	type FileV2,
} from "../file-selection-field/file-selection-field.types";
import { FilesTable } from "../files-table";

const FileSelectionFieldWithUrlComponent = ({
	name,
	selectedFile,
	setSelectedFile,
	onChange,
	fileTypeOptions,
	disabled,
	canUploadFiles,
	canDeleteFiles,
}: FileSelectionFieldProps) => {
	const [selection, setSelection] = useState<FileV2 | undefined>(selectedFile);
	const [isModalOpen, setModalOpen] = useState<boolean>(false);
	const [copied, setCopied] = useState(false);

	useEffect(() => {
		if (!copied) return;

		setTimeout(() => {
			setCopied(false);
		}, 1000);
	}, [copied]);

	return (
		<div className="flex flex-column gap-1">
			{selection?.url && (
				<div className={"flex gap-1"}>
					{" "}
					<InputText value={selection?.url} />{" "}
					<Button
						className={classNames({
							"w-auto p-2 mr-2": true,
							"p-button-secondary": !copied,
						})}
						type={"button"}
						label={""}
						icon="pi pi-copy"
						onClick={() => {
							navigator.clipboard.writeText(selection?.url ?? "");
							setCopied(true);
						}}
					/>
				</div>
			)}
			<div className={"flex"}>
				<InputText
					onClick={() => {
						setModalOpen(true);
					}}
					className="mr-2 flex-grow-1 w-auto"
					name={name}
					disabled={true}
					value={selection?.name ?? "Bitte Datei auswählen"}
				/>
				{selection?.url && (
					<a target="_blank" rel="noopener noreferrer" href={selection?.url}>
						<Button
							className="w-auto p-button-secondary p-2 mr-2"
							type={"button"}
							disabled={selection?.id === undefined}
							label={""}
							icon="pi pi-download"
						/>
					</a>
				)}
				<Button
					className="w-auto p-button-secondary p-2"
					type={"button"}
					disabled={selection?.id === undefined || disabled}
					label={""}
					icon="pi pi-times"
					onClick={() => {
						setSelection(undefined);
						setSelectedFile(undefined);
					}}
				/>
				<Button
					className="w-auto ml-2"
					type={"button"}
					disabled={disabled}
					onClick={() => {
						setModalOpen(true);
					}}
				>
					Datei auswählen
				</Button>

				<Dialog
					header="Datei auswählen"
					onHide={() => {
						setModalOpen(false);
					}}
					className="w-10"
					footer={
						<div>
							<Button
								label={"Auswählen"}
								icon="pi pi-check"
								type="button"
								onClick={() => {
									setSelectedFile(selection);
									setModalOpen(false);
									onChange?.();
								}}
								autoFocus
							/>
						</div>
					}
					visible={isModalOpen}
				>
					<FilesTable
						selectionSettings={{
							selection,
							onSelectionChange: (e) => {
								setSelection(e.value);
							},
							fileTypeOptions,
						}}
						canUploadFiles={canUploadFiles}
						canDeleteFiles={canDeleteFiles}
					/>
				</Dialog>
			</div>
		</div>
	);
};
export const FileSelectionFieldWithUrl = withSuspense(FileSelectionFieldWithUrlComponent);
