import { Dialog } from "@thekeytechnology/epic-ui";
import { useFragment } from "react-relay";
import { messagePageFormDialog_PageFragment$key } from "@relay/messagePageFormDialog_PageFragment.graphql";
import { MessagePageForm } from "@screens/message-definition-edit/parts/message-page-form";
import { PAGE_FRAGMENT } from "@screens/message-definition-edit/parts/message-page-form-dialog/message-page-form-dialog.graphql";
import { MessagePageFormDialogProps } from "@screens/message-definition-edit/parts/message-page-form-dialog/message-page-form-dialog.types";

export const MessagePageFormDialog = ({
	pageFragmentRef,
	onHide,
	onSubmit,
	isVisible,
}: MessagePageFormDialogProps) => {
	const page = useFragment<messagePageFormDialog_PageFragment$key>(
		PAGE_FRAGMENT,
		pageFragmentRef ?? null,
	);

	const dialogTitle = page ? `Seite ${page.internalTitle} bearbeiten` : "Seite erstellen";

	return (
		<Dialog visible={isVisible} onHide={onHide} title={dialogTitle}>
			<MessagePageForm pageFragmentRef={page} onSubmit={onSubmit} isCreating={!page} />
		</Dialog>
	);
};
