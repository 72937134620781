import graphql from "babel-plugin-relay/macro";

export const DELETE_LICENSE_MUTATION = graphql`
	mutation deleteLicenseButton_DeleteLicenseMutation(
		$input: DeleteLicenseInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				deleteLicense(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
