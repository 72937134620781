import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { type UsageUsedAtColumn_DiscountCodeUsageFragment$key } from "@relay/UsageUsedAtColumn_DiscountCodeUsageFragment.graphql";
import { DateTimeDisplay } from "../../../components/datetime-display/datetime-display.component";

const DISCOUNT_CODE_USAGE_FRAGMENT = graphql`
	fragment UsageUsedAtColumn_DiscountCodeUsageFragment on DiscountCodeUsage {
		at
	}
`;

type OwnProps = {
	discountCodeUsageFragmentRef: UsageUsedAtColumn_DiscountCodeUsageFragment$key;
};

export const UsageUsedAtColumn = ({ discountCodeUsageFragmentRef }: OwnProps) => {
	const discountCodeUsage = useFragment<UsageUsedAtColumn_DiscountCodeUsageFragment$key>(
		DISCOUNT_CODE_USAGE_FRAGMENT,
		discountCodeUsageFragmentRef,
	);

	return <DateTimeDisplay value={discountCodeUsage.at} />;
};
