/**
 * @generated SignedSource<<845855c1aabe63fd4d9cc36800187ade>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageInvoiceColumn_DiscountCodeUsageFragment$data = {
  readonly order: {
    readonly invoiceData: {
      readonly invoiceFile: {
        readonly url: string | null;
      } | null;
      readonly invoiceNumber: string | null;
    } | null;
  } | null;
  readonly " $fragmentType": "UsageInvoiceColumn_DiscountCodeUsageFragment";
};
export type UsageInvoiceColumn_DiscountCodeUsageFragment$key = {
  readonly " $data"?: UsageInvoiceColumn_DiscountCodeUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageInvoiceColumn_DiscountCodeUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageInvoiceColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Order",
      "kind": "LinkedField",
      "name": "order",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "InvoiceData",
          "kind": "LinkedField",
          "name": "invoiceData",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "invoiceNumber",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "File",
              "kind": "LinkedField",
              "name": "invoiceFile",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "url",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};

(node as any).hash = "f45dbf646e91b5534849b202a6be05af";

export default node;
