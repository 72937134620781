import { graphql } from "babel-plugin-relay/macro";

export const CREATE_TEXT_ELEMENT_MUTATION = graphql`
	mutation createTextElementButton_CreateTextElementMutation($input: CreateTextElementV2Input!) {
		Admin {
			ElearningV2 {
				createTextElement(input: $input) {
					eLearningContentNode {
						id
						#						...contentCard_TreeNodeFragment
					}
				}
			}
		}
	}
`;
