import {
	DndContext,
	DragOverlay,
	closestCenter,
	useSensor,
	useSensors,
	PointerSensor,
	type DragStartEvent,
	type DragMoveEvent,
	type DragOverEvent,
	type DragEndEvent,
} from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useEffect, useMemo, useState } from "react";
import { createPortal } from "react-dom";
import { SortableTreeItem } from "@components/sortable-tree-item";
import { adjustTranslate, measuring } from "./sortable-tree.consts";
import { type SortableTreeProps, type FlattenedItem } from "./sortable-tree.types";
import {
	buildTree,
	findItemDeep,
	flattenTree,
	getChildCount,
	getProjection,
	removeChildrenOf,
} from "./sortable-tree.utils";

export const SortableTree = ({
	expandedKeys,
	onExpand,
	collapsible = true,
	removable = true,
	dragable = true,
	defaultItems,
	onRemove,
	onNodeClick,
	shouldDropBeAllowed,
	onNodeMove,
	nodeTemplate,
}: SortableTreeProps) => {
	const [items, setItems] = useState(() => defaultItems || []);
	const [activeId, setActiveId] = useState<string>();
	const [overId, setOverId] = useState<string>();
	const [offsetLeft, setOffsetLeft] = useState(0);
	const rootNode = useMemo(() => {
		return items.find((item) => !item.data.parentId);
	}, [items]);

	const flattenedItems = useMemo(() => {
		const flattenedTree = flattenTree(items);
		const collapsedIds = flattenedTree
			.filter(({ id }) => !expandedKeys.includes(id))
			.map(({ id }) => id);

		return removeChildrenOf(
			flattenedTree,
			activeId ? [activeId, ...collapsedIds] : collapsedIds,
		);
	}, [activeId, items, expandedKeys]);

	const projected =
		activeId && overId ? getProjection(flattenedItems, activeId, overId, offsetLeft) : null;

	const sensors = useSensors(useSensor(PointerSensor));

	const sortedIds = useMemo(
		() => flattenedItems.map(({ id }) => id) as string[],
		[flattenedItems],
	);
	const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null;

	useEffect(() => {
		setItems(defaultItems || []);
	}, [defaultItems]);

	function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
		setActiveId(activeId as string);
		setOverId(activeId as string);

		document.body.style.setProperty("cursor", "grabbing");
	}

	function handleDragMove({ delta, over }: DragMoveEvent) {
		if (over?.id === rootNode?.id) return;
		setOffsetLeft(delta.x);
	}

	function handleDragOver({ over }: DragOverEvent) {
		if (over?.id === rootNode?.id) return;
		setOverId(over?.id as string);
	}

	async function handleDragEnd({ active, over }: DragEndEvent) {
		resetState();

		if (projected && over) {
			const { depth, parentId } = projected;
			const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)));
			const overIndex = clonedItems.findIndex(({ data: { id } }) => id === over.id);
			const activeIndex = clonedItems.findIndex(({ data: { id } }) => id === active.id);
			const activeTreeItem = clonedItems[activeIndex];

			clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId };

			const oldTree = buildTree(flattenedItems);
			const sortedItems = arrayMove(clonedItems, activeIndex, overIndex);
			const newItems = buildTree(sortedItems);

			// Check if the tree has changed
			if (JSON.stringify(oldTree) === JSON.stringify(newItems)) {
				return;
			}

			const parent = findItemDeep(newItems, parentId ?? "");
			if (parent && parentId) {
				const siblings = parent.children;
				const activeIndexInParent = siblings.findIndex(
					({ data: { id } }) => id === activeTreeItem.data.id,
				);
				const previousSibling = siblings[activeIndexInParent - 1];

				if (shouldDropBeAllowed) {
					const dropAllowed = await shouldDropBeAllowed(activeTreeItem.id);
					if (!dropAllowed) return;
				}

				onNodeMove?.(activeTreeItem.id, parentId, previousSibling?.data.id);
			}

			setItems(newItems);
		}
	}

	function handleDragCancel() {
		resetState();
	}

	function handleCollapse(id: string) {
		const expandedKeysSet = new Set(expandedKeys);
		if (expandedKeysSet.has(id)) {
			expandedKeysSet.delete(id);
		} else {
			expandedKeysSet.add(id);
		}

		onExpand(Array.from(expandedKeysSet));
	}

	function handleRemove(id: string) {
		onRemove?.(id);
	}

	function resetState() {
		setOverId(undefined);
		setActiveId(undefined);
		setOffsetLeft(0);

		document.body.style.setProperty("cursor", "");
	}

	function handleNodeClick(id: string) {
		onNodeClick?.(id);
	}

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			measuring={measuring}
			onDragStart={handleDragStart}
			onDragMove={handleDragMove}
			onDragOver={handleDragOver}
			onDragEnd={(event) => {
				void handleDragEnd(event);
			}}
			onDragCancel={handleDragCancel}
		>
			<SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
				{flattenedItems.map(({ id, children, depth }, index) => {
					const originalItem = findItemDeep(items, id);
					if (!originalItem) return null;
					const isRootNode = index === 0;

					return (
						<SortableTreeItem
							key={id}
							id={id}
							dragable={dragable && !isRootNode}
							depth={id === activeId && projected ? projected.depth : depth}
							childCount={children.length}
							collapsed={Boolean(!expandedKeys.includes(id) && children.length)}
							treeNode={originalItem.data}
							nodeTemplate={nodeTemplate}
							onClick={() => {
								handleNodeClick(id);
							}}
							onCollapse={
								collapsible && children.length
									? () => {
											handleCollapse(id);
									  }
									: undefined
							}
							onRemove={
								removable && !isRootNode
									? () => {
											handleRemove(id);
									  }
									: undefined
							}
						/>
					);
				})}
				{createPortal(
					<DragOverlay modifiers={[adjustTranslate]}>
						{activeId && activeItem ? (
							<SortableTreeItem
								id={activeId}
								clone
								depth={activeItem.depth}
								childCount={getChildCount(items, activeId) + 1}
								treeNode={activeItem.data}
								nodeTemplate={nodeTemplate}
							/>
						) : null}
					</DragOverlay>,
					document.body,
				)}
			</SortableContext>
		</DndContext>
	);
};
