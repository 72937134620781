import { MeasuringStrategy, type Modifier } from "@dnd-kit/core";

export const INDENTATION_WIDTH = 20;

export const measuring = {
	droppable: {
		strategy: MeasuringStrategy.Always,
	},
};

export const adjustTranslate: Modifier = ({ transform }) => {
	return {
		...transform,
		y: transform.y - 25,
	};
};
