import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useFragment } from "react-relay";
import { type answerMatrixElementCellForm_AnswerMatrixElementCellFragment$key } from "@relay/answerMatrixElementCellForm_AnswerMatrixElementCellFragment.graphql";
import { ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT } from "./answer-matrix-element-cell-form.graphql";
import { type AnswerMatrixElementCellFormProps } from "./answer-matrix-element-cell-form.types";
import { EditAnswersMatrixElementCellDialogForm } from "../edit-answers-matrix-element-cell-dialog-form";

export const AnswerMatrixElementCellForm = ({
	answerMatrixElementCellFragmentRef,
	matrixElementId,
}: AnswerMatrixElementCellFormProps) => {
	const answerCell = useFragment<answerMatrixElementCellForm_AnswerMatrixElementCellFragment$key>(
		ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT,
		answerMatrixElementCellFragmentRef,
	);

	const [isEditingAnswerCell, setEditingAnswerCell] = useState<boolean>(false);

	return (
		<div key={`${answerCell.xIdx}-${answerCell.yIdx}`} className="flex m-0">
			<div className="flex align-items-center m-0 " style={{ height: "45px" }}>
				<div
					style={{ width: "240px" }}
					className="justify-content-start white-space-nowrap overflow-hidden text-overflow-ellipsis m-1"
				>
					{answerCell.correctAnswer.content}
				</div>
				<Button
					icon="pi pi-pencil"
					className="m-1 flex flex-grow-1"
					onClick={() => {
						setEditingAnswerCell(true);
					}}
				/>
			</div>
			<Dialog
				header={() => {
					return <div>Antworten</div>;
				}}
				visible={isEditingAnswerCell}
				onHide={() => {
					setEditingAnswerCell(false);
				}}
			>
				<EditAnswersMatrixElementCellDialogForm
					answersMatrixElementCellFragmentRef={answerCell}
					matrixElementId={matrixElementId}
					onBack={() => {
						setEditingAnswerCell(false);
					}}
				/>
			</Dialog>
		</div>
	);
};
