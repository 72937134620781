import { createSelector, createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { type ReduxState } from "../store.redux";

export type Core = {
	arrayOfEditedForms: string[];
};

const CoreSlice = createSlice({
	name: "core",
	initialState: {
		arrayOfEditedForms: [] as string[],
	},
	reducers: {
		addEditedFormToEditedFormsArray: (state, action: PayloadAction<{ form: string }>) => {
			const isFormInArray = state.arrayOfEditedForms.includes(action.payload.form);
			if (!isFormInArray) {
				state.arrayOfEditedForms.push(action.payload.form);
			}
		},
		removeEditedFormFromEditedFormsArray: (state, action: PayloadAction<{ form: string }>) => {
			const index = state.arrayOfEditedForms.indexOf(action.payload.form);
			state.arrayOfEditedForms.splice(index, 1);
		},
		resetArrayOfEditedForms: (state) => {
			state.arrayOfEditedForms = [];
		},
	},
});

export const {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
	resetArrayOfEditedForms,
} = CoreSlice.actions;
export const CoreSliceReducer = CoreSlice.reducer;

const selectCoreSlice = (state: ReduxState) => state.core;

export const selectArrayOfEditedForms = createSelector(
	selectCoreSlice,
	(state) => state.arrayOfEditedForms,
);
