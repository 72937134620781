import { graphql } from "babel-plugin-relay/macro";

export const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment discountActionForm_DiscountActionFragment on DiscountAction {
		id
		title
		calcType {
			... on DiscountActionPercentageCalcType {
				percentage
			}
		}
		validUntil {
			kind
			... on DiscountActionValidUntilDateTime {
				dateTime
			}
		}
		discountType {
			kind
		}
		usageLimitation {
			kind
			... on DiscountActionUsageLimited {
				kind
				maxAmountOfUsages {
					... on DiscountActionAmountLimited {
						max
					}
				}
				maxAmountOfUsagesPerAccount {
					... on DiscountActionAmountLimited {
						max
					}
				}
				onlyForAccountIds
				onlyForProductIds
			}
		}
	}
`;

export const EDIT_PERCENTAGE_DISCOUNT_ACTION_MUTATION = graphql`
	mutation discountActionForm_EditPercentageDiscountActionMutation(
		$input: EditPercentageDiscountActionInput!
	) {
		Admin {
			Billing {
				editPercentageDiscountAction(input: $input) {
					discountAction {
						...discountActionForm_DiscountActionFragment
					}
				}
			}
		}
	}
`;
