import { graphql } from "babel-plugin-relay/macro";

export const EDIT_EXTERNAL_LICENSE_POOL_MUTATION = graphql`
	mutation editExternalLicensePoolForm_EditExternalLicensePoolMutation(
		$input: EditExternalLicensePoolInput!
	) {
		Admin {
			Billing {
				editExternalLicensePool(input: $input) {
					externalLicensePool {
						...editExternalLicensePoolForm_ExternalLicensePoolFragment
					}
				}
			}
		}
	}
`;

export const EXTERNAL_LICENSE_POOL_FRAGMENT = graphql`
	fragment editExternalLicensePoolForm_ExternalLicensePoolFragment on ExternalLicensePool {
		id
		data {
			name
			link
			usageInformation
			validNumDaysAfterIssuing
		}
	}
`;
