import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ReportDetailsScreen } from "@screens/report-details/report-details.screen";

export const ReportDetailsRoutes: RouteDefinition[] = [
	{
		path: Paths.reports.withIdPlaceholder().details.path,
		element: <ReportDetailsScreen />,
		requiredPermissions: ["UserInAccountPermission_ReportAdmin_Read"],
	},
];
