import graphql from "babel-plugin-relay/macro";

export const RESET_ALL_STAGING_DATA_MUTATION = graphql`
	mutation resetAllStagingDataButton_ResetAllStagingDataMutation {
		Admin {
			Staging {
				resetAllStagingData(input: {}) {
					clientMutationId
				}
			}
		}
	}
`;
