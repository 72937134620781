import { Dropdown } from "primereact/dropdown";
import { useMutation } from "react-relay";
import { type addNodeButton_CreateBranchChildMutation } from "@relay/addNodeButton_CreateBranchChildMutation.graphql";
import { type addNodeButton_CreateContentChildMutation } from "@relay/addNodeButton_CreateContentChildMutation.graphql";
import { type addNodeButton_CreateUploadAsyncElementMutation } from "@relay/addNodeButton_CreateUploadAsyncElementMutation.graphql";
import { DROPDOWN_OPTIONS } from "./add-node-button.consts";
import {
	CREATE_BRANCH_CHILD_MUTATION,
	CREATE_CONTENT_CHILD_MUTATION,
	CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
} from "./add-node-button.graphql";
import { type AddNodeButtonProps, type NodeDefinitionType } from "./add-node-button.types";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../translations/tree";

export const AddNodeButton = ({ parentBranchId, onSuccessful }: AddNodeButtonProps) => {
	const [createBranchChild, isCreatingBranchChild] =
		useMutation<addNodeButton_CreateBranchChildMutation>(CREATE_BRANCH_CHILD_MUTATION);

	const [createContentChild, isCreatingContentChild] =
		useMutation<addNodeButton_CreateContentChildMutation>(CREATE_CONTENT_CHILD_MUTATION);

	const [createUploadAsyncElement, isCreatingUploadAsyncElement] =
		useMutation<addNodeButton_CreateUploadAsyncElementMutation>(
			CREATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
		);

	const onChange = (nodeDefinitionType: NodeDefinitionType) => {
		if (!parentBranchId) return;

		switch (nodeDefinitionType) {
			case "branch":
				createBranchChild({
					variables: {
						input: {
							parentBranchId,
							title: TREE_I18N_MAP(TREE_I18N_KEY.newBranchNode),
						},
					},
					onCompleted: (response) => {
						onSuccessful(response.Admin.Tree.createBranchChild?.createdChildNode.id);
					},
				});
				return;
			case "content":
				createContentChild({
					variables: {
						input: {
							contentKind: "ELearning",
							parentBranchId,
							title: TREE_I18N_MAP(TREE_I18N_KEY.newELearningContentNode),
						},
					},
					onCompleted: (response) => {
						onSuccessful(response.Admin.Tree.createContentChild?.createdChildNode.id);
					},
				});
				return;
			case "asyncContent":
				createContentChild({
					variables: {
						input: {
							contentKind: "Async",
							parentBranchId,
							title: TREE_I18N_MAP(TREE_I18N_KEY.newAsyncContentNode),
						},
					},
					onCompleted: (response) => {
						const asyncContentId =
							response.Admin.Tree.createContentChild?.createdChildNode.id;
						asyncContentId &&
							createUploadAsyncElement({
								variables: {
									input: {
										asyncContentId,
										title: "",
									},
								},
								onCompleted: () => {
									onSuccessful(asyncContentId);
								},
							});
					},
				});
		}
	};

	return (
		<Dropdown
			options={DROPDOWN_OPTIONS}
			className="static w-full"
			onChange={(e) => {
				onChange(e.value.nodeDefinitionType);
			}}
			placeholder={TREE_I18N_MAP(TREE_I18N_KEY.newNode)}
			optionLabel={"name"}
			disabled={
				parentBranchId === undefined ||
				isCreatingBranchChild ||
				isCreatingContentChild ||
				isCreatingUploadAsyncElement
			}
		/>
	);
};
