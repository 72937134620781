import { graphql } from "babel-plugin-relay/macro";

export const CREATE_MUTATION = graphql`
	mutation createCourseLearnableButton_AcademiesCreateBranchRootMutation(
		$input: CreateBranchRootInput! # $connections: [ID!]!
	) {
		Admin {
			Tree {
				createBranchRoot(input: $input) {
					tree {
						nodes {
							__typename
						}
					}
					# edge @appendEdge(connections: $connections) {
					# 	node {
					# 		id
					# 		...offersTable_UnpublishedLearnableFragment
					# 	}
					# }
				}
			}
		}
	}
`;
