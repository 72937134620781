import { Dropdown, type DropdownChangeEvent } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { pageKindOptions } from "@components/page-kind-dropdown/page-kind-dropdown.consts";
import { type RenderConfig } from "@components/ValidatedField";
import { PageDataKind } from "@relay/messagePagesEditor_PagesFragment.graphql";

export function PageKindDropdown({
	fieldName,
	fieldValue,
	updateField,
	disabled,
	onChange,
	isValid,
	placeholder,
	required,
}: RenderConfig<PageDataKind>) {
	const checkIfDropdownFieldChanged = (e: any) => {
		if (e.value !== fieldValue) {
			onChange?.();
		}
	};

	const handleOnChange = (dropdownChangeEvent: DropdownChangeEvent) => {
		updateField(dropdownChangeEvent.value);
		checkIfDropdownFieldChanged(dropdownChangeEvent.value);
	};
	return (
		<Dropdown
			options={pageKindOptions}
			name={fieldName}
			disabled={disabled}
			required={required}
			placeholder={placeholder}
			onChange={handleOnChange}
			value={fieldValue}
			className={classNames({ "p-invalid": !isValid })}
		/>
	);
}
