import tw from "twin.macro";

export const ControllsWrapper = tw.div`
	tw-flex
	tw-flex-row
	tw-gap-16
`;

export const SelectedControllsWrapper = tw.div`
	tw-flex
	tw-flex-row
	tw-gap-16
	tw-mt-8
	tw-mb-16
`;
