import { SelectComplexDataField } from "@components/select-complex-data-field";

import {
	SelectMultipleRootNodesFieldProps,
	SelectMultipleRootNodesFieldState,
} from "@features/educational-offer/select-multiple-root-nodes-field/select-multiple-root-nodes-field.types";

import { SearchablePublishedRootTable } from "../searchable-published-root-table";

export const SelectMultipleRootNodesField = ({ ...props }: SelectMultipleRootNodesFieldProps) => {
	return (
		<>
			<SelectComplexDataField<SelectMultipleRootNodesFieldState>
				valuePlaceholder="Keine Weiterbildungen ausgewählt"
				actionButtonLabel="Weiterbildung auswählen"
				headerLabel="Wähle mindestens eine Weiterbildung aus"
				{...props}
			>
				<SearchablePublishedRootTable
					selection={props.fieldValue}
					onChange={props.updateField}
					selectionMode="multiple"
				/>
			</SelectComplexDataField>
		</>
	);
};
