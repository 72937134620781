import {
	type ButtonProps,
	Button as EpicButton,
	EpicB1Typography,
} from "@thekeytechnology/epic-ui";
import { ButtonVariantMap } from "./button.consts";
import { ButtonVariant } from "./button.types";

export const Button = ({
	variant = ButtonVariant.Default,
	...props
}: ButtonProps<ButtonVariant>) => {
	return (
		<EpicButton
			{...props}
			typography={EpicB1Typography}
			variant={variant}
			variantMap={ButtonVariantMap}
		/>
	);
};
