/**
 * @generated SignedSource<<807d8b4aa2f112ca50ff5255f9cf2ae3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AcademiesTagKind = "Child" | "Root";
import { FragmentRefs } from "relay-runtime";
export type academiesTagsTable_AcademiesTagFragment$data = {
  readonly canBeDeleted?: boolean;
  readonly children?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly canBeDeleted: boolean;
        readonly data: {
          readonly isClickable: boolean;
          readonly isTopic: boolean;
          readonly name: string;
        };
        readonly id: string;
        readonly kind: AcademiesTagKind;
        readonly subId: string;
        readonly " $fragmentSpreads": FragmentRefs<"editAcademiesTagButton_AcademiesTagFragment">;
      };
    } | null> | null;
  };
  readonly data?: {
    readonly isClickable: boolean;
    readonly isTopic: boolean;
    readonly name: string;
  };
  readonly id: string;
  readonly kind?: AcademiesTagKind;
  readonly " $fragmentSpreads": FragmentRefs<"editAcademiesTagButton_AcademiesTagFragment">;
  readonly " $fragmentType": "academiesTagsTable_AcademiesTagFragment";
};
export type academiesTagsTable_AcademiesTagFragment$key = {
  readonly " $data"?: academiesTagsTable_AcademiesTagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"academiesTagsTable_AcademiesTagFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "academiesTagsTable_AcademiesTagFragment"
};

(node as any).hash = "22740ac54015630692abb39480a086fd";

export default node;
