/**
 * @generated SignedSource<<0e769e4d90080fce6ad666b5565fba85>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type reportsTableFilters_Query$variables = {};
export type reportsTableFilters_Query$data = {
  readonly Viewer: {
    readonly Reports: {
      readonly SystemEmail: string;
    };
  };
};
export type reportsTableFilters_Query = {
  response: reportsTableFilters_Query$data;
  variables: reportsTableFilters_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "ViewerQueryType",
    "kind": "LinkedField",
    "name": "Viewer",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ReportsViewerSchema",
        "kind": "LinkedField",
        "name": "Reports",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "SystemEmail",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "reportsTableFilters_Query",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "reportsTableFilters_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "4fc8404a1f22df458f70ce33c60b7744",
    "id": null,
    "metadata": {},
    "name": "reportsTableFilters_Query",
    "operationKind": "query",
    "text": "query reportsTableFilters_Query {\n  Viewer {\n    Reports {\n      SystemEmail\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c1de1b2869808c8be89f01485d28b374";

export default node;
