import graphql from "babel-plugin-relay/macro";

export const BILLING_DETAILS_FRAGMENT = graphql`
	fragment billingDetailsForm_BillingDetailsFragment on BillingDetails {
		customerType
		... on PrivateBillingDetails {
			...privateBillingDetailsForm_BillingDetailsFragment
		}
		... on BusinessBillingDetails {
			...businessBillingDetailsForm_BillingDetailsFragment
		}
	}
`;
