/**
 * @generated SignedSource<<d7573783afcd405f6414d0488682fcd0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SetIHKFlowPassContentConfigInput = {
  clientMutationId?: string | null;
  contentNodeId: string;
};
export type passContentConfigEditor_SetIHKFlowPassContentConfigMutation$variables = {
  input: SetIHKFlowPassContentConfigInput;
};
export type passContentConfigEditor_SetIHKFlowPassContentConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly setIHKFlowPassContentConfig: {
        readonly content: {
          readonly " $fragmentSpreads": FragmentRefs<"passContentConfigEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type passContentConfigEditor_SetIHKFlowPassContentConfigMutation = {
  response: passContentConfigEditor_SetIHKFlowPassContentConfigMutation$data;
  variables: passContentConfigEditor_SetIHKFlowPassContentConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "passContentConfigEditor_SetIHKFlowPassContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetIHKFlowPassContentConfigPayload",
                "kind": "LinkedField",
                "name": "setIHKFlowPassContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "passContentConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "passContentConfigEditor_SetIHKFlowPassContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "SetIHKFlowPassContentConfigPayload",
                "kind": "LinkedField",
                "name": "setIHKFlowPassContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "content",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "passConfig",
                                "plural": false,
                                "selections": [
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  (v2/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "751ef9474bb2875cf52b9ce3b8daac7d",
    "id": null,
    "metadata": {},
    "name": "passContentConfigEditor_SetIHKFlowPassContentConfigMutation",
    "operationKind": "mutation",
    "text": "mutation passContentConfigEditor_SetIHKFlowPassContentConfigMutation(\n  $input: SetIHKFlowPassContentConfigInput!\n) {\n  Admin {\n    Tree {\n      setIHKFlowPassContentConfig(input: $input) {\n        content {\n          ...passContentConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment passContentConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      passConfig {\n        __typename\n        configType\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "41e0c79664885719dff4953b7a5bea35";

export default node;
