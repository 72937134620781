import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type addColumnToMatrixElementButton_AddColumnToMatrixElementMutation } from "@relay/addColumnToMatrixElementButton_AddColumnToMatrixElementMutation.graphql";
import { ADD_COLUMN_TO_MATRIX_ELEMENT_MUTATION } from "./add-column-to-matrix-element-button.graphql";
import { type AddColumnToMatrixElementButtonProps } from "./add-column-to-matrix-element-button.types";

export const AddColumnToMatrixElementButton = ({
	matrixElementId,
}: AddColumnToMatrixElementButtonProps) => {
	const [addColumnToMatrixElement] =
		useMutation<addColumnToMatrixElementButton_AddColumnToMatrixElementMutation>(
			ADD_COLUMN_TO_MATRIX_ELEMENT_MUTATION,
		);

	return (
		<Button
			type="button"
			className="m-2 flex flex-grow-1"
			label={"Neue Spalte"}
			onClick={() => {
				addColumnToMatrixElement({
					variables: {
						input: {
							matrixElementId,
							columnTitle: "Neue Spalte",
							correctAnswerContent: "korrekte Antwort",
						},
					},
				});
			}}
		/>
	);
};
