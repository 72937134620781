import moment from "moment-timezone";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import React, { useContext, useState } from "react";
import { AllFileTypeOptions, type FileTypeOption } from "./file-filters.const";
import { CertificateTag, FilesSearchContext } from "../FileSearchContainer";

enum ShowFilesMode {
	All,
	AllButCertificates,
	OnlyCertificates,
}

export type FileFiltersProps = {
	fileTypeOptions?: FileTypeOption[];
};

export const FileFilters = ({ fileTypeOptions = AllFileTypeOptions }: FileFiltersProps) => {
	const { filters, setFilters, clearFilters } = useContext(FilesSearchContext);
	const defaultCertificateFilter = ShowFilesMode.AllButCertificates;
	const [showFilesMode, setShowFilesMode] = useState(defaultCertificateFilter);

	return (
		<div className="flex mb-3 gap-2">
			<InputText
				value={filters.filterByName ?? ""}
				onChange={(e) => {
					setFilters({
						...filters,
						filterByName: e.target.value,
					});
				}}
				placeholder={"Name"}
			/>

			<MultiSelect
				value={filters.filterByFileTypes}
				onChange={(e) => {
					setFilters({
						...filters,
						filterByFileTypes: e.target.value,
					});
				}}
				options={fileTypeOptions}
				showSelectAll={false}
				placeholder="Typ"
			/>

			<div className="flex align-items-center">
				<span className="mr-1">Zertifikate:</span>
				<Dropdown
					value={showFilesMode}
					options={[
						{ label: "mit anzeigen", value: ShowFilesMode.All },
						{ label: "nicht anzeigen", value: ShowFilesMode.AllButCertificates },
						{ label: "allein anzeigen", value: ShowFilesMode.OnlyCertificates },
					]}
					onChange={(e) => {
						const showMode: ShowFilesMode = e.target.value;
						setShowFilesMode(showMode);
						const issuedCertificateTags = [CertificateTag];
						if (showMode === ShowFilesMode.OnlyCertificates) {
							setFilters({
								...filters,
								filterFileTagsIncluded: issuedCertificateTags,
								filterFileTagsExcluded: [],
							});
						} else if (showMode === ShowFilesMode.AllButCertificates) {
							setFilters({
								...filters,
								filterFileTagsIncluded: undefined,
								filterFileTagsExcluded: issuedCertificateTags,
							});
						} else {
							setFilters({
								...filters,
								filterFileTagsIncluded: undefined,
								filterFileTagsExcluded: [],
							});
						}
					}}
				/>
			</div>

			<Calendar
				showTime
				hourFormat="24"
				dateFormat={"dd.mm.yy"}
				placeholder={"Erstellt am (von)"}
				value={
					filters.filterByFromDateTimeInclusive
						? moment(
								filters.filterByFromDateTimeInclusive.replace("[UTC]", ""),
						  ).toDate()
						: undefined
				}
				onChange={(e) => {
					setFilters({
						...filters,
						filterByFromDateTimeInclusive: e.value
							? moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ")
							: undefined,
					});
				}}
			/>

			<Calendar
				showTime
				hourFormat="24"
				dateFormat={"dd.mm.yy"}
				placeholder={"Erstellt am (bis)"}
				value={
					filters.filterByToDateTimeInclusive
						? moment(filters.filterByToDateTimeInclusive.replace("[UTC]", "")).toDate()
						: undefined
				}
				onChange={(e) => {
					setFilters({
						...filters,
						filterByToDateTimeInclusive: e.value
							? moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ")
							: undefined,
					});
				}}
			/>

			<div className="flex align-items-center">
				<Button
					className=""
					label={"Zurücksetzen"}
					icon="pi pi-times"
					onClick={() => {
						clearFilters();
						setShowFilesMode(defaultCertificateFilter);
					}}
				/>
			</div>
		</div>
	);
};
