/**
 * @generated SignedSource<<0c9f359f9ff53db9bc3f8d35d8edb547>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteLimitedCartDiscountInput = {
  clientMutationId?: string | null;
  ids: ReadonlyArray<string>;
};
export type deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteLimitedCartDiscountInput;
};
export type deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly deleteLimitedCartDiscount: {
        readonly deletedIds: ReadonlyArray<string>;
      } | null;
    };
  };
};
export type deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation = {
  response: deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation$data;
  variables: deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deletedIds",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteLimitedCartDiscountPayload",
                "kind": "LinkedField",
                "name": "deleteLimitedCartDiscount",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteLimitedCartDiscountPayload",
                "kind": "LinkedField",
                "name": "deleteLimitedCartDiscount",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "deletedIds",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "56b98e7d4bb1a1763e0a99bc4c7a0d48",
    "id": null,
    "metadata": {},
    "name": "deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation",
    "operationKind": "mutation",
    "text": "mutation deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation(\n  $input: DeleteLimitedCartDiscountInput!\n) {\n  Admin {\n    Billing {\n      deleteLimitedCartDiscount(input: $input) {\n        deletedIds\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d02a5a78fed315f1c2a312507c70b374";

export default node;
