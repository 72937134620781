/**
 * @generated SignedSource<<f7c574b57d1d86b3685c9265505ad248>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PublishingStatus = "acknowledged" | "failed" | "inprogress" | "succeeded";
import { FragmentRefs } from "relay-runtime";
export type educationalOfferPublishing_PublishingV2Fragment$data = {
  readonly canBeAcknowledged: boolean;
  readonly finishedAt: string | null;
  readonly id: string;
  readonly initiatedAt: string;
  readonly messages: ReadonlyArray<{
    readonly additionalMessage: string | null;
    readonly message: string;
    readonly timestamp: string;
  }>;
  readonly status: PublishingStatus;
  readonly " $fragmentType": "educationalOfferPublishing_PublishingV2Fragment";
};
export type educationalOfferPublishing_PublishingV2Fragment$key = {
  readonly " $data"?: educationalOfferPublishing_PublishingV2Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferPublishing_PublishingV2Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "educationalOfferPublishing_PublishingV2Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initiatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finishedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "PublishingMessageV2",
      "kind": "LinkedField",
      "name": "messages",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "timestamp",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "message",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "additionalMessage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canBeAcknowledged",
      "storageKey": null
    }
  ],
  "type": "PublishingV2",
  "abstractKey": null
};

(node as any).hash = "33ae944deaa74d09492e5780b07343f6";

export default node;
