import React, { useState } from "react";
import { useRefetchableFragment } from "react-relay";
import { graphql } from "babel-plugin-relay/macro";
import { MultiSelect } from "primereact/multiselect";
import { AccountsSelectWithId_QueryFragmentRefetchQuery } from "@relay/AccountsSelectWithId_QueryFragmentRefetchQuery.graphql";
import { AccountsSelectWithId_QueryFragment$key } from "@relay/AccountsSelectWithId_QueryFragment.graphql";

const NUM_ACCOUNTS_PER_PAGE = 20;

const QUERY_FRAGMENT = graphql`
	fragment AccountsSelectWithId_QueryFragment on Query
	@refetchable(queryName: "AccountsSelectWithId_QueryFragmentRefetchQuery")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String", defaultValue: null }
		name: { type: "String", defaultValue: null }
		ids: { type: "[ID!]!" }
	) {
		Admin {
			Auth {
				SelectAccounts(first: $first, after: $after, nameOpt: $name, ids: $ids)
					@connection(key: "AccountsSelectWithId_SelectAccounts") {
					edges {
						cursor
						node {
							id
							... on Account {
								name
							}
						}
					}
				}
			}
		}
	}
`;

export interface Account {
	id: string;
	name: string;
}

interface OwnProps {
	fieldValue: string[];
	onChange: (accounts: string[]) => void;
	queryFragmentRef: AccountsSelectWithId_QueryFragment$key;
}

export const AccountsSelectWithId = ({ fieldValue, onChange, queryFragmentRef }: OwnProps) => {
	const [data, refetch] = useRefetchableFragment<
		AccountsSelectWithId_QueryFragmentRefetchQuery,
		AccountsSelectWithId_QueryFragment$key
	>(QUERY_FRAGMENT, queryFragmentRef);

	const accounts =
		data.Admin.Auth.SelectAccounts.edges
			?.filter((e) => !!e)
			.map((e) => e!.node)
			.map((node) => {
				return {
					id: node.id,
					name: node.name,
				} as Account;
			}) || [];

	const [accountsCache, setAccountsCache] = useState<Account[]>(
		accounts.filter((acc) => fieldValue.includes(acc.id)),
	);

	const onFilter = (e: { filter: string }) => {
		refetch({ name: e.filter, first: NUM_ACCOUNTS_PER_PAGE, ids: fieldValue });
	};

	return (
		<MultiSelect
			value={accountsCache}
			options={accounts}
			optionLabel="name"
			onHide={() => onChange(accountsCache?.map((l: Account) => l.id) || [])}
			onChange={(e) => {
				setAccountsCache(e.value);
			}}
			filter
			resetFilterOnHide={true}
			filterPlaceholder={"Suche nach Accounts"}
			className="multiselect-custom"
			onFilter={onFilter}
			showSelectAll={false}
			emptyFilterMessage="Keine Accounts vorhanden"
		/>
	);
};
