import { DefaultTextFieldComponent } from "@thekeytechnology/framework-react-components";
import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { CorrectSelectionText } from "./AnswerOptionDisplay";
import { type AnswerOptionV2Input } from "../../../__generated__/CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation.graphql";
import { FileSelectionField, type FileV2 } from "../../../features/files/file-selection-field";

type OwnProps = {
	answerOption: AnswerOptionV2Input & { image?: FileV2 };
	onUpdate: (answerOption: AnswerOptionV2Input & { image?: FileV2 }) => void;
	onBack: () => void;
	canUploadFiles: boolean;
	canDeleteFiles: boolean;
};

export const EditAnswerOptionForm = ({
	answerOption,
	onUpdate,
	onBack,
	canUploadFiles,
	canDeleteFiles,
}: OwnProps) => {
	return (
		<>
			<h3>Antwort bearbeiten</h3>
			<DefaultTextFieldComponent
				fieldName={"answer"}
				fieldValue={answerOption.answer}
				updateField={(value) => {
					onUpdate({ ...answerOption, answer: value || "" });
				}}
				required={false}
				isValid={true}
				disabled={false}
			/>
			<div className="flex align-items-center mt-2 justify-content-between">
				<FileSelectionField
					name={"imageRef"}
					selectedFile={answerOption.image || undefined}
					setSelectedFile={(file) => {
						onUpdate({ ...answerOption, imageRef: file?.id, image: file });
					}}
					filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
					canUploadFiles={canUploadFiles}
					canDeleteFiles={canDeleteFiles}
				/>
				<div className="p-field-checkbox flex align-items-center" style={{ minWidth: 150 }}>
					<Checkbox
						className="mr-2"
						inputId="item"
						onChange={(e) => {
							onUpdate({ ...answerOption, isCorrect: !!e.target.checked });
						}}
						checked={answerOption.isCorrect}
					/>
					<label htmlFor="city4">{CorrectSelectionText}</label>
				</div>
			</div>
			<Button
				type="button"
				onClick={onBack}
				label="Zurück zu allen Antworten"
				className="p-button-secondary mt-2"
			/>
		</>
	);
};
