import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { LicenseDefinitionsScreen } from "./license-definitions.screen";

export const LicenseDefinitionsRoutes: RouteDefinition[] = [
	{
		path: Paths.licenseDefinitions.path,
		element: <LicenseDefinitionsScreen />,
		requiredPermissions: ["UserInAccountPermission_LicenseAdmin_Read"],
	},
];
