import { useMutation } from "react-relay";
import { type editUserInAccountGroup_SetUserInAccountGroupsMutation } from "@relay/editUserInAccountGroup_SetUserInAccountGroupsMutation.graphql";
import { SET_USER_IN_ACCOUNT_GROUPS_MUTATION } from "./edit-user-in-account-group.graphql";
import { type EditUserInAccountGroupProps } from "./edit-user-in-account-group.interface";
import { EditUserInAccountGroupForm } from "../edit-user-in-account-group-form/edit-user-in-account-group-form.component";
import { type EditUserInAccountGroupFormState } from "../edit-user-in-account-group-form/edit-user-in-account-group-form.interface";

export const EditUserInAccountGroup = ({
	accountId,
	userId,
	groupsId,
	onUserEdited,
}: EditUserInAccountGroupProps) => {
	const [setUserInAccountGroups] =
		useMutation<editUserInAccountGroup_SetUserInAccountGroupsMutation>(
			SET_USER_IN_ACCOUNT_GROUPS_MUTATION,
		);

	const handleAccountGroupsOnSubmitted = (state: EditUserInAccountGroupFormState) => {
		setUserInAccountGroups({
			variables: {
				input: {
					accountId,
					userId,
					groupIds: state.groupsId,
				},
			},
			onCompleted: onUserEdited,
		});
	};

	return (
		<EditUserInAccountGroupForm
			groupsId={groupsId}
			accountId={accountId}
			onSubmitted={handleAccountGroupsOnSubmitted}
		/>
	);
};
