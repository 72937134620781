import { Button } from "primereact/button";

import {
	DataTableSelectionMultipleChangeEvent,
	DataTableSelectionSingleChangeEvent,
} from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import { useRelayEnvironment } from "react-relay";
import { fetchQuery } from "relay-runtime";
import { MultipleSelectionDataTable } from "@features/multiple-selection-data-table/multiple-selection-data-table.component";
import { SingleSelectionDataTable } from "@features/single-selection-data-table/single-selection-data-table.component";
import { type selectUserField_EditorsQuery } from "@relay/selectUserField_EditorsQuery.graphql";
import {
	type selectUserField_UsersQuery,
	type selectUserField_UsersQuery$data,
} from "@relay/selectUserField_UsersQuery.graphql";
import { EDITORS_QUERY, USERS_QUERY } from "./select-user-field.graphql";
import { type SelectUserFieldProps } from "./select-user-field.interface";

export const SelectUserField = <T extends string | string[]>({
	updateField,
	fieldValue,
	selectEditors,
	accountId,
	isMultipleSelection = false,
}: SelectUserFieldProps<T>) => {
	const environment = useRelayEnvironment();

	const [nameOrEmail, setNameOrEmail] = useState("");
	const [allUsers, setAllUsers] = useState<
		| Writeable<selectUserField_UsersQuery$data["Admin"]["Auth"]["SelectUsers"]["edges"]>
		| undefined
	>();

	const updateUsers = useCallback((updateNameOrEmail: string, accountId?: string) => {
		if (selectEditors) {
			void fetchQuery<selectUserField_EditorsQuery>(environment, EDITORS_QUERY, {
				nameOrEmail: updateNameOrEmail,
			})
				.toPromise()
				.then((result) => {
					// setAllUsers(result!.Admin.Auth.SearchEditors.edges?.slice());
					setAllUsers(result!.Admin.Auth.SelectUsers.edges?.slice());
				});
		} else {
			void fetchQuery<selectUserField_UsersQuery>(environment, USERS_QUERY, {
				nameOrEmail: updateNameOrEmail,
				accountId,
			})
				.toPromise()
				.then((result) => {
					setAllUsers(result!.Admin.Auth.SelectUsers.edges?.slice());
				});
		}
	}, []);

	const handleOnSubmit = useCallback(() => {
		updateUsers(nameOrEmail, accountId);
	}, [nameOrEmail, updateUsers, accountId]);

	useEffect(handleOnSubmit, [accountId]);

	useEffect(() => {
		if (fieldValue && !allUsers?.find((user) => user?.node.id === fieldValue)) {
			updateField(undefined);
		}
	}, [allUsers]);

	const handleClearFormOnClick = () => {
		setNameOrEmail("");
		updateUsers("", accountId);
	};

	const handelNameOrEmailChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
		setNameOrEmail(e.target.value);
	}, []);

	const handleSingleSelection = useCallback(
		(event: DataTableSelectionSingleChangeEvent<any>) => {
			updateField(event.value?.node?.id);
		},
		[updateField],
	);

	const handleMultipleSelection = useCallback(
		(event: DataTableSelectionMultipleChangeEvent<any>) => {
			const selectedIds = event.value.map((item: { node: { id: string } }) => item.node.id);
			updateField(selectedIds);
		},
		[updateField],
	);

	return (
		<>
			<div className="flex flex-row align-items-center">
				<InputText
					value={nameOrEmail}
					onChange={handelNameOrEmailChange}
					className="mr-2"
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={handleClearFormOnClick}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					onClick={handleOnSubmit}
					type="button"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
			{isMultipleSelection ? (
				<MultipleSelectionDataTable
					values={allUsers}
					onSelectionChange={handleMultipleSelection}
					selectedUserIds={Array.isArray(fieldValue) ? fieldValue : []}
				/>
			) : (
				<SingleSelectionDataTable
					values={allUsers}
					onSelectionChange={handleSingleSelection}
					selectedUserId={Array.isArray(fieldValue) ? fieldValue[0] : fieldValue}
				/>
			)}
		</>
	);
};
