import {
	DefaultStyledInputTextAreaComponents,
	type InputBaseProps,
	type StyledInputTextAreaComponents,
} from "@thekeytechnology/framework-react-components";
import {
	InputTextarea as PrInputTextArea,
	type InputTextareaProps as PrInputTextAreaProps,
} from "primereact/inputtextarea";
import styled from "styled-components";
import { DefaultInputStyles } from "@corestyle/component-theme/common/input.styles";

export const tkaAdminInputTextAreaStyles: StyledInputTextAreaComponents = {
	InputTextArea: {
		...DefaultStyledInputTextAreaComponents.InputTextArea,
		StyledInputTextArea: styled(PrInputTextArea)<InputBaseProps & PrInputTextAreaProps>`
			${DefaultInputStyles()};
		`,
	},
};
