import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { MessageDefinitionsScreen } from "@screens/message-definitions/message-definitions.screen";
import { MessageDefinitionEditRoutes } from "src/screens/message-definition-edit";

export const MessageDefinitionsRoutes: RouteDefinition[] = [
	{
		path: Paths.messageDefinitions.path,
		element: <MessageDefinitionsScreen />,
		requiredPermissions: ["UserInAccountPermission_MessageDefinitionAdmin_Read"],
	},
	...MessageDefinitionEditRoutes,
];
