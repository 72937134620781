import { graphql } from "babel-plugin-relay/macro";

export const ASYNC_ELEMENT_FRAGMENT = graphql`
	fragment asyncElementForm_AsyncElementFragment on AsyncElement {
		id
		title
		kind
		...uploadAsyncElementForm_UploadAsyncElementFragment
	}
`;
