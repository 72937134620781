/**
 * @generated SignedSource<<56f905b2e5cfd772819a11843744f97e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type messageDispatches_MessageDispatchesQuery$variables = {};
export type messageDispatches_MessageDispatchesQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"messageDispatchesTable_QueryFragment">;
};
export type messageDispatches_MessageDispatchesQuery = {
  response: messageDispatches_MessageDispatchesQuery$data;
  variables: messageDispatches_MessageDispatchesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "messageDispatches_MessageDispatchesQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "messageDispatchesTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "messageDispatches_MessageDispatchesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageAdminSchema",
            "kind": "LinkedField",
            "name": "Message",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageDispatchesConnection",
                "kind": "LinkedField",
                "name": "MessageDispatches",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDispatchesEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "MessageDispatch",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "createdBy",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "createdAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "sendAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "dispatchedAt",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "MessageDefinition",
                            "kind": "LinkedField",
                            "name": "messageDefinition",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "internalTitle",
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "filters": null,
                "handle": "connection",
                "key": "messageDispatchesTable_MessageDispatches",
                "kind": "LinkedHandle",
                "name": "MessageDispatches"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0aaeeafc0d28934779edf3525016a3eb",
    "id": null,
    "metadata": {},
    "name": "messageDispatches_MessageDispatchesQuery",
    "operationKind": "query",
    "text": "query messageDispatches_MessageDispatchesQuery {\n  ...messageDispatchesTable_QueryFragment\n}\n\nfragment deleteMessageDispatchButton_MessageDispatchFragment on MessageDispatch {\n  id\n  sendAt\n}\n\nfragment editMessageDispatchButton_MessageDispatchFragment on MessageDispatch {\n  id\n  dispatchedAt\n}\n\nfragment messageDispatchesTable_MessageDispatchFragment on MessageDispatch {\n  id\n  createdBy {\n    name\n    id\n  }\n  createdAt\n  sendAt\n  dispatchedAt\n  messageDefinition {\n    internalTitle\n    id\n  }\n  ...editMessageDispatchButton_MessageDispatchFragment\n  ...deleteMessageDispatchButton_MessageDispatchFragment\n}\n\nfragment messageDispatchesTable_QueryFragment on Query {\n  Admin {\n    Message {\n      MessageDispatches {\n        pageInfo {\n          endCursor\n          hasPreviousPage\n          hasNextPage\n          startCursor\n        }\n        edges {\n          node {\n            ...messageDispatchesTable_MessageDispatchFragment\n            id\n            __typename\n          }\n          cursor\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "fde763024fa21cd4c7799a863583a05c";

export default node;
