/**
 * @generated SignedSource<<e51ac9924b0f581aaced45cd15885218>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RewardKind = "CrmTreeHeadUpdater" | "ExternalLicense" | "GamificationPoints" | "IHKCertificate";
export type StructureType = "child" | "root";
export type TypeDefinitionType = "branch" | "content";
import { FragmentRefs } from "relay-runtime";
export type NodeConfigurationBadges_TreeNodeFragment$data = {
  readonly id: string;
  readonly structureDefinition: {
    readonly definitionType: StructureType;
  };
  readonly typeDefinition: {
    readonly definitionType: TypeDefinitionType;
    readonly rewards?: ReadonlyArray<{
      readonly kind: RewardKind;
    }>;
  };
  readonly versioning: {
    readonly draftVersion: number;
    readonly releaseVersion: number;
  };
  readonly " $fragmentType": "NodeConfigurationBadges_TreeNodeFragment";
};
export type NodeConfigurationBadges_TreeNodeFragment$key = {
  readonly " $data"?: NodeConfigurationBadges_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"NodeConfigurationBadges_TreeNodeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "definitionType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NodeConfigurationBadges_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "structureDefinition",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "typeDefinition",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "rewards",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "ContentTypeDefinition",
          "abstractKey": "__isContentTypeDefinition"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Versioning",
      "kind": "LinkedField",
      "name": "versioning",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "draftVersion",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "releaseVersion",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};
})();

(node as any).hash = "c57e5e6ebed5a2188a684f80a3139a3d";

export default node;
