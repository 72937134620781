/**
 * @generated SignedSource<<26c6b9f4d9c05de74ae3f70df1b7cd2b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UsageUsedAtColumn_DiscountCodeUsageFragment$data = {
  readonly at: string;
  readonly " $fragmentType": "UsageUsedAtColumn_DiscountCodeUsageFragment";
};
export type UsageUsedAtColumn_DiscountCodeUsageFragment$key = {
  readonly " $data"?: UsageUsedAtColumn_DiscountCodeUsageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UsageUsedAtColumn_DiscountCodeUsageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UsageUsedAtColumn_DiscountCodeUsageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "at",
      "storageKey": null
    }
  ],
  "type": "DiscountCodeUsage",
  "abstractKey": null
};

(node as any).hash = "510f12ad13d11ef7714582b4fe06f3c8";

export default node;
