/**
 * @generated SignedSource<<953ce5cf3e3a0c8f84c391e833e10adf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type resetAllStagingDataButton_ResetAllStagingDataMutation$variables = {};
export type resetAllStagingDataButton_ResetAllStagingDataMutation$data = {
  readonly Admin: {
    readonly Staging: {
      readonly resetAllStagingData: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type resetAllStagingDataButton_ResetAllStagingDataMutation = {
  response: resetAllStagingDataButton_ResetAllStagingDataMutation$data;
  variables: resetAllStagingDataButton_ResetAllStagingDataMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StagingAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Staging",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "ResetAllStagingDataPayload",
            "kind": "LinkedField",
            "name": "resetAllStagingData",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": "resetAllStagingData(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "resetAllStagingDataButton_ResetAllStagingDataMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "resetAllStagingDataButton_ResetAllStagingDataMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "e13c03e34ad79a2ee4bcdb6d9118e29a",
    "id": null,
    "metadata": {},
    "name": "resetAllStagingDataButton_ResetAllStagingDataMutation",
    "operationKind": "mutation",
    "text": "mutation resetAllStagingDataButton_ResetAllStagingDataMutation {\n  Admin {\n    Staging {\n      resetAllStagingData(input: {}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7aa9988937d92594adaa126d87e6d9ba";

export default node;
