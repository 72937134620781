import { DialogButton } from "@thekeytechnology/framework-react-components";
import {
	// useFragment,
	useMutation,
} from "react-relay";
import { type createLicensesButton_CreateLicensesMutation } from "@relay/createLicensesButton_CreateLicensesMutation.graphql";
// import { type createLicensesButton_QueryFragment$key } from "@relay/createLicensesButton_QueryFragment.graphql";
import {
	CREATE_LICENSES_MUTATION,
	// QUERY_FRAGMENT
} from "./create-licenses-button.graphql";
import { type CreateLicensesButtonProps } from "./create-licenses-button.types";
import { CreateLicensesForm } from "../create-licenses-form";
import { type CreateLicensesFormState } from "../create-licenses-form/create-licenses-form.types";

export const CreateLicensesButton = ({
	accountId,
	// queryFragmentRef,
	connectionId,
}: CreateLicensesButtonProps) => {
	// const query = useFragment<createLicensesButton_QueryFragment$key>(
	// 	QUERY_FRAGMENT,
	// 	queryFragmentRef,
	// );

	const [createLicenses, isCreatingLicenses] =
		useMutation<createLicensesButton_CreateLicensesMutation>(CREATE_LICENSES_MUTATION);

	const handleOnSubmit = (values: CreateLicensesFormState) => {
		if (accountId)
			createLicenses({
				variables: {
					input: {
						amount: values.amount,
						licenseDefinitionId: "", // values.licenseDefinition?.id,
						accountId,
					},
					connections: [connectionId!],
				},
			});
	};
	return (
		<>
			<DialogButton
				buttonContent={{ label: "Lizenzen Hinzufügen" }}
				title={"Lizenzen Hinzufügen"}
				disabled={isCreatingLicenses}
			>
				{() => (
					<CreateLicensesForm
						// queryFragmentRef={query}
						onSubmit={handleOnSubmit}
					/>
				)}
			</DialogButton>
		</>
	);
};
