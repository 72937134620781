import React, { useState } from "react";
import graphql from "babel-plugin-relay/macro";
import * as Yup from "yup";
import { useFragment, useMutation } from "react-relay";
import { useFormik } from "formik";
import { useTypedDispatch } from "../../../../store/store.redux";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { Button } from "primereact/button";
import { RenderConfig, ValidatedField } from "../../../../components/ValidatedField";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment$key } from "@relay/PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment.graphql";
import { PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation } from "@relay/PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation.graphql";
import { TimestampWithImageForm } from "../timestamp-with-image-form";
import { OrderList } from "primereact/orderlist";
import styled from "styled-components";
import { CreateTimestampWithImageButton } from "../create-timestamp-with-image-button";
import { TimestampWithImageTemplate } from "../timestamp-with-image-template";
import { v4 } from "uuid";
import { secondToTimestamp, timestampToSeconds } from "../../../../util/TimestampConverter";
import { toast } from "react-toastify";
import {
	PodcastWithTimestampElementFormProps,
	PodcastWithTimestampElementFormState,
} from "./podcast-with-timestamp-element-form.interface";
import { TimestampWithImage } from "../timestamp-with-image-form/timestamp-with-image-form.interface";
import { FileSelectionField, FileV2 } from "../../../../features/files/file-selection-field";
import { AudioFileTypeOptions } from "../../../../features/files/file-filters/file-filters.const";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { Dialog } from "@thekeytechnology/epic-ui";

const PODCAST_WITH_TIMESTAMP_FRAGMENT = graphql`
	fragment PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment on PodcastWithTimestamp {
		id
		title
		podcastFile {
			id
			name
		}
		timestampsWithImage {
			start
			end
			imageFile {
				id
				name
			}
		}
	}
`;

const EDIT_PODCAST_WITH_TIMESTAMP_ELEMENT_MUTATION = graphql`
	mutation PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation(
		$input: EditPodcastWithTimestampInput!
	) {
		Admin {
			ElearningV2 {
				editPodcastWithTimestampElement(input: $input) {
					podcastWithTimestampElement {
						...PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment
					}
				}
			}
		}
	}
`;

export const PodcastWithTimestampElementForm = ({
	podcastWithTimestampElementFragmentRef,
	onBack,
}: PodcastWithTimestampElementFormProps) => {
	const podcastWithTimestampElement =
		useFragment<PodcastWithTimestampElementForm_PodcastWithTimestampElementFragment$key>(
			PODCAST_WITH_TIMESTAMP_FRAGMENT,
			podcastWithTimestampElementFragmentRef,
		);

	const [editPodcastWithTimestampElement, isEditingPodcastWithTimestampElement] =
		useMutation<PodcastWithTimestampElementForm_EditPodcastWithTimestampElementMutation>(
			EDIT_PODCAST_WITH_TIMESTAMP_ELEMENT_MUTATION,
		);

	const formId = "PodcastWithTimestampElementFormComponent";

	const [editTimestamp, setIsEditingTimestamp] = useState<TimestampWithImage | null>(null);

	const dispatch = useTypedDispatch();

	const formik = useFormik<PodcastWithTimestampElementFormState>({
		initialValues: {
			id: podcastWithTimestampElement.id,
			title: podcastWithTimestampElement.title || "",
			podcastFile: podcastWithTimestampElement.podcastFile as FileV2,
			timestampsWithImage: podcastWithTimestampElement.timestampsWithImage
				? podcastWithTimestampElement.timestampsWithImage.map((timestampWithImage) => ({
						tempId: v4(),
						start: secondToTimestamp(timestampWithImage.start),
						end: secondToTimestamp(timestampWithImage.end),
						imageFileId: timestampWithImage.imageFile?.id,
						imageFile: timestampWithImage.imageFile as FileV2,
				  }))
				: [],
		},
		validationSchema: Yup.object().shape({
			title: Yup.string()
				.max(500, "Der Titel darf nicht länger als 500 Zeichen sein")
				.required("Das Feld Titel wird benötigt."),
			podcastFile: Yup.object().required("Das Feld Podcast Datei wird benötigt."),
			timestampsWithImage: Yup.array()
				.min(1)
				.required("Es wird mindestens ein Zeitintervall erwartet"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			editPodcastWithTimestampElement({
				variables: {
					input: {
						elementId: values.id,
						newTitle: values.title,
						newPodcastFileId: values.podcastFile.id,
						newTimestampsWithImage: values.timestampsWithImage.map(
							(timestampWithImage) => ({
								start: timestampToSeconds(timestampWithImage.start),
								end: timestampToSeconds(timestampWithImage.end),
								imageFileId: timestampWithImage.imageFile?.id,
							}),
						),
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
					toast.success("Baustein gespeichert");
				},
			});
			setSubmitting(false);
		},
	});

	const canUpload = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);

	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<PodcastWithTimestampElementFormState, string>
				name={"title"}
				label={"Titel"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, fieldName, isValid }) => {
					return (
						<DefaultTextFieldComponent
							fieldName={fieldName + "-string"}
							disabled={isEditingPodcastWithTimestampElement}
							isValid={isValid}
							fieldValue={fieldValue}
							required
							updateField={(value) => {
								updateField(value);
								dispatch(addEditedFormToEditedFormsArray({ form: formId }));
							}}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<ValidatedField<PodcastWithTimestampElementFormState, FileV2>
				name={"podcastFile"}
				label={"Podcast Datei"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldName, fieldValue, updateField, onChange }) => {
					return (
						<FileSelectionField
							name={fieldName}
							filterByFileTypes={AudioFileTypeOptions.map((a) => a.value)}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							onChange={onChange}
							canUploadFiles={canUpload}
							canDeleteFiles={canDelete}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<ValidatedField<PodcastWithTimestampElementFormState, TimestampWithImage[]>
				name={"timestampsWithImage"}
				label={"Zeitintervalle"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField }: RenderConfig<TimestampWithImage[]>) => {
					return (
						<>
							<div className="p-buttonset mb-2">
								<CreateTimestampWithImageButton
									fieldValue={fieldValue}
									updateField={updateField}
								/>
							</div>
							<OrderListWithoutControls
								className={"mt-2"}
								value={fieldValue || []}
								dragdrop
								listStyle={{ height: "auto" }}
								itemTemplate={(timestampWithImage: TimestampWithImage) => (
									<TimestampWithImageTemplate
										edit={() => setIsEditingTimestamp(timestampWithImage)}
										remove={() =>
											fieldValue &&
											updateField([
												...fieldValue.filter(
													(twi) =>
														twi.tempId != timestampWithImage.tempId,
												),
											])
										}
										timestampWithImage={timestampWithImage}
									/>
								)}
							/>
							{editTimestamp && (
								<Dialog visible={true} onHide={() => setIsEditingTimestamp(null)}>
									<TimestampWithImageForm
										timestampWithImage={editTimestamp}
										onSave={(updatedTimestampWithImage: TimestampWithImage) => {
											fieldValue &&
												updateField([
													...fieldValue.map((twi) =>
														twi.tempId ==
														updatedTimestampWithImage.tempId
															? updatedTimestampWithImage
															: twi,
													),
												]);
											setIsEditingTimestamp(null);
										}}
										onBack={() => setIsEditingTimestamp(null)}
									/>
								</Dialog>
							)}
						</>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingPodcastWithTimestampElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={onBack}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};

const OrderListWithoutControls = styled(OrderList)`
	.p-orderlist-controls {
		display: none;
	}

	.p-orderlist-list {
		.p-disabled {
			opacity: 1;
		}
	}
`;
