/**
 * @generated SignedSource<<5635f2c95db89309e7443912a366a813>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ButtonKind = "confirm" | "link" | "textCopy";
export type PageDataKind = "image" | "imageWithButton";
import { FragmentRefs } from "relay-runtime";
export type messagePageForm_PageFragment$data = {
  readonly data: {
    readonly button?: {
      readonly kind: ButtonKind;
      readonly text?: string;
      readonly url?: string;
    };
    readonly element?: {
      readonly image: {
        readonly fileType: string;
        readonly id: string;
        readonly name: string;
      };
    };
    readonly kind: PageDataKind;
    readonly " $fragmentSpreads": FragmentRefs<"pageButtonForm_ButtonFragment">;
  };
  readonly id: string;
  readonly internalTitle: string;
  readonly " $fragmentType": "messagePageForm_PageFragment";
};
export type messagePageForm_PageFragment$key = {
  readonly " $data"?: messagePageForm_PageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"messagePageForm_PageFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageElement",
  "kind": "LinkedField",
  "name": "element",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "File",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fileType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "messagePageForm_PageFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "data",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/)
          ],
          "type": "ImagePage",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "pageButtonForm_ButtonFragment"
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "button",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": (v4/*: any*/),
                  "type": "ConfirmButton",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": (v4/*: any*/),
                  "type": "TextCopyButton",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v3/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "url",
                      "storageKey": null
                    }
                  ],
                  "type": "LinkButton",
                  "abstractKey": null
                }
              ],
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "type": "ImageWithButtonPage",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageDefinitionPage",
  "abstractKey": null
};
})();

(node as any).hash = "69546ee98c52201726b9249936fb21ba";

export default node;
