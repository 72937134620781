import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { type ProductActionColumn_ProductFragment$key } from "@relay/ProductActionColumn_ProductFragment.graphql";
import { EditProductButton } from "./EditProductButton";
import { DeleteProductButton } from "../../../features/products/DeleteProductButton";
import {Wrapper} from "@screens/products/products.styles";

const PRODUCT_FRAGMENT = graphql`
	fragment ProductActionColumn_ProductFragment on Product {
		id
	}
`;

type OwnProps = {
	productFragmentRef: ProductActionColumn_ProductFragment$key;
	connectionId: string;
};

export const ProductActionColumn = ({ productFragmentRef, connectionId }: OwnProps) => {
	const product = useFragment<ProductActionColumn_ProductFragment$key>(
		PRODUCT_FRAGMENT,
		productFragmentRef,
	);

	return (
		<Wrapper>
			<EditProductButton productId={product.id} />
			<DeleteProductButton id={product.id} connectionId={connectionId}/>
		</Wrapper>
	);
};
