import { Dialog } from "@thekeytechnology/framework-react-components";
import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import React, { useState } from "react";
import { useMutation } from "react-relay";
import { type FileV2 } from "@features/files/file-selection-field";
import { type CreateDefaultFileAttachmentButton_AddDefaultFileAttachmentMutation } from "@relay/CreateDefaultFileAttachmentButton_AddDefaultFileAttachmentMutation.graphql";
import { type AttachmentKind } from "@relay/DefaultFileAttachmentForm_AttachmentV2Fragment.graphql";
import { DefaultFileAttachmentForm } from "./DefaultFileAttachmentForm";

const ADD_DEFAULT_FILE_ATTACHMENT_MUTATION = graphql`
	mutation CreateDefaultFileAttachmentButton_AddDefaultFileAttachmentMutation(
		$input: AddDefaultFileAttachmentInput!
	) {
		Admin {
			Tree {
				addDefaultFileAttachment(input: $input) {
					node {
						...AttachmentEditor_TreeNodeFragment
					}
				}
			}
		}
	}
`;

type AttachmentInterface = {
	file?: FileV2;
	attachmentType?: AttachmentKind;
	description?: string;
	title?: string;
};

type OwnProps = {
	contentNodeId: string;
	canUploadFiles: boolean;
	canDeleteFiles: boolean;
};

export const CreateDefaultFileAttachmentButton = ({
	contentNodeId,
	canUploadFiles,
	canDeleteFiles,
}: OwnProps) => {
	const [isOpen, setOpen] = useState(false);
	const [addDefaultFileAttachment, isAddingDefaultFileAttachment] =
		useMutation<CreateDefaultFileAttachmentButton_AddDefaultFileAttachmentMutation>(
			ADD_DEFAULT_FILE_ATTACHMENT_MUTATION,
		);

	const handleAddDefaultFileAttachment = (attachment: AttachmentInterface) => {
		if (attachment.file) {
			addDefaultFileAttachment({
				variables: {
					input: {
						fileId: attachment.file.id,
						titleOpt: attachment.title,
						descriptionOpt: attachment.description,
						nodeId: contentNodeId,
					},
				},
			});
		}
		setOpen(false);
	};
	return (
		<>
			<Dialog
				title="Erstellen"
				visible={isOpen}
				onHide={() => {
					setOpen(false);
				}}
			>
				<DefaultFileAttachmentForm
					attachmentFragmentRef={null}
					onBack={(values) => {
						handleAddDefaultFileAttachment({ ...values });
					}}
					lockButton={isAddingDefaultFileAttachment}
					canUploadFiles={canUploadFiles}
					canDeleteFiles={canDeleteFiles}
				/>
			</Dialog>
			<Button
				className={"mr-2"}
				tooltip={"Datei anhängen"}
				icon="pi pi-paperclip"
				onClick={() => {
					setOpen(true);
				}}
			/>
		</>
	);
};
