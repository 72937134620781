import { useFormik } from "formik";
import { Button } from "primereact/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import {
	type AccountsTableFilterProps,
	type AccountsTableFilterState,
} from "./accounts-table-filter.interface";

export const AccountsTableFilter = ({ refetch }: AccountsTableFilterProps) => {
	const initialValues = {};

	const formik = useFormik<AccountsTableFilterState>({
		initialValues,
		onSubmit: (values: AccountsTableFilterState, { setSubmitting }) => {
			refetch(values.name);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<AccountsTableFilterState, string>
					name="name"
					label="Kontoname"
					className="mr-2 w-25rem"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={clearForm}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					type="submit"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
		</form>
	);
};
