import { type NodeDefinitionType } from "./add-node-button.types";
import { TREE_I18N_KEY, TREE_I18N_MAP } from "../../../../translations/tree";

export const DROPDOWN_OPTIONS: Array<{ nodeDefinitionType: NodeDefinitionType; name: string }> = [
	{
		nodeDefinitionType: "branch",
		name: `${TREE_I18N_MAP(TREE_I18N_KEY.branchNode)} erstellen`,
	},
	{
		nodeDefinitionType: "content",
		name: `${TREE_I18N_MAP(TREE_I18N_KEY.eLearningContentNode)} erstellen`,
	},
	{
		nodeDefinitionType: "asyncContent",
		name: `${TREE_I18N_MAP(TREE_I18N_KEY.asyncContentNode)} erstellen`,
	},
];
