/**
 * @generated SignedSource<<6e749241a6cb95661db80bc79ad06f29>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAccountGroupsForm_AccountGroupFragment$data = {
  readonly id: string;
  readonly name: string;
  readonly " $fragmentType": "editAccountGroupsForm_AccountGroupFragment";
};
export type editAccountGroupsForm_AccountGroupFragment$key = {
  readonly " $data"?: editAccountGroupsForm_AccountGroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAccountGroupsForm_AccountGroupFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "editAccountGroupsForm_AccountGroupFragment"
};

(node as any).hash = "e9db1447e32fc38fc190493d9b4b8df9";

export default node;
