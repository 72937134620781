import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query messageDispatchForm_Query($id: ID!, $skip: Boolean!) {
		...messageDispatchForm_MessageDispatchFragment @arguments(id: $id) @skip(if: $skip)
	}
`;

export const MESSAGE_DISPATCH_FRAGMENT = graphql`
	fragment messageDispatchForm_MessageDispatchFragment on Query
	@refetchable(queryName: "messageDispatch_Refetch")
	@argumentDefinitions(id: { type: "ID!" }) {
		node(id: $id) {
			... on MessageDispatch {
				id
				sendAt
				messageDefinition {
					internalTitle
				}
				includes {
					id
				}
				...dispatchInclusions_MessageDispatchFragment
				...dispatchExclusions_MessageDispatchFragment
			}
		}
	}
`;

export const SEND_MESSAGE_MUTATION = graphql`
	mutation messageDispatchForm_SendMessageDispatchMutation($input: SendMessageInput!) {
		Admin {
			Message {
				sendMessage(input: $input) {
					clientMutationId
				}
			}
		}
	}
`;
