/**
 * @generated SignedSource<<82ae6e6c946dc22bb974061a346426fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type LearnableKind = "Course";
import { FragmentRefs } from "relay-runtime";
export type offersTable_UnpublishedLearnableFragment$data = {
  readonly id: string;
  readonly kind: LearnableKind;
  readonly root?: {
    readonly id: string;
    readonly structureDefinition: {
      readonly title: string;
    };
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"editCourseLearnableButton_UnpublishedLearnableFragment">;
  readonly " $fragmentType": "offersTable_UnpublishedLearnableFragment";
};
export type offersTable_UnpublishedLearnableFragment$key = {
  readonly " $data"?: offersTable_UnpublishedLearnableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offersTable_UnpublishedLearnableFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "offersTable_UnpublishedLearnableFragment"
};

(node as any).hash = "a85a8df1155bd223fe2580a922cc9a1e";

export default node;
