import { graphql } from "babel-plugin-relay/macro";

export const DISPLAY_MATRIX_ELEMENT_CELL_FRAGMENT = graphql`
	fragment displayMatrixElementCellForm_DisplayMatrixElementCellFragment on DisplayMatrixElementCell {
		id
		content
		xIdx
		yIdx
	}
`;

export const SET_MATRIX_ELEMENT_COLUMN_TITLE_MUTATION = graphql`
	mutation displayMatrixElementCellForm_SetMatrixElementColumnTitleMutation(
		$input: SetMatrixElementColumnTitleInput!
	) {
		Admin {
			ElearningV2 {
				setMatrixElementColumnTitle(input: $input) {
					matrixElement {
						...matrixFieldElement_MatrixElementFragment
					}
				}
			}
		}
	}
`;

export const SET_MATRIX_ELEMENT_ROW_TITLE_MUTATION = graphql`
	mutation displayMatrixElementCellForm_SetMatrixElementRowTitleMutation(
		$input: SetMatrixElementRowTitleInput!
	) {
		Admin {
			ElearningV2 {
				setMatrixElementRowTitle(input: $input) {
					matrixElement {
						...matrixFieldElement_MatrixElementFragment
					}
				}
			}
		}
	}
`;
