import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import {
	type ordersTable_OrderFragment$data,
	type ordersTable_OrderFragment$key,
} from "@relay/ordersTable_OrderFragment.graphql";
import { type ordersTable_OrdersFragment$key } from "@relay/ordersTable_OrdersFragment.graphql";
import { type ordersTable_OrdersFragmentRefetch } from "@relay/ordersTable_OrdersFragmentRefetch.graphql";
import { ORDER_FRAGMENT, ORDERS_FRAGMENT } from "./orders-table.graphql";
import { type OrdersTableProps } from "./orders-table.interface";
import { DateTimeDisplay } from "../../../components/datetime-display/datetime-display.component";
import { EditOrderButton } from "../edit-order-button/edit-order-button.component";
import { OrdersTableFilters } from "../OrdersTableFilters";
import { OrderPriceDisplay } from "../order-price-display/order-price-display.component";
import { Pagination } from "../../../components/Pagination";
import { PaymentProviderLink } from "../payment-provider-link/payment-provider-link.component";
import { PaymentMethodType } from "../../../translations/PaymentMethodType";
import { PaymentStatus } from "../../../translations/PaymentStatus";

export const OrdersTable = ({
	ordersFragmentRef,
	ordersPerPage,
	enableFilter,
	canModifyOrders,
}: OrdersTableProps) => {
	const {
		data: orders,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<ordersTable_OrdersFragmentRefetch, ordersTable_OrdersFragment$key>(
		ORDERS_FRAGMENT,
		ordersFragmentRef,
	);

	const ordersConnection = orders.Admin.Billing.Orders.edges?.map((edge) =>
		readInlineData<ordersTable_OrderFragment$key>(ORDER_FRAGMENT, edge?.node!),
	);

	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Keine Bestellungen"}
				className="p-mb-2"
				header={
					enableFilter && (
						<OrdersTableFilters
							refetch={(username?: string, email?: string) =>
								refetch({
									username,
									email,
									first: ordersPerPage,
								})
							}
						/>
					)
				}
				value={ordersConnection}
			>
				<Column
					header={"ID"}
					body={(order: ordersTable_OrderFragment$data) => (
						<div
							className="cursor-pointer"
							onClick={async () => {
								await navigator.clipboard.writeText(order.id);
							}}
						>
							<b>
								ID<i className="pi pi-copy"></i>
							</b>
						</div>
					)}
				/>
				<Column
					header={"Erstellungsdatum"}
					body={(order: ordersTable_OrderFragment$data) => (
						<DateTimeDisplay value={order.createdAt} hideTimezone />
					)}
				/>
				<Column
					header={"Status"}
					body={(order: ordersTable_OrderFragment$data) => (
						<div>{PaymentStatus[order.status]}</div>
					)}
				/>
				<Column
					header={"Preis"}
					body={(order: ordersTable_OrderFragment$data) => (
						<OrderPriceDisplay orderFragmentRef={order} />
					)}
				/>
				<Column
					header={"Zahlungsdienstleister"}
					body={(order: ordersTable_OrderFragment$data) => (
						<PaymentProviderLink orderFragmentRef={order} />
					)}
				/>
				<Column
					header={"Zahlweise"}
					body={(order: ordersTable_OrderFragment$data) => {
						if (!order.selectedPaymentMethod?.selectedPaymentMethodType)
							return <div>N/A</div>;

						return (
							<div>
								{
									PaymentMethodType[
										order.selectedPaymentMethod?.selectedPaymentMethodType
									]
								}
							</div>
						);
					}}
				/>
				{canModifyOrders && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(order: ordersTable_OrderFragment$data) => (
							<EditOrderButton orderId={order.id} />
						)}
					/>
				)}
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(ordersPerPage)} />
		</>
	);
};
