import { graphql } from "babel-plugin-relay/macro";
import { useFragment, useMutation } from "react-relay";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { FormState, OwnProps } from "./multiple-choice-element-form.types";
import { AnswerOptionV2Input } from "@relay/CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation.graphql";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	resetArrayOfEditedForms,
	addEditedFormToEditedFormsArray,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";
import { AnswerOptionsField } from "../AnswerOptionsField";
import { FileSelectionField, FileV2 } from "../../../../features/files/file-selection-field";
import { stripHtml } from "src/util/html.utils";
import { useHasPermissions } from "@hooks/use-has-permissions";
import {
	EDIT_MULTIPLE_CHOICE_ELEMENT_MUTATION,
	MULTIPLE_CHOICE_ELEMENTV2_FRAGMENT,
} from "./multiple-choice-element-form.graphql";
import {
	AnsweringTypeV2,
	multipleChoiceElementForm_MultipleChoiceElementV2Fragment$key,
} from "@relay/multipleChoiceElementForm_MultipleChoiceElementV2Fragment.graphql";
import { multipleChoiceElementForm_EditMultipleChoiceElementMutation } from "@relay/multipleChoiceElementForm_EditMultipleChoiceElementMutation.graphql";

export const MultipleChoiceElementForm = ({
	multipleChoiceElementFragmentRef,
	onBack,
}: OwnProps) => {
	const multipleChoiceElement =
		useFragment<multipleChoiceElementForm_MultipleChoiceElementV2Fragment$key>(
			MULTIPLE_CHOICE_ELEMENTV2_FRAGMENT,
			multipleChoiceElementFragmentRef,
		);
	const [editMultipleChoiceElement, isEditingMultipleChoiceElement] =
		useMutation<multipleChoiceElementForm_EditMultipleChoiceElementMutation>(
			EDIT_MULTIPLE_CHOICE_ELEMENT_MUTATION,
		);
	const dispatch = useTypedDispatch();
	const formId = "MultipleChoiceElementForm";
	const formik = useFormik<FormState>({
		initialValues: {
			question: stripHtml(multipleChoiceElement.question),
			imageOpt: (multipleChoiceElement.image as FileV2) || undefined,
			answeringType: multipleChoiceElement.answeringType,
			answeringTypeText: multipleChoiceElement.answeringTypeText || undefined,
			answerOptions: [
				...multipleChoiceElement.answerOptions.map((ao) => ({
					answer: stripHtml(ao.answer),
					isCorrect: ao.isCorrect,
					imageRef: ao.image?.id,
					image: ao.image as FileV2,
				})),
			],
		},
		validationSchema: Yup.object().shape({
			question: Yup.string().required("Das Feld Frage wird benötigt."),
			answeringType: Yup.string().required("Das Feld Antwort-Typ wird benötigt."),
			answerOptions: Yup.array()
				.required("Antwortmöglichkeiten werden benötigt")
				.min(1, "Es muss mindestens eine Antwort geben")
				.test("test2", "test2", function (value) {
					const options = value as AnswerOptionV2Input[];
					if (!options.find((ao) => ao.isCorrect)) {
						return this.createError({
							path: "answerOptions",
							message: "Es muss mindestens eine richtige Antwort geben.",
						});
					}
					if (options.find((o) => !o.answer)) {
						return this.createError({
							path: "answerOptions",
							message: "Jede Antwort benötigt einen Antworttext.",
						});
					}
					if (new Set(options.map((o) => o.answer)).size < options.length) {
						return this.createError({
							path: "answerOptions",
							message: "Jede Antwort muss einzigartig sein.",
						});
					}
					return true;
				})
				.typeError("Es gab ein Fehler bei der Validierung"),
		}),
		onSubmit: (values) => {
			editMultipleChoiceElement({
				variables: {
					input: {
						multipleChoiceElementId: multipleChoiceElement.id,
						title: values.question,
						question: values.question,
						imageIdOpt: values.imageOpt?.id || undefined,
						answeringType: values.answeringType,
						answeringTypeText: values.answeringTypeText,
						answerOptions: values.answerOptions.map((o) => ({
							answer: o.answer,
							isCorrect: o.isCorrect,
							imageRef: o.imageRef,
						})),
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const canUpload = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);
	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"question"}
				label={"Titel"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, FileV2>
				name={"imageOpt"}
				label={"Bild zur Frage"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldName, fieldValue, updateField, onChange }) => (
					<FileSelectionField
						name={fieldName}
						selectedFile={fieldValue}
						setSelectedFile={updateField}
						filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
						onChange={onChange}
						canUploadFiles={canUpload}
						canDeleteFiles={canDelete}
					/>
				)}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, AnsweringTypeV2>
				name={"answeringType"}
				label={"Erlaubte Antworten"}
				component={({ fieldName, fieldValue, updateField }) => {
					return (
						<div>
							<Dropdown
								name={fieldName}
								value={fieldValue}
								options={[
									{
										label: "Nur eine Antwort erlauben",
										value: "allowOnlyOneAnswer",
									},
									{
										label: "Mehrere Antworten erlauben - es müssen alle richtigen Möglichkeiten ausgewählt werden.",
										value: "allowMultipleAnswersAllCorrectMustBeGiven",
									},
								]}
								onChange={(e) => {
									updateField(e.value);
									dispatch(addEditedFormToEditedFormsArray({ form: formId }));
								}}
							/>
						</div>
					);
				}}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, string>
				name={"answeringTypeText"}
				label={"Aufgabenstellung"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, AnswerOptionV2Input[]>
				name={"answerOptions"}
				label={"Antwortmöglichkeiten"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, onChange }) => (
					<AnswerOptionsField
						answerOptions={fieldValue || []}
						onUpdate={updateField}
						onChange={onChange}
						canUploadFiles={canUpload}
						canDeleteFiles={canDelete}
					/>
				)}
				formikConfig={formik}
			/>

			<Button
				disabled={
					Object.entries(formik.touched).length === 0 || isEditingMultipleChoiceElement
				}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				disabled={!formik.isValid}
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
