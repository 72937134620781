import {
	AttachmentsPath,
	ConfigPath,
	ContentsPath,
	OverviewPath,
	RewardsPath,
} from "@screens/educational-offer/educational-offer.paths";

export const TabsOrder = [
	OverviewPath.pathKey,
	ContentsPath.pathKey,
	AttachmentsPath.pathKey,
	ConfigPath.pathKey,
	RewardsPath.pathKey,
] as const;
