import { graphql } from "babel-plugin-relay/macro";

export const FILE_ELEMENTV2_FRAGMENT = graphql`
	fragment fileElementForm_FileElementV2Fragment on FileElementV2 {
		id
		title
		file {
			id
			name
			url
		}
	}
`;

export const EDIT_FILE_ELEMENT_MUTATION = graphql`
	mutation fileElementForm_EditFileElementMutation($input: EditFileElementV2Input!) {
		Admin {
			ElearningV2 {
				editFileElement(input: $input) {
					fileElement {
						...fileElementForm_FileElementV2Fragment
					}
				}
			}
		}
	}
`;
