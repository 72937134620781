import React, { createContext, PropsWithChildren, useState, useCallback } from "react";
import { DispatchCondition } from "@hooks/use-dispatch-conditions/use-dispatch-conditions.types";

export type IMessageDispatchFormContext = {
	inclusionConditions: Array<DispatchCondition<any, any>>;
	exclusionConditions: Array<DispatchCondition<any, any>>;
	addInclusionCondition: (condition: DispatchCondition<any, any>) => void;
	removeInclusionCondition: (condition: DispatchCondition<any, any>) => void;
	addExclusionCondition: (condition: DispatchCondition<any, any>) => void;
	removeExclusionCondition: (condition: DispatchCondition<any, any>) => void;
};

export const MessageDispatchFormContext = createContext<IMessageDispatchFormContext>({
	inclusionConditions: [],
	exclusionConditions: [],
	addInclusionCondition: () => {},
	removeInclusionCondition: () => {},
	addExclusionCondition: () => {},
	removeExclusionCondition: () => {},
});

export const MessageDispatchFormContextProvider = ({
	children,
}: PropsWithChildren): JSX.Element => {
	const [inclusionConditions, setInclusionConditions] = useState<
		Array<DispatchCondition<any, any>>
	>([]);
	const [exclusionConditions, setExclusionConditions] = useState<
		Array<DispatchCondition<any, any>>
	>([]);

	const addInclusionCondition = useCallback((condition: DispatchCondition<any, any>) => {
		setInclusionConditions((prevConditions) => [...prevConditions, condition]);
	}, []);

	const removeInclusionCondition = useCallback((condition: DispatchCondition<any, any>) => {
		setInclusionConditions((prevConditions) => prevConditions.filter((c) => c !== condition));
	}, []);

	const addExclusionCondition = useCallback((condition: DispatchCondition<any, any>) => {
		setExclusionConditions((prevConditions) => [...prevConditions, condition]);
	}, []);

	const removeExclusionCondition = useCallback((condition: DispatchCondition<any, any>) => {
		setExclusionConditions((prevConditions) => prevConditions.filter((c) => c !== condition));
	}, []);

	const value: IMessageDispatchFormContext = {
		inclusionConditions,
		exclusionConditions,
		addInclusionCondition,
		removeInclusionCondition,
		addExclusionCondition,
		removeExclusionCondition,
	};

	return (
		<MessageDispatchFormContext.Provider value={value}>
			{children}
		</MessageDispatchFormContext.Provider>
	);
};
