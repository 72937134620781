import graphql from "babel-plugin-relay/macro";

export const DELETE_CONDITION_MUTATION = graphql`
	mutation deleteConditionButton_DeleteConditionMutation($input: DeleteConditionInput!) {
		Admin {
			Billing {
				deleteCondition(input: $input) {
					limitedCartDiscount {
						...limitedCartDiscountForm_LimitedCartDiscountFragment
					}
				}
			}
		}
	}
`;
