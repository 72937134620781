import { Card } from "primereact/card";
import { TabPanel, TabView } from "primereact/tabview";
import { useContext, useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";

import { useHasPermissions } from "@hooks/use-has-permissions";
import { type nodePart_Query } from "@relay/nodePart_Query.graphql";
import { Paths } from "@routes/paths";
import { RewardEditor } from "@screens/educational-offer/parts/reward-editor";
import { QUERY } from "./node-part.graphql";
import { getPathForTabIndex, getTabIndex } from "./node-part.utils";

import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import { TREE_I18N_MAP, TREE_I18N_KEY } from "../../../../translations/tree";
import { selectArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedSelector } from "../../../../store/store.redux";
import { BaseScreenNoBackground } from "../../../BaseScreen";
import { AttachmentEditor } from "../AttachmentEditor";
import { ContentCard } from "../content-card";
import { ContentConfigEditorComponent } from "../content-config-editor";
import { EditNodeForm } from "../edit-node-form";
import { EditRootExtensionForm } from "../edit-root-extension-form/edit-root-extension-form.component";
import { EditRootNodePartForm } from "../EditRootNodePartForm";
import { TreeConfigEditor } from "../TreeConfigEditor";
import { TreeSelectionContext } from "../TreeSelectionContext";

export const NodePart = () => {
	const navigate = useNavigate();
	const { selectedNodeId, treeId } = useContext(TreeSelectionContext);

	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);

	const params = useParams();

	const tabIndex = getTabIndex(params["*"]);

	const data = useLazyLoadQuery<nodePart_Query>(QUERY, {
		id: selectedNodeId ?? "",
		skip: !selectedNodeId,
	});

	const [activeIndex, setActiveIndex] = useState(tabIndex);
	const isRoot = data.node?.structureDefinition?.definitionType === "root";
	const isContent = data.node?.typeDefinition?.definitionType === "content";
	const [showDialog, setShowDialog] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState<number>(activeIndex);
	const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(
		arrayOfEditedForm.length > 0,
	);

	const canUpload = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);
	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<BaseScreenNoBackground>
			{(showPrompt || showDialog) && (
				<WarningUnsavedChangesDialog
					setShowDialog={setShowDialog}
					confirmNavigation={confirmNavigation}
					cancelNavigation={cancelNavigation}
					callback={setActiveIndex}
					value={selectedIndex}
				/>
			)}
			{!data.node && <Card>Bitte einen Ordner auswählen</Card>}
			{data.node && (
				<TabView
					activeIndex={activeIndex}
					onTabChange={(e) => {
						if (arrayOfEditedForm.length > 0) {
							setShowDialog(true);
							setSelectedIndex(e.index);
						} else {
							setActiveIndex(e.index);
						}

						const pageKey = getPathForTabIndex(e.index);
						if (!pageKey) return;

						switch (selectedNodeId) {
							case undefined:
							case treeId:
								navigate(Paths.educationalOffer.withId(treeId)[pageKey].path);
								return;
							default:
								navigate(
									Paths.educationalOffer
										.withId(treeId)
										.node.withId(selectedNodeId)[pageKey].path,
								);
						}
					}}
				>
					<TabPanel header="Ordner">
						<EditNodeForm key={data?.node.id} treeNodeFragmentRef={data.node} />
						{isRoot && (
							<EditRootNodePartForm
								treeNodeFragmentRef={data.node}
								canUploadFiles={canUpload}
								canDeleteFiles={canDelete}
							/>
						)}
						{isRoot && data.node.structureDefinition.extension && (
							<EditRootExtensionForm
								rootId={data.node.id}
								rootExtensionImplFragmentRef={
									data.node.structureDefinition.extension
								}
								queryFragmentRef={data}
							/>
						)}
					</TabPanel>
					{
						<TabPanel
							header={(() => {
								switch (data.node?.typeDefinition?.definitionType) {
									case "branch":
										return `Inhalt: ${TREE_I18N_MAP(TREE_I18N_KEY.branchNode)}`;
									case "content":
										switch (data.node?.typeDefinition.contentKind) {
											case "ELearning":
												return `Inhalt: ${TREE_I18N_MAP(
													TREE_I18N_KEY.eLearningContentNode,
												)} (${data.node?.typeDefinition.elements
													?.length} Baustein${
													data.node?.typeDefinition.elements?.length === 1
														? ""
														: "e"
												})`;
											case "Async":
												return `Inhalt: ${TREE_I18N_MAP(
													TREE_I18N_KEY.asyncContentNode,
												)}`;
											default:
												return null;
										}
									default:
										return null;
								}
							})()}
						>
							<ContentCard
								// queryFragmentRef={data}
								treeNodeFragmentRef={data.node}
							/>
						</TabPanel>
					}
					<TabPanel header={`Anhänge (${data.node.attachments?.length})`}>
						<AttachmentEditor
							treeNodeFragmentRef={data.node}
							canUploadFiles={canUpload}
							canDeleteFiles={canDelete}
						/>
					</TabPanel>
					<TabPanel header="Bedingungen">
						{isRoot && <TreeConfigEditor treeNodeFragmentRef={data.node} />}
						{isContent && (
							<ContentConfigEditorComponent treeNodeFragmentRef={data.node} />
						)}
					</TabPanel>
					{isContent && (
						<TabPanel
							header={`Belohnungen (${data.node.typeDefinition?.rewards?.length})`}
						>
							<RewardEditor treeNodeFragmentRef={data.node} />
						</TabPanel>
					)}
				</TabView>
			)}
		</BaseScreenNoBackground>
	);
};
