import graphql from "babel-plugin-relay/macro";

export const INSTRUCTORS_TABLE_FRAGMENT = graphql`
	fragment instructorsTable_InstructorsListFragment on Query
	@refetchable(queryName: "instructorsTable_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			Instructor {
				InstructorImpls(first: $first, after: $after)
					@connection(key: "instructorsTable_InstructorImpls") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							superId
							name
							position
							shortDescription
							description
							image {
								id
								url
							}
						}
					}
				}
			}
		}
	}
`;

export const DELETE_INSTRUCTOR_MUTATION = graphql`
	mutation instructorsTable_DeleteMutation(
		$input: DeleteInstructorImplInput!
		$connections: [ID!]!
	) {
		Admin {
			Instructor {
				deleteInstructorImpl(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
