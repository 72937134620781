import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query orderEditScreen_OrderQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Order {
				id
				billingDetails {
					...billingDetailsForm_BillingDetailsFragment
				}
				...cartForm_OrderFragment
				...paymentDataForm_OrderFragment
				...invoiceDataForm_OrderFragment
				...creditNoteDataForm_OrderFragment
			}
		}
		...orderHistoryTable_OrderHistoriesFragment @arguments(first: 10, after: null)
	}
`;
