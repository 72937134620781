import { graphql } from "babel-plugin-relay/macro";

export const TEXT_ELEMENT_FRAGMENT = graphql`
	fragment textElementForm_TextElementFragment on TextElementV2 {
		id
		title
		text
	}
`;

export const EDIT_TEXT_ELEMENT_MUTATION = graphql`
	mutation textElementForm_EditTextElementMutation($input: EditTextElementV2Input!) {
		Admin {
			ElearningV2 {
				editTextElement(input: $input) {
					textElement {
						...textElementForm_TextElementFragment
					}
				}
			}
		}
	}
`;
