import { type FileUploadBeforeSendEvent } from "primereact/fileupload";

interface HookProps {
	isFilesPublic: boolean;
	accessToken?: string;
}

export const useFileUploadWithJwt = ({ isFilesPublic, accessToken }: HookProps) => {
	const onBeforeSendWithJwt = (ev: FileUploadBeforeSendEvent) => {
		if (accessToken) {
			ev.xhr.setRequestHeader("Authorization", accessToken);
			ev.formData.set("isPublic", JSON.stringify(isFilesPublic));
		}
	};

	return {
		onBeforeSendWithJwt,
	};
};
