import { Paths } from "@routes/paths";
import { StagingScreen } from "./staging.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const StagingRoutes: RouteDefinition[] = [
	{
		path: Paths.staging.path,
		element: <StagingScreen />,
		requiredPermissions: "onlyOwnerOfRoot",
	},
];
