import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { EditLimitedCartDiscountScreen } from "./limited-cart-discount-edit.screen";

export const LimitedCartDiscountEditRoutes: RouteDefinition[] = [
	{
		path: Paths.cartDiscounts.withIdPlaceholder().edit.path,
		element: <EditLimitedCartDiscountScreen />,
		requiredPermissions: ["UserInAccountPermission_LimitedCartDiscountAdmin_Modify"],
	},
];
