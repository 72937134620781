/**
 * @generated SignedSource<<4d65d7fb688abe3e7e39b48e1ec5b096>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SetConfirmButtonInPageInput = {
  clientMutationId?: string | null;
  messageDefinitionId: string;
  pageId: string;
  text: string;
};
export type useMessagePageButtonMutations_SetConfirmButtonMutation$variables = {
  connections: ReadonlyArray<string>;
  input: SetConfirmButtonInPageInput;
};
export type useMessagePageButtonMutations_SetConfirmButtonMutation$data = {
  readonly Admin: {
    readonly MessageDefinitions: {
      readonly setConfirmButtonInPage: {
        readonly messageDefinition: {
          readonly node: {
            readonly id: string;
          };
        };
      } | null;
    };
  };
};
export type useMessagePageButtonMutations_SetConfirmButtonMutation = {
  response: useMessagePageButtonMutations_SetConfirmButtonMutation$data;
  variables: useMessagePageButtonMutations_SetConfirmButtonMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "MessageDefinitionsEdge",
  "kind": "LinkedField",
  "name": "messageDefinition",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MessageDefinition",
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "useMessagePageButtonMutations_SetConfirmButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageDefinitionsAdminMutationSchema",
            "kind": "LinkedField",
            "name": "MessageDefinitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "SetConfirmButtonInPagePayload",
                "kind": "LinkedField",
                "name": "setConfirmButtonInPage",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "useMessagePageButtonMutations_SetConfirmButtonMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageDefinitionsAdminMutationSchema",
            "kind": "LinkedField",
            "name": "MessageDefinitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "SetConfirmButtonInPagePayload",
                "kind": "LinkedField",
                "name": "setConfirmButtonInPage",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "messageDefinition",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "867be57c6de8d5b3d172a9cbb18f77e4",
    "id": null,
    "metadata": {},
    "name": "useMessagePageButtonMutations_SetConfirmButtonMutation",
    "operationKind": "mutation",
    "text": "mutation useMessagePageButtonMutations_SetConfirmButtonMutation(\n  $input: SetConfirmButtonInPageInput!\n) {\n  Admin {\n    MessageDefinitions {\n      setConfirmButtonInPage(input: $input) {\n        messageDefinition {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a57a1d3b7127e9a253f0b3876a34d65";

export default node;
