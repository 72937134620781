import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Paths } from "@routes/paths";
import { type EditLimitedCartDiscountButtonProps } from "./edit-limited-cart-discount-button.interface";

export const EditLimitedCartDiscountButton = ({
	limitedCartDiscountId,
	className,
}: EditLimitedCartDiscountButtonProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon="pi pi-pencil"
			tooltip="Bestellung bearbeiten"
			className={className}
			onClick={() => {
				navigate(Paths.cartDiscounts.withId(limitedCartDiscountId).edit.path);
			}}
		/>
	);
};
