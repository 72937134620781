import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type AddOrderItemButtonProps } from "./add-order-item-button.interface";
import { type AddOrderItemButton_AddOrderItemMutation } from "../../../../__generated__/AddOrderItemButton_AddOrderItemMutation.graphql";

const ADD_ORDER_ITEM_MUTATION = graphql`
	mutation AddOrderItemButton_AddOrderItemMutation($input: AddOrderItemInput!) {
		Admin {
			ElearningV2 {
				addOrderItem(input: $input) {
					orderElement {
						...OrderElementForm_OrderElementFragment
					}
				}
			}
		}
	}
`;

export const AddOrderItemButton = ({ orderElementId }: AddOrderItemButtonProps) => {
	const [addOrderItem, isAddingOrderItem] =
		useMutation<AddOrderItemButton_AddOrderItemMutation>(ADD_ORDER_ITEM_MUTATION);

	return (
		<Button
			label="Reihenfolgeelement hinzufügen"
			type="button"
			disabled={isAddingOrderItem}
			onClick={() =>
				addOrderItem({
					variables: {
						input: {
							orderElementId,
							text: "neues Reihenfolgeelement",
						},
					},
				})
			}
		/>
	);
};
