import { PageKindDropdownType } from "@components/page-kind-dropdown/page-kind-dropdown.types";

export const pageKindOptions: PageKindDropdownType[] = [
	{
		value: "image",
		label: "Bild",
	},
	{
		value: "imageWithButton",
		label: "Bild mit Button",
	},
];
