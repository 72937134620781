/**
 * @generated SignedSource<<6e8675edbb750a901c569f19b176cb88>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient";
import { FragmentRefs } from "relay-runtime";
export type orderHistoryTable_OrderHistoryFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly status: OrderStatus;
  readonly " $fragmentSpreads": FragmentRefs<"orderHistoryEvent_OrderHistoryFragment">;
  readonly " $fragmentType": "orderHistoryTable_OrderHistoryFragment";
};
export type orderHistoryTable_OrderHistoryFragment$key = {
  readonly " $data"?: orderHistoryTable_OrderHistoryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"orderHistoryTable_OrderHistoryFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "orderHistoryTable_OrderHistoryFragment"
};

(node as any).hash = "5900e94d4756c2247ac4b1351c630933";

export default node;
