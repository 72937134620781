import {
	DefaultStyledInputTextComponents,
	type InputBaseProps,
	type StyledInputTextComponents,
} from "@thekeytechnology/framework-react-components";
import {
	InputText as PrInputText,
	type InputTextProps as PrInputTextProps,
} from "primereact/inputtext";
import styled from "styled-components";
import tw from "twin.macro";
import { DefaultInputStyles } from "@corestyle/component-theme/common/input.styles";

export const tkaAdminInputTextStyles: StyledInputTextComponents = {
	InputText: {
		...DefaultStyledInputTextComponents.InputText,
		StyledInputText: styled(PrInputText)<
			InputBaseProps & PrInputTextProps & { hasIcon: boolean }
		>`
			${DefaultInputStyles()};

			${(props) => (props.hasIcon ? tw`tw-ml-24` : ``)}
		`,
		TextWrapper: tw.div`tw-flex-grow tw-flex tw-relative`,
		IconContainer: styled.div`
			${tw`tw-absolute tw-w-full tw-h-full tw-z-10 tw-flex tw-items-center tw-pl-4`};
			pointer-events: none;
		`,
		IconWrapper: styled.div``,
	},
};
