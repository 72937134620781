import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query reportsTableFilters_Query {
		Viewer {
			Reports {
				SystemEmail
			}
		}
	}
`;
