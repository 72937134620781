import {
	DefaultStyledInputMultiSelectComponents,
	type InputBaseProps,
	type StyledInputMultiSelectComponents,
} from "@thekeytechnology/framework-react-components";
import {
	MultiSelect as PrMultiSelect,
	type MultiSelectProps as PrMultiSelectProps,
} from "primereact/multiselect";
import styled, { createGlobalStyle } from "styled-components";
import tw from "twin.macro";

export const tkaAdminInputMultiSelectStyles: StyledInputMultiSelectComponents = {
	InputMultiSelect: {
		...DefaultStyledInputMultiSelectComponents.InputMultiSelect,
		StyledMultiSelect: styled(PrMultiSelect)<PrMultiSelectProps & InputBaseProps>`
			${tw`tw-max-h-[5.5rem] tw-min-h-[37px]`}
		`,
		MultiSelectValueTemplate: tw.div`
		
`,
		MultiSelectValueIconWrapper: tw.div`
`,
		SelectedItemWrapper: tw.div`
`,
		MultiSelectGlobalStyles: createGlobalStyle`
		`,
	},
};
