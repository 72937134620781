import graphql from "babel-plugin-relay/macro";

export const ORDER_FRAGMENT = graphql`
	fragment invoiceDataForm_OrderFragment on Order {
		invoiceData {
			invoiceNumber
			invoiceId
			invoiceFile {
				url
			}
		}
	}
`;
