import React from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type uploadTaskEditScreen_EvaluateUploadAsyncElementMutation } from "@relay/uploadTaskEditScreen_EvaluateUploadAsyncElementMutation.graphql";
import { Paths } from "@routes/paths";
import { EditUploadTaskForm } from "./parts/edit-upload-task-form/edit-upload-task-form.component";
import {
	ASYNC_ELEMENT_TASK_FRAGMENT,
	EVALUATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
} from "./upload-task-edit.graphql";
import { type UploadTaskEditScreenProps } from "./upload-task-edit.types";
import { NoAccess } from "../../components/no-access";
import { type FileV2 } from "../../features/files/file-selection-field";
import { BaseScreen } from "../BaseScreen";

export const UploadTaskEditScreen = ({
	asyncElementTaskFragmentRef,
}: UploadTaskEditScreenProps) => {
	const navigate = useNavigate();

	const elementTask = useFragment(ASYNC_ELEMENT_TASK_FRAGMENT, asyncElementTaskFragmentRef);
	const [update, isUpdating] =
		useMutation<uploadTaskEditScreen_EvaluateUploadAsyncElementMutation>(
			EVALUATE_UPLOAD_ASYNC_ELEMENT_MUTATION,
		);

	const isEvaluated = elementTask.status === "Evaluated";
	const title = isEvaluated ? "Bewertung" : "Bearbeite Aufgabe";

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_AsyncElementTaskAdmin_Read",
		"UserInAccountPermission_AsyncElementTaskAdmin_Modify",
	]);

	if (!canReadAndModify) return <NoAccess />;

	return (
		<BaseScreen>
			<h1 className="mb-3">{title}</h1>
			<h3>Eingereichte Datei</h3>
			<div className="mb-6">
				<a href={elementTask.file?.url || ""} target="_blank" rel="noreferrer">
					{elementTask.file?.name}
				</a>
			</div>

			<EditUploadTaskForm
				editable={!isUpdating && !isEvaluated}
				initialValues={{
					evaluationText: elementTask?.evaluation?.text || undefined,
					file: elementTask?.evaluation?.file as FileV2,
				}}
				loading={isUpdating || isUpdating}
				onSubmit={(values) => {
					if (!values.file?.id) return toast.error("Datei nicht ausgewählt.");
					update({
						variables: {
							input: {
								taskId: elementTask.id,
								fileId: values.file.id,
								text: values.evaluationText,
							},
						},
						onCompleted: () => {
							navigate(Paths.tasks.path);
						},
					});
				}}
			/>
		</BaseScreen>
	);
};
