import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { SelectUserFieldDialog } from "@features/users/select-user-field-dialog";
import { type uploadAsyncElementForm_EditUploadAsyncElementMutation } from "@relay/uploadAsyncElementForm_EditUploadAsyncElementMutation.graphql";
import { type uploadAsyncElementForm_UploadAsyncElementFragment$key } from "@relay/uploadAsyncElementForm_UploadAsyncElementFragment.graphql";
import {
	EDIT_UPLOAD_ASYNC_ELEMENT_MUTATION,
	UPLOAD_ASYNC_ELEMENT_FRAGMENT,
} from "./upload-async-element-form.graphql";
import {
	type UploadAsyncElementFormProps,
	type UploadAsyncElementFormState,
} from "./upload-async-element-form.types";
import { DefaultTextAreaComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";

export const UploadAsyncElementForm = ({
	uploadAsyncElementFragmentRef,
}: UploadAsyncElementFormProps) => {
	const asyncUploadElement = useFragment<uploadAsyncElementForm_UploadAsyncElementFragment$key>(
		UPLOAD_ASYNC_ELEMENT_FRAGMENT,
		uploadAsyncElementFragmentRef,
	);

	const formId = "UploadAsyncElementForm";
	const [editUploadAsyncElement, isEditingAsyncUploadElement] =
		useMutation<uploadAsyncElementForm_EditUploadAsyncElementMutation>(
			EDIT_UPLOAD_ASYNC_ELEMENT_MUTATION,
		);
	const dispatch = useTypedDispatch();

	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const isEdited = arrayOfEditedForms.length > 0;
	const formik = useFormik<UploadAsyncElementFormState>({
		initialValues: {
			taskDescription: asyncUploadElement.taskDescription || "",
			userId: asyncUploadElement.assignedEvaluator?.id,
		},
		validationSchema: Yup.object().shape({
			taskDescription: Yup.string().required("Das Feld Beschreibung wird benötigt."),
			userId: Yup.string().required("Ein Bearbeiter wird benötigt."),
		}),
		onSubmit: (values) => {
			editUploadAsyncElement({
				variables: {
					input: {
						taskDescription: values.taskDescription,
						assignedEvaluator: values.userId!,
						id: asyncUploadElement.id,
						title: "",
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const handleOnChange = useCallback(
		() => dispatch(addEditedFormToEditedFormsArray({ form: formId })),
		[formId],
	);

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<UploadAsyncElementFormState, string>
				name={"taskDescription"}
				label={"Aufgabenstellung"}
				component={DefaultTextAreaComponent}
				onChange={handleOnChange}
				formikConfig={formik}
			/>
			<ValidatedField<UploadAsyncElementFormState, string>
				className="mb-4"
				name={"userId"}
				label={"Wähle einen Bearbeiter aus"}
				formikConfig={formik}
				component={(config) => {
					return (
						<SelectUserFieldDialog
							{...config}
							selectEditors
							onChange={handleOnChange}
						/>
					);
				}}
			/>

			<Button
				disabled={isEditingAsyncUploadElement || !isEdited || !formik.isValid}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>
		</form>
	);
};
