import graphql from "babel-plugin-relay/macro";

export const DELETE_LIMITED_CART_DISCOUNT_MUTATION = graphql`
	mutation deleteLimitedCartDiscountButton_DeleteLimitedCartDiscountMutation(
		$input: DeleteLimitedCartDiscountInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				deleteLimitedCartDiscount(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
