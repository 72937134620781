import { configureStore } from "@reduxjs/toolkit";
import { type TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { AuthSliceReducer } from "./slices/AuthSlice";
import { CoreSliceReducer } from "./slices/CoreSlice";
import { CurrentUserSliceReducer } from "./slices/CurrentUserSlice";

export const ReduxStore = configureStore({
	reducer: {
		auth: AuthSliceReducer,
		currentUser: CurrentUserSliceReducer,
		core: CoreSliceReducer,
	},
});
export type ReduxState = ReturnType<typeof ReduxStore.getState>;
export type TypedDispatch = typeof ReduxStore.dispatch;

export const useTypedDispatch: () => TypedDispatch = useDispatch;
export const useTypedSelector: TypedUseSelectorHook<ReduxState> = useSelector;
