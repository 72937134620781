import { Dropdown, type DropdownChangeEvent, type DropdownProps } from "primereact/dropdown";
import React from "react";
import styled from "styled-components";
import { CONFIGS_TRANSLATIONS } from "../../../translations/educational-offer";

const StyledConfigDropdown = styled(Dropdown)`
	background-color: "#fff";
`;

type Props<T> = {
	isPresentational: boolean;
	configOptions?: Array<ConfigDropdownOptions<T>>;
	onChange?: (e: DropdownChangeEvent) => void;
	isLoading?: boolean;
} & DropdownProps;

export type ConfigDropdownOptions<T> = {
	label: string;
	value: T;
};

export function ConfigDropdown<OptionType>(props: Props<OptionType>) {
	const options = props.configOptions?.map((e) => {
		return {
			// @ts-expect-error
			label: `${CONFIGS_TRANSLATIONS[e.value]} ${e.label !== e.value ? e.label : ""}`,
			value: e.value,
		};
	});

	return (
		<StyledConfigDropdown
			{...props}
			className={"w-full"}
			options={options}
			optionLabel="label"
			optionValue="value"
			onChange={(e: DropdownChangeEvent) => {
				props.onChange!(e);
			}}
			placeholder="auswählen..."
			disabled={props.isPresentational || props.isLoading}
		/>
	);
}
