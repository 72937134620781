import React from "react";
import CardImage from "@assets/payment-method/card.png";
import GiropayImage from "@assets/payment-method/giropay.png";
import InvoiceImage from "@assets/payment-method/invoice.png";
import PaypalImage from "@assets/payment-method/paypal.png";
import SepaImage from "@assets/payment-method/sepa.png";
import SofortImage from "@assets/payment-method/sofort.png";
import { type PaymentMethodDisplayProps } from "./payment-method-display.interface";
import { PaymentMethodIcon, PaymentMethodImage } from "./payment-method-display.styles";
import { PaymentMethod } from "../../../translations/PaymentMethod";

export const PaymentMethodDisplay = ({ paymentMethod }: PaymentMethodDisplayProps) => {
	let Image;
	switch (paymentMethod) {
		case "Card":
			Image = CardImage;
			break;
		case "Giropay":
			Image = GiropayImage;
			break;
		case "Paypal":
			Image = PaypalImage;
			break;
		case "Sofort":
			Image = SofortImage;
			break;
		case "MonthlyTk":
		case "MonthlyPartner":
		case "Sepa":
			Image = SepaImage;
			break;
		case "InvoiceTk":
		case "InvoicePartner":
			Image = InvoiceImage;
			break;
		case "IapGoogle":
		case "IapApple":
			Image = "IAP";
			break;
	}

	return (
		<div className="flex align-items-center m-1">
			{Image === "IAP" ? (
				<PaymentMethodIcon className="pi pi-shopping-cart" />
			) : (
				<PaymentMethodImage src={Image} alt={Image} />
			)}

			<div>{PaymentMethod[paymentMethod]}</div>
		</div>
	);
};
