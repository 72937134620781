import { Button } from "primereact/button";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "@routes/paths";

type OwnProps = {
	discountActionId: string;
	codeId: string;
};

export const EditCodeButton = ({ discountActionId, codeId }: OwnProps) => {
	const navigate = useNavigate();

	const handleOnClick = () => {
		navigate(
			Paths.discountActions.withId(discountActionId).edit.discountCode.withId(codeId).path,
		);
	};
	return <Button icon="pi pi-pencil" tooltip="Aktion editieren" onClick={handleOnClick} />;
};
