/**
 * @generated SignedSource<<26e1bd3d3452a0aaa63069b367e29075>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type academiesTagsTable_Refetch$variables = {
  after?: string | null;
  before?: string | null;
  first?: number | null;
  isClickable?: boolean | null;
  isTopic?: boolean | null;
  last?: number | null;
  name?: string | null;
};
export type academiesTagsTable_Refetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"academiesTagsTable_QueryFragment">;
};
export type academiesTagsTable_Refetch = {
  response: academiesTagsTable_Refetch$data;
  variables: academiesTagsTable_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "before"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isClickable"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "isTopic"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "last"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "before",
    "variableName": "before"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "isClickable",
    "variableName": "isClickable"
  },
  {
    "kind": "Variable",
    "name": "isTopic",
    "variableName": "isTopic"
  },
  {
    "kind": "Variable",
    "name": "last",
    "variableName": "last"
  },
  {
    "kind": "Variable",
    "name": "name",
    "variableName": "name"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeDeleted",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClickable",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTopic",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "AcademiesTagData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    (v5/*: any*/),
    (v6/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "academiesTagsTable_Refetch",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "academiesTagsTable_QueryFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "academiesTagsTable_Refetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "AcademiesTagAdminSchema",
            "kind": "LinkedField",
            "name": "AcademiesTag",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AcademiesRootTagsConnection",
                "kind": "LinkedField",
                "name": "RootTags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AcademiesRootTagsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AcademiesRootTag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v3/*: any*/),
                                  (v4/*: any*/),
                                  (v8/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AcademiesChildTagsConnection",
                                    "kind": "LinkedField",
                                    "name": "children",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "AcademiesChildTagsEdge",
                                        "kind": "LinkedField",
                                        "name": "edges",
                                        "plural": true,
                                        "selections": [
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "AcademiesChildTag",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                              (v2/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "AcademiesTagData",
                                                "kind": "LinkedField",
                                                "name": "data",
                                                "plural": false,
                                                "selections": [
                                                  (v5/*: any*/),
                                                  (v7/*: any*/),
                                                  (v6/*: any*/)
                                                ],
                                                "storageKey": null
                                              },
                                              (v4/*: any*/),
                                              {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "subId",
                                                "storageKey": null
                                              },
                                              (v3/*: any*/),
                                              {
                                                "kind": "TypeDiscriminator",
                                                "abstractKey": "__isAcademiesTag"
                                              }
                                            ],
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "type": "AcademiesRootTag",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  (v8/*: any*/)
                                ],
                                "type": "AcademiesChildTag",
                                "abstractKey": null
                              }
                            ],
                            "type": "AcademiesTag",
                            "abstractKey": "__isAcademiesTag"
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasPreviousPage",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "startCursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [
                  "name",
                  "isClickable",
                  "isTopic"
                ],
                "handle": "connection",
                "key": "academiesTagsTable_RootTags",
                "kind": "LinkedHandle",
                "name": "RootTags"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "918104a17edf9832b4a285d494620615",
    "id": null,
    "metadata": {},
    "name": "academiesTagsTable_Refetch",
    "operationKind": "query",
    "text": "query academiesTagsTable_Refetch(\n  $after: String\n  $before: String\n  $first: Int\n  $isClickable: Boolean\n  $isTopic: Boolean\n  $last: Int\n  $name: String\n) {\n  ...academiesTagsTable_QueryFragment_3LEmJA\n}\n\nfragment academiesTagsTable_AcademiesTagFragment on AcademiesTag {\n  __isAcademiesTag: __typename\n  id\n  ... on AcademiesRootTag {\n    kind\n    canBeDeleted\n    data {\n      name\n      isClickable\n      isTopic\n    }\n    children {\n      edges {\n        node {\n          id\n          data {\n            name\n            isTopic\n            isClickable\n          }\n          canBeDeleted\n          subId\n          kind\n          ...editAcademiesTagButton_AcademiesTagFragment\n        }\n      }\n    }\n  }\n  ...editAcademiesTagButton_AcademiesTagFragment\n}\n\nfragment academiesTagsTable_QueryFragment_3LEmJA on Query {\n  Admin {\n    AcademiesTag {\n      RootTags(first: $first, after: $after, last: $last, before: $before, name: $name, isClickable: $isClickable, isTopic: $isTopic) {\n        edges {\n          node {\n            ...academiesTagsTable_AcademiesTagFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n          hasPreviousPage\n          startCursor\n        }\n      }\n    }\n  }\n}\n\nfragment editAcademiesTagButton_AcademiesTagFragment on AcademiesTag {\n  __isAcademiesTag: __typename\n  id\n  ... on AcademiesRootTag {\n    data {\n      name\n      isTopic\n      isClickable\n    }\n  }\n  ... on AcademiesChildTag {\n    data {\n      name\n      isClickable\n      isTopic\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3fd8e1ce8c6892ba14b4bac1bccab6b5";

export default node;
