import {
	BusinessTypeOptions,
	type BusinessTypeOptionsRecord,
} from "./business-type-dropdown.types";

// TODO: add-translations
export const getBusinessTypeOptions = () => {
	const translations: BusinessTypeOptionsRecord = {
		[BusinessTypeOptions.Ag]: "AG",
		[BusinessTypeOptions.Ev]: "Ev",
		[BusinessTypeOptions.Einzelunternehmen]: "Einzelunternehmen",
		[BusinessTypeOptions.Gmbh]: "GmbH",
		[BusinessTypeOptions.Gbr]: "GbR",
		[BusinessTypeOptions.Kg]: "KG",
		[BusinessTypeOptions.Ohg]: "OHG",
		[BusinessTypeOptions.Ug]: "UG",
		[BusinessTypeOptions.OeffentlicheEinrichtung]: "Öffentliche Einrichtung",
		[BusinessTypeOptions.GmbhCoKg]: "GmbH & Co. KG",
		[BusinessTypeOptions.PartmbH]: "Partnerschaftsgesellschaft mit beschränkter Berufshaftung",
		Unknown: "Unbekannt",
	};
	return Object.keys(translations)
		.sort()
		.map((key) => ({
			value: key,
			label: translations[key as unknown as BusinessTypeOptions],
		}));
};

export const getAvailableBusinessType = (companyType?: string): BusinessTypeOptions | undefined => {
	if (
		companyType &&
		Object.values(BusinessTypeOptions).includes(companyType as BusinessTypeOptions)
	)
		return companyType as BusinessTypeOptions;
	return undefined;
};
