import graphql from "babel-plugin-relay/macro";
import React from "react";
import { useFragment } from "react-relay";
import { type ActionActionColumn_DiscountActionFragment$key } from "@relay/ActionActionColumn_DiscountActionFragment.graphql";
import { DeleteDiscountActionButton } from "./DeleteDiscountActionButton";
import { EditDiscountActionButton } from "./EditDiscountActionButton";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment ActionActionColumn_DiscountActionFragment on DiscountAction {
		id
	}
`;

type OwnProps = {
	connectionId: string;
	discountActionFragmentRef: ActionActionColumn_DiscountActionFragment$key;
};

export const ActionActionColumn = ({ discountActionFragmentRef, connectionId }: OwnProps) => {
	const discountAction = useFragment<ActionActionColumn_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);

	return (
		<div>
			<EditDiscountActionButton id={discountAction.id} />
			<DeleteDiscountActionButton
				id={discountAction.id}
				className="ml-2"
				connectionId={connectionId}
			/>
		</div>
	);
};
