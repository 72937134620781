import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useFragment, useMutation } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type editAnswersMatrixElementCellDialogForm_AddWrongAnswerInMatrixElementCellMutation } from "@relay/editAnswersMatrixElementCellDialogForm_AddWrongAnswerInMatrixElementCellMutation.graphql";
import { type editAnswersMatrixElementCellDialogForm_SetCorrectAnswerInMatrixElementCellMutation } from "@relay/editAnswersMatrixElementCellDialogForm_SetCorrectAnswerInMatrixElementCellMutation.graphql";
import { addEditedFormToEditedFormsArray, resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import {
	ADD_WRONG_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION,
	ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT,
	SET_CORRECT_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION,
} from "./edit-answers-matrix-element-cell-dialog-form.graphql";
import {
	type EditAnswersMatrixElementCellDialogFormFormState,
	type EditAnswersMatrixElementCellDialogFormProps,
} from "./edit-answers-matrix-element-cell-dialog-form.types";
import { EditWrongAnswerInMatrixElementCellForm } from "../edit-wrong-answer-in-matrix-element-cell-form";

export const EditAnswersMatrixElementCellDialogForm = ({
	answersMatrixElementCellFragmentRef,
	matrixElementId,
	onBack,
}: EditAnswersMatrixElementCellDialogFormProps) => {
	const answerCell = useFragment(
		ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT,
		answersMatrixElementCellFragmentRef,
	);
	const [setCorrectAnswerInMatrixElementCell, isSettingCorrectAnswerInMatrixElementCell] =
		useMutation<editAnswersMatrixElementCellDialogForm_SetCorrectAnswerInMatrixElementCellMutation>(
			SET_CORRECT_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION,
		);
	const [addWrongAnswerInMatrixElementCell] =
		useMutation<editAnswersMatrixElementCellDialogForm_AddWrongAnswerInMatrixElementCellMutation>(
			ADD_WRONG_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION,
		);

	const dispatch = useTypedDispatch();
	const formId = "MatrixElementForm";
	const formik = useFormik<EditAnswersMatrixElementCellDialogFormFormState>({
		initialValues: {
			correctAnswerContent: answerCell.correctAnswer.content,
			wrongAnswersContent: answerCell.wrongAnswers.map((a) => a.content),
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			setCorrectAnswerInMatrixElementCell({
				variables: {
					input: {
						matrixElementId,
						newCorrectAnswerContent: values.correctAnswerContent,
						matrixCellId: answerCell.id,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});
	return (
		<>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditAnswersMatrixElementCellDialogFormFormState, string>
					name={"correctAnswerContent"}
					label={"Richtige Antwort"}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={
						Object.entries(formik.touched).length === 0 ||
						isSettingCorrectAnswerInMatrixElementCell
					}
					type="submit"
					label="Korrekte Antwort Speichern"
					className="mb-4"
				/>
			</form>
			<Card>
				<Button
					type="button"
					className="mb-2"
					label={"Neue Antwortmöglichkeit"}
					onClick={() => {
						addWrongAnswerInMatrixElementCell({
							variables: {
								input: {
									matrixElementId,
									matrixCellId: answerCell.id,
									wrongAnswerContent: "Falsche Antwort",
								},
							},
						});
					}}
				/>

				<div className="m-2">Falsche Antwortmöglichkeiten</div>
				<Card>
					{answerCell.wrongAnswers.map((answer) => {
						return (
							<EditWrongAnswerInMatrixElementCellForm
								key={answer.id}
								answerMatrixElementCellFragmentRef={answerCell}
								matrixElementId={matrixElementId}
								content={answer.content}
								answerId={answer.id}
							/>
						);
					})}
				</Card>
			</Card>

			<Button
				type="button"
				onClick={onBack}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</>
	);
};
