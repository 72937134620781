import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";

import { useNavigate } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type accountsTable_AnonymizeAccountAdminMutation } from "@relay/accountsTable_AnonymizeAccountAdminMutation.graphql";
import { type accountsTable_Query } from "@relay/accountsTable_Query.graphql";
import { type AccountsTable_Refetch } from "@relay/AccountsTable_Refetch.graphql";
import { type accountsTable_UsersListFragment$key } from "@relay/accountsTable_UsersListFragment.graphql";
import { Paths } from "@routes/paths";
import {
	QUERY,
	ACCOUNTS_LIST_FRAGMENT,
	ANONYMIZE_ACCOUNT_MUTATION,
} from "./accounts-table.graphql";
import { DataTable } from "../../../../components/data-table";
import { AccountsTableFilter } from "../accounts-table-filter";

export const AccountsTable = () => {
	const data = useLazyLoadQuery<accountsTable_Query>(
		QUERY,
		{ first: 20 },
		{ fetchPolicy: "network-only" },
	);

	const {
		hasNext,
		loadNext,
		data: {
			Admin: {
				Auth: { Accounts },
			},
		},
		refetch,
	} = usePaginationFragment<AccountsTable_Refetch, accountsTable_UsersListFragment$key>(
		ACCOUNTS_LIST_FRAGMENT,
		data,
	);

	const [anonymizeAccount] = useMutation<accountsTable_AnonymizeAccountAdminMutation>(
		ANONYMIZE_ACCOUNT_MUTATION,
	);

	const navigate = useNavigate();

	const accounts = Accounts.edges;

	const handleDeleteAccountOnClick = (id: string) => {
		confirmDialog({
			header: "Konto entfernen",
			message: "Bist du sicher, dass du dieses Konto entfernen möchtest?",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				anonymizeAccount({
					variables: {
						input: {
							id,
						},
						connections: [Accounts.__id] || [],
					},
				}),
			reject: () => {},
		});
	};

	const canModify = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	return (
		<>
			<div className="mb-5">
				<DataTable
					emptyMessage={
						<div className="flex justify-content-center align-items-center">
							<div className="mr-2">Es wurden keine Konten gefunden.</div>
						</div>
					}
					header={
						<AccountsTableFilter
							refetch={(name?: string) =>
								refetch({
									name,
								})
							}
						/>
					}
					className="mb-3"
					value={accounts?.map((edge) => edge!.node!)}
				>
					{(Column) => (
						<>
							<Column header="Id" field="id" />
							<Column header="Name" field="name" />
							<Column
								header="Berechtigungen"
								body={(row) => {
									return row?.groupAssociations?.map((groupAssociation: any) => {
										return (
											<div key={groupAssociation.group?.id} className="flex">
												<div>{groupAssociation.group?.name}</div>
											</div>
										);
									});
								}}
							/>
							{canModify && (
								<Column
									header="Aktionen"
									body={(account) => {
										return (
											<div>
												<Button
													onClick={() => {
														navigate(
															Paths.accounts.withId(account.id).edit
																.path,
														);
													}}
													icon={"pi pi-pencil"}
												/>
												<Button
													className="ml-2"
													onClick={() => {
														handleDeleteAccountOnClick(account.id);
													}}
													icon={"pi pi-trash"}
												/>
											</div>
										);
									}}
								/>
							)}
						</>
					)}
				</DataTable>
				{hasNext && (
					<div className="flex justify-content-center align-items-center">
						<Button
							type="button"
							className="p-button-secondary"
							disabled={!hasNext}
							onClick={() => loadNext(20)}
						>
							Mehr
						</Button>
					</div>
				)}
				<ConfirmDialog />
			</div>
		</>
	);
};
