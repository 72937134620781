import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { MessageDefinitionEditScreen } from "./message-definition-edit.screen";

export const MessageDefinitionEditRoutes: RouteDefinition[] = [
	{
		path: Paths.messageDefinitions.withIdPlaceholder().edit.path,
		element: <MessageDefinitionEditScreen />,
		requiredPermissions: ["UserInAccountPermission_MessageDefinitionAdmin_Modify"],
	},
];
