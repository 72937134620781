import graphql from "babel-plugin-relay/macro";

export const TASKS_FRAGMENT = graphql`
	fragment tasksTable_TasksFragment on Query
	@refetchable(queryName: "TasksTable_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			AsyncContent {
				GetAsyncElementTasks(first: $first, after: $after)
					@connection(key: "TasksTable_GetAsyncElementTasks") {
					__id
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							id
							status
							elementKind
							submissionInfo {
								by {
									name
								}
							}
							extension {
								... on AsyncElementTaskExtensionImpl {
									hierarchy {
										asyncContentTitle
										branchTitle
										rootTitle
									}
								}
							}
						}
					}
				}
			}
		}
	}
`;
