/**
 * @generated SignedSource<<9c0968aa60bbe9b1174a937607bd9d86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type DSAReportProblemType = "Accessibility" | "ConsumerProtection" | "Disinformation" | "IllegalContents" | "Other" | "PrivacyRequest" | "ProtectionOfMinors" | "TransparencyForOnlineAds" | "UserRightsAndComplaints";
export type DSAReportType = "Complaint" | "Feedback" | "Other" | "Report";
import { FragmentRefs } from "relay-runtime";
export type reportsTable_DSAReportInlineFragment$data = {
  readonly createdAt: string;
  readonly data: {
    readonly email: string | null;
    readonly extra: string | null;
    readonly firstName: string | null;
    readonly lastName: string | null;
    readonly problemType: DSAReportProblemType;
    readonly reportType: DSAReportType;
    readonly url: string | null;
  };
  readonly id: string;
  readonly referenceNumber: string;
  readonly " $fragmentType": "reportsTable_DSAReportInlineFragment";
};
export type reportsTable_DSAReportInlineFragment$key = {
  readonly " $data"?: reportsTable_DSAReportInlineFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"reportsTable_DSAReportInlineFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "reportsTable_DSAReportInlineFragment"
};

(node as any).hash = "6f1a9c3f9a310da49dfa238abb17235c";

export default node;
