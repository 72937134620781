/**
 * @generated SignedSource<<d5727d9aa352baa8e6d510df4ff4b852>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type removeDispatchConditionButton_DispatchConditionFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "removeDispatchConditionButton_DispatchConditionFragment";
};
export type removeDispatchConditionButton_DispatchConditionFragment$key = {
  readonly " $data"?: removeDispatchConditionButton_DispatchConditionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"removeDispatchConditionButton_DispatchConditionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "removeDispatchConditionButton_DispatchConditionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "DispatchCondition",
  "abstractKey": "__isDispatchCondition"
};

(node as any).hash = "05d8738f7ab5f01f3f7f9b2f5c4a696e";

export default node;
