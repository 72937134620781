import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import {
	FileUpload,
	type FileUploadErrorEvent,
	type FileUploadHeaderTemplateOptions,
} from "primereact/fileupload";
import { Suspense, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { usePaginationFragment, usePreloadedQuery, useQueryLoader } from "react-relay";
import { toast } from "react-toastify";
import { useDebounceFilters } from "@hooks/useDebounceFilters";
import { type filesTableV2_FilesListFragment$key } from "@relay/filesTableV2_FilesListFragment.graphql";
import { type filesTableV2_Query } from "@relay/filesTableV2_Query.graphql";
import { type FilesTableV2_Refetch } from "@relay/FilesTableV2_Refetch.graphql";
import { selectLoginData } from "@store/slices/AuthSlice";
import { FILES_FRAGMENT, QUERY } from "./files-table.graphql";
import { EmptyImageContainer, ShareableButton } from "./files-table.styles";
import {
	type FilesTableV2WithPreloadedQueryProps,
	type FilesTableV2Props,
} from "./files-table.types";
import { DateTimeDisplay } from "../../../components/DateTimeDisplay";
import { useFileUploadWithJwt } from "../../../hooks/useFileUploadWithJwt";
import { ERROR_MESSAGES } from "../../../translations/error-messages";

import { FileFilters } from "../file-filters/file-filters.component";
import { FileIsUsedInNodeAttachmentsDialog } from "../FileIsUsedInNodeAttachmentsDialog";
import { FileSearchContainer, type FilesFilters, FilesSearchContext } from "../FileSearchContainer";

const FilesTableComponent = ({
	selectionSettings,
	canUploadFiles,
	canDeleteFiles,
	queryRef,
}: FilesTableV2WithPreloadedQueryProps) => {
	const { filters } = useContext(FilesSearchContext);

	const data = usePreloadedQuery<filesTableV2_Query>(QUERY, queryRef);

	const {
		data: { Admin },
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
		refetch,
	} = usePaginationFragment<FilesTableV2_Refetch, filesTableV2_FilesListFragment$key>(
		FILES_FRAGMENT,
		data,
	);

	const filtersWithDefault = useMemo(() => {
		const filtersCopy = { ...filters };
		filtersCopy.filterByFileTypes = filters.filterByFileTypes?.length
			? filters.filterByFileTypes
			: selectionSettings?.fileTypeOptions?.map((type) => type.value);
		return filtersCopy;
	}, [filters]);

	useDebounceFilters<FilesFilters>(filtersWithDefault, refetch);

	const jwtTokenData = useSelector(selectLoginData);

	const files = Admin.Files.Files.edges;
	const [selectedFile, setSelectedFile] = useState("");
	const [showDialog, setShowDialog] = useState(false);

	const [shareable, setShareable] = useState(false);

	const { onBeforeSendWithJwt } = useFileUploadWithJwt({
		isFilesPublic: shareable,
		accessToken: jwtTokenData?.accessToken,
	});

	const customFileUploadHeader = (options: FileUploadHeaderTemplateOptions) => {
		const { className, chooseButton, uploadButton, cancelButton } = options;

		return (
			<div
				className={className}
				style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}
			>
				{chooseButton}

				<ShareableButton
					onClick={() => {
						setShareable(!shareable);
					}}
					sharable={shareable}
					label="Teilbar"
					icon="pi pi-share-alt"
				/>

				{uploadButton}
				{cancelButton}
			</div>
		);
	};

	return (
		<>
			{canUploadFiles && (
				<FileUpload
					className="mb-3"
					name="file[]"
					url={`${process.env.REACT_APP_API_BASE}/api/upload`}
					onUpload={() => refetch({}, { fetchPolicy: "network-only" })}
					onBeforeSend={onBeforeSendWithJwt}
					onError={(e: FileUploadErrorEvent) =>
						toast.error(ERROR_MESSAGES[e.xhr.response] ?? e.xhr.response)
					}
					multiple
					headerTemplate={customFileUploadHeader}
				/>
			)}
			<FileFilters fileTypeOptions={selectionSettings?.fileTypeOptions} />
			{showDialog && (
				<Suspense fallback={<div>Loading</div>}>
					<FileIsUsedInNodeAttachmentsDialog
						id={selectedFile}
						connection={Admin.Files.Files.__id}
						showDialog={showDialog}
						setShowDialog={setShowDialog}
					/>
				</Suspense>
			)}
			<DataTable
				emptyMessage={"Keine passenden Dateien"}
				className="mb-3"
				value={files?.map((b) => b!.node!) as any[]}
				onSelectionChange={
					selectionSettings ? selectionSettings.onSelectionChange : undefined
				}
				selection={selectionSettings ? selectionSettings.selection : undefined}
				selectionMode={selectionSettings ? "single" : undefined}
			>
				<Column selectionMode="single" style={{ width: "3em" }} />
				<Column
					header="Datei"
					body={(row) => (
						<a href={row.url} rel="noreferrer nofollow" target={"_blank"}>
							{row.thumbnail ? (
								<img height={75} src={row.thumbnail} alt={row.name} />
							) : (
								<EmptyImageContainer className="flex justify-content-center align-items-center">
									Keine Vorschau
								</EmptyImageContainer>
							)}
						</a>
					)}
				/>
				<Column header="Name" field="name" />
				<Column
					header="Zugriff"
					body={(row) => <p>{row.accessType === "signedUrl" ? "private" : "public"}</p>}
				/>
				<Column header="Typ" field="fileType" />
				<Column
					header="Erstellt am"
					body={(row) => <DateTimeDisplay value={row.uploadDateTime} />}
				/>
				{canDeleteFiles && (
					<Column
						header="Aktionen"
						style={{ width: "20%" }}
						body={(item) => (
							<Button
								type="button"
								onClick={() => {
									setSelectedFile(item.id);
									setShowDialog(true);
								}}
								icon={"pi pi-trash"}
							/>
						)}
					/>
				)}
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					type="button"
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					type="button"
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};

export const FilesTable = ({
	selectionSettings,
	filterByFileTypes,
	...props
}: FilesTableV2Props) => {
	const [queryReference, loadQuery] = useQueryLoader<filesTableV2_Query>(QUERY);

	useEffect(() => {
		loadQuery(
			{
				filterByFileTypes:
					selectionSettings?.fileTypeOptions?.map((o) => o.value) ?? filterByFileTypes,
			},
			{ fetchPolicy: "network-only" },
		);
	}, [loadQuery]);

	return (
		<Suspense fallback="Loading...">
			{queryReference && (
				<FileSearchContainer>
					<FilesTableComponent
						{...props}
						selectionSettings={selectionSettings}
						filterByFileTypes={filterByFileTypes}
						queryRef={queryReference}
					/>
				</FileSearchContainer>
			)}
		</Suspense>
	);
};
