import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type fileElementForm_EditFileElementMutation } from "@relay/fileElementForm_EditFileElementMutation.graphql";
import { stripHtml } from "src/util/html.utils";
import { EDIT_FILE_ELEMENT_MUTATION, FILE_ELEMENTV2_FRAGMENT } from "./file-element-form.graphql";

import { type FileElementFormProps, type FileElementFormState } from "./file-element-form.types";
import { ValidatedField, type RenderConfig } from "../../../../components/ValidatedField";
import { FileSelectionField, type FileV2 } from "../../../../features/files/file-selection-field";
import {
	resetArrayOfEditedForms,
	addEditedFormToEditedFormsArray,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";

export const FileElementForm = ({
	fileElementV2FragmentRef,
	onBack,
	fileTypeOptions,
}: FileElementFormProps) => {
	const fileElement = useFragment(FILE_ELEMENTV2_FRAGMENT, fileElementV2FragmentRef);

	const formId = "AcademiesFileElementForm";
	const [editFileElement, isEditingFileElement] =
		useMutation<fileElementForm_EditFileElementMutation>(EDIT_FILE_ELEMENT_MUTATION);
	const dispatch = useTypedDispatch();
	const formik = useFormik<FileElementFormState>({
		initialValues: {
			title: stripHtml(fileElement.title),
			file: { ...fileElement.file } as any as FileV2,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			file: Yup.object().required("Das Feld Datei wird benötigt."),
		}),
		onSubmit: (values) => {
			editFileElement({
				variables: {
					input: {
						fileElementId: fileElement.id,
						title: values.title,
						fileId: values.file?.id,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const canUpload = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);
	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FileElementFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>
			<ValidatedField<FileElementFormState, FileV2>
				name={"file"}
				label={"Datei"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({
					fieldName,
					fieldValue,
					updateField,
					onChange,
				}: RenderConfig<FileV2>) => {
					return (
						<FileSelectionField
							name={fieldName}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							onChange={onChange}
							fileTypeOptions={fileTypeOptions}
							canUploadFiles={canUpload}
							canDeleteFiles={canDelete}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingFileElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
