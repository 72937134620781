import { createContext, useState } from "react";
import { type ReportsTableFiltersFormState } from "@screens/reports/parts/reports-table-filters/reports-table-filters.types";

export type IReportsContext = {
	filters: ReportsTableFiltersFormState;
	setFilters: (filters: IReportsContext["filters"]) => void;
};
export const ReportsContext = createContext<IReportsContext>({
	filters: {
		filterByReferenceNumberOpt: undefined,
	},
	setFilters: () => {},
});
export const ReportsContextProvider = ({ children }: React.PropsWithChildren) => {
	const [filterByReferenceNumberOpt, setFilterByReferenceNumberOpt] = useState<
		string | undefined
	>("");
	const value: IReportsContext = {
		filters: {
			filterByReferenceNumberOpt,
		},
		setFilters: (filters) => {
			setFilterByReferenceNumberOpt(filters.filterByReferenceNumberOpt);
		},
	};
	return <ReportsContext.Provider value={value}>{children}</ReportsContext.Provider>;
};
