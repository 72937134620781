/**
 * @generated SignedSource<<a55efcb68e0b699b975169f69a99a892>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ClozeTextElementForm_ClozeTextElementFragment$data = {
  readonly additionalWords: ReadonlyArray<string>;
  readonly id: string;
  readonly text: string;
  readonly title: string;
  readonly " $fragmentType": "ClozeTextElementForm_ClozeTextElementFragment";
};
export type ClozeTextElementForm_ClozeTextElementFragment$key = {
  readonly " $data"?: ClozeTextElementForm_ClozeTextElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ClozeTextElementForm_ClozeTextElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClozeTextElementForm_ClozeTextElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "additionalWords",
      "storageKey": null
    }
  ],
  "type": "ClozeTextElementV2",
  "abstractKey": null
};

(node as any).hash = "6e796c0f65dabf90b52949903887c00f";

export default node;
