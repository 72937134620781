/**
 * @generated SignedSource<<a58578f441fbfcd898336f0bffbe1963>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PaymentMethodType = "Card" | "Giropay" | "IapApple" | "IapGoogle" | "InvoicePartner" | "InvoiceTk" | "Klarna" | "MonthlyPartner" | "MonthlyTk" | "Paypal" | "Sepa" | "Sofort";
export type SelectedPaymentMethodType = "EmployerInvoice" | "MonthlyPayment" | "OneTimePayment";
import { FragmentRefs } from "relay-runtime";
export type paymentDataForm_OrderFragment$data = {
  readonly allowedPaymentMethods: ReadonlyArray<PaymentMethodType>;
  readonly selectedPaymentMethod: {
    readonly paymentMethodId: PaymentMethodType;
    readonly selectedPaymentMethodType: SelectedPaymentMethodType;
  } | null;
  readonly " $fragmentType": "paymentDataForm_OrderFragment";
};
export type paymentDataForm_OrderFragment$key = {
  readonly " $data"?: paymentDataForm_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"paymentDataForm_OrderFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "paymentDataForm_OrderFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowedPaymentMethods",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "selectedPaymentMethod",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "paymentMethodId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedPaymentMethodType",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Order",
  "abstractKey": null
};

(node as any).hash = "7b513a309fe39db7f31d6395b8229e23";

export default node;
