import { type TeamSizeDropDownType } from "@components/team-size-dropdown/team-size-dropdown.types";

export const teamSizeOptions: TeamSizeDropDownType[] = [
	{
		value: "oneToTen",
		label: "1-10",
	},
	{
		value: "tenToTwentyFive",
		label: "10-25",
	},
	{
		value: "twentyFiveToFifty",
		label: "25-50",
	},
	{
		value: "fiftyToHundred",
		label: "50-100",
	},
	{
		value: "overHundred",
		label: "100+",
	},
];
