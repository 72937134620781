/**
 * @generated SignedSource<<436e1c5df7d062df3dc2abb221e643e0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ContentKind = "Async" | "ELearning";
export type ElementTypeV2 = "clozeText" | "enhancedText" | "file" | "markMistakes" | "matrix" | "multipleChoice" | "order" | "podcast" | "podcastWithTimestamp" | "text" | "video";
export type StructureType = "child" | "root";
export type TypeDefinitionType = "branch" | "content";
import { FragmentRefs } from "relay-runtime";
export type educationalOfferTree_TreeNodeFragment$data = {
  readonly hasBeenPublishedOnce: boolean;
  readonly id: string;
  readonly parentId: string | null;
  readonly structureDefinition: {
    readonly coordinates: {
      readonly parentRef: string | null;
    };
    readonly definitionType: StructureType;
    readonly title: string;
  };
  readonly typeDefinition: {
    readonly childRefs?: ReadonlyArray<string>;
    readonly contentKind?: ContentKind;
    readonly definitionType: TypeDefinitionType;
    readonly elements?: ReadonlyArray<{
      readonly elementType: ElementTypeV2;
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"ElementNode_ElementV2Fragment">;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"DeleteChildNodeButton_TreeNodeFragment" | "Node_TreeNodeFragment">;
  readonly " $fragmentType": "educationalOfferTree_TreeNodeFragment";
};
export type educationalOfferTree_TreeNodeFragment$key = {
  readonly " $data"?: educationalOfferTree_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"educationalOfferTree_TreeNodeFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "educationalOfferTree_TreeNodeFragment"
};

(node as any).hash = "db8cdc42b91aa7e3dc066e9702e3adb4";

export default node;
