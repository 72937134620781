import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation } from "@relay/removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation.graphql";
import { resetArrayOfEditedForms } from "@store/slices/CoreSlice";
import { useTypedDispatch } from "@store/store.redux";
import { REMOVE_ROW_FROM_MATRIX_ELEMENT_MUTATION } from "./remove-row-from-matrix-element-button.graphql";
import { type RemoveRowFromMatrixElementButtonProps } from "./remove-row-from-matrix-element-button.types";

export const RemoveRowFromMatrixElementButton = ({
	matrixElementId,
	idx,
}: RemoveRowFromMatrixElementButtonProps) => {
	const [removeRowFromMatrixElement] =
		useMutation<removeRowFromMatrixElementButton_RemoveRowFromMatrixElementMutation>(
			REMOVE_ROW_FROM_MATRIX_ELEMENT_MUTATION,
		);
	const dispatch = useTypedDispatch();

	return (
		<Button
			type="button"
			className="m-1"
			icon="pi pi-trash"
			onClick={() => {
				removeRowFromMatrixElement({
					variables: {
						input: {
							matrixElementId,
							idx,
						},
					},
					onCompleted: () => {
						dispatch(resetArrayOfEditedForms());
					},
				});
			}}
		/>
	);
};
