import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type externalLicensePoolEditScreen_ExternalLicensePoolQuery } from "@relay/externalLicensePoolEditScreen_ExternalLicensePoolQuery.graphql";
import { QUERY } from "./external-license-pool-edit.graphql";
import { EditExternalLicensePoolForm } from "./parts/edit-external-license-pool-form/edit-external-license-pool-form.component";
import { NoAccess } from "../../components/no-access";

export const ExternalLicensePoolEditScreen = () => {
	const { [`externalLicensePoolId`]: externalLicensePoolId } = useParams();

	const query = useLazyLoadQuery<externalLicensePoolEditScreen_ExternalLicensePoolQuery>(QUERY, {
		id: externalLicensePoolId!,
		skip: !externalLicensePoolId,
	});

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_ExternalLicenseAdmin_Read",
		"UserInAccountPermission_ExternalLicenseAdmin_Modify",
	]);

	if (!canReadAndModify) {
		return <NoAccess />;
	}

	return query.node ? (
		<Suspense fallback={<ProgressSpinner />}>
			<div className="p-4">
				<Card>
					<h2 className="mb-3 text-center">Externer Lizenz-Pool </h2>
					<EditExternalLicensePoolForm entityRef={query?.node} />
				</Card>
			</div>
		</Suspense>
	) : (
		<h4>Fehler beim Laden der Pools</h4>
	);
};
