import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Paths } from "@routes/paths";
import { type ViewExternalLicensePoolButtonProps } from "./view-external-license-pool-button.interface";

export const ViewExternalLicensePoolButton = ({
	externalLicensePoolId,
}: ViewExternalLicensePoolButtonProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon="pi pi-eye"
			tooltip="Pool-Details"
			onClick={() => {
				navigate(Paths.externalLicensePools.withId(externalLicensePoolId).path);
			}}
		/>
	);
};
