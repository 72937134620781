import React, {useContext} from "react";
import {ProductsScreenContext} from "@screens/products/products.context";
import {useFormik} from "formik";
import {ValidatedField} from "@components/ValidatedField";
import {DefaultTextFieldComponent, DefaultYesNoFieldComponent} from "@components/DefaultTextInput";
import {ProductsTableFiltersState} from "@screens/products/parts/products-table-filters/products-table-filters.types";
import {Button} from "@components/button";
import {ButtonType, EpicIcons} from "@thekeytechnology/epic-ui";
import {HeaderWrapper, Wrapper} from "@screens/products/products.styles";

export const ProductsTableFilters = () => {

    const {setFilters} = useContext(ProductsScreenContext);

    const initialValues: ProductsTableFiltersState = {
        isHiddenOpt: undefined,
        titleOpt: undefined,
    };

    const formik = useFormik<ProductsTableFiltersState>({
        initialValues,
        onSubmit: (values: ProductsTableFiltersState, {setSubmitting}) => {
            setFilters(values);
            setSubmitting(false);
        },
    });

    const clearForm = () => {
        formik.handleReset(initialValues);
        formik.handleSubmit();
    };

    return (
        <form onSubmit={formik.handleSubmit} className="p-fluid">
            <HeaderWrapper>
                <Wrapper>
                    <ValidatedField<ProductsTableFiltersState, boolean>
                        name="isHiddenOpt"
                        label="Ist Versteckt"
                        className="mr-2"
                        component={DefaultYesNoFieldComponent}
                        formikConfig={formik}
                    />
                    <ValidatedField<ProductsTableFiltersState, string>
                        name="titleOpt"
                        label="Name des Produkts"
                        className="mr-2"
                        component={DefaultTextFieldComponent}
                        formikConfig={formik}
                    />
                </Wrapper>
                <Wrapper>
                    <Button
                        disabled={false}
                        type={ButtonType.Reset}
                        onClick={clearForm}
                        icon={EpicIcons.TIMES}
                    />
                    <Button
                        disabled={false}
                        type={ButtonType.Submit}
                        icon={EpicIcons.SEARCH}
                    />
                </Wrapper>
            </HeaderWrapper>
        </form>
    );
};
