import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { useMutation } from "react-relay";
import { type addProductSelectionConditionButton_AddProductSelectionConditionMutation } from "@relay/addProductSelectionConditionButton_AddProductSelectionConditionMutation.graphql";
import { ADD_PRODUCT_SELECTION_CONDITION_MUTATION } from "./add-product-selection-condition-button.graphql";
import { type AddProductSelectionConditionButtonProps } from "./add-product-selection-condition-button.interface";
import { ProductSelectionForm } from "../../../../features/products/product-selection-form";
import { type ProductSelection } from "../../../../features/products/product-selection-form/product-selection-form.interface";

export const AddProductSelectionConditionButton = ({
	limitedCartDiscountId,
}: AddProductSelectionConditionButtonProps) => {
	const [showDialog, setIsShowingDialog] = useState(false);
	const [addProductSelectionCondition, isAddingProductSelectionCondition] =
		useMutation<addProductSelectionConditionButton_AddProductSelectionConditionMutation>(
			ADD_PRODUCT_SELECTION_CONDITION_MUTATION,
		);

	const handleOnClick = () => {
		setIsShowingDialog(true);
	};
	const handleOnHide = () => {
		setIsShowingDialog(false);
	};
	const handleOnBack = () => {
		setIsShowingDialog(false);
	};

	const handleOnSubmit = (values: ProductSelection) => {
		if (values.product.id) {
			addProductSelectionCondition({
				variables: {
					input: {
						limitedCartDiscountId,
						productId: values.product.id,
						amount: values.amount,
					},
				},
			});
		}
	};

	return (
		<>
			<Button
				icon="pi pi-box"
				tooltip="Produktanzahl Beschränkung"
				tooltipOptions={{ position: "bottom" }}
				onClick={handleOnClick}
			/>
			<Dialog
				header={<div>Produkt Bedingung</div>}
				visible={showDialog}
				onHide={handleOnHide}
			>
				<ProductSelectionForm
					onBack={handleOnBack}
					onSubmit={handleOnSubmit}
					isLoading={isAddingProductSelectionCondition}
					initialValues={{
						product: {
							id: "",
							title: "",
						},
						amount: 0,
					}}
				/>
			</Dialog>
		</>
	);
};
