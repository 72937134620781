import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import React, { useEffect, useState } from "react";
import { CurrencyDisplay } from "./CurrencyDisplay";
import {Price} from "@screens/product-edit/products-edit.types";

type OwnProps = {
	fieldValue: Price | undefined;
	onUpdate: (value: Price | undefined) => void;
};

export type PriceType = "gross" | "net";

type State = {
	price: number;
	priceType: PriceType;
	taxRate: number;
};

export const PriceField = ({ fieldValue, onUpdate }: OwnProps) => {
	const [price, setPrice] = useState<State>({
		priceType: "gross",
		price: fieldValue?.grossPrice || 0,
		taxRate: fieldValue?.taxRatePercentage || 0,
	});

	useEffect(() => {
		onUpdate({
			netPrice:
				price.priceType == "net"
					? price.price || 0
					: toNetPrice(price.price, price.taxRate),
			grossPrice:
				price.priceType == "gross"
					? price.price || 0
					: toGrossPrice(price.price, price.taxRate),
			taxRatePercentage: price.taxRate,
		});
	}, [price]);

	const options: Array<{ label: string; value: PriceType }> = [
		{
			label: "Brutto",
			value: "gross",
		},
		{
			label: "Netto",
			value: "net",
		},
	];

	return (
		<div className="flex align-items-center flex-wrap mt-3">
			<span className="p-float-label">
				<InputNumber
					inputId="taxrate"
					value={fieldValue?.taxRatePercentage}
					min={0}
					max={99}
					className="mr-2 w-5rem"
					suffix="%"
					onChange={(e) => {
						setPrice({ ...price, taxRate: e.value || 0 });
					}}
				/>
				<label htmlFor="taxrate">Steuersatz</label>
			</span>
			<span className="p-float-label">
				<InputNumber
					inputId="price"
					value={price.price}
					onChange={(e) => {
						setPrice({ ...price, price: e.value || 0 });
					}}
					className="w-10rem mr-2"
					mode="currency"
					currency="EUR"
					step={0.01}
					locale="de-DE"
				/>
				<label htmlFor="price">Preis</label>
			</span>
			<Dropdown
				options={options}
				value={price.priceType}
				onChange={(e) => {
					setPrice({ ...price, priceType: e.value });
				}}
				className="mr-2"
			/>

			<div>
				Daraus ergibt sich ein {options.find((e) => e.value !== price.priceType)?.label}{" "}
				Betrag von{" "}
				{price.priceType === "gross" ? (
					<CurrencyDisplay value={toNetPrice(price.price, price.taxRate)} />
				) : (
					<CurrencyDisplay value={toGrossPrice(price.price, price.taxRate)} />
				)}
			</div>
		</div>
	);
};

const toNetPrice = (grossPrice: number, taxRate: number): number =>
	roundWithPrecision(grossPrice / getTaxMultiplier(taxRate), 2);

const toGrossPrice = (netPrice: number, taxRate: number): number =>
	roundWithPrecision(netPrice * getTaxMultiplier(taxRate), 2);

const getTaxMultiplier = (taxRate: number): number => (100 + taxRate) / 100.0;

function roundWithPrecision(value: number, decimalPlaces: number): number {
	const power = Math.pow(10, decimalPlaces);
	return Math.ceil(value * power) / power;
}
