import {
	DefaultStyledFieldComponents,
	type StyledFieldComponents,
} from "@thekeytechnology/framework-react-components";
import styled from "styled-components";
import tw from "twin.macro";
import { accent, utilityBad } from "@corestyle/color";
import { heading2XsUppercase, paragraphSmSpan } from "@typography/index";

export const tkaAdminFieldStyles: StyledFieldComponents = {
	Field: {
		...DefaultStyledFieldComponents.Field,
		FieldContainer: styled.div``,
		LabelWrapper: tw.div` 
	tw-mb-8
`,
		StyledLabel: styled.label<{ hasError: boolean }>`
			${heading2XsUppercase.getCSS()}
		`,
		FieldWrapper: styled.div`
			${tw`tw-flex tw-flex-grow`}
		`,
		ErrorWrapper: styled(paragraphSmSpan)`
			color: ${utilityBad.rgbaValue()};
		`,
		HelpTextWrapper: styled(paragraphSmSpan)`
			${tw`tw-mt-8`}
		`,
		RequiredWrapper: styled.span`
			color: ${accent.rgbaValue()};
		`,
	},
};
