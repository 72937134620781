import {useContext} from "react";
import {useLazyLoadQuery} from "react-relay";
import {useHasPermissions} from "@hooks/use-has-permissions";
import {type productsScreen_Query} from "@relay/productsScreen_Query.graphql";
import {ProductsTable} from "./parts/products-table";
import {QUERY} from "./products.graphql";
import {NoAccess} from "@components/no-access";
import {ProductsScreenContext} from "@screens/products/products.context";
import {ProductsContextProvider} from "@screens/products/products.provider";
import {BaseScreen} from "@screens/BaseScreen";
import {CreateLicenseProductButton} from "@features/products/create-license-product-button";
import {ProductsTableFilters} from "@screens/products/parts/products-table-filters/products-table-filters.component";
import {Wrapper} from "@screens/products/products.styles";

export const ProductsScreen = () => {
    const {filters} = useContext(ProductsScreenContext);

    const query = useLazyLoadQuery<productsScreen_Query>(QUERY, {
            ...filters
        },
        {fetchPolicy: "network-only"}
    );

    const canRead = useHasPermissions(["UserInAccountPermission_ProductAdmin_Read"]);
    const canModify = useHasPermissions(["UserInAccountPermission_ProductAdmin_Modify"]);

    if (!canRead) return <NoAccess/>;

    return (
        <BaseScreen>
            <ProductsContextProvider>
                <h2 className="mb-3 text-center">Produkte</h2>
                <ProductsTableFilters/>
                {canModify && (
                    <Wrapper>
                        <CreateLicenseProductButton
                            className="ml-2 align-self-end"
                        />
                    </Wrapper>
                )}
                <ProductsTable productsQueryFragment={query}/>
            </ProductsContextProvider>
        </BaseScreen>
    );
};
