import { Paths } from "@routes/paths";
import { type RouteDefinition } from "../../routes/route-definition";
import { UserEditRoutes } from "../user-edit";
import { UsersScreen } from ".";

export const UsersRoutes: RouteDefinition[] = [
	{
		path: Paths.users.path,
		element: <UsersScreen />,
		requiredPermissions: ["UserInAccountPermission_AuthAdmin_Read"],
	},
	...UserEditRoutes,
];
