import { useMutation } from "react-relay";
import { useMessagePageButtonMutations } from "@hooks/use-message-page-button-mutations";
import {
	CREATE_IMAGE_BUTTON_PAGE_MUTATION,
	CREATE_IMAGE_PAGE_MUTATION,
	DELETE_PAGE_MUTATION,
	EDIT_IMAGE_BUTTON_PAGE_MUTATION,
	EDIT_IMAGE_PAGE_MUTATION,
	REARRANGE_PAGES_MUTATION,
} from "@hooks/use-message-page-mutations/use-message-page-mutations.graphql";
import { usePageMutationsProps } from "@hooks/use-message-page-mutations/use-message-page-mutations.types";
import { useMessagePageMutations_CreateImageButtonPageMutation } from "@relay/useMessagePageMutations_CreateImageButtonPageMutation.graphql";
import { useMessagePageMutations_CreateImagePageMutation } from "@relay/useMessagePageMutations_CreateImagePageMutation.graphql";
import { useMessagePageMutations_DeletePageMutation } from "@relay/useMessagePageMutations_DeletePageMutation.graphql";
import { useMessagePageMutations_EditImageButtonPageMutation } from "@relay/useMessagePageMutations_EditImageButtonPageMutation.graphql";
import { useMessagePageMutations_EditImagePageMutation } from "@relay/useMessagePageMutations_EditImagePageMutation.graphql";
import { useMessagePageMutations_RearrangePagesMutation } from "@relay/useMessagePageMutations_RearrangePagesMutation.graphql";
import { MessagePageFormState } from "@screens/message-definition-edit/parts/message-page-form/message-page-form.types";
import { PageButtonFormState } from "@screens/message-definition-edit/parts/page-button-form/page-button-form.types";

export const usePageMutations = ({
	messageDefinitionId,
	toast,
	refresh,
}: usePageMutationsProps) => {
	const { executeButtonMutation } = useMessagePageButtonMutations({ messageDefinitionId });

	const [createImagePage] = useMutation<useMessagePageMutations_CreateImagePageMutation>(
		CREATE_IMAGE_PAGE_MUTATION,
	);
	const [createImageWithButtonPage] =
		useMutation<useMessagePageMutations_CreateImageButtonPageMutation>(
			CREATE_IMAGE_BUTTON_PAGE_MUTATION,
		);
	const [editImagePage] =
		useMutation<useMessagePageMutations_EditImagePageMutation>(EDIT_IMAGE_PAGE_MUTATION);
	const [editImageWithButtonPage] =
		useMutation<useMessagePageMutations_EditImageButtonPageMutation>(
			EDIT_IMAGE_BUTTON_PAGE_MUTATION,
		);
	const [deletePage] =
		useMutation<useMessagePageMutations_DeletePageMutation>(DELETE_PAGE_MUTATION);
	const [rearrangePages] =
		useMutation<useMessagePageMutations_RearrangePagesMutation>(REARRANGE_PAGES_MUTATION);

	const onCompleted = (summary: string, detail: string) => {
		toast.current?.show({
			severity: "success",
			life: 3000,
			summary: summary || "Erfolg",
			detail: detail || "Die Aktion wurde erfolgreich abgeschlossen",
		});
		refresh();
	};

	const onError = (summary: string, detail: string) => {
		toast.current?.show({
			severity: "error",
			life: 3000,
			summary: summary || "Fehlgeschlagen",
			detail: detail || "Die Aktion konnte nicht abgeschlossen werden",
		});
	};

	const handleOnCreatePage = async (
		pageValues: MessagePageFormState,
		buttonValues?: PageButtonFormState,
	) => {
		if (!messageDefinitionId) return;
		switch (pageValues.kind) {
			case "image":
				createImagePage({
					variables: {
						input: {
							messageDefinitionId: messageDefinitionId,
							internalTitle: pageValues.internalTitle,
							image: pageValues.element.id!,
						},
					},
					onCompleted: () =>
						onCompleted("Seite erstellt", "Die Seite wurde erfolgreich erstellt"),
					onError: () =>
						onError("Seite nicht erstellt", "Die Seite konnte nicht erstellt werden"),
				});
				break;
			case "imageWithButton":
				createImageWithButtonPage({
					variables: {
						input: {
							messageDefinitionId: messageDefinitionId,
							internalTitle: pageValues.internalTitle,
							image: pageValues.element.id!,
						},
					},
					onCompleted: (response) => {
						const newPageId =
							response.Admin.MessageDefinitions.createImageWithButtonPage?.pageId ??
							"";
						if (!buttonValues) return;
						executeButtonMutation(
							buttonValues,
							newPageId,
							() =>
								onCompleted(
									"Seite erstellt",
									"Die Seite mit Button wurde erfolgreich erstellt",
								),
							() =>
								onError(
									"Seite nicht erstellt",
									"Die Seite mit Button konnte nicht erstellt werden",
								),
						);
					},
					onError: () =>
						onError(
							"Seite nicht erstellt",
							"Die Seite mit Button konnte nicht erstellt werden",
						),
				});
				break;
			default:
				break;
		}
	};

	const handleOnEditPage = async (
		pageValues: MessagePageFormState,
		pageId: string,
		buttonValues?: PageButtonFormState,
	) => {
		if (!messageDefinitionId || !pageId) return;
		switch (pageValues.kind) {
			case "image":
				editImagePage({
					variables: {
						input: {
							messageDefinitionId: messageDefinitionId,
							pageId: pageId,
							internalTitle: pageValues.internalTitle,
							image: pageValues.element.id!,
						},
					},
					onCompleted: () =>
						onCompleted("Seite bearbeitet", "Die Seite wurde erfolgreich bearbeitet"),
					onError: () =>
						onError(
							"Seite nicht bearbeitet",
							"Die Seite konnte nicht bearbeitet werden",
						),
				});
				break;
			case "imageWithButton":
				editImageWithButtonPage({
					variables: {
						input: {
							messageDefinitionId: messageDefinitionId,
							pageId: pageId,
							internalTitle: pageValues.internalTitle,
							image: pageValues.element.id!,
						},
					},
					onCompleted: () => {
						if (!buttonValues) return;

						executeButtonMutation(
							buttonValues,
							pageId,
							() =>
								onCompleted(
									"Seite bearbeitet",
									"Die Seite mit Button wurde erfolgreich bearbeitet",
								),
							() =>
								onError(
									"Seite nicht bearbeitet",
									"Die Seite mit Button konnte nicht bearbeitet werden",
								),
						);
					},
					onError: () =>
						onError(
							"Seite nicht bearbeitet",
							"Die Seite mit Button konnte nicht bearbeitet werden",
						),
				});
				break;
			default:
				break;
		}
	};

	const handleDeletePage = (pageId: string) => {
		if (!messageDefinitionId) return;
		deletePage({
			variables: {
				input: {
					messageDefinitionId: messageDefinitionId,
					pageId: pageId,
				},
			},
			onCompleted: () =>
				onCompleted("Seite gelöscht", "Die Seite wurde erfolgreich gelöscht"),
			onError: () =>
				onError("Seite nicht gelöscht", "Die Seite konnte nicht gelöscht werden"),
		});
	};

	const handleRearrangePages = (rearrangedPageIds: string[]) => {
		if (!messageDefinitionId) return;
		rearrangePages({
			variables: {
				input: {
					messageDefinitionId: messageDefinitionId,
					pageIds: rearrangedPageIds,
				},
			},
			onCompleted: () =>
				onCompleted(
					"Seiten-Reihenfolge gespeichert",
					"Die Seiten-Reihenfolge wurde erfolgreich gespeichert",
				),
			onError: () =>
				onError(
					"Seiten-Reihenfolge nicht gespeichert",
					"Die Seiten-Reihenfolge konnte nicht gespeichert werden",
				),
		});
	};

	return {
		handleOnCreatePage,
		handleOnEditPage,
		handleDeletePage,
		handleRearrangePages,
	};
};
