import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query nodePart_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			id
			... on TreeNode {
				id
				attachments {
					... on DefaultFileAttachment {
						file {
							id
						}
					}
				}
				structureDefinition {
					definitionType
					... on RootStructureDefinition {
						extension {
							...editRootExtensionForm_RootExtensionImplFragment
						}
					}
				}
				typeDefinition {
					definitionType
					... on ContentTypeDefinition {
						contentKind
						... on ELearningContentTypeDefinition {
							elements {
								id
							}
						}
						rewards {
							id
						}
					}
				}
				...AttachmentEditor_TreeNodeFragment
				...contentCard_TreeNodeFragment
				...contentConfigEditor_TreeNodeFragment
				...editNodeForm_TreeNodeFragment
				...EditRootNodePartForm_TreeNodeFragment
				...TreeConfigEditor_TreeNodeFragment
				...rewardEditor_TreeNodeFragment
			}
		}
		...editRootExtensionForm_QueryFragment
	}
`;
