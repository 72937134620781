/**
 * @generated SignedSource<<98e56950e2ba6d0d78b45d539312e5b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AsyncElementKind = "Upload";
export type taskEditScreen_Query$variables = {
  id: string;
  skip: boolean;
};
export type taskEditScreen_Query$data = {
  readonly node?: {
    readonly elementKind?: AsyncElementKind;
    readonly " $fragmentSpreads": FragmentRefs<"uploadTaskEditScreen_AsyncElementTaskFragment">;
  } | null;
};
export type taskEditScreen_Query = {
  response: taskEditScreen_Query$data;
  variables: taskEditScreen_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "elementKind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "File",
  "kind": "LinkedField",
  "name": "file",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "taskEditScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "uploadTaskEditScreen_AsyncElementTaskFragment"
                  },
                  (v2/*: any*/)
                ],
                "type": "AsyncElementTask",
                "abstractKey": "__isAsyncElementTask"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "taskEditScreen_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "status",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "type": "FreshUploadAsyncElementTask",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UploadEvaluation",
                        "kind": "LinkedField",
                        "name": "evaluation",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "EvaluatedUploadAsyncElementTask",
                    "abstractKey": null
                  }
                ],
                "type": "AsyncElementTask",
                "abstractKey": "__isAsyncElementTask"
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "6924d306e585cec84ccf5aa8a733c794",
    "id": null,
    "metadata": {},
    "name": "taskEditScreen_Query",
    "operationKind": "query",
    "text": "query taskEditScreen_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on AsyncElementTask {\n      __isAsyncElementTask: __typename\n      ...uploadTaskEditScreen_AsyncElementTaskFragment\n      elementKind\n    }\n    id\n  }\n}\n\nfragment uploadTaskEditScreen_AsyncElementTaskFragment on AsyncElementTask {\n  __isAsyncElementTask: __typename\n  id\n  status\n  ... on FreshUploadAsyncElementTask {\n    file {\n      id\n      url\n      name\n    }\n  }\n  ... on EvaluatedUploadAsyncElementTask {\n    file {\n      id\n      url\n      name\n    }\n    evaluation {\n      file {\n        id\n        url\n        name\n      }\n      text\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3f94a7da75eeac0f908d27d4e0853a1c";

export default node;
