/**
 * @generated SignedSource<<324b9ac6d6dcdc51604e83c55b0b4d51>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type emailTemplatesTable_PersonalizedEmailTemplatesFragment$data = {
  readonly id: string;
  readonly template: {
    readonly key: string;
    readonly subject: string;
  };
  readonly " $fragmentType": "emailTemplatesTable_PersonalizedEmailTemplatesFragment";
};
export type emailTemplatesTable_PersonalizedEmailTemplatesFragment$key = {
  readonly " $data"?: emailTemplatesTable_PersonalizedEmailTemplatesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"emailTemplatesTable_PersonalizedEmailTemplatesFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "emailTemplatesTable_PersonalizedEmailTemplatesFragment"
};

(node as any).hash = "0568ed49924ecc5b3f6fb51f0f5a213b";

export default node;
