/**
 * @generated SignedSource<<3bb5c8dbabc968d253defaed8ce8deeb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type licenseProductForm_LicenseProductFragment$data = {
  readonly costUnit: string | null;
  readonly id: string;
  readonly isHidden: boolean;
  readonly licenseDefinition: {
    readonly data: {
      readonly name: string;
    };
    readonly id: string;
  } | null;
  readonly licenseDefinitionAmount: number | null;
  readonly price: {
    readonly grossPrice: any;
    readonly netPrice: any;
    readonly taxRatePercentage: any;
  };
  readonly title: string;
  readonly " $fragmentType": "licenseProductForm_LicenseProductFragment";
};
export type licenseProductForm_LicenseProductFragment$key = {
  readonly " $data"?: licenseProductForm_LicenseProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"licenseProductForm_LicenseProductFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "licenseProductForm_LicenseProductFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "isHidden",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "netPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "grossPrice",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "taxRatePercentage",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "LicenseDefinition",
      "kind": "LinkedField",
      "name": "licenseDefinition",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "data",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "licenseDefinitionAmount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "costUnit",
      "storageKey": null
    }
  ],
  "type": "LicenseProduct",
  "abstractKey": null
};
})();

(node as any).hash = "b190cfa417ddc3fcd1c9c60db96bea45";

export default node;
