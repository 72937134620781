import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useCallback } from "react";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type enhancedTextElementForm_EditEnhancedTextElementMutation } from "@relay/enhancedTextElementForm_EditEnhancedTextElementMutation.graphql";
import { type enhancedTextElementForm_EnhancedTextElementFragment$key } from "@relay/enhancedTextElementForm_EnhancedTextElementFragment.graphql";
import {
	EDIT_ENHANCED_TEXT_ELEMENT_MUTATION,
	ENHANCED_TEXT_ELEMENT_FRAGMENT,
} from "./enhanced-text-element-form.graphql";
import {
	type EnhancedTextElementFormProps,
	type EnhancedTextElementFormState,
} from "./enhanced-text-element-form.types";
import { MemoizedRichTextEditor } from "../../../../components/rich-text-editor";
import { type RenderConfig, ValidatedField } from "../../../../components/ValidatedField";
import { type FileV2 } from "../../../../features/files/file-selection-field";
import { FileSelectionFieldWithUrl } from "../../../../features/files/file-selection-field-with-url";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";
import { stripHtml } from "../../../../util/html.utils";

export const EnhancedTextElementForm = ({
	enhancedTextElementFragmentRef,
	onBack,
}: EnhancedTextElementFormProps) => {
	const element = useFragment<enhancedTextElementForm_EnhancedTextElementFragment$key>(
		ENHANCED_TEXT_ELEMENT_FRAGMENT,
		enhancedTextElementFragmentRef,
	);
	const [editTextElement, isEditingTextElement] =
		useMutation<enhancedTextElementForm_EditEnhancedTextElementMutation>(
			EDIT_ENHANCED_TEXT_ELEMENT_MUTATION,
		);

	const dispatch = useTypedDispatch();
	const formId = "EnhancedTextElementForm";
	const formik = useFormik<EnhancedTextElementFormState>({
		enableReinitialize: false,
		initialValues: {
			title: stripHtml(element.title),
			text: element.text,
			readMore: element.readMore,
			readMoreButtonText: element.readMoreButtonText,
			illustration: undefined,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			text: Yup.string().required("Das Feld Text wird benötigt."),
		}),
		onSubmit: (values) => {
			editTextElement({
				variables: {
					input: {
						enhancedTextElementId: element.id,
						title: values.title,
						text: values.text,
						readMore: values.readMore,
						readMoreButtonText: values.readMoreButtonText,
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	const handleFormEdited = useCallback(() => {
		dispatch(addEditedFormToEditedFormsArray({ form: formId }));
	}, [dispatch]);

	const canUpload = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);

	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<EnhancedTextElementFormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>

			<ValidatedField<EnhancedTextElementFormState, string>
				name={"text"}
				label={"Text"}
				onChange={handleFormEdited}
				component={MemoizedRichTextEditor}
				formikConfig={formik}
			/>
			<ValidatedField<EnhancedTextElementFormState, string>
				name={"readMore"}
				label={"Weiterlesen"}
				component={MemoizedRichTextEditor}
				onChange={handleFormEdited}
				formikConfig={formik}
			/>
			<ValidatedField
				name={"readMoreButtonText"}
				label={"Text 'Mehr anzeigen' überschreiben."}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			></ValidatedField>
			<ValidatedField<EnhancedTextElementFormState, FileV2>
				name={"illustration"}
				label={
					"Illustration (wird nur für die generierung des Links verwendet, Bild erscheint nicht im Baustein)"
				}
				component={({
					fieldName,
					fieldValue,
					updateField,
					onChange,
				}: RenderConfig<FileV2>) => {
					return (
						<FileSelectionFieldWithUrl
							name={fieldName}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							onChange={onChange}
							canUploadFiles={canUpload}
							canDeleteFiles={canDelete}
						/>
					);
				}}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingTextElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
