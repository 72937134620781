import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import * as Yup from "yup";
import {
	type IssueExternalLicenseToUserFormState,
	type IssueExternalLicenseToUserProps,
} from "./issue-external-license-to-user-form.interface";
import { ValidatedField } from "../../../../components/ValidatedField";
import { SelectUserField } from "../../../../features/users/select-user-field";

export const IssueExternalLicenseToUserForm = ({
	onSubmitCallback,
	displayMaximizable,
	setDisplayMaximizable,
}: IssueExternalLicenseToUserProps) => {
	const formik = useFormik<IssueExternalLicenseToUserFormState>({
		enableReinitialize: false,
		initialValues: { issueTo: "" },
		onSubmit: (values, { setSubmitting }) => {
			onSubmitCallback(values);
			setSubmitting(false);
		},
		validationSchema: Yup.object().shape({
			issueTo: Yup.string().required("Ein Benutzer muss ausgewählt sein."),
		}),
	});

	return (
		<>
			<Dialog
				header={"Nutzer zuweisen"}
				visible={displayMaximizable}
				maximizable
				modal
				style={{ width: "50vw" }}
				onHide={() => {
					setDisplayMaximizable(false);
				}}
			>
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<IssueExternalLicenseToUserFormState, string>
						name={"issueTo"}
						component={SelectUserField}
						formikConfig={formik}
					/>

					<Button
						disabled={Object.entries(formik.touched).length === 0}
						type="submit"
						label="Speichern"
						className="mt-2"
					/>

					<Button
						type="button"
						onClick={() => {
							setDisplayMaximizable(false);
						}}
						label="Zurück"
						className="p-button-secondary mt-2"
					/>
				</form>
			</Dialog>
		</>
	);
};
