import graphql from "babel-plugin-relay/macro";

export const LIMITED_CART_DISCOUNT_FRAGMENT = graphql`
	fragment limitedCartDiscountForm_LimitedCartDiscountFragment on LimitedCartDiscount {
		id
		title
		conditions {
			kind
			... on ProductSelectionCondition {
				product {
					... on LicenseProduct {
						title
					}
				}
				id
				amount
				productId
				...limitedCartDiscountForm_ProductSelectionConditionFragment
			}
		}
		productSelectionConfig {
			... on PercentageProductSelectionConfig {
				value
			}
		}
	}
`;

export const ProductSelectionCondition = graphql`
	fragment limitedCartDiscountForm_ProductSelectionConditionFragment on ProductSelectionCondition
	@inline {
		id
		amount
		productId
		product {
			... on LicenseProduct {
				title
			}
		}
	}
`;

export const EDIT_LIMITED_CART_DISCOUNT_MUTATION = graphql`
	mutation limitedCartDiscountForm_EditLimitedCartDiscountMutation(
		$input: EditLimitedCartDiscountInput!
	) {
		Admin {
			Billing {
				editLimitedCartDiscount(input: $input) {
					limitedCartDiscount {
						...limitedCartDiscountForm_LimitedCartDiscountFragment
					}
				}
			}
		}
	}
`;

export const EDIT_PRODUCT_SELECTION_CONDITION_MUTATION = graphql`
	mutation limitedCartDiscountForm_EditProductSelectionConditionMutation(
		$input: EditProductSelectionConditionInput!
	) {
		Admin {
			Billing {
				editProductSelectionCondition(input: $input) {
					limitedCartDiscount {
						...limitedCartDiscountForm_LimitedCartDiscountFragment
					}
				}
			}
		}
	}
`;
