/**
 * @generated SignedSource<<9d4499e8e50cd3f9cc8bb197405587bf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeleteMessageDefinitionInput = {
  clientMutationId?: string | null;
  messageDefinitionId: string;
};
export type deleteMessageDefinitionButton_DeleteMessageDefinitionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: DeleteMessageDefinitionInput;
};
export type deleteMessageDefinitionButton_DeleteMessageDefinitionMutation$data = {
  readonly Admin: {
    readonly MessageDefinitions: {
      readonly deleteMessageDefinition: {
        readonly id: string;
      } | null;
    };
  };
};
export type deleteMessageDefinitionButton_DeleteMessageDefinitionMutation = {
  response: deleteMessageDefinitionButton_DeleteMessageDefinitionMutation$data;
  variables: deleteMessageDefinitionButton_DeleteMessageDefinitionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "deleteMessageDefinitionButton_DeleteMessageDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageDefinitionsAdminMutationSchema",
            "kind": "LinkedField",
            "name": "MessageDefinitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "deleteMessageDefinitionButton_DeleteMessageDefinitionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MessageDefinitionsAdminMutationSchema",
            "kind": "LinkedField",
            "name": "MessageDefinitions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "DeleteMessageDefinitionPayload",
                "kind": "LinkedField",
                "name": "deleteMessageDefinition",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "deleteEdge",
                    "key": "",
                    "kind": "ScalarHandle",
                    "name": "id",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4b477c6c3d1dd37aac524335c623be4f",
    "id": null,
    "metadata": {},
    "name": "deleteMessageDefinitionButton_DeleteMessageDefinitionMutation",
    "operationKind": "mutation",
    "text": "mutation deleteMessageDefinitionButton_DeleteMessageDefinitionMutation(\n  $input: DeleteMessageDefinitionInput!\n) {\n  Admin {\n    MessageDefinitions {\n      deleteMessageDefinition(input: $input) {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "71423cbeb24d5b44ea50121544694644";

export default node;
