import { Paths } from "@routes/paths";
import { type RouteDefinition } from "../../routes/route-definition";
import { LicensesScreen } from ".";

export const LicensesRoutes: RouteDefinition[] = [
	{
		path: Paths.licenses.path,
		element: <LicensesScreen />,
		requiredPermissions: ["UserInAccountPermission_LicenseAdmin_Read"],
	},
];
