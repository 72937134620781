import React from "react";
import { useFragment } from "react-relay";
import { type orderHistoryEvent_OrderHistoryFragment$key } from "@relay/orderHistoryEvent_OrderHistoryFragment.graphql";
import { ORDER_HISTORY_FRAGMENT } from "./order-history-event.graphql";
import { type OrderHistoryEventProps } from "./order-history-event.interface";
import { OrderHistoryEventDisplay } from "../order-history-event-display/order-history-event-display.component";

export const OrderHistoryEvent = ({ orderHistoryFragmentRef }: OrderHistoryEventProps) => {
	const orderHistory = useFragment<orderHistoryEvent_OrderHistoryFragment$key>(
		ORDER_HISTORY_FRAGMENT,
		orderHistoryFragmentRef,
	);

	let eventComponent: any;
	switch (orderHistory.event.kind) {
		case "OrderCreationUpdate":
			eventComponent = <OrderHistoryEventDisplay event={orderHistory.event.kind} />;
			break;
		case "CartUpdate":
			eventComponent = <OrderHistoryEventDisplay event={orderHistory.event.kind} />;
			break;
		case "BillingDetailsUpdate":
			eventComponent = eventComponent = (
				<OrderHistoryEventDisplay event={orderHistory.event.kind} />
			);
			break;
		case "PaymentMethodUpdate":
			eventComponent = eventComponent = (
				<OrderHistoryEventDisplay event={orderHistory.event.kind} />
			);
			break;
		case "PaymentInProcessUpdate":
			eventComponent = eventComponent = (
				<OrderHistoryEventDisplay event={orderHistory.event.kind} />
			);
			break;
		case "PurchaseUpdate":
			eventComponent = eventComponent = (
				<OrderHistoryEventDisplay event={orderHistory.event.kind} />
			);
			break;
		case "InvoiceSentUpdate":
			eventComponent = eventComponent = (
				<OrderHistoryEventDisplay event={orderHistory.event.kind}>
					<div className="grid">
						<div className="col-3">Rechnungs ID:</div>
						<div className="col">{orderHistory.event.invoiceId}</div>
					</div>
					<div className="grid">
						<div className="col-3">Rechnungsnummer:</div>
						<div className="col">{orderHistory.event.invoiceNumber}</div>
					</div>
				</OrderHistoryEventDisplay>
			);
			break;
		case "CreditNoteSentUpdate":
			eventComponent = eventComponent = (
				<OrderHistoryEventDisplay event={orderHistory.event.kind}>
					<div className="grid">
						<div className="col-3">Gutschrift ID:</div>
						<div className="col">{orderHistory.event.creditNoteId}</div>
					</div>
					<div className="grid">
						<div className="col-3">Gutschriftsnummer:</div>
						<div className="col">{orderHistory.event.creditNoteNumber}</div>
					</div>
				</OrderHistoryEventDisplay>
			);
			break;
		default:
			eventComponent = <div>Ereignis nicht implementiert</div>;
	}

	return <div>{eventComponent}</div>;
};
