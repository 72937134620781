import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { RESET_STAGING_BILLING_MUTATION } from "./reset-staging-billing-button.graphql";

export const ResetStagingBillingButton = () => {
	const [resetStagingBilling, isResettingStagingBilling] = useMutation(
		RESET_STAGING_BILLING_MUTATION,
	);

	const handleOnClick = () => {
		resetStagingBilling({
			variables: {},
			onCompleted: () => {
				toast.success("Staging-Billing zurückgesetzt");
			},
		});
	};

	return (
		<Button
			className={"mb-2"}
			label={"Billing zurücksetzen"}
			disabled={isResettingStagingBilling}
			onClick={handleOnClick}
		/>
	);
};
