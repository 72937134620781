import React from "react";
import { type IdDisplayFieldProps } from "./id-display-field.types";

export const IdDisplayField = ({ id, className }: IdDisplayFieldProps) => {
	const handleOnClick = async () => {
		if (id) {
			await navigator.clipboard.writeText(id);
		}
	};

	return (
		<div className={`cursor-pointer ${className}`} onClick={handleOnClick}>
			<b>
				ID<i className="pi pi-copy"></i>
			</b>
		</div>
	);
};
