import React from "react";
import { useFragment } from "react-relay";
import { type privateBillingDetailsForm_BillingDetailsFragment$key } from "@relay/privateBillingDetailsForm_BillingDetailsFragment.graphql";
import { PRIVATE_BILLING_DETAILS_FRAGMENT } from "./private-billing-details-form.graphql";
import { type PrivateBillingDetailsFormProps } from "./private-billing-details-form.interface";
import { formatDateTime } from "../../../../components/datetime-display/datetime-display.util";
import { TextDisplayField } from "../../../../components/text-display-field/text-display-field.component";

export const PrivateBillingDetailsForm = ({
	privateBillingDetailsFragmentRef,
}: PrivateBillingDetailsFormProps) => {
	const privateBillingDetails = useFragment<privateBillingDetailsForm_BillingDetailsFragment$key>(
		PRIVATE_BILLING_DETAILS_FRAGMENT,
		privateBillingDetailsFragmentRef,
	);

	return (
		<div>
			<div className="formgrid grid">
				<TextDisplayField label="Vorname" value={privateBillingDetails.firstName} />
				<TextDisplayField label="Nachname" value={privateBillingDetails.lastName} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Straße" value={privateBillingDetails.street} />
				<TextDisplayField label="Hausnummer" value={privateBillingDetails.houseNumber} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Stadt/Ort" value={privateBillingDetails.city} />
				<TextDisplayField label="Postleitzahl" value={privateBillingDetails.postalCode} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField label="Land" value={privateBillingDetails.country} />
				<TextDisplayField label="Anrede" value={privateBillingDetails.salutation} />
			</div>
			<div className="formgrid grid">
				<TextDisplayField
					label="Rechnungs E-Mail"
					value={privateBillingDetails.invoiceEmail}
				/>
				<TextDisplayField
					label="Geburtstag"
					value={formatDateTime(privateBillingDetails.dateOfBirth)}
				/>
			</div>
			{privateBillingDetails.invoiceAddress && (
				<div>
					<h4>Rechnungsadresse</h4>
					<div className="formgrid grid">
						<TextDisplayField
							label="Vorname"
							value={privateBillingDetails.invoiceAddress.firstName}
						/>
						<TextDisplayField
							label="Nachname"
							value={privateBillingDetails.invoiceAddress.lastName}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Straße"
							value={privateBillingDetails.invoiceAddress.street}
						/>
						<TextDisplayField
							label="Hausnummer"
							value={privateBillingDetails.invoiceAddress.houseNumber}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Stadt/Ort"
							value={privateBillingDetails.invoiceAddress.city}
						/>
						<TextDisplayField
							label="Postleitzahl"
							value={privateBillingDetails.invoiceAddress.postalCode}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Land"
							value={privateBillingDetails.invoiceAddress.country}
						/>
						<TextDisplayField
							label="Unternehmensname"
							value={privateBillingDetails.invoiceAddress.companyName}
						/>
					</div>
					<div className="formgrid grid">
						<TextDisplayField
							label="Unternehmensdetails"
							value={privateBillingDetails.invoiceAddress.companyDetails || undefined}
						/>
					</div>
				</div>
			)}
		</div>
	);
};
