/**
 * @generated SignedSource<<c0088390a32e336ce038d1d4f44e1fce>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RemoveRestartIfFailedContentConfigInput = {
  clientMutationId?: string | null;
  configId: string;
  contentNodeId: string;
};
export type RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation$variables = {
  input: RemoveRestartIfFailedContentConfigInput;
};
export type RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly removeRestartIfFailedContentConfig: {
        readonly contentNode: {
          readonly " $fragmentSpreads": FragmentRefs<"RestartIfFailedContentConfigEditor_TreeNodeFragment">;
        };
      } | null;
    };
  };
};
export type RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation = {
  response: RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation$data;
  variables: RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveRestartIfFailedContentConfigPayload",
                "kind": "LinkedField",
                "name": "removeRestartIfFailedContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "RestartIfFailedContentConfigEditor_TreeNodeFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "RemoveRestartIfFailedContentConfigPayload",
                "kind": "LinkedField",
                "name": "removeRestartIfFailedContentConfig",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "contentNode",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "typeDefinition",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "restartIfFailedConfigs",
                                "plural": true,
                                "selections": [
                                  (v3/*: any*/),
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "configType",
                                    "storageKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "daysToBlock",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "BlockTemporarilyRestartIfFailedContentConfig",
                                    "abstractKey": null
                                  },
                                  {
                                    "kind": "InlineFragment",
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "maximumTries",
                                        "storageKey": null
                                      }
                                    ],
                                    "type": "BlockPermanentlyRestartIfFailedContentConfig",
                                    "abstractKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "ContentTypeDefinition",
                            "abstractKey": "__isContentTypeDefinition"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "345266b4fbe746d328d9142cbfa0ee4f",
    "id": null,
    "metadata": {},
    "name": "RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation",
    "operationKind": "mutation",
    "text": "mutation RestartIfFailedContentConfigEditor_RemoveNonLogicalRestartIfFailedContentConfigMutation(\n  $input: RemoveRestartIfFailedContentConfigInput!\n) {\n  Admin {\n    Tree {\n      removeRestartIfFailedContentConfig(input: $input) {\n        contentNode {\n          ...RestartIfFailedContentConfigEditor_TreeNodeFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment on BlockPermanentlyRestartIfFailedContentConfig {\n  id\n  configType\n  maximumTries\n}\n\nfragment BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n\nfragment BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment on BlockTemporarilyRestartIfFailedContentConfig {\n  id\n  configType\n  daysToBlock\n}\n\nfragment BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment on TreeNode {\n  id\n}\n\nfragment RestartIfFailedContentConfigEditor_TreeNodeFragment on TreeNode {\n  id\n  typeDefinition {\n    __typename\n    ... on ContentTypeDefinition {\n      __isContentTypeDefinition: __typename\n      restartIfFailedConfigs {\n        __typename\n        id\n        configType\n        ... on BlockTemporarilyRestartIfFailedContentConfig {\n          daysToBlock\n        }\n        ... on BlockPermanentlyRestartIfFailedContentConfig {\n          maximumTries\n        }\n        ...BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment\n        ...BlockPermanentlyRestartIfFailedContentConfigForm_BlockPermanentlyRestartIfFailedContentConfigFragment\n      }\n    }\n  }\n  ...BlockTemporarilyRestartIfFailedContentConfigForm_TreeNodeFragment\n  ...BlockPermanentlyRestartIfFailedContentConfigForm_TreeNodeFragment\n}\n"
  }
};
})();

(node as any).hash = "16198bbe5284615d9ccdcc6cbf67e82e";

export default node;
