import * as Yup from "yup";
import { messagePageForm_PageFragment$key } from "@relay/messagePageForm_PageFragment.graphql";
import { PageButtonFormState } from "@screens/message-definition-edit/parts/page-button-form/page-button-form.types";

export const MessagePageFormSchema = Yup.object({
	internalTitle: Yup.string().required("Bitte gib einen Titel ein."),
	kind: Yup.string().required("Bitte gib eine Seiten-Art ein."),
	element: Yup.object({
		id: Yup.string().required("Bitte gib ein Bild ein."),
	}),
});
export type MessagePageFormState = Yup.InferType<typeof MessagePageFormSchema>;

export type MessagePageFormProps = {
	isCreating?: boolean;
	pageFragmentRef?: messagePageForm_PageFragment$key | null;
	onSubmit?: (pageValues: MessagePageFormState, buttonValues?: PageButtonFormState) => void;
};
