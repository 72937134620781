/**
 * @generated SignedSource<<0940e9f5760324648c4aa43446fb4812>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type inAppNotificationForm_InAppNotificationFragment$data = {
  readonly inAppNotification: {
    readonly content: string;
    readonly title: string;
  } | null;
  readonly " $fragmentType": "inAppNotificationForm_InAppNotificationFragment";
};
export type inAppNotificationForm_InAppNotificationFragment$key = {
  readonly " $data"?: inAppNotificationForm_InAppNotificationFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"inAppNotificationForm_InAppNotificationFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "inAppNotificationForm_InAppNotificationFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "InAppNotification",
      "kind": "LinkedField",
      "name": "inAppNotification",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "content",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "MessageDefinition",
  "abstractKey": null
};

(node as any).hash = "285e70dc2e95cfd6474497806849cf6f";

export default node;
