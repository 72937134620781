import _, { isArray } from "lodash";
import { Button } from "primereact/button";
import { Menubar } from "primereact/menubar";
import { type MenuItem, type MenuItemOptions } from "primereact/menuitem";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLazyLoadQuery } from "react-relay";
import { BrowserRouter, Navigate, NavLink, Route, Routes } from "react-router-dom";
import { EnvironmentBadge } from "@components/environment-badge";
import { useCheckPermissions } from "@hooks/use-check-permissions";
import {
	type Permission,
	type permissionBasedNavigation_Query,
} from "@relay/permissionBasedNavigation_Query.graphql";
import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { AppRoutes } from "@routes/routes";
import { HomeScreen } from "@screens/home";
import { LoginScreen } from "@screens/login/login.screen";
import { logout } from "@store/slices/AuthSlice";
import { type CurrentUserData, setCurrentUser } from "@store/slices/CurrentUserSlice";
import { PERMISSION_QUERY } from "./permission-based-navigation.graphql";

export function PermissionBasedNavigation() {
	const query = useLazyLoadQuery<permissionBasedNavigation_Query>(PERMISSION_QUERY, {});
	const dispatch = useDispatch();

	const checkPermissions = useCheckPermissions();

	useEffect(() => {
		if (query.Viewer.Auth.currentUser) {
			dispatch(setCurrentUser(query.Viewer.Auth.currentUser as CurrentUserData));
		}
		// eslint-disable-next-line
	}, [query]);

	const routes = [
		...AppRoutes,
		{
			path: "/",
			element: <HomeScreen />,
			requiredPermissions: "logged-in",
		} satisfies RouteDefinition,
		{
			path: "*",
			element: <LoginScreen />,
			requiredPermissions: "only-logged-out",
		} satisfies RouteDefinition,
	];
	const MenuTemplate = (item: MenuItem, options: MenuItemOptions) => (
		<NavLink className={options.className} target={item.target} to={item.url!}>
			{item.label}
		</NavLink>
	);

	const items: MenuItem[] = [
		{
			label: "Hauptseite",
			url: "/",
			template: MenuTemplate,
		},
	];

	if (checkPermissions(["UserInAccountPermission_Nodes_ReadNodes"])) {
		items.push({
			label: "Angebote",
			url: Paths.offers.path,
			template: MenuTemplate,
		});
	}

	const canViewProducts = checkPermissions(["UserInAccountPermission_ProductAdmin_Read"]);
	const canViewOrders = checkPermissions(["UserInAccountPermission_OrderAdmin_Read"]);
	const canViewDiscounts = checkPermissions(["UserInAccountPermission_DiscountAdmin_Read"]);
	const canViewGenerateCartLink = checkPermissions([
		"UserInAccountPermission_GenerateCartLinkAdmin_Modify",
	]);
	const canViewLimitedCartDiscounts = checkPermissions([
		"UserInAccountPermission_LimitedCartDiscountAdmin_Read",
	]);
	const canViewLicenses = checkPermissions(["UserInAccountPermission_LicenseAdmin_Read"]);
	const canViewExternalLicenses = checkPermissions([
		"UserInAccountPermission_ExternalLicenseAdmin_Read",
	]);

	const billingItems: MenuItem[] = [];

	if (canViewProducts) {
		billingItems.push({
			label: "Produkte",
			url: Paths.products.path,
			template: MenuTemplate,
		});
	}
	if (canViewLimitedCartDiscounts) {
		billingItems.push({
			label: "Warenkorb-Rabatt",
			url: Paths.cartDiscounts.path,
			template: MenuTemplate,
		});
	}

	if (canViewGenerateCartLink) {
		billingItems.push({
			label: "Warenkorblink Generator",
			url: Paths.generateCartLink.path,
			template: MenuTemplate,
		});
	}

	if (canViewOrders) {
		billingItems.push({
			label: "Bestellungen",
			url: Paths.orders.path,
			template: MenuTemplate,
		});
	}

	if (canViewDiscounts) {
		billingItems.push({
			label: "Rabattaktionen",
			url: Paths.discountActions.path,
			template: MenuTemplate,
		});
	}

	if (canViewLicenses) {
		billingItems.push({
			label: "Lizenzdefinitionen",
			url: Paths.licenseDefinitions.path,
			template: MenuTemplate,
		});

		billingItems.push({
			label: "Lizenzen",
			url: Paths.licenses.path,
			template: MenuTemplate,
		});
	}

	if (canViewExternalLicenses) {
		billingItems.push({
			label: "Externe Lizenz-Pools",
			url: Paths.externalLicensePools.path,
			template: MenuTemplate,
		});
	}

	if (billingItems.length > 0) {
		items.push({
			label: "Billing",
			items: billingItems,
		});
	}

	if (
		checkPermissions([
			"UserInAccountPermission_TagAdmin_Read",
			"UserInAccountPermission_TagAdmin_Modify",
		])
	) {
		items.push({
			label: "Schlagworte",
			url: Paths.tags.path,
			template: MenuTemplate,
		});
	}

	if (checkPermissions(["UserInAccountPermission_Instructors_ReadInstructors"])) {
		items.push({
			label: "Trainer:innen",
			url: Paths.instructors.path,
			template: MenuTemplate,
		});
	}

	if (checkPermissions(["UserInAccountPermission_Email_Templates"])) {
		items.push({
			label: "Kommunikation",
			items: [
				{
					label: "Versendete Nachrichten",
					url: Paths.messagesDispatches.path,
					template: MenuTemplate,
				},
				{
					label: "Nachrichten-Vorlagen",
					url: Paths.messageDefinitions.path,
					template: MenuTemplate,
				},
				{
					label: "E-Mail-Vorlagen",
					url: Paths.emailTemplates.path,
					template: MenuTemplate,
				},
			],
		});
	}

	if (checkPermissions(["UserInAccountPermission_AuthAdmin_Read"])) {
		items.push({
			label: "Kunden",
			items: [
				{
					label: "Konten",
					url: Paths.accounts.path,
					template: MenuTemplate,
				},
				{
					label: "Benutzer",
					url: Paths.users.path,
					template: MenuTemplate,
				},
			],
		});
	}

	if (checkPermissions(["UserInAccountPermission_AsyncElementTaskAdmin_Read"])) {
		items.push({
			label: "Aufgaben",
			url: Paths.tasks.path,
			template: MenuTemplate,
		});
	}

	if (checkPermissions("onlyOwnerOfRoot")) {
		items.push({
			label: "Dateien",
			url: Paths.files.path,
			template: MenuTemplate,
		});
	}

	if (
		["staging", "dev"].includes(process.env.REACT_APP_APP_ENVIRONMENT || "") &&
		checkPermissions("onlyOwnerOfRoot")
	) {
		items.push({
			label: "Staging",
			url: Paths.staging.path,
			template: MenuTemplate,
		});
	}
	if (checkPermissions(["UserInAccountPermission_ReportAdmin_Read"])) {
		items.push({
			label: "Meldungen",
			url: Paths.reports.path,
			template: MenuTemplate,
		});
	}
	if (checkPermissions("onlyOwnerOfRoot")) {
		items.push({
			label: "Einstellungen",
			url: Paths.settings.path,
			template: MenuTemplate,
		});
	}

	const handleOnClick = () => dispatch(logout());

	return (
		<BrowserRouter>
			<Menubar
				model={items}
				end={
					<Button
						label="Ausloggen"
						onClick={handleOnClick}
						className="mr-2"
						icon="pi pi-power-off"
					>
						<EnvironmentBadge />
					</Button>
				}
			/>
			<Routes>
				{filterByPermission(routes, !!query.Viewer.Auth.currentUser, [
					...(query.Viewer.Auth.currentUser?.permissionsInAccount ?? []),
				]).map((prd) => {
					return <Route key={prd.path} path={prd.path} element={prd.element} />;
				})}

				<Route key={"verboten"} path={"*"} element={<Navigate replace to="/" />} />
			</Routes>
		</BrowserRouter>
	);
}

export type RequiredPermissionsType =
	| Permission[]
	| "logged-in"
	| "logged-in-and-logged-out"
	| "only-logged-out"
	| "onlyOwnerOfRoot";
export type HasRequiredPermissionsType = {
	requiredPermissions: RequiredPermissionsType;
};

export function filterByPermission<ItemType extends HasRequiredPermissionsType>(
	items: ItemType[],
	isLoggedIn: boolean,
	permissionsInAccount?: Permission[],
) {
	return items.filter((r) => {
		const loggedOutAndRouteRequiresLoggedOut =
			r.requiredPermissions === "only-logged-out" && !isLoggedIn;
		const loggedInAndRouteOnlyRequiredLoggedIn =
			r.requiredPermissions === "logged-in" && isLoggedIn;
		const loggedInOrLoggedOut = r.requiredPermissions === "logged-in-and-logged-out";

		const permissionsAreKnown = permissionsInAccount && isArray(r.requiredPermissions);
		const isRoot = permissionsInAccount?.includes("AccountPermission_System_Root");
		const noRootPermissionRequiredAndUserIsOwner =
			!r.requiredPermissions.includes("AccountPermission_System_Root") &&
			permissionsInAccount?.includes("AccountPermission_System_Root");
		const isLoggedInAndHasAllNecessaryPermissions =
			permissionsAreKnown &&
			(_.difference(r.requiredPermissions, permissionsInAccount).length === 0 ||
				isRoot ||
				noRootPermissionRequiredAndUserIsOwner);

		if (r.requiredPermissions === "onlyOwnerOfRoot") return isRoot;

		return (
			loggedInOrLoggedOut ||
			loggedOutAndRouteRequiresLoggedOut ||
			loggedInAndRouteOnlyRequiredLoggedIn ||
			isLoggedInAndHasAllNecessaryPermissions
		);
	});
}
