import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { useLazyLoadQuery, useMutation, usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type usersTable_AnonymizeUserMutation } from "@relay/usersTable_AnonymizeUserMutation.graphql";
import { type usersTable_Query } from "@relay/usersTable_Query.graphql";
import { type UsersTable_Refetch } from "@relay/UsersTable_Refetch.graphql";
import { type usersTable_UsersListFragment$key } from "@relay/usersTable_UsersListFragment.graphql";
import { Paths } from "@routes/paths";
import { ANONYMIZE_USER_MUTATION, QUERY, USERS_LIST_FRAGMENT } from "./users-table.graphql";
import { DataTable } from "../../../components/data-table";
import { UsersTableFilter } from "../users-table-filter";

export const UsersTable = () => {
	const data = useLazyLoadQuery<usersTable_Query>(
		QUERY,
		{ first: 20 },
		{ fetchPolicy: "network-only" },
	);

	const {
		hasNext,
		loadNext,
		data: {
			Admin: {
				Auth: { Users },
			},
		},
		refetch,
	} = usePaginationFragment<UsersTable_Refetch, usersTable_UsersListFragment$key>(
		USERS_LIST_FRAGMENT,
		data,
	);

	const [anonymizeUser] = useMutation<usersTable_AnonymizeUserMutation>(ANONYMIZE_USER_MUTATION);

	const navigate = useNavigate();

	const users = Users?.edges;

	const handleEditUserOnClick = (id: string) => {
		navigate(Paths.users.withId(id).edit.path);
	};

	const handleDeleteUserOnClick = (userId: string) => {
		confirmDialog({
			message: "Bist du sicher das du diesen Benutzer entfernen möchtest?",
			header: "Benutzer entfernen",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				anonymizeUser({
					variables: {
						input: {
							userId,
						},
						connections: [Users.__id] || [],
					},
				}),
			reject: () => {},
		});
	};

	const handleLoadNextOnClick = () => {
		loadNext(20);
	};

	const canModify = useHasPermissions(["UserInAccountPermission_AuthAdmin_Modify"]);

	return (
		<div className="mb-5">
			<DataTable
				header={
					<UsersTableFilter
						refetch={(name?: string) =>
							refetch({
								nameOrEmail: name,
							})
						}
					/>
				}
				emptyMessage={
					<div className="flex justify-content-center align-items-center">
						<div className="mr-2">Es wurden keine Benutzer gefunden.</div>
					</div>
				}
				className="mb-3"
				value={users?.map((edge) => edge!.node!)}
			>
				{(Column) => (
					<>
						<Column header="Name" field="name" />
						<Column header="E-Mail" field="email" />
						<Column
							header="Aktiviert"
							body={(row) => {
								return row?.activated ? "Aktiviert" : "Nicht Aktiviert";
							}}
						/>
						{canModify && (
							<Column
								header="Aktionen"
								body={(user) => {
									return (
										<div>
											<Button
												onClick={() => {
													handleEditUserOnClick(user.id);
												}}
												icon={"pi pi-pencil"}
											/>
											<Button
												className="ml-2"
												onClick={() => {
													handleDeleteUserOnClick(user.id);
												}}
												icon={"pi pi-trash"}
											/>
										</div>
									);
								}}
							/>
						)}
					</>
				)}
			</DataTable>
			{hasNext && (
				<div className="flex justify-content-center align-items-center">
					<Button
						type="button"
						className="p-button-secondary"
						disabled={!hasNext}
						onClick={handleLoadNextOnClick}
					>
						Load more
					</Button>
				</div>
			)}

			<ConfirmDialog />
		</div>
	);
};
