/**
 * @generated SignedSource<<c662a1ce57dd21ac7f5855104b0b2d03>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type rewardForm_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "rewardForm_TreeNodeFragment";
};
export type rewardForm_TreeNodeFragment$key = {
  readonly " $data"?: rewardForm_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"rewardForm_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "rewardForm_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "9f157ae931df38ac5d8095f1f023d1a8";

export default node;
