import { type PathParams } from "@thekeytechnology/epic-ui";
import { graphql } from "babel-plugin-relay/macro";
import { Card } from "primereact/card";
import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useParams } from "react-router-dom";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type productEditScreen_ProductQuery } from "@relay/productEditScreen_ProductQuery.graphql";
import { type ProductsPath } from "@screens/products";
import { LicenseProductForm } from "@screens/product-edit/parts/license-product-form/license-product-form.component";

const QUERY = graphql`
	query productEditScreen_ProductQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on Product {
				id
				productType
				...licenseProductForm_LicenseProductFragment
			}
		}
		#...licenseProductForm_QueryFragment
	}
`;
export const ProductEditScreen = () => {
	const { productId } = useParams<PathParams<typeof ProductsPath>>();

	const query = useLazyLoadQuery<productEditScreen_ProductQuery>(QUERY, {
		id: productId!,
		skip: !productId,
	});

	const canReadAndModify = useHasPermissions([
		"UserInAccountPermission_ProductAdmin_Read",
		"UserInAccountPermission_ProductAdmin_Modify",
	]);

	const buildProductFormByProductType = () => {
		switch (query.node?.productType) {
			case "license-product":
				return (
					<LicenseProductForm
						productFragmentRef={query.node}
						// queryFragmentRef={query}
					/>
				);
			default:
				return <></>;
		}
	};

	if (!canReadAndModify) return <NoAccess />;

	return query.node ? (
		<Suspense fallback={<ProgressSpinner />}>
			<div className="p-4">
				<Card>
					<h2 className="mb-3 text-center">[Produkt] {query.node?.id}</h2>
					{buildProductFormByProductType()}
				</Card>
			</div>
		</Suspense>
	) : (
		<h4>Fehler beim Laden des Produkts</h4>
	);
};
