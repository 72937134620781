import {
	DefaultStyledFormComponents,
	type StyledFormComponents,
} from "@thekeytechnology/framework-react-components";
import styled from "styled-components";
import tw from "twin.macro";

export const TkaAdminFormStyles: StyledFormComponents = {
	Form: {
		...DefaultStyledFormComponents.Form,
		StyledForm: styled.form`
			${tw`tw-flex-grow`}
			> div:not(:last-child) {
				${tw`tw-mb-16`}
			}
		`,
	},
};
