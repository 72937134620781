import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { GenerateCartLinkScreen } from "./generate-cart-link.screen";

export const GenerateCartLinkRoutes: RouteDefinition[] = [
	{
		path: Paths.generateCartLink.path,
		element: <GenerateCartLinkScreen />,
		requiredPermissions: ["UserInAccountPermission_GenerateCartLinkAdmin_Modify"],
	},
];
