import graphql from "babel-plugin-relay/macro";

export const ADD_PRODUCT_SELECTION_CONDITION_MUTATION = graphql`
	mutation addProductSelectionConditionButton_AddProductSelectionConditionMutation(
		$input: AddProductSelectionConditionInput!
	) {
		Admin {
			Billing {
				addProductSelectionCondition(input: $input) {
					limitedCartDiscount {
						...limitedCartDiscountForm_LimitedCartDiscountFragment
					}
				}
			}
		}
	}
`;
