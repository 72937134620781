import type TreeNode from "primereact/treenode";
import { type academiesTagsTable_AcademiesTagFragment$data } from "@relay/academiesTagsTable_AcademiesTagFragment.graphql";
import { type AcademiesTagsTableData } from "./academies-tags-table.types";

export const tagsToTreeNode = (
	data: academiesTagsTable_AcademiesTagFragment$data[] | undefined,
): TreeNode.TreeNode[] => {
	return (
		data?.map(
			(e) =>
				({
					key: e.id,
					data: {
						...e.data,
						id: e.id,
						canBeDeleted: e.canBeDeleted,
						kind: e.kind,
					} as AcademiesTagsTableData,
					...e,
					children: e.children?.edges?.map(
						(children) =>
							({
								key: `${e.id}-${children?.node.id}`,
								data: {
									...children?.node.data,
									id: children?.node.id,
									canBeDeleted: children?.node.canBeDeleted,
									kind: children?.node.kind,
								} as AcademiesTagsTableData,
								...children?.node,
							}) as TreeNode.TreeNode,
					),
				}) as TreeNode.TreeNode,
		) ?? []
	);
};
