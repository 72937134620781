/**
 * @generated SignedSource<<80709c3866739694779892a622d4a3ac>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type academiesTagsTable_QueryFragment$data = {
  readonly Admin: {
    readonly AcademiesTag: {
      readonly RootTags: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"academiesTagsTable_AcademiesTagFragment">;
          };
        } | null> | null;
      };
    };
  };
  readonly " $fragmentType": "academiesTagsTable_QueryFragment";
};
export type academiesTagsTable_QueryFragment$key = {
  readonly " $data"?: academiesTagsTable_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"academiesTagsTable_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "AcademiesTag",
  "RootTags"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "canBeDeleted",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isClickable",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "isTopic",
  "storageKey": null
},
v7 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "editAcademiesTagButton_AcademiesTagFragment"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isClickable"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isTopic"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "name"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./academiesTagsTable_Refetch.graphql')
    }
  },
  "name": "academiesTagsTable_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AcademiesTagAdminSchema",
          "kind": "LinkedField",
          "name": "AcademiesTag",
          "plural": false,
          "selections": [
            {
              "alias": "RootTags",
              "args": [
                {
                  "kind": "Variable",
                  "name": "isClickable",
                  "variableName": "isClickable"
                },
                {
                  "kind": "Variable",
                  "name": "isTopic",
                  "variableName": "isTopic"
                },
                {
                  "kind": "Variable",
                  "name": "name",
                  "variableName": "name"
                }
              ],
              "concreteType": "AcademiesRootTagsConnection",
              "kind": "LinkedField",
              "name": "__academiesTagsTable_RootTags_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AcademiesRootTagsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AcademiesRootTag",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "academiesTagsTable_AcademiesTagFragment",
                          "selections": [
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "kind": "InlineFragment",
                                  "selections": [
                                    (v2/*: any*/),
                                    (v3/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "AcademiesTagData",
                                      "kind": "LinkedField",
                                      "name": "data",
                                      "plural": false,
                                      "selections": [
                                        (v4/*: any*/),
                                        (v5/*: any*/),
                                        (v6/*: any*/)
                                      ],
                                      "storageKey": null
                                    },
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "AcademiesChildTagsConnection",
                                      "kind": "LinkedField",
                                      "name": "children",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "AcademiesChildTagsEdge",
                                          "kind": "LinkedField",
                                          "name": "edges",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "AcademiesChildTag",
                                              "kind": "LinkedField",
                                              "name": "node",
                                              "plural": false,
                                              "selections": [
                                                (v1/*: any*/),
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "concreteType": "AcademiesTagData",
                                                  "kind": "LinkedField",
                                                  "name": "data",
                                                  "plural": false,
                                                  "selections": [
                                                    (v4/*: any*/),
                                                    (v6/*: any*/),
                                                    (v5/*: any*/)
                                                  ],
                                                  "storageKey": null
                                                },
                                                (v3/*: any*/),
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "subId",
                                                  "storageKey": null
                                                },
                                                (v2/*: any*/),
                                                (v7/*: any*/)
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "type": "AcademiesRootTag",
                                  "abstractKey": null
                                },
                                (v7/*: any*/)
                              ],
                              "type": "AcademiesTag",
                              "abstractKey": "__isAcademiesTag"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "3fd8e1ce8c6892ba14b4bac1bccab6b5";

export default node;
