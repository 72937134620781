/**
 * @generated SignedSource<<f51c3a90b23f267c7ac622c34730a29d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type answerMatrixElementCellForm_AnswerMatrixElementCellFragment$data = {
  readonly correctAnswer: {
    readonly content: string;
  };
  readonly wrongAnswers: ReadonlyArray<{
    readonly content: string;
  }>;
  readonly xIdx: number;
  readonly yIdx: number;
  readonly " $fragmentSpreads": FragmentRefs<"editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment">;
  readonly " $fragmentType": "answerMatrixElementCellForm_AnswerMatrixElementCellFragment";
};
export type answerMatrixElementCellForm_AnswerMatrixElementCellFragment$key = {
  readonly " $data"?: answerMatrixElementCellForm_AnswerMatrixElementCellFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"answerMatrixElementCellForm_AnswerMatrixElementCellFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "content",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "answerMatrixElementCellForm_AnswerMatrixElementCellFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "xIdx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "yIdx",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MatrixElementCellCorrectAnswer",
      "kind": "LinkedField",
      "name": "correctAnswer",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MatrixElementCellWrongAnswer",
      "kind": "LinkedField",
      "name": "wrongAnswers",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment"
    }
  ],
  "type": "AnswerMatrixElementCell",
  "abstractKey": null
};
})();

(node as any).hash = "7cb71bc5d005c97ac7f1917ab9031ce1";

export default node;
