/**
 * @generated SignedSource<<c68a7ebf3f98c0f6ffec2c0ffa41a582>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AccountEditScreen_Refetch$variables = {
  after?: string | null;
  first?: number | null;
  id: string;
};
export type AccountEditScreen_Refetch$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"accountEditScreen_AccountFragment">;
  } | null;
};
export type AccountEditScreen_Refetch = {
  response: AccountEditScreen_Refetch$data;
  variables: AccountEditScreen_Refetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": 20,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AccountEditScreen_Refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": (v2/*: any*/),
            "kind": "FragmentSpread",
            "name": "accountEditScreen_AccountFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AccountEditScreen_Refetch",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v4/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "registeredAt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isDeleted",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccountGroupAssociation",
                "kind": "LinkedField",
                "name": "groupAssociations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AccountGroup",
                    "kind": "LinkedField",
                    "name": "group",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "extension",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "concreteType": "UserInAccountConnection",
                        "kind": "LinkedField",
                        "name": "users",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserInAccountEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "UserInAccount",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v4/*: any*/),
                                  (v3/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserInAccountGroup",
                                    "kind": "LinkedField",
                                    "name": "groups",
                                    "plural": true,
                                    "selections": (v6/*: any*/),
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v4/*: any*/),
                                      (v5/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "email",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "activated",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "kind": "ClientExtension",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__id",
                                "storageKey": null
                              }
                            ]
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v2/*: any*/),
                        "filters": null,
                        "handle": "connection",
                        "key": "AccountEditScreen_users",
                        "kind": "LinkedHandle",
                        "name": "users"
                      }
                    ],
                    "type": "AccountExtensionImpl",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "Account",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4d9363178b6d328bdd1a61623cddc544",
    "id": null,
    "metadata": {},
    "name": "AccountEditScreen_Refetch",
    "operationKind": "query",
    "text": "query AccountEditScreen_Refetch(\n  $after: String\n  $first: Int = 20\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...accountEditScreen_AccountFragment_2HEEH6\n    id\n  }\n}\n\nfragment accountEditScreen_AccountFragment_2HEEH6 on Account {\n  name\n  id\n  registeredAt\n  isDeleted\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n  extension {\n    __typename\n    ... on AccountExtensionImpl {\n      users(first: $first, after: $after) {\n        edges {\n          node {\n            id\n            __typename\n          }\n          cursor\n        }\n        ...usersInAccountTable_UserInAccountConnectionFragment\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n  ...editAccountGroupsButton_AccountFragment\n}\n\nfragment editAccountGroupsButton_AccountFragment on Account {\n  ...editAccountGroupsForm_AccountFragment\n}\n\nfragment editAccountGroupsForm_AccountFragment on Account {\n  id\n  groupAssociations {\n    group {\n      id\n      name\n    }\n  }\n}\n\nfragment editUserInAccountGroup_UserInAccountFragment on UserInAccount {\n  groups {\n    id\n    name\n  }\n  user {\n    id\n  }\n}\n\nfragment usersInAccountTable_UserInAccountConnectionFragment on UserInAccountConnection {\n  edges {\n    node {\n      groups {\n        id\n        name\n      }\n      user {\n        id\n        name\n        email\n        activated\n      }\n      ...editUserInAccountGroup_UserInAccountFragment\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8b75e3ae23890282e1a173292db4a677";

export default node;
