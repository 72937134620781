/**
 * @generated SignedSource<<24fd21dc479dc150423d6736fbabe2f7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreatePercentageDiscountActionInput = {
  clientMutationId?: string | null;
  title: string;
};
export type createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreatePercentageDiscountActionInput;
};
export type createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createPercentageDiscountAction: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"DiscountActionsTable_DiscountActionFragment">;
          };
        };
      } | null;
    };
  };
};
export type createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation = {
  response: createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation$data;
  variables: createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v5 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreatePercentageDiscountActionPayload",
                "kind": "LinkedField",
                "name": "createPercentageDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "DiscountActionsTable_DiscountActionFragment",
                            "selections": [
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionIdColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionTitleColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionTypeColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionCalcTypeColumn_DiscountActionFragment"
                              },
                              {
                                "args": null,
                                "kind": "FragmentSpread",
                                "name": "ActionActionColumn_DiscountActionFragment"
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreatePercentageDiscountActionPayload",
                "kind": "LinkedField",
                "name": "createPercentageDiscountAction",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "DiscountActionsEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "DiscountAction",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "discountType",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": (v5/*: any*/),
                                "type": "SystemDiscountType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": (v5/*: any*/),
                                "type": "UserDiscountType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "calcType",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "percentage",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "type": "DiscountActionPercentageCalcType",
                                "abstractKey": null
                              },
                              {
                                "kind": "InlineFragment",
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "euro",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "type": "DiscountActionEuroCalcType",
                                "abstractKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fda01659e463ad95713b90e0a48b1acb",
    "id": null,
    "metadata": {},
    "name": "createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation",
    "operationKind": "mutation",
    "text": "mutation createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation(\n  $input: CreatePercentageDiscountActionInput!\n) {\n  Admin {\n    Billing {\n      createPercentageDiscountAction(input: $input) {\n        edge {\n          node {\n            ...DiscountActionsTable_DiscountActionFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment ActionActionColumn_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment ActionCalcTypeColumn_DiscountActionFragment on DiscountAction {\n  calcType {\n    __typename\n    ... on DiscountActionPercentageCalcType {\n      percentage\n      kind\n    }\n    ... on DiscountActionEuroCalcType {\n      euro\n      kind\n    }\n  }\n}\n\nfragment ActionIdColumn_DiscountActionFragment on DiscountAction {\n  id\n}\n\nfragment ActionTitleColumn_DiscountActionFragment on DiscountAction {\n  title\n}\n\nfragment ActionTypeColumn_DiscountActionFragment on DiscountAction {\n  discountType {\n    __typename\n    ... on SystemDiscountType {\n      kind\n    }\n    ... on UserDiscountType {\n      kind\n    }\n  }\n}\n\nfragment DiscountActionsTable_DiscountActionFragment on DiscountAction {\n  ...ActionIdColumn_DiscountActionFragment\n  ...ActionTitleColumn_DiscountActionFragment\n  ...ActionTypeColumn_DiscountActionFragment\n  ...ActionCalcTypeColumn_DiscountActionFragment\n  ...ActionActionColumn_DiscountActionFragment\n}\n"
  }
};
})();

(node as any).hash = "f011aeb2f60c294b401d2489fd1720ae";

export default node;
