import { Paths } from "@routes/paths";
import { FilesScreen } from "./files.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const FilesRoutes: RouteDefinition[] = [
	{
		path: Paths.files.path,
		element: <FilesScreen />,
		requiredPermissions: "onlyOwnerOfRoot",
	},
];
