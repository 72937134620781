import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Paths } from "@routes/paths";
import { type EditOrderButtonProps } from "./edit-order-button.interface";

export const EditOrderButton = ({ orderId }: EditOrderButtonProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon="pi pi-pencil"
			tooltip="Bestellung bearbeiten"
			onClick={() => {
				navigate(Paths.orders.withId(orderId).edit.path);
			}}
		/>
	);
};
