import { type AsyncElementKind } from "@relay/asyncContentEditor_TreeNodeFragment.graphql";

export const AsyncElementsDropdownOptions: Array<{
	typeDefinitionType: AsyncElementKind;
	name: string;
}> = [
	{
		typeDefinitionType: "Upload",
		name: `Upload Baustein`,
	},
];
