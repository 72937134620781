import { useFormik } from "formik";
import { Button } from "primereact/button";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { MemoizedRichTextEditor } from "@components/rich-text-editor";
import { RichTextEditorToolbar } from "@components/rich-text-editor/rich-text-editor.const";
import { type editExternalLicensePoolForm_EditExternalLicensePoolMutation } from "@relay/editExternalLicensePoolForm_EditExternalLicensePoolMutation.graphql";
import { type editExternalLicensePoolForm_ExternalLicensePoolFragment$key } from "@relay/editExternalLicensePoolForm_ExternalLicensePoolFragment.graphql";
import { Paths } from "@routes/paths";
import {
	EDIT_EXTERNAL_LICENSE_POOL_MUTATION,
	EXTERNAL_LICENSE_POOL_FRAGMENT,
} from "./edit-external-license-pool-form.graphql";
import {
	type EditExternalLicensePoolFormFormState,
	type EditExternalLicensePoolFormProps,
} from "./edit-external-license-pool-form.interface";
import {
	DefaultIntegerFieldComponent,
	DefaultTextFieldComponent,
} from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { useCallbackPrompt } from "../../../../hooks/UseCallBackPrompt";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
	selectArrayOfEditedForms,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch, useTypedSelector } from "../../../../store/store.redux";

export const EditExternalLicensePoolForm = ({
	loading,
	entityRef,
}: EditExternalLicensePoolFormProps) => {
	const navigate = useNavigate();
	const dispatch = useTypedDispatch();
	const arrayOfEditedForms = useTypedSelector(selectArrayOfEditedForms);
	const [showPrompt, confirmNavigation, cancelNavigation, setShowPrompt] = useCallbackPrompt(
		arrayOfEditedForms.length > 0,
	);

	const [updateExternalLicensePool] =
		useMutation<editExternalLicensePoolForm_EditExternalLicensePoolMutation>(
			EDIT_EXTERNAL_LICENSE_POOL_MUTATION,
		);

	const externalLicensePool =
		useFragment<editExternalLicensePoolForm_ExternalLicensePoolFragment$key>(
			EXTERNAL_LICENSE_POOL_FRAGMENT,
			entityRef,
		);

	const onSubmit = (values: EditExternalLicensePoolFormFormState) => {
		updateExternalLicensePool({
			variables: {
				input: {
					id: externalLicensePool.id!,
					data: {
						name: values.name ?? "",
						usageInformation: values.usageInformation,
						validNumDaysAfterIssuing: values.validNumDaysAfterIssuing,
						link: values.link ?? "",
					},
				},
			},
		});
	};

	const formId = "EditExternalLicensePoolForm";
	const formik = useFormik<EditExternalLicensePoolFormFormState>({
		initialValues: {
			name: externalLicensePool.data.name ?? "Neuer Lizenz Pool",
			usageInformation: externalLicensePool.data.usageInformation ?? "Nutzungsinfo",
			validNumDaysAfterIssuing: externalLicensePool.data.validNumDaysAfterIssuing ?? "1",
			link: externalLicensePool.data.link ?? "",
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name wird benötigt"),
			validNumDaysAfterIssuing: Yup.number().required(
				"Anzahl Tage für die Einlösung durch Nutzer nach Vergabe wird benötigt",
			),
			link: Yup.string().url("Bitte URL überprüfen"),
		}),
		onSubmit: (values, { setSubmitting }) => {
			onSubmit({
				name: values.name,
				usageInformation: values.usageInformation,
				validNumDaysAfterIssuing: values.validNumDaysAfterIssuing,
				link: values.link,
			});
			setSubmitting(false);
			dispatch(resetArrayOfEditedForms());
			toast.success("Gespeichert");
		},
	});

	return (
		<>
			{showPrompt && (
				<WarningUnsavedChangesDialog
					confirmNavigation={confirmNavigation}
					setShowDialog={setShowPrompt}
					cancelNavigation={cancelNavigation}
				/>
			)}
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditExternalLicensePoolFormFormState, string>
					name={"name"}
					label={"Name"}
					component={DefaultTextFieldComponent}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>
				<ValidatedField<EditExternalLicensePoolFormFormState, string>
					name={"usageInformation"}
					label={"Verwendungsinformationen"}
					component={(props) => (
						<MemoizedRichTextEditor
							{...props}
							height={200}
							toolbar={RichTextEditorToolbar.Default}
						/>
					)}
					onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
					formikConfig={formik}
				/>

				<ValidatedField<EditExternalLicensePoolFormFormState, number>
					name={"validNumDaysAfterIssuing"}
					label={"Anzahl Tage für die Einlösung durch Nutzer nach Vergabe"}
					component={DefaultIntegerFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<EditExternalLicensePoolFormFormState, string>
					name={"link"}
					label={"Link einfügen (optional)"}
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>

				<Button disabled={loading} type="submit" label="Speichern" className="mt-2" />
				<Button
					disabled={loading}
					type="button"
					label="Zurück"
					onClick={() => {
						navigate(Paths.externalLicensePools.path);
					}}
					className="mt-2"
				/>
			</form>
		</>
	);
};
