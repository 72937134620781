import { PathWithId } from "@thekeytechnology/epic-ui";
import { MessageDispatchEditPath } from "@screens/message-dispatch-edit/message-dispatch-edit.paths";

export class MessageDispatchesPath extends PathWithId<typeof MessageDispatchesPath> {
	static readonly pathKey = "messagesDispatches";
	static readonly pathName = "messages-dispatches";
	static readonly idKey = "messageDispatchId";
	static readonly childPaths = [MessageDispatchEditPath];

}
