import React, { Suspense } from "react";

import { useHasPermissions } from "@hooks/use-has-permissions";
import { LicenseDefinitionsScreenContext } from "./license-defintions.context";
import { Card } from "../../components/card";
import { NoAccess } from "../../components/no-access";
// import { CreateTreeLicenseDefinitionButton } from "../../features/license-definitions/create-tree-license-definition-button";
// import { LicenseDefinitionsTable } from "../../features/license-definitions/license-definitions-table";
import { BaseScreen } from "../BaseScreen";

export const LicenseDefinitionsScreen = () => {
	const [connectionId, setConnectionId] = React.useState("");

	// const canModify = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Modify"]);

	const canRead = useHasPermissions(["UserInAccountPermission_LicenseAdmin_Read"]);

	const handleSetConnectionId = (id: string) => {
		setConnectionId(id);
	};

	if (!canRead) return <NoAccess />;

	return (
		<LicenseDefinitionsScreenContext.Provider
			value={{ connectionId, setConnectionId: handleSetConnectionId }}
		>
			<BaseScreen>
				<Card
					header={
						<div className="flex p-3 align-items-center justify-content-between card-flat">
							<h1 className="mt-0 mr-3 mb-0 ml-0">Lizenzdefinitionen</h1>
							<div className={"flex gap-2"}>
								{/* {canModify && <CreateTreeLicenseDefinitionButton />} */}
							</div>
						</div>
					}
				>
					<Suspense fallback={<div>Lade...</div>}>
						{/* <LicenseDefinitionsTable /> */}
					</Suspense>
				</Card>
			</BaseScreen>
		</LicenseDefinitionsScreenContext.Provider>
	);
};
