import { OrderList } from "primereact/orderlist";
import React from "react";
import { useFragment } from "react-relay";
import { dispatchConditionsPreview_MessageDispatchFragment$key } from "@relay/dispatchConditionsPreview_MessageDispatchFragment.graphql";

import { DispatchConditionDisplayType } from "@screens/message-dispatch-edit/parts/dispatch-condition-dropdown/dispatch-condition-dropdown.types";
import { MESSAGE_DISPATCH_FRAGMENT } from "@screens/message-dispatch-edit/parts/dispatch-conditions-preview/dispatch-conditions-preview.graphql";
import { conditionsPreviewItemClass } from "@screens/message-dispatch-edit/parts/dispatch-conditions-preview/dispatch-conditions-preview.styles";
import { DispatchConditionsPreviewProps } from "@screens/message-dispatch-edit/parts/dispatch-conditions-preview/dispatch-conditions-preview.types";
import { RemoveDispatchConditionButton } from "@screens/message-dispatch-edit/parts/remove-dispatch-condition-button";
import { DISPATCHES_TRANSLATIONS } from "../../../../translations/dispatch-conditions";

export const DispatchConditionPreview = ({
	messageDispatchFragmentRef,
	isInclusion = true,
	onChange,
}: DispatchConditionsPreviewProps) => {
	const messageDispatch = useFragment<dispatchConditionsPreview_MessageDispatchFragment$key>(
		MESSAGE_DISPATCH_FRAGMENT,
		messageDispatchFragmentRef,
	);

	const conditionsToRender = isInclusion ? messageDispatch?.includes : messageDispatch?.excludes;

	const conditions: DispatchConditionDisplayType[] | undefined = conditionsToRender?.map(
		(condition) => {
			return {
				label: DISPATCHES_TRANSLATIONS[condition.kind!],
				value: condition.kind!,
			};
		},
	);

	const itemTemplate = (condition: DispatchConditionDisplayType) => {
		return (
			<div className={conditionsPreviewItemClass}>
				{condition.label}
				<RemoveDispatchConditionButton
					isInclusion={isInclusion}
					onCompleted={onChange}
					dispatchConditionFragmentRef={conditionsToRender?.find(
						(conditionToRender) => conditionToRender.kind === condition.value,
					)}
				/>
			</div>
		);
	};

	return (
		<OrderList
			pt={{
				controls: {
					style: { display: "none" },
				},
			}}
			value={conditions}
			listStyle={{ height: "auto" }}
			itemTemplate={itemTemplate}
		/>
	);
};
