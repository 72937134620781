import { graphql } from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query messageDefinitionForm_Query($id: ID!) {
		...messageDefinitionForm_MessageDefinitionFragment @arguments(id: $id)
	}
`;

export const MESSAGE_DEFINITION_FRAGMENT = graphql`
	fragment messageDefinitionForm_MessageDefinitionFragment on Query
	@refetchable(queryName: "messageDefinition_Refetch")
	@argumentDefinitions(id: { type: "ID!" }) {
		node(id: $id) {
			... on MessageDefinition {
				internalTitle
				showUntil
				inAppNotification {
					title
					content
				}
				desktopNotification {
					title
					content
					url
					icon {
						id
						name
					}
				}
				...inAppNotificationForm_InAppNotificationFragment
				...desktopNotificationForm_DesktopNotificationFragment
				...messagePagesEditor_PagesFragment
			}
		}
	}
`;

export const EDIT_MESSAGE_DEFINITION_MUTATION = graphql`
	mutation messageDefinitionForm_EditMessageDefinitionMutation(
		$input: EditMessageDefinitionInput!
	) {
		Admin {
			MessageDefinitions {
				editMessageDefinition(input: $input) {
					messageDefinition {
						node {
							id
						}
					}
				}
			}
		}
	}
`;
