import { graphql } from "babel-plugin-relay/macro";

export const DELETE_EXTERNAL_LICENSE_MUTATION = graphql`
	mutation deleteExternalLicenseButton_DeleteExternalLicenseMutation(
		$input: DeleteExternalLicenseInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				deleteExternalLicense(input: $input) {
					clientMutationId
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;
