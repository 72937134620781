import tw from "twin.macro";

export const Wrapper = tw.div`
	tw-flex
	tw-flex-col
	tw-gap-12
`;

export const InputGroupWrapper = tw.div`
	tw-grid
	tw-grid-cols-2
	tw-gap-6
	tw-pb-6
`;

export const ShortInputsRow = tw.div`
	tw-grid
	tw-grid-cols-6
	tw-gap-16
`;

export const ColSpan2 = tw.div`
	tw-col-span-2
`;

export const ColSpan4 = tw.div`
	tw-col-span-4
`;

export const RowPlaceholder = tw.div`
	tw-hidden
	md:tw-block
`;
