import { Button } from "primereact/button";
import { readInlineData, useFragment } from "react-relay";
import { type minimizedDiscountCodeTable_DiscountCodeFragment$key } from "@relay/minimizedDiscountCodeTable_DiscountCodeFragment.graphql";
import { type minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment$key } from "@relay/minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment.graphql";
import {
	DISCOUNT_CODE_FRAGMENT,
	DISCOUNT_CODES_V2_CONNECTION_FRAGMENT,
} from "./minimized-discount-code-table.graphql";
import { type MinimizedDiscountCodeTableProps } from "./minimized-discount-code-table.interface";
import { StyledDataTable } from "../../products/products-table/products-table.styles";

export const MinimizedDiscountCodeTable = ({
	hasNext,
	loadNext,
	selectionMode,
	selectedId,
	onDiscountCodeSelected,
	loadPrevious,
	hasPrevious,
	discountCodeV2ConnectionFragmentRef,
}: MinimizedDiscountCodeTableProps) => {
	const { edges: discountCodes } =
		useFragment<minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment$key>(
			DISCOUNT_CODES_V2_CONNECTION_FRAGMENT,
			discountCodeV2ConnectionFragmentRef,
		);

	const values =
		discountCodes
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<minimizedDiscountCodeTable_DiscountCodeFragment$key>(
					DISCOUNT_CODE_FRAGMENT,
					e!.node,
				),
			) || [];

	return (
		<>
			<StyledDataTable
				className="mb-3"
				value={values}
				emptyMessage={`Keine passenden Discount-Codes gefunden`}
				selectionMode={selectionMode}
				selection={{ id: selectedId }}
				dataKey="id"
				onSelectionChange={onDiscountCodeSelected}
			>
				{(Column) => (
					<>
						<Column header="Code" field="code" />
					</>
				)}
			</StyledDataTable>

			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => {
						loadPrevious(20);
					}}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => {
						loadNext(20);
					}}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};
