import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { MessageDispatchEditScreen } from "./message-dispatch-edit.screen";

export const MessageDispatchEditRoutes: RouteDefinition[] = [
	{
		path: Paths.messagesDispatches.withIdPlaceholder().edit.path,
		element: <MessageDispatchEditScreen />,
		requiredPermissions: ["UserInAccountPermission_MessageDispatchAdmin_Modify"],
	},
];
