import { useFormik } from "formik";
import { Button } from "primereact/button";
import {
	type AcademiesTagsTableFilterProps,
	type AcademiesTagsTableFilterState,
} from "./academies-tags-table-filter.types";
import {
	DefaultSwitchComponent,
	DefaultTextFieldComponent,
} from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";

export const AcademiesTagsTableFilter = ({ refetch }: AcademiesTagsTableFilterProps) => {
	const initialValues: AcademiesTagsTableFilterState = {
		name: undefined,
		isClickable: undefined,
		isTopic: undefined,
	};

	const formik = useFormik<AcademiesTagsTableFilterState>({
		initialValues,
		onSubmit: (values: AcademiesTagsTableFilterState, { setSubmitting }) => {
			refetch(values);
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		formik.handleReset(initialValues);
		formik.handleSubmit();
		refetch({});
	};

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<div className="flex flex-row align-items-center">
				<ValidatedField<AcademiesTagsTableFilterState, string>
					name="name"
					label="Name"
					className="mr-2 w-25rem"
					component={DefaultTextFieldComponent}
					formikConfig={formik}
				/>
				<ValidatedField<AcademiesTagsTableFilterState, boolean>
					name="isClickable"
					label="ist Klickbar"
					className="mr-2 w-25rem"
					component={DefaultSwitchComponent}
					formikConfig={formik}
				/>
				<ValidatedField<AcademiesTagsTableFilterState, boolean>
					name="isTopic"
					label="ist Thema"
					className="mr-2 w-25rem"
					component={DefaultSwitchComponent}
					formikConfig={formik}
				/>
				<Button
					disabled={false}
					type="reset"
					onClick={clearForm}
					icon="pi pi-times"
					className="h-3rem w-3rem"
				/>
				<Button
					disabled={false}
					type="submit"
					icon="pi pi-search"
					className="h-3rem w-3rem ml-2"
				/>
			</div>
		</form>
	);
};
