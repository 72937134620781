import { TREE_I18N_KEY, TREE_I18N_MAP } from "./tree";

export const ERROR_MESSAGES: Record<string, string> = {
	"auth-unspecified-auth-exception":
		"Login fehlgeschlagen. Bitte überprüfe Benutzername und Passwort.",
	certificates_certificate_template_not_found: "Kein Zertifikat gefunden.",
	file_already_exists: "Die Datei existiert bereits!",
	publishingv2_tree_for_publishing_does_not_contain_content_nodes_with_elements: `Keine Elemente im ${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)}.`,
	tree_cant_remove_first_published_date_for_published_root_node: `Veröffentlichungszeitpunkt kann nicht gelöscht werden, da ${TREE_I18N_MAP(
		TREE_I18N_KEY.tree,
	)} bereits veröffentlicht ist.`,
	tree_published_tree_parts_can_not_be_modified: `Bereits veröffentlichte Teile des ${TREE_I18N_MAP(
		TREE_I18N_KEY.treeAttributiveGenitive,
	)} können nicht verändert werden.`,
	auth_login_with_email_and_password_unspecified_auth: "Login fehlgeschlagen!",
	auth_not_enough_permissions_exception: "Keine Berichtigung!",

	auth_user_needs_to_be_in_at_least_one_account:
		"Der Benutzer muss mindestens einem Konto zugehören.",
	auth_root_account_can_not_be_deleted: "Root Konto kann nicht gelöscht werden.",
	auth_cant_delete_only_owner_exception: "Es muss mindestens ein Admin im Konto existieren.",
	auth_user_not_activated: "Der Benutzer ist noch nicht Aktiviert.",
	auth_user_already_activated: "Der Benutzer ist hat seine E-Mail bereits aktiviert.",
	coaching_already_a_participant: "Benutzer ist bereits ein Teilnehmer",
};
