import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { type orderHistoryTable_OrderHistoriesFragment$key } from "@relay/orderHistoryTable_OrderHistoriesFragment.graphql";
import {
	type orderHistoryTable_OrderHistoryFragment$data,
	type orderHistoryTable_OrderHistoryFragment$key,
} from "@relay/orderHistoryTable_OrderHistoryFragment.graphql";
import { type OrderHistoryTable_Refetch } from "@relay/OrderHistoryTable_Refetch.graphql";
import { ORDER_HISTORIES_FRAGMENT, ORDER_HISTORY_FRAGMENT } from "./order-history-table.graphql";
import { type OrderHistoryTableProps } from "./order-history-table.interface";
import { DateTimeDisplay } from "../../../components/datetime-display/datetime-display.component";
import { Pagination } from "../../../components/Pagination";
import { OrderStatusType } from "../../../translations/OrderStatusType";
import { OrderHistoryEvent } from "../order-history-event/order-history-event.component";

export const OrderHistoryTable = ({ orderHistoriesFragmentRef }: OrderHistoryTableProps) => {
	const {
		data: orders,
		hasNext,
		loadNext,
	} = usePaginationFragment<
		OrderHistoryTable_Refetch,
		orderHistoryTable_OrderHistoriesFragment$key
	>(ORDER_HISTORIES_FRAGMENT, orderHistoriesFragmentRef);

	const ordersConnection = orders.node?.history?.edges?.map((edge) =>
		readInlineData<orderHistoryTable_OrderHistoryFragment$key>(
			ORDER_HISTORY_FRAGMENT,
			edge?.node!,
		),
	);
	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Kein Verlauf"}
				className="p-mb-2"
				value={ordersConnection}
			>
				<Column
					header={"ID"}
					body={(orderHistory: orderHistoryTable_OrderHistoryFragment$data) => (
						<div
							className="cursor-pointer"
							onClick={async () => {
								await navigator.clipboard.writeText(orderHistory.id);
							}}
						>
							<b>
								ID<i className="pi pi-copy"></i>
							</b>
						</div>
					)}
				/>
				<Column
					header={"Erstellungsdatum"}
					body={(orderHistory: orderHistoryTable_OrderHistoryFragment$data) => (
						<DateTimeDisplay value={orderHistory.createdAt} hideTimezone />
					)}
				/>
				<Column
					header={"Status"}
					body={(orderHistory: orderHistoryTable_OrderHistoryFragment$data) => (
						<div>{OrderStatusType[orderHistory.status]}</div>
					)}
				/>
				<Column
					header={"Ereignis"}
					body={(orderHistory: orderHistoryTable_OrderHistoryFragment$data) => (
						<OrderHistoryEvent orderHistoryFragmentRef={orderHistory} />
					)}
				/>
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(10)} />
		</>
	);
};
