import graphql from "babel-plugin-relay/macro";

export const ACADEMIES_TAG_FRAGMENT = graphql`
	fragment editAcademiesTagButton_AcademiesTagFragment on AcademiesTag {
		id
		... on AcademiesRootTag {
			data {
				name
				isTopic
				isClickable
			}
		}
		... on AcademiesChildTag {
			data {
				name
				isClickable
				isTopic
			}
		}
	}
`;

export const EDIT_TAG_MUTATION = graphql`
	mutation editAcademiesTagButton_EditTagMutation($input: editTagInput!) {
		Admin {
			AcademiesTag {
				editTag(input: $input) {
					tag {
						...editAcademiesTagButton_AcademiesTagFragment
					}
				}
			}
		}
	}
`;
