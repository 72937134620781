/**
 * @generated SignedSource<<5af22e330170c493742632b69a8d6304>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicensePoolEditScreen_ExternalLicensePoolQuery$variables = {
  id: string;
  skip: boolean;
};
export type externalLicensePoolEditScreen_ExternalLicensePoolQuery$data = {
  readonly node?: {
    readonly data?: {
      readonly name: string;
    };
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"editExternalLicensePoolForm_ExternalLicensePoolFragment">;
  } | null;
};
export type externalLicensePoolEditScreen_ExternalLicensePoolQuery = {
  response: externalLicensePoolEditScreen_ExternalLicensePoolQuery$data;
  variables: externalLicensePoolEditScreen_ExternalLicensePoolQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "externalLicensePoolEditScreen_ExternalLicensePoolQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePoolData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "editExternalLicensePoolForm_ExternalLicensePoolFragment"
                  }
                ],
                "type": "ExternalLicensePool",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "externalLicensePoolEditScreen_ExternalLicensePoolQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePoolData",
                    "kind": "LinkedField",
                    "name": "data",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "link",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "usageInformation",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "validNumDaysAfterIssuing",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ExternalLicensePool",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "91f44bfef5508a4e0bdcd7ec5b025c0b",
    "id": null,
    "metadata": {},
    "name": "externalLicensePoolEditScreen_ExternalLicensePoolQuery",
    "operationKind": "query",
    "text": "query externalLicensePoolEditScreen_ExternalLicensePoolQuery(\n  $id: ID!\n  $skip: Boolean!\n) {\n  node(id: $id) @skip(if: $skip) {\n    __typename\n    ... on ExternalLicensePool {\n      id\n      data {\n        name\n      }\n      ...editExternalLicensePoolForm_ExternalLicensePoolFragment\n    }\n    id\n  }\n}\n\nfragment editExternalLicensePoolForm_ExternalLicensePoolFragment on ExternalLicensePool {\n  id\n  data {\n    name\n    link\n    usageInformation\n    validNumDaysAfterIssuing\n  }\n}\n"
  }
};
})();

(node as any).hash = "25804352e54f7062a9a14a2546ad86d0";

export default node;
