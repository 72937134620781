import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useLazyLoadQuery } from "react-relay";

import * as Yup from "yup";
import { type selectUserFieldDialog_Query } from "@relay/selectUserFieldDialog_Query.graphql";
import { QUERY } from "./select-user-field-dialog.graphql";
import {
	type SelectUserFieldDialogState,
	type SelectUserFieldDialogProps,
} from "./select-user-field-dialog.interface";

import { ValidatedField } from "../../../components/ValidatedField";
import { SelectUserField } from "../select-user-field";

export const SelectUserFieldDialog = ({
	updateField,
	fieldValue: selectedUserId,
	selectEditors,
	disabled,
	onChange,
}: SelectUserFieldDialogProps) => {
	const { node: user } = useLazyLoadQuery<selectUserFieldDialog_Query>(QUERY, {
		userId: selectedUserId || "",
		skip: !selectedUserId,
	});

	const [isModalOpen, setModalOpen] = useState<boolean>(false);

	const formik = useFormik<SelectUserFieldDialogState>({
		initialValues: {
			userId: selectedUserId,
		},
		validationSchema: Yup.object().shape({
			userId: Yup.string().required("Ein Benutzer wird benötigt."),
		}),
		onSubmit: (values) => {
			updateField(values.userId);
			setModalOpen(false);
			onChange?.();
		},
	});

	const handleDialogOnHide = () => {
		setModalOpen(false);
	};

	const handleSelectUserOnClick = () => {
		setModalOpen(true);
	};

	const handleUnselectUserOnClick = () => {
		updateField(undefined);
	};

	const name =
		user?.name && user?.email ? `${user?.name} - ${user?.email}` : "Kein Benutzer ausgewählt";

	return (
		<>
			<div className="flex">
				<InputText
					onClick={handleSelectUserOnClick}
					className="mr-2 flex-grow-1 w-auto"
					disabled={true}
					value={name}
				/>
				<Button
					className="w-auto p-button-secondary p-2"
					type={"button"}
					disabled={selectedUserId === undefined || disabled}
					label={""}
					icon="pi pi-times"
					onClick={handleUnselectUserOnClick}
				/>
				<Button
					className="w-auto ml-2"
					type={"button"}
					disabled={disabled}
					onClick={handleSelectUserOnClick}
				>
					Benutzer auswählen
				</Button>
			</div>
			<Dialog
				header="Benutzer auswählen"
				onHide={handleDialogOnHide}
				className="w-10"
				visible={isModalOpen}
			>
				<form onSubmit={formik.handleSubmit}>
					<ValidatedField<SelectUserFieldDialogState, string>
						className="mb-4"
						name={"userId"}
						label={"Wähle einen Bearbeiter aus"}
						formikConfig={{
							...formik,
							errors: { ...formik.errors, userId: undefined },
						}}
						component={(config) => {
							return <SelectUserField {...config} selectEditors={selectEditors} />;
						}}
					/>
					<Button
						disabled={!formik.isValid}
						label={"Auswählen"}
						icon="pi pi-check"
						type="submit"
						autoFocus
					/>
				</form>
			</Dialog>
		</>
	);
};
