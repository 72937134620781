import { DEFAULT_THEME, type EpicUITheme } from "@thekeytechnology/epic-ui";
import { Button, ButtonVariant } from "@components/button";
import {
	brandMain100,
	brandStrong100,
	error100,
	info100,
	onBrandMain,
	onBrandStrong,
	shade100,
	shade20,
	success100,
	warning100,
} from "./colors";

export const LightTheme: EpicUITheme = {
	...DEFAULT_THEME,
	colors: {
		...DEFAULT_THEME.colors,
		primary100: brandStrong100,
		secondary100: brandMain100,
		onPrimary: onBrandStrong,
		onSecondary: onBrandMain,
		fontPrimary: shade100,
		fontDisabled: shade20,
		focus: info100,
		info100,
		error100,
		success100,
		warning100,
	},
	ButtonComponent: Button,
	buttons: {
		destructiveButtonVariant: ButtonVariant.Error,
		successButtonVariant: ButtonVariant.Strong,
	},
};
