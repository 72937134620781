import { graphql } from "babel-plugin-relay/macro";

export const ADD_USER_TO_ACCOUNT_MUTATION = graphql`
	mutation addUserToAccount_AddUserToAccountMutation(
		$input: AddUserToAccountAdminInput!
		$connections: [ID!]!
	) {
		Admin {
			Auth {
				addUserToAccount(input: $input) {
					clientMutationId
					userInAccount @appendEdge(connections: $connections) {
						node {
							groups {
								id
								name
							}
							user {
								id
								name
								email
								activated
							}
						}
					}
				}
			}
		}
	}
`;
