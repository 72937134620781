import { css } from "@styled-system/css";

export const headerClass = css({
	display: "flex",
	gap: "16",
	alignItems: "center",
	my: "32",
});
export const orderListItemClass = css({
	display: "flex",
	justifyContent: "space-between",
	alignItems: "center",
});
export const orderListActionsClass = css({
	display: "flex",
	alignItems: "center",
	gap: "8",
});
