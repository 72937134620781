import { H1Span } from "@thekeytechnology/epic-ui";
import React from "react";
import { useLazyLoadQuery } from "react-relay";
import { messageDispatches_MessageDispatchesQuery } from "@relay/messageDispatches_MessageDispatchesQuery.graphql";
import { BaseScreen } from "@screens/BaseScreen";
import { MessageDispatchesTable } from "@screens/message-dispatch-edit/parts/message-dispatches-table";
import { MessageDispatchContextProvider } from "@screens/message-dispatches/message-dispatches.context";
import { QUERY } from "@screens/message-dispatches/message-dispatches.graphql";

export const MessageDispatchesScreen = () => {
	const data = useLazyLoadQuery<messageDispatches_MessageDispatchesQuery>(
		QUERY,
		{},
		{ fetchPolicy: "network-only" },
	);

	return (
		<MessageDispatchContextProvider>
			<BaseScreen>
				<H1Span>Versendete Nachrichten</H1Span>
				<MessageDispatchesTable messageDispatchesFragmentRef={data} />
			</BaseScreen>
		</MessageDispatchContextProvider>
	);
};
