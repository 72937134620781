import { graphql } from "babel-plugin-relay/macro";

export const ORDER_HISTORIES_FRAGMENT = graphql`
	fragment orderHistoryTable_OrderHistoriesFragment on Query
	@refetchable(queryName: "OrderHistoryTable_Refetch")
	@argumentDefinitions(first: { type: "Int!" }, after: { type: "String" }) {
		node(id: $id) {
			... on Order {
				history(after: $after, first: $first)
					@connection(key: "OrderHistoryTable_history") {
					edges {
						node {
							...orderHistoryTable_OrderHistoryFragment
						}
					}
				}
			}
		}
	}
`;

export const ORDER_HISTORY_FRAGMENT = graphql`
	fragment orderHistoryTable_OrderHistoryFragment on OrderHistory @inline {
		id
		createdAt
		status
		...orderHistoryEvent_OrderHistoryFragment
	}
`;
