import { useMutation } from "react-relay";
import {
	ADD_EXCLUDE_ACCOUNT_DISPATCH_CONDITION,
	ADD_EXCLUDE_OPT_IN_DISPATCH_CONDITION,
	ADD_EXCLUDE_TREE_FINISHED_DISPATCH_CONDITION,
	ADD_EXCLUDE_TREE_STARTED_DISPATCH_CONDITION,
	ADD_EXCLUDE_TREE_UNLOCKED_DISPATCH_CONDITION,
	ADD_EXCLUDE_USER_DISPATCH_CONDITION,
	ADD_INCLUDE_ACCOUNT_DISPATCH_CONDITION,
	ADD_INCLUDE_ALL_USER_DISPATCH_CONDITION,
	ADD_INCLUDE_OPT_IN_DISPATCH_CONDITION,
	ADD_INCLUDE_TREE_FINISHED_DISPATCH_CONDITION,
	ADD_INCLUDE_TREE_STARTED_DISPATCH_CONDITION,
	ADD_INCLUDE_TREE_UNLOCKED_DISPATCH_CONDITION,
	ADD_INCLUDE_USER_DISPATCH_CONDITION,
} from "@hooks/use-dispatch-conditions/use-dispatch-conditions.graphql";
import { createDispatchCondition } from "@hooks/use-dispatch-conditions/use-dispatch-conditions.utils";
import {
	AddExcludeAccountDispatchConditionInput,
	useDispatchConditions_AddExcludeAccountDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddExcludeAccountDispatchConditionMutation.graphql";
import {
	AddExcludeOptInDispatchConditionInput,
	useDispatchConditions_AddExcludeOptInDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddExcludeOptInDispatchConditionMutation.graphql";
import {
	AddExcludeTreeFinishedDispatchConditionInput,
	useDispatchConditions_AddExcludeTreeFinishedDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddExcludeTreeFinishedDispatchConditionMutation.graphql";
import {
	AddExcludeTreeStartedDispatchConditionInput,
	useDispatchConditions_AddExcludeTreeStartedDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddExcludeTreeStartedDispatchConditionMutation.graphql";
import {
	AddExcludeTreeUnlockedDispatchConditionInput,
	useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation.graphql";
import {
	AddExcludeUserDispatchConditionInput,
	useDispatchConditions_AddExcludeUserDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddExcludeUserDispatchConditionMutation.graphql";
import {
	AddIncludeAccountDispatchConditionInput,
	useDispatchConditions_AddIncludeAccountDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddIncludeAccountDispatchConditionMutation.graphql";
import {
	AddIncludeAllUserDispatchConditionInput,
	useDispatchConditions_AddIncludeAllUsersDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddIncludeAllUsersDispatchConditionMutation.graphql";
import {
	AddIncludeOptInDispatchConditionInput,
	useDispatchConditions_AddIncludeOptInDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddIncludeOptInDispatchConditionMutation.graphql";
import {
	AddIncludeTreeFinishedDispatchConditionInput,
	useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation.graphql";
import {
	AddIncludeTreeStartedDispatchConditionInput,
	useDispatchConditions_AddIncludeTreeStartedDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddIncludeTreeStartedDispatchConditionMutation.graphql";
import {
	AddIncludeTreeUnlockedDispatchConditionInput,
	useDispatchConditions_AddIncludeTreeUnlockedDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddIncludeTreeUnlockedDispatchConditionMutation.graphql";
import {
	AddIncludeUserDispatchConditionInput,
	useDispatchConditions_AddIncludeUserDispatchConditionMutation,
} from "@relay/useDispatchConditions_AddIncludeUserDispatchConditionMutation.graphql";

export const useDispatchConditions = () => {
	const [addIncludeAllUserDispatchCondition] =
		useMutation<useDispatchConditions_AddIncludeAllUsersDispatchConditionMutation>(
			ADD_INCLUDE_ALL_USER_DISPATCH_CONDITION,
		);
	const [addIncludeAccountDispatchCondition] =
		useMutation<useDispatchConditions_AddIncludeAccountDispatchConditionMutation>(
			ADD_INCLUDE_ACCOUNT_DISPATCH_CONDITION,
		);
	const [addExcludeAccountDispatchCondition] =
		useMutation<useDispatchConditions_AddExcludeAccountDispatchConditionMutation>(
			ADD_EXCLUDE_ACCOUNT_DISPATCH_CONDITION,
		);
	const [addIncludeUserDispatchCondition] =
		useMutation<useDispatchConditions_AddIncludeUserDispatchConditionMutation>(
			ADD_INCLUDE_USER_DISPATCH_CONDITION,
		);
	const [addExcludeUserDispatchCondition] =
		useMutation<useDispatchConditions_AddExcludeUserDispatchConditionMutation>(
			ADD_EXCLUDE_USER_DISPATCH_CONDITION,
		);
	const [addIncludeOptInDispatchCondition] =
		useMutation<useDispatchConditions_AddIncludeOptInDispatchConditionMutation>(
			ADD_INCLUDE_OPT_IN_DISPATCH_CONDITION,
		);
	const [addExcludeOptInDispatchCondition] =
		useMutation<useDispatchConditions_AddExcludeOptInDispatchConditionMutation>(
			ADD_EXCLUDE_OPT_IN_DISPATCH_CONDITION,
		);

	const [addIncludeTreeStartedDispatchCondition] =
		useMutation<useDispatchConditions_AddIncludeTreeStartedDispatchConditionMutation>(
			ADD_INCLUDE_TREE_STARTED_DISPATCH_CONDITION,
		);

	const [addExcludeTreeStartedDispatchCondition] =
		useMutation<useDispatchConditions_AddExcludeTreeStartedDispatchConditionMutation>(
			ADD_EXCLUDE_TREE_STARTED_DISPATCH_CONDITION,
		);

	const [addIncludeTreeFinishedDispatchCondition] =
		useMutation<useDispatchConditions_AddIncludeTreeFinishedDispatchConditionMutation>(
			ADD_INCLUDE_TREE_FINISHED_DISPATCH_CONDITION,
		);

	const [addExcludeTreeFinishedDispatchCondition] =
		useMutation<useDispatchConditions_AddExcludeTreeFinishedDispatchConditionMutation>(
			ADD_EXCLUDE_TREE_FINISHED_DISPATCH_CONDITION,
		);

	const [addIncludeTreeUnlockedDispatchCondition] =
		useMutation<useDispatchConditions_AddIncludeTreeUnlockedDispatchConditionMutation>(
			ADD_INCLUDE_TREE_UNLOCKED_DISPATCH_CONDITION,
		);

	const [addExcludeTreeUnlockedDispatchCondition] =
		useMutation<useDispatchConditions_AddExcludeTreeUnlockedDispatchConditionMutation>(
			ADD_EXCLUDE_TREE_UNLOCKED_DISPATCH_CONDITION,
		);

	return [
		createDispatchCondition<AddIncludeAllUserDispatchConditionInput, undefined>(
			"AllUserDispatchCondition",
			undefined,
			(input, config) =>
				addIncludeAllUserDispatchCondition({ ...config, variables: { input } }),
			undefined,
		),
		createDispatchCondition<
			AddIncludeAccountDispatchConditionInput,
			AddExcludeAccountDispatchConditionInput
		>(
			"AccountDispatchCondition",
			"account",
			(input, config) =>
				addIncludeAccountDispatchCondition({ ...config, variables: { input } }),
			(input, config) =>
				addExcludeAccountDispatchCondition({ ...config, variables: { input } }),
		),
		createDispatchCondition<
			AddIncludeUserDispatchConditionInput,
			AddExcludeUserDispatchConditionInput
		>(
			"UserDispatchCondition",
			"user",
			(input, config) => addIncludeUserDispatchCondition({ ...config, variables: { input } }),
			(input, config) => addExcludeUserDispatchCondition({ ...config, variables: { input } }),
		),
		createDispatchCondition<
			AddIncludeOptInDispatchConditionInput,
			AddExcludeOptInDispatchConditionInput
		>(
			"OptInDispatchCondition",
			"newsletterOptIn",
			(input, config) =>
				addIncludeOptInDispatchCondition({ ...config, variables: { input } }),
			(input, config) =>
				addExcludeOptInDispatchCondition({ ...config, variables: { input } }),
		),
		createDispatchCondition<
			AddIncludeTreeStartedDispatchConditionInput,
			AddExcludeTreeStartedDispatchConditionInput
		>(
			"TreeStartedDispatchCondition",
			"rootNode",
			(input, config) =>
				addIncludeTreeStartedDispatchCondition({ ...config, variables: { input } }),
			(input, config) =>
				addExcludeTreeStartedDispatchCondition({ ...config, variables: { input } }),
		),
		createDispatchCondition<
			AddIncludeTreeFinishedDispatchConditionInput,
			AddExcludeTreeFinishedDispatchConditionInput
		>(
			"TreeFinishedDispatchCondition",
			"rootNode",
			(input, config) =>
				addIncludeTreeFinishedDispatchCondition({ ...config, variables: { input } }),
			(input, config) =>
				addExcludeTreeFinishedDispatchCondition({ ...config, variables: { input } }),
		),
		createDispatchCondition<
			AddIncludeTreeUnlockedDispatchConditionInput,
			AddExcludeTreeUnlockedDispatchConditionInput
		>(
			"TreeUnlockedDispatchCondition",
			"rootNode",
			(input, config) =>
				addIncludeTreeUnlockedDispatchCondition({ ...config, variables: { input } }),
			(input, config) =>
				addExcludeTreeUnlockedDispatchCondition({ ...config, variables: { input } }),
		),
	];
};
