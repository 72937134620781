import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useFragment, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant } from "@components/button";
import { createMessageDispatchButton_CreateMessageDispatchMutation } from "@relay/createMessageDispatchButton_CreateMessageDispatchMutation.graphql";
import { createMessageDispatchButton_MessageDefinitionFragment$key } from "@relay/createMessageDispatchButton_MessageDefinitionFragment.graphql";
import { Paths } from "@routes/paths";
import {
	CREATE_MESSAGE_DISPATCH_MUTATION,
	MESSAGE_DEFINITION_FRAGMENT,
} from "@screens/message-definitions/parts/message-definitions-table/create-message-dispatch-button/create-message-dispatch-button.graphql";
import { buttonWrapperClass } from "@screens/message-definitions/parts/message-definitions-table/create-message-dispatch-button/create-message-dispatch-button.styles";
import { SendMessageButtonProps } from "./create-message-dispatch-button.types";

export const CreateMessageDispatchButton = ({
	messageDefinitionFragmentRef,
}: SendMessageButtonProps) => {
	const toast = useRef<Toast>(null);
	const messageDefinition =
		useFragment<createMessageDispatchButton_MessageDefinitionFragment$key>(
			MESSAGE_DEFINITION_FRAGMENT,
			messageDefinitionFragmentRef,
		);

	const [createMessageDispatch] =
		useMutation<createMessageDispatchButton_CreateMessageDispatchMutation>(
			CREATE_MESSAGE_DISPATCH_MUTATION,
		);

	const navigate = useNavigate();
	const handleOnCreateClick = () => {
		if (!messageDefinition) return;
		createMessageDispatch({
			variables: {
				input: {
					messageDefinitionId: messageDefinition.id,
				},
			},
			onCompleted: (response) => {
				const dispatchId =
					response.Admin.Message.createMessageDispatch?.messageDispatch.node.id;

				if (!dispatchId) return;
				navigate(Paths.messagesDispatches.withId(dispatchId).edit.path);
			},
			onError: () => {
				toast.current?.show({
					severity: "error",
					life: 3000,
					summary: "Nachricht nicht gespeichert",
					detail: "Das Nachricht konnte nicht erstellt werden",
				});
			},
		});
	};

	return (
		<div className={buttonWrapperClass}>
			<Toast ref={toast} />
			<Button
				icon={EpicIcons.SEND}
				variant={ButtonVariant.Strong}
				onClick={handleOnCreateClick}
			/>
		</div>
	);
};
