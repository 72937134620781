import { graphql } from "babel-plugin-relay/macro";

export const ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT = graphql`
	fragment answerMatrixElementCellForm_AnswerMatrixElementCellFragment on AnswerMatrixElementCell {
		xIdx
		yIdx
		correctAnswer {
			content
		}
		wrongAnswers {
			content
		}
		...editAnswersMatrixElementCellDialogForm_AnswersMatrixElementCellFragment
	}
`;
