/**
 * @generated SignedSource<<eca6daaf4e7328cfe6625ba5a3b99158>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch$variables = {
  after?: string | null;
  first: number;
  name?: string | null;
};
export type externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch$data = {
  readonly " $fragmentSpreads": FragmentRefs<"externalLicensePoolsTable_ExternalLicensePoolsFragment">;
};
export type externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch = {
  response: externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch$data;
  variables: externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "name"
  }
],
v1 = {
  "kind": "Variable",
  "name": "after",
  "variableName": "after"
},
v2 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "nameOpt",
    "variableName": "name"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch",
    "selections": [
      {
        "args": [
          (v1/*: any*/),
          (v2/*: any*/),
          {
            "kind": "Variable",
            "name": "name",
            "variableName": "name"
          }
        ],
        "kind": "FragmentSpread",
        "name": "externalLicensePoolsTable_ExternalLicensePoolsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminQueryType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "ExternalLicensePoolsConnection",
                "kind": "LinkedField",
                "name": "GetExternalLicensePools",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ExternalLicensePoolsEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalLicensePool",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "canBeDeleted",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ExternalLicensePoolData",
                            "kind": "LinkedField",
                            "name": "data",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "usageInformation",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "validNumDaysAfterIssuing",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "link",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "kind": "ClientExtension",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__id",
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v3/*: any*/),
                "filters": [
                  "nameOpt"
                ],
                "handle": "connection",
                "key": "ExternalLicensePoolsTable_GetExternalLicensePools",
                "kind": "LinkedHandle",
                "name": "GetExternalLicensePools"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8940412ad13fa6bb7721a7941b92f7b5",
    "id": null,
    "metadata": {},
    "name": "externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch",
    "operationKind": "query",
    "text": "query externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch(\n  $after: String\n  $first: Int!\n  $name: String\n) {\n  ...externalLicensePoolsTable_ExternalLicensePoolsFragment_3cTRis\n}\n\nfragment externalLicensePoolsTable_ExternalLicensePoolFragment on ExternalLicensePool {\n  canBeDeleted\n  id\n  data {\n    name\n    usageInformation\n    validNumDaysAfterIssuing\n    link\n  }\n}\n\nfragment externalLicensePoolsTable_ExternalLicensePoolsFragment_3cTRis on Query {\n  Admin {\n    Billing {\n      GetExternalLicensePools(nameOpt: $name, first: $first, after: $after) {\n        edges {\n          node {\n            ...externalLicensePoolsTable_ExternalLicensePoolFragment\n            id\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7acbadfefbf0a5da11dc4bc12184e6de";

export default node;
