import { Button, EpicIcons, H1Span, PathParams } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import React, { useRef } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { ValidatedField } from "@components/ValidatedField";

import { messageDispatchForm_MessageDispatchFragment$key } from "@relay/messageDispatchForm_MessageDispatchFragment.graphql";
import { messageDispatchForm_Query } from "@relay/messageDispatchForm_Query.graphql";
import { messageDispatchForm_SendMessageDispatchMutation } from "@relay/messageDispatchForm_SendMessageDispatchMutation.graphql";
import { Paths } from "@routes/paths";
import { DispatchExclusions } from "@screens/message-dispatch-edit/parts/dispatch-exclusions";
import { DispatchInclusions } from "@screens/message-dispatch-edit/parts/dispatch-inclusions";
import { MessageDispatchFormContextProvider } from "@screens/message-dispatch-edit/parts/message-dispatch-form/message-dispatch-form.context";
import {
	MESSAGE_DISPATCH_FRAGMENT,
	QUERY,
	SEND_MESSAGE_MUTATION,
} from "@screens/message-dispatch-edit/parts/message-dispatch-form/message-dispatch-form.graphql";
import { headerClass } from "@screens/message-dispatch-edit/parts/message-dispatch-form/message-dispatch-form.styles";
import { MessageDispatchFormState } from "@screens/message-dispatch-edit/parts/message-dispatch-form/message-dispatch-form.types";
import { MessageDispatchesPath } from "@screens/message-dispatches";

export const MessageDispatchForm = () => {
	const toast = useRef<Toast>(null);
	const navigate = useNavigate();

	const { messageDispatchId } = useParams<PathParams<typeof MessageDispatchesPath>>();

	const query = useLazyLoadQuery<messageDispatchForm_Query>(QUERY, {
		id: messageDispatchId ?? "",
		skip: !messageDispatchId || messageDispatchId === "new",
	});

	const data = useFragment<messageDispatchForm_MessageDispatchFragment$key>(
		MESSAGE_DISPATCH_FRAGMENT,
		query ?? null,
	);
	const messageDispatch = data?.node;

	const [sendMessage] =
		useMutation<messageDispatchForm_SendMessageDispatchMutation>(SEND_MESSAGE_MUTATION);

	const handleSendMessage = () => {
		if (!messageDispatchId) return;
		formik.setSubmitting(true);
		sendMessage({
			variables: {
				input: {
					messageDispatchId: messageDispatchId,
					sentAtOpt: formik.values.sendAt ?? null,
				},
			},
			onCompleted: () => {
				formik.setSubmitting(false);
				navigate(Paths.messagesDispatches.path);
			},
			onError: () => {
				toast.current?.show({
					severity: "error",
					life: 3000,
					summary: "Nachricht nicht versendet",
					detail: "Das Nachricht konnte nicht versendet werden",
				});
				formik.setSubmitting(false);
			},
		});
	};

	const formik = useFormik<MessageDispatchFormState>({
		initialValues: {
			sendAt: undefined,
		},
		onSubmit: async (values, { setSubmitting }) => {
			handleSendMessage();
			setSubmitting(false);
		},
	});

	const hasNoInclusions = !(messageDispatch?.includes && messageDispatch?.includes?.length > 0);

	return (
		<MessageDispatchFormContextProvider>
			<Toast ref={toast}></Toast>
			<div className={headerClass}>
				<H1Span>
					Nachricht versenden: {messageDispatch?.messageDefinition?.internalTitle}
				</H1Span>
			</div>
			<DispatchInclusions messageDispatchFragmentRef={messageDispatch} />
			<Divider />
			<DispatchExclusions messageDispatchFragmentRef={messageDispatch} />
			<Divider />
			<ValidatedField<MessageDispatchFormState, string>
				name={"sendAt"}
				label={"Versenden am"}
				component={({ fieldName, fieldValue, updateField }) => (
					<Calendar
						name={fieldName}
						dateFormat={"dd.mm.yy"}
						showTime
						showButtonBar
						value={
							fieldValue
								? moment(fieldValue.replace("[UTC]", "")).toDate()
								: undefined
						}
						onChange={(e) => {
							updateField(
								e.value ? moment(e.value as Date).toISOString(true) : undefined,
							);
						}}
					/>
				)}
				formikConfig={formik}
			/>
			<Button
				icon={EpicIcons.SEND}
				label={"Nachricht versenden"}
				onClick={formik.submitForm}
				disabled={hasNoInclusions}
			></Button>
		</MessageDispatchFormContextProvider>
	);
};
