import { graphql } from "babel-plugin-relay/macro";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import * as Yup from "yup";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { VideoSourceField } from "./VideoSourceField";
import { type VideoElementForm_EditVideoElementMutation } from "../../../__generated__/VideoElementForm_EditVideoElementMutation.graphql";
import { type VideoElementForm_VideoElementFragment$key } from "../../../__generated__/VideoElementForm_VideoElementFragment.graphql";
import { ValidatedField } from "../../../components/ValidatedField";
import {
	addEditedFormToEditedFormsArray,
	resetArrayOfEditedForms,
} from "../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../store/store.redux";
import { stripHtml } from "../../../util/html.utils";

const VIDEO_ELEMENT_FRAGMENT = graphql`
	fragment VideoElementForm_VideoElementFragment on VideoElement {
		id
		title
		vimeoId
	}
`;

const EDIT_VIDEO_ELEMENT_MUTATION = graphql`
	mutation VideoElementForm_EditVideoElementMutation($input: EditVideoElementV2Input!) {
		Admin {
			ElearningV2 {
				editVideoElement(input: $input) {
					videoElement {
						...VideoElementForm_VideoElementFragment
					}
				}
			}
		}
	}
`;

type FormState = {
	title: string;
	vimeoId?: string;
};

type OwnProps = {
	videoElementFragmentRef: VideoElementForm_VideoElementFragment$key;
	onBack: () => void;
};

export const VideoElementForm = ({ videoElementFragmentRef, onBack }: OwnProps) => {
	const element = useFragment<VideoElementForm_VideoElementFragment$key>(
		VIDEO_ELEMENT_FRAGMENT,
		videoElementFragmentRef,
	);
	const [editVideoElement, isEditingVideoElement] =
		useMutation<VideoElementForm_EditVideoElementMutation>(EDIT_VIDEO_ELEMENT_MUTATION);

	const formId = "VideoElementForm";
	const dispatch = useTypedDispatch();
	const formik = useFormik<FormState>({
		initialValues: {
			title: stripHtml(element.title),
			vimeoId: element.vimeoId,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Das Feld Titel wird benötigt."),
			vimeoId: Yup.string().required("Das Feld Video wird benötigt."),
		}),
		onSubmit: (values) => {
			editVideoElement({
				variables: {
					input: {
						videoElementId: element.id,
						title: values.title,
						vimeoId: values.vimeoId ?? "",
					},
				},
				onCompleted: () => {
					dispatch(resetArrayOfEditedForms());
				},
			});
		},
	});

	return (
		<form onSubmit={formik.handleSubmit} className="p-fluid">
			<ValidatedField<FormState, string>
				name={"title"}
				label={"Titel"}
				component={DefaultTextFieldComponent}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				formikConfig={formik}
			/>

			<ValidatedField<FormState, string>
				name={"vimeoId"}
				label={"Video"}
				onChange={() => dispatch(addEditedFormToEditedFormsArray({ form: formId }))}
				component={({ fieldValue, updateField, onChange }) => {
					return (
						<VideoSourceField
							onChange={onChange}
							onUpdate={updateField}
							vimeoId={fieldValue}
						/>
					);
				}}
				formikConfig={formik}
			/>

			<Button
				disabled={isEditingVideoElement}
				type="submit"
				label="Speichern"
				className="mt-2"
			/>

			<Button
				type="button"
				onClick={() => {
					onBack();
				}}
				label="Zurück"
				className="p-button-secondary mt-2"
			/>
		</form>
	);
};
