import React, { useState } from "react";
import { MessageDefinitionsTableFiltersFormState } from "@screens/message-definitions/parts/message-definitions-table/message-definitions-table-filters/message-definitions-table-filters.types";

export type IMessageDefinitionContext = {
	connectionId: string;
	setConnectionId: React.Dispatch<string>;
	filters: MessageDefinitionsTableFiltersFormState;
	setFilters: (filters: IMessageDefinitionContext["filters"]) => void;
};

export const MessageDefinitionsContext = React.createContext<IMessageDefinitionContext>({
	filters: {
		filterByTextOpt: undefined,
	},
	setFilters: () => {},
	connectionId: "",
	setConnectionId: () => {},
});

export const MessageDefinitionsContextProvider = ({ children }: React.PropsWithChildren) => {
	const [connectionId, setConnectionId] = React.useState("");
	const [filterByTextOpt, setFilterByTextOpt] = useState<string | undefined>("");
	const value: IMessageDefinitionContext = {
		filters: {
			filterByTextOpt,
		},
		setFilters: (filters) => {
			setFilterByTextOpt(filters.filterByTextOpt);
		},
		connectionId,
		setConnectionId,
	};
	return (
		<MessageDefinitionsContext.Provider value={value}>
			{children}
		</MessageDefinitionsContext.Provider>
	);
};
