import { Button } from "primereact/button";
import { usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type TasksTable_Refetch } from "@relay/TasksTable_Refetch.graphql";
import { type tasksTable_TasksFragment$key } from "@relay/tasksTable_TasksFragment.graphql";
import { Paths } from "@routes/paths";
import { TASKS_FRAGMENT } from "./tasks-table.graphql";
import { type TasksTableProps } from "./tasks-table.interface";
import { convertTaskStatus } from "./tasks-table.utils";
import { DataTable } from "../../../components/data-table";

export const TasksTable = ({ tasksFragmentRef }: TasksTableProps) => {
	const navigate = useNavigate();
	const {
		data: Admin,
		hasPrevious,
		hasNext,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<TasksTable_Refetch, tasksTable_TasksFragment$key>(
		TASKS_FRAGMENT,
		tasksFragmentRef,
	);

	const tasks = Admin.Admin.AsyncContent.GetAsyncElementTasks.edges;

	const canModify = useHasPermissions(["UserInAccountPermission_AsyncElementTaskAdmin_Modify"]);

	return (
		<>
			<DataTable
				emptyMessage="Keine Aufgaben"
				className="mb-3"
				value={tasks?.map((b) => b!.node!)}
			>
				{(Column) => (
					<>
						<Column header="Einreichende" field={"submissionInfo.by.name"} />
						<Column header="Weiterbildung" field={"extension.hierarchy.rootTitle"} />
						<Column header="Modul" field={"extension.hierarchy.branchTitle"} />
						<Column header="Lektion" field={"extension.hierarchy.asyncContentTitle"} />
						<Column
							header="Status"
							field={"status"}
							body={(item) => convertTaskStatus(item.status)}
						/>
						{canModify && (
							<Column
								header="Aktionen"
								style={{ width: "20%" }}
								body={(item) => (
									<>
										<Button
											className="mr-2"
											onClick={() => {
												navigate(Paths.tasks.withId(item.id).edit.path);
											}}
											icon={"pi pi-pencil"}
										/>
									</>
								)}
							/>
						)}
					</>
				)}
			</DataTable>
			<div className="flex justify-content-center align-items-center">
				<Button
					disabled={!hasPrevious}
					onClick={() => loadPrevious(20)}
					className="mr-3 p-button-secondary"
				>
					Zurück
				</Button>
				<Button
					className="p-button-secondary"
					disabled={!hasNext}
					onClick={() => loadNext(20)}
				>
					Weiter
				</Button>
			</div>
		</>
	);
};
