/**
 * @generated SignedSource<<030f7b14d89bf4464fcf7e0de89d105b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateLimitedCartDiscountInput = {
  clientMutationId?: string | null;
};
export type createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation$variables = {
  connections: ReadonlyArray<string>;
  input: CreateLimitedCartDiscountInput;
};
export type createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation$data = {
  readonly Admin: {
    readonly Billing: {
      readonly createLimitedCartDiscount: {
        readonly edge: {
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"limitedCartDiscountTable_LimitedCartDiscountFragment">;
          };
        };
      } | null;
    };
  };
};
export type createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation = {
  response: createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation$data;
  variables: createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v6 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "value",
      "storageKey": null
    }
  ],
  "type": "PercentageProductSelectionConfig",
  "abstractKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLimitedCartDiscountPayload",
                "kind": "LinkedField",
                "name": "createLimitedCartDiscount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LimitedCartDiscountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LimitedCartDiscount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "kind": "InlineDataFragmentSpread",
                            "name": "limitedCartDiscountTable_LimitedCartDiscountFragment",
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/),
                              (v5/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "productSelectionConfig",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "conditions",
                                "plural": true,
                                "selections": [
                                  (v7/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "args": null,
                            "argumentDefinitions": []
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "BillingAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Billing",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v2/*: any*/),
                "concreteType": "CreateLimitedCartDiscountPayload",
                "kind": "LinkedField",
                "name": "createLimitedCartDiscount",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "LimitedCartDiscountEdge",
                    "kind": "LinkedField",
                    "name": "edge",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "LimitedCartDiscount",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "productSelectionConfig",
                            "plural": false,
                            "selections": [
                              (v8/*: any*/),
                              (v6/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": null,
                            "kind": "LinkedField",
                            "name": "conditions",
                            "plural": true,
                            "selections": [
                              (v8/*: any*/),
                              (v7/*: any*/),
                              (v3/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "filters": null,
                    "handle": "appendEdge",
                    "key": "",
                    "kind": "LinkedHandle",
                    "name": "edge",
                    "handleArgs": [
                      {
                        "kind": "Variable",
                        "name": "connections",
                        "variableName": "connections"
                      }
                    ]
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca1f7a7a60ad016dc8bc01fbd1855dbe",
    "id": null,
    "metadata": {},
    "name": "createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation",
    "operationKind": "mutation",
    "text": "mutation createLimitedCartDiscountButton_CreateLimitedCartDiscountMutation(\n  $input: CreateLimitedCartDiscountInput!\n) {\n  Admin {\n    Billing {\n      createLimitedCartDiscount(input: $input) {\n        edge {\n          node {\n            ...limitedCartDiscountTable_LimitedCartDiscountFragment\n            id\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment limitedCartDiscountTable_LimitedCartDiscountFragment on LimitedCartDiscount {\n  id\n  title\n  createdAt\n  productSelectionConfig {\n    __typename\n    ... on PercentageProductSelectionConfig {\n      value\n    }\n  }\n  conditions {\n    __typename\n    kind\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4a5f30856179172dcffac3ca67382bc3";

export default node;
