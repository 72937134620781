import { InputMask } from "primereact/inputmask";

import styled, { css } from "styled-components";
import tw from "twin.macro";

export const Wrapper = tw.div`
	tw-relative
`;

export const GrowDiv = styled.div`
	${tw`
		tw-flex 
		tw-flex-col
		tw-w-full
	`}
	&.p-input-icon-left > i {
		margin-top: -0.75rem;
	}
	&.p-input-icon-left > .p-inputtext {
		padding-left: 2.5rem;
	}
`;

export const Label = styled.label`
	${tw`
		tw-mb-8
	`}
`;

const InputCSS = css<{ error: boolean }>`
	${tw`tw-w-full`}
	border-radius: 0.25rem;
`;

export const StyledInputMask = styled(InputMask)<{
	error: boolean;
}>`
	${InputCSS}
`;

export const SuggestionsWrapper = styled.div<{ width: number }>`
	${tw`
		tw-flex
		tw-flex-col
		tw-overflow-hidden
		tw-mt-4
	`}
	width: ${({ width }) => width}px;
`;
