/**
 * @generated SignedSource<<d0ce06e393c6f58f9a37a5d63bb2aebc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type offersFilters_QueryFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"selectAcademiesTagField_QueryFragment">;
  readonly " $fragmentType": "offersFilters_QueryFragment";
};
export type offersFilters_QueryFragment$key = {
  readonly " $data"?: offersFilters_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"offersFilters_QueryFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "offersFilters_QueryFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "selectAcademiesTagField_QueryFragment"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "7e9a31a71fa0d8b479dd5615b65881f0";

export default node;
