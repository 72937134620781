import { ButtonType, EpicIcons } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import React, { useContext } from "react";
import { Button } from "@components/button";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { MessageDefinitionsContext } from "@screens/message-definitions/message-definitions.context";
import { filterClass } from "@screens/message-definitions/parts/message-definitions-table/message-definitions-table-filters/message-definitions-table-filters.styles";
import { MessageDefinitionsTableFiltersFormState } from "@screens/message-definitions/parts/message-definitions-table/message-definitions-table-filters/message-definitions-table-filters.types";

export const MessageDefinitionsTableFilters = () => {
	const { filters, setFilters } = useContext(MessageDefinitionsContext);

	const formik = useFormik<MessageDefinitionsTableFiltersFormState>({
		initialValues: {
			...filters,
		},
		onSubmit: (values: MessageDefinitionsTableFiltersFormState, { setSubmitting }) => {
			setFilters({ filterByTextOpt: values.filterByTextOpt });
			setSubmitting(false);
		},
	});

	const clearForm = () => {
		setFilters({});
		void formik.setFieldValue("filterByTextOpt", "");
		formik.handleSubmit();
	};

	return (
		<div className={filterClass}>
			<ValidatedField<MessageDefinitionsTableFiltersFormState, string>
				name="filterByTextOpt"
				placeholder={"Interner Titel..."}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>

			<Button
				disabled={false}
				type={ButtonType.Reset}
				onClick={clearForm}
				icon={EpicIcons.TIMES}
			/>
			<Button onClick={formik.submitForm} type={ButtonType.Submit} icon={EpicIcons.SEARCH} />
		</div>
	);
};
