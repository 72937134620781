import { Card } from "primereact/card";
import { useFragment } from "react-relay";
import { TREE_NODE_FRAGMENT } from "./content-card.graphql";
import { type ContentCardProps } from "./content-card.types";
import { BaseContentEditor } from "../BaseContentEditor";
import { AsyncContentEditor } from "../async-content-editor";
import { ELearningContentEditor } from "../elearning-content-editor";

export const ContentCard = ({ treeNodeFragmentRef }: ContentCardProps) => {
	const node = useFragment(TREE_NODE_FRAGMENT, treeNodeFragmentRef);

	return (
		<Card className="mb-2">
			{(() => {
				switch (node.typeDefinition.definitionType) {
					case "branch":
						return (
							<BaseContentEditor>
								<p>Ein Verzweig-Ordner kann keine Elemente haben.</p>
							</BaseContentEditor>
						);
					case "content":
						switch (node.typeDefinition.contentKind) {
							case "ELearning":
								return <ELearningContentEditor treeNodeFragmentRef={node} />;
							case "Async":
								return <AsyncContentEditor treeNodeFragmentRef={node} />;
							default:
								return null;
						}
					default:
						return null;
				}
			})()}
		</Card>
	);
};
