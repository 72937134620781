import { Paths } from "@routes/paths";
import { InstructorEditRoutes } from "@screens/instructor-edit";
import { InstructorsScreen } from "./instructors.screen";
import { type RouteDefinition } from "../../routes/route-definition";

export const InstructorsRoutes: RouteDefinition[] = [
	{
		path: Paths.instructors.path,
		element: <InstructorsScreen />,
		requiredPermissions: ["UserInAccountPermission_Instructors_ReadInstructors"],
	},
	...InstructorEditRoutes,
];
