import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { EmailTemplatesScreen } from "./email-templates.screen";
import { EmailTemplateEditRoutes } from "../email-template-edit";

export const EmailTemplatesRoutes: RouteDefinition[] = [
	{
		path: Paths.emailTemplates.path,
		element: <EmailTemplatesScreen />,
		requiredPermissions: ["UserInAccountPermission_Email_Templates"],
	},
	...EmailTemplateEditRoutes,
];
