import graphql from "babel-plugin-relay/macro";

export const USER_IN_ACCOUNT_FRAGMENT = graphql`
	fragment editUserInAccountGroup_UserInAccountFragment on UserInAccount {
		groups {
			id
			name
		}
		user {
			id
		}
	}
`;

export const SET_USER_IN_ACCOUNT_GROUPS_MUTATION = graphql`
	mutation editUserInAccountGroup_SetUserInAccountGroupsMutation(
		$input: SetUserInAccountGroupsInput!
	) {
		Admin {
			Auth {
				setUserInAccountGroups(input: $input) {
					userInAccount {
						node {
							groups {
								id
								name
							}
							user {
								id
								name
								email
								activated
							}
						}
					}
				}
			}
		}
	}
`;
