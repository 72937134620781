import styled from "styled-components";
import { DataTable } from "../../../components/data-table";

export const StyledDataTable = styled(DataTable)`
	.is-expired-row {
		background: repeating-linear-gradient(
			45deg,
			#eeeeee,
			#eeeeee 10px,
			#ffffff 10px,
			#ffffff 20px
		) !important;
	}
` as typeof DataTable;
