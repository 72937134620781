import { Button } from "primereact/button";
import React from "react";
import { type TimestampWithImageTemplateProps } from "./timestamp-with-image-template.interface";

export const TimestampWithImageTemplate = ({
	timestampWithImage,
	edit,
	remove,
}: TimestampWithImageTemplateProps) => {
	return (
		<div className="flex align-items-center justify-content-between">
			<div>
				{timestampWithImage.start}
				{" - "}
				{timestampWithImage.end}
				{" - "}
				{timestampWithImage.imageFileId ? "mit Bild" : "ohne Bild"}
			</div>
			<div>
				<Button type="button" className="ml-2" icon="pi pi-pencil" onClick={edit} />
				<Button type="button" className="ml-2" icon="pi pi-trash" onClick={remove} />
			</div>
		</div>
	);
};
