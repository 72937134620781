import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div`
	display: flex;
	gap: 0.5rem;
	margin-bottom: 1rem;
`;

export const HeaderWrapper = tw.div`
	tw-flex
	tw-flex-row
	tw-justify-between
`;
