import React from "react";
import { readInlineData, usePaginationFragment } from "react-relay";
import { type DataTableSelectionChangeParams } from "@components/data-table";
import { SearchableTable } from "@components/searchable-table";
import { SelectComplexDataField } from "@components/select-complex-data-field";
import {
	type selectAcademiesTagField_AcademiesTagFragment$data,
	type selectAcademiesTagField_AcademiesTagFragment$key,
} from "@relay/selectAcademiesTagField_AcademiesTagFragment.graphql";
import { type selectAcademiesTagField_QueryFragment$key } from "@relay/selectAcademiesTagField_QueryFragment.graphql";
import { type selectAcademiesTagField_Refetch } from "@relay/selectAcademiesTagField_Refetch.graphql";
import { ACADEMIES_TAG_FRAGMENT, QUERY } from "./select-academies-tag-field.graphql";
import {
	type SelectAcademiesTagFieldProps,
	type SelectAcademiesTagFieldState,
} from "./select-academies-tag-field.types";

export const SelectAcademiesTagField = ({
	queryFragmentRef,
	...props
}: SelectAcademiesTagFieldProps) => {
	const {
		data: {
			Admin: {
				AcademiesTag: { SelectTags },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		selectAcademiesTagField_Refetch,
		selectAcademiesTagField_QueryFragment$key
	>(QUERY, queryFragmentRef);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ name: search });
	};

	const handleOnSelectionChange = (
		event: DataTableSelectionChangeParams<selectAcademiesTagField_AcademiesTagFragment$data[]>,
	) => {
		props.updateField(event.value);
	};

	const values =
		SelectTags.edges
			?.filter((e) => !!e?.node)
			.map((e) =>
				readInlineData<selectAcademiesTagField_AcademiesTagFragment$key>(
					ACADEMIES_TAG_FRAGMENT,
					e!.node,
				),
			) || [];
	return (
		<SelectComplexDataField<SelectAcademiesTagFieldState>
			valuePlaceholder="Keine Schlagworte ausgewaählt"
			actionButtonLabel="Schlagworte auswählen"
			headerLabel="Wähle Schlagworte aus"
			{...props}
		>
			<SearchableTable
				dataKey={"id"}
				selectionMode="multiple"
				selection={props.fieldValue}
				hasPrevious={hasPrevious}
				metaKeySelection={false}
				hasNext={hasNext}
				value={values}
				loadPrevious={handleLoadPrevious}
				loadNext={handleLoadNext}
				onSearch={handleOnSearch}
				onSelectionChange={handleOnSelectionChange}
			>
				{(Column) => (
					<>
						<Column header="Name" body={(e) => e.data.name} />
					</>
				)}
			</SearchableTable>
		</SelectComplexDataField>
	);
};
