/**
 * @generated SignedSource<<71779eb7ffa009489de02568f5f8d70b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type RestartIfFailedContentConfigType = "RestartIfFailedContent_BlockPermanently" | "RestartIfFailedContent_BlockTemporarily" | "RestartIfFailedContent_CanNot";
import { FragmentRefs } from "relay-runtime";
export type BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$data = {
  readonly configType: RestartIfFailedContentConfigType;
  readonly daysToBlock: number;
  readonly id: string;
  readonly " $fragmentType": "BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment";
};
export type BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$key = {
  readonly " $data"?: BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlockTemporarilyRestartIfFailedContentConfigForm_BlockTemporarilyRestartIfFailedContentConfigFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "configType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "daysToBlock",
      "storageKey": null
    }
  ],
  "type": "BlockTemporarilyRestartIfFailedContentConfig",
  "abstractKey": null
};

(node as any).hash = "31fc17720cecfb72573df8294dcaabbd";

export default node;
