import { InputText } from "primereact/inputtext";
import React from "react";
import { TextDisplayFieldProps } from "./text-display-field.interface";

export const TextDisplayField = ({ label, value }: TextDisplayFieldProps) => {
	return (
		<div className="field col">
			<label htmlFor={value} className="col-fixed">
				{label}
			</label>
			<InputText
				id={value}
				tooltip={value}
				tooltipOptions={{ position: "top" }}
				className="w-full"
				value={value}
			/>
		</div>
	);
};
