import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import React from "react";
import { useMutation } from "react-relay";
import { type DeleteDiscountCodeButton_DeleteMutation } from "@relay/DeleteDiscountCodeButton_DeleteMutation.graphql";

const DELETE_DISCOUNT_CODE_MUTATION = graphql`
	mutation DeleteDiscountCodeButton_DeleteMutation(
		$input: DeleteDiscountCodeInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				deleteDiscountCode(input: $input) {
					deletedIds @deleteEdge(connections: $connections)
				}
			}
		}
	}
`;

type OwnProps = {
	id: string;
	className?: string;
	connectionId: string;
};

export const DeleteDiscountCodeButton = ({ connectionId, className, id }: OwnProps) => {
	const [deleteCode, isLoading] = useMutation<DeleteDiscountCodeButton_DeleteMutation>(
		DELETE_DISCOUNT_CODE_MUTATION,
	);

	return (
		<Button
			icon="pi pi-trash"
			tooltip="Aktion löschen"
			loading={isLoading}
			disabled={isLoading}
			className={className}
			onClick={(e) => {
				deleteCode({
					variables: {
						input: {
							ids: [id],
						},
						connections: [connectionId],
					},
				});
			}}
		/>
	);
};
