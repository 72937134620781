/**
 * @generated SignedSource<<2250e256bc1c6b83300faaece3b2e9a0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type selectAcademiesTagField_AcademiesTagFragment$data = {
  readonly data: {
    readonly isClickable: boolean;
    readonly isTopic: boolean;
    readonly name: string;
  };
  readonly id: string;
  readonly " $fragmentType": "selectAcademiesTagField_AcademiesTagFragment";
};
export type selectAcademiesTagField_AcademiesTagFragment$key = {
  readonly " $data"?: selectAcademiesTagField_AcademiesTagFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"selectAcademiesTagField_AcademiesTagFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "selectAcademiesTagField_AcademiesTagFragment"
};

(node as any).hash = "33dd369d63f82e5b0ed5eea16b3a5273";

export default node;
