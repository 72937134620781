import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ProductEditRoutes } from "@screens/product-edit";
import { ProductsScreen } from "./products.screen";

export const ProductsRoutes: RouteDefinition[] = [
	{
		path: Paths.products.path,
		element: <ProductsScreen />,
		requiredPermissions: ["UserInAccountPermission_ProductAdmin_Read"],
	},
	...ProductEditRoutes,
];
