/**
 * @generated SignedSource<<8dc3c90777f1c99ea13d439cfd2705f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type EditImageWithButtonPageInput = {
  clientMutationId?: string | null;
  image: string;
  internalTitle: string;
  messageDefinitionId: string;
  pageId: string;
};
export type useMessagePageMutations_EditImageButtonPageMutation$variables = {
  input: EditImageWithButtonPageInput;
};
export type useMessagePageMutations_EditImageButtonPageMutation$data = {
  readonly Admin: {
    readonly MessageDefinitions: {
      readonly editImageWithButtonPage: {
        readonly messageDefinition: {
          readonly node: {
            readonly id: string;
          };
        };
      } | null;
    };
  };
};
export type useMessagePageMutations_EditImageButtonPageMutation = {
  response: useMessagePageMutations_EditImageButtonPageMutation$data;
  variables: useMessagePageMutations_EditImageButtonPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "MessageDefinitionsAdminMutationSchema",
        "kind": "LinkedField",
        "name": "MessageDefinitions",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "input",
                "variableName": "input"
              }
            ],
            "concreteType": "EditImageWithButtonPagePayload",
            "kind": "LinkedField",
            "name": "editImageWithButtonPage",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MessageDefinitionsEdge",
                "kind": "LinkedField",
                "name": "messageDefinition",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MessageDefinition",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "id",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useMessagePageMutations_EditImageButtonPageMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useMessagePageMutations_EditImageButtonPageMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2628a893119afd68dadbbd46492b2df5",
    "id": null,
    "metadata": {},
    "name": "useMessagePageMutations_EditImageButtonPageMutation",
    "operationKind": "mutation",
    "text": "mutation useMessagePageMutations_EditImageButtonPageMutation(\n  $input: EditImageWithButtonPageInput!\n) {\n  Admin {\n    MessageDefinitions {\n      editImageWithButtonPage(input: $input) {\n        messageDefinition {\n          node {\n            id\n          }\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c45dd8d575b01c2e49a915c04dbd7214";

export default node;
