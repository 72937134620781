import { ProgressSpinner } from "primereact/progressspinner";
import { Suspense } from "react";
import { GenerateCartLinkForm } from "./parts/generate-cart-link-form";

export const GenerateCartLinkScreen = () => {
	return (
		<div>
			<Suspense fallback={<ProgressSpinner />}>
				<h2 className="mb-3 text-center">Warenkorblink Generator</h2>
				<GenerateCartLinkForm />
			</Suspense>
		</div>
	);
};
