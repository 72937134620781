import { Button, ButtonType, PathParams } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { Divider } from "primereact/divider";
import React, { useRef } from "react";

import { useFragment } from "react-relay";

import { useParams } from "react-router-dom";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { PageKindDropdown } from "@components/page-kind-dropdown";
import { ValidatedField } from "@components/ValidatedField";
import { FileSelectionField, FileV2 } from "@features/files/file-selection-field";
import {
	messagePageForm_PageFragment$key,
	PageDataKind,
} from "@relay/messagePageForm_PageFragment.graphql";

import { PAGE_FRAGMENT } from "@screens/message-definition-edit/parts/message-page-form/message-page-form.graphql";
import {
	MessagePageFormProps,
	MessagePageFormSchema,
	MessagePageFormState,
} from "@screens/message-definition-edit/parts/message-page-form/message-page-form.types";
import { PageButtonForm } from "@screens/message-definition-edit/parts/page-button-form";
import {
	PageButtonFormRef,
	PageButtonFormState,
} from "@screens/message-definition-edit/parts/page-button-form/page-button-form.types";
import { MessageDefinitionsPath } from "@screens/message-definitions";

export const MessagePageForm = ({
	pageFragmentRef,
	isCreating,
	onSubmit,
}: MessagePageFormProps) => {
	const { messageDefinitionId } = useParams<PathParams<typeof MessageDefinitionsPath>>();
	const page = useFragment<messagePageForm_PageFragment$key>(
		PAGE_FRAGMENT,
		pageFragmentRef ?? null,
	);

	const initialData = {
		internalTitle: page?.internalTitle ?? "",
		kind: page?.data.kind ?? "",
		element: {
			id: page?.data?.element?.image?.id ?? "",
			name: page?.data?.element?.image?.name ?? "",
		},
	};

	const formik = useFormik<MessagePageFormState>({
		initialValues: initialData,
		validationSchema: MessagePageFormSchema,
		onSubmit: async (values) => {
			if (!messageDefinitionId) return;
			onSubmit?.(values, pageButtonFormRef?.current?.values);
		},
	});

	const pageButtonFormRef = useRef<PageButtonFormRef>(null);
	const handlePageButtonFormOnSubmit = async (values: PageButtonFormState) => {
		await formik.setFieldValue("button", values);
	};

	const handleSubmit = async () => {
		if (formik.values.kind === "imageWithButton") {
			await pageButtonFormRef?.current?.submit();
			const buttonFormIsValid = await pageButtonFormRef.current?.validate();
			if (!buttonFormIsValid) return;
		}

		formik.handleSubmit();
	};
	return (
		<>
			<ValidatedField<MessagePageFormState, PageDataKind>
				disabled={!isCreating}
				name={"kind"}
				label={"Art der Seite"}
				required
				component={PageKindDropdown}
				formikConfig={formik}
			/>
			<ValidatedField<MessagePageFormState, string>
				name={"internalTitle"}
				label={"Interner Titel"}
				required
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<MessagePageFormState, FileV2>
				name={"element"}
				label={"Bild"}
				required
				component={({ fieldValue, updateField }) => {
					return (
						<FileSelectionField
							name={"element"}
							selectedFile={fieldValue}
							setSelectedFile={updateField}
							filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
							canUploadFiles={true}
							canDeleteFiles={true}
						/>
					);
				}}
				formikConfig={formik}
			/>
			{formik.values.kind === "imageWithButton" && (
				<>
					<Divider />
					<PageButtonForm
						ref={pageButtonFormRef}
						buttonFragmentRef={page?.data}
						onSubmit={handlePageButtonFormOnSubmit}
					/>
				</>
			)}
			<Button type={ButtonType.Submit} label={"Speichern"} onClick={handleSubmit} />
		</>
	);
};
