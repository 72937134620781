import { Button } from "primereact/button";
import { SearchableTableProps } from "./searchable-table.types";
import { PaginationTable } from "../pagination-table/pagination-table.component";
import { InputText } from "primereact/inputtext";
import { useState } from "react";

export const SearchableTable = <T,>({ onSearch, ...props }: SearchableTableProps<T>) => {
	const [search, setSearch] = useState("");
	const handleSearchOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSearch(event.target.value);
	};
	const handleClearOnClick = () => {
		setSearch("");
		onSearch?.("");
	};
	const handleSearchOnClick = () => {
		onSearch?.(search);
	};
	return (
		<PaginationTable
			header={
				<div className="flex flex-row align-items-center">
					<InputText
						placeholder="Suchen..."
						value={search}
						onChange={handleSearchOnChange}
						className="mr-2 w-20rem"
					/>
					<Button
						disabled={false}
						type="reset"
						onClick={handleClearOnClick}
						icon="pi pi-times"
					/>
					<Button
						disabled={false}
						onClick={handleSearchOnClick}
						type="button"
						icon="pi pi-search"
						className="ml-2"
					/>
				</div>
			}
			{...props}
		/>
	);
};
