import styled from "styled-components";
import { Card as PRCard } from "primereact/card";

export const Card = styled(PRCard)`
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.15);
	border-radius: 8px;

	.p-card-content {
		padding: 0;
	}

	&.card-flat {
		box-shadow: none !important;
	}
`;
