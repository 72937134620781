import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import * as React from "react";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type editNodeForm_EditNodeCoreMutation } from "@relay/editNodeForm_EditNodeCoreMutation.graphql";
import { type editNodeForm_TreeNodeFragment$key } from "@relay/editNodeForm_TreeNodeFragment.graphql";
import { SHORT_DESCRIPTION_MAX_LENGTH } from "./edit-node-form.const";
import { EDIT_NODE_CORE_MUTATION, TREE_NODE_FRAGMENT } from "./edit-node-form.graphql";
import { type EditNodeFormProps, type EditNodeFormState } from "./edit-node-form.types";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { MemoizedRichTextEditor } from "../../../../components/rich-text-editor";
import { RichTextEditorToolbar } from "../../../../components/rich-text-editor/rich-text-editor.const";
import { ValidatedField } from "../../../../components/ValidatedField";
import { FileSelectionField, type FileV2 } from "../../../../features/files/file-selection-field";
import { InstructorsMultiSelect } from "../../../../features/instructors/InstructorsMultiSelect";
import {
	addEditedFormToEditedFormsArray,
	removeEditedFormFromEditedFormsArray,
} from "../../../../store/slices/CoreSlice";
import { useTypedDispatch } from "../../../../store/store.redux";
import { SHORT_DESCRIPTION } from "../../../../translations/short-description-label";

export const EditNodeForm = ({ treeNodeFragmentRef }: EditNodeFormProps) => {
	const node = useFragment<editNodeForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const dispatch = useTypedDispatch();
	const [editNodeCore, isEditingNodeCore] =
		useMutation<editNodeForm_EditNodeCoreMutation>(EDIT_NODE_CORE_MUTATION);

	const formId = "AcademiesEditNodeForm";
	const formik = useFormik<EditNodeFormState>({
		enableReinitialize: true,
		initialValues: {
			title: node.structureDefinition.title,
			description: node.description ?? undefined,
			shortDescription: node.shortDescription ?? undefined,
			image: node.image
				? ({
						id: node.image.id,
						name: node.image.name,
						url: node.image.url,
				  } satisfies FileV2)
				: undefined,
			instructors: node.instructors.map((i) => i.superId) ?? null,
		},
		validationSchema: Yup.object().shape({
			title: Yup.string().required("Titel wird benötigt"),
			shortDescription: Yup.string().max(
				SHORT_DESCRIPTION_MAX_LENGTH,
				`Überschreitet ${SHORT_DESCRIPTION_MAX_LENGTH} Zeichen`,
			),
		}),

		onSubmit: (values, { setSubmitting }) => {
			editNodeCore({
				variables: {
					input: {
						nodeId: node.id,
						title: values.title,
						description: values.description,
						shortDescription: values.shortDescription,
						imageId: values.image?.id,
						instructorIds: values.instructors || [],
					},
				},
				onError: () => {
					toast.error("Fehler beim speichern der Weiterbildung.");
				},
				onCompleted: () => {
					void formik.setTouched({});
					setSubmitting(false);
					dispatch(removeEditedFormFromEditedFormsArray({ form: formId }));
				},
			});
		},
	});

	const handleFormEdited = React.useCallback(
		() => dispatch(addEditedFormToEditedFormsArray({ form: formId })),
		[dispatch],
	);

	const canUpload = useHasPermissions(["UserInAccountPermission_Nodes_UpdateNodes"]);
	const canDelete = useHasPermissions("onlyOwnerOfRoot");

	const disabled = Object.entries(formik.touched).length === 0 || isEditingNodeCore;

	return (
		<Card className="mb-2">
			<h2>Ordner-Einstellungen</h2>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<EditNodeFormState, string>
					name={"title"}
					label={"Titel"}
					component={DefaultTextFieldComponent}
					onChange={handleFormEdited}
					formikConfig={formik}
				/>
				<ValidatedField<EditNodeFormState, string>
					name={"description"}
					label={"Beschreibung"}
					component={(props) => (
						<MemoizedRichTextEditor
							{...props}
							height={200}
							toolbar={RichTextEditorToolbar.WithLists}
						/>
					)}
					onChange={handleFormEdited}
					formikConfig={formik}
				/>
				<ValidatedField<EditNodeFormState, string[]>
					name={"instructors"}
					label={"Expert:innen"}
					helpText={"Wählen Sie die für das Modul verantwortlichen Lehrenden."}
					onChange={handleFormEdited}
					component={InstructorsMultiSelect}
					formikConfig={formik}
				/>
				<ValidatedField<EditNodeFormState, string>
					name={"shortDescription"}
					label={SHORT_DESCRIPTION.short_description}
					helpText={`Maximal ${SHORT_DESCRIPTION_MAX_LENGTH} Zeichen (${
						formik.values.shortDescription?.length ?? 0
					} / ${SHORT_DESCRIPTION_MAX_LENGTH})`}
					component={DefaultTextFieldComponent}
					onChange={handleFormEdited}
					formikConfig={formik}
				/>

				<ValidatedField<EditNodeFormState, FileV2>
					name={"image"}
					label={"Bild"}
					onChange={handleFormEdited}
					component={({ fieldName, fieldValue, updateField, onChange }) => {
						return (
							<FileSelectionField
								name={fieldName}
								selectedFile={fieldValue}
								setSelectedFile={updateField}
								filterByFileTypes={["image/png", "image/jpg", "image/jpeg"]}
								onChange={onChange}
								canUploadFiles={canUpload}
								canDeleteFiles={canDelete}
							/>
						);
					}}
					formikConfig={formik}
				/>

				<Button disabled={disabled} type="submit" label="Speichern" className="p-mt-2" />
			</form>
		</Card>
	);
};
