/**
 * @generated SignedSource<<022c7780a07fb3b20690d01a19aeddf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAccountGroupsForm_AccountFragment$data = {
  readonly groupAssociations: ReadonlyArray<{
    readonly group: {
      readonly id: string;
      readonly name: string;
    } | null;
  }>;
  readonly id: string;
  readonly " $fragmentType": "editAccountGroupsForm_AccountFragment";
};
export type editAccountGroupsForm_AccountFragment$key = {
  readonly " $data"?: editAccountGroupsForm_AccountFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAccountGroupsForm_AccountFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "editAccountGroupsForm_AccountFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AccountGroupAssociation",
      "kind": "LinkedField",
      "name": "groupAssociations",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AccountGroup",
          "kind": "LinkedField",
          "name": "group",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Account",
  "abstractKey": null
};
})();

(node as any).hash = "669f01dadec2ea78de1b981165604861";

export default node;
