import { Column } from "primereact/column";
import { DataTable, type DataTableSelectionSingleChangeEvent } from "primereact/datatable";

import { useCallback } from "react";
import {
	dataTableClass,
	emptyMessageClass,
} from "@features/single-selection-data-table/single-selection-data-table.styles";
import { SingleSelectionDataTableProps } from "@features/single-selection-data-table/single-selection-data-table.types";

export const SingleSelectionDataTable = ({
	selectedUserId,
	values,
	onSelectionChange,
}: SingleSelectionDataTableProps) => {
	const handleSelection = useCallback(
		(event: DataTableSelectionSingleChangeEvent<any>) => {
			onSelectionChange(event);
		},
		[onSelectionChange],
	);

	return (
		<DataTable
			emptyMessage={
				<div className="flex justify-content-center align-items-center">
					<div className={emptyMessageClass}>
						Es wurde kein Benutzer mit dieser E-Mail adresse oder diesem Namen gefunden.
					</div>
				</div>
			}
			className={dataTableClass}
			selectionMode={"single"}
			selection={{ node: { id: selectedUserId } }}
			dataKey="node.id"
			onSelectionChange={handleSelection}
			value={values ?? []}
		>
			<Column header="Name" field="node.name" />
			<Column header="E-Mail" field="node.email" />
			<Column
				header="Aktiviert"
				body={(user) => {
					return user?.node?.activated ? "Aktiviert" : "Nicht Aktiviert";
				}}
			/>
		</DataTable>
	);
};
