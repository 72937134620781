/**
 * @generated SignedSource<<fb50861d65367578a18429d3931d0b69>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditOrderItemButton_OrderItemFragment$data = {
  readonly id: string;
  readonly text: string;
  readonly " $fragmentType": "EditOrderItemButton_OrderItemFragment";
};
export type EditOrderItemButton_OrderItemFragment$key = {
  readonly " $data"?: EditOrderItemButton_OrderItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditOrderItemButton_OrderItemFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditOrderItemButton_OrderItemFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "OrderItem",
  "abstractKey": null
};

(node as any).hash = "a888f278417d75daf33d687a1eb85f09";

export default node;
