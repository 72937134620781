import graphql from "babel-plugin-relay/macro";

export const ACCOUNT_FRAGMENT = graphql`
	fragment editAccountGroupsForm_AccountFragment on Account {
		id
		groupAssociations {
			group {
				id
				name
			}
		}
	}
`;

export const ACCOUNT_GROUP_FRAGMENT = graphql`
	fragment editAccountGroupsForm_AccountGroupFragment on AccountGroup @inline {
		id
		name
	}
`;

export const UPDATE_ACCOUNT_GROUPS_MUTATION = graphql`
	mutation editAccountGroupsForm_UpdateAccountGroupsMutation($input: SetAccountGroupsInput!) {
		Admin {
			Auth {
				setAccountGroups(input: $input) {
					account {
						...editAccountGroupsForm_AccountFragment
					}
				}
			}
		}
	}
`;
