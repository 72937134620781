import { Button } from "primereact/button";
import { confirmDialog, ConfirmDialog } from "primereact/confirmdialog";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import { type usersInAccountTable_RemoveUserFromAccountMutation } from "@relay/usersInAccountTable_RemoveUserFromAccountMutation.graphql";
import { type usersInAccountTable_UserInAccountConnectionFragment$key } from "@relay/usersInAccountTable_UserInAccountConnectionFragment.graphql";
import { USER_GROUP_NAME } from "./users-in-account-table.consts";
import {
	REMOVE_USER_FROM_ACCOUNT_MUTATION,
	USER_IN_ACCOUNT_CONNECTION_FRAGMENT,
} from "./users-in-account-table.graphql";
import {
	type EditUserInAccountGroups,
	type UsersInAccountTableProps,
} from "./users-in-account-table.interface";
import { convertUserInAccountGroupName } from "./users-in-account-table.util";
import { DataTable } from "../../../components/data-table";
import { EditUserInAccountGroup } from "../edit-user-in-account-group/edit-user-in-account-group.component";

export const UsersInAccountTable = ({
	accountId,
	connections,
	hasNext,
	loadNext,
	userInAccountConnectionRef,
}: UsersInAccountTableProps) => {
	const { edges: users } = useFragment<usersInAccountTable_UserInAccountConnectionFragment$key>(
		USER_IN_ACCOUNT_CONNECTION_FRAGMENT,
		userInAccountConnectionRef,
	);

	const [removeUserFromAccount] = useMutation<usersInAccountTable_RemoveUserFromAccountMutation>(
		REMOVE_USER_FROM_ACCOUNT_MUTATION,
	);

	const toastRef = useRef<Toast>(null);

	const [editUserInAccountGroups, setEditUserInAccountGroups] = useState<
		EditUserInAccountGroups | undefined
	>();

	const handleEditUserOnClick = (
		userId: string,
		groups: ReadonlyArray<{ readonly id: string; readonly name: string }>,
	) => {
		const groupsId = groups?.filter((g) => g.name !== USER_GROUP_NAME).map((g) => g.id);
		setEditUserInAccountGroups({ userId, groupsId });
	};

	const handleUserInAccountGroupDialogOnHide = () => {
		setEditUserInAccountGroups(undefined);
	};

	const handleDeleteUserOnClick = (userId: string) => {
		confirmDialog({
			message: "Bist du sicher, dass du diesen Benutzer aus dem Account entfernen möchtest?",
			header: "Benutzer entfernen",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			acceptLabel: "Ja",
			rejectLabel: "Nein",
			accept: () =>
				removeUserFromAccount({
					variables: {
						input: {
							userId,
							accountId,
						},
						connections: connections ?? [],
					},
				}),
		});
	};

	return (
		<div className="mb-5">
			<DataTable
				emptyMessage={
					<div className="flex justify-content-center align-items-center">
						<div className="mr-2">Es wurden keine Benutzer gefunden.</div>
					</div>
				}
				className="mb-3"
				value={users?.map((edge) => edge!.node)}
			>
				{(Column) => (
					<>
						<Column header="Name" field="user.name" />
						<Column header="E-Mail" field="user.email" />
						<Column
							header="Aktiviert"
							body={(row) => {
								return row?.user?.activated ? "Aktiviert" : "Nicht Aktiviert";
							}}
						/>
						<Column
							header="Berechtigungen"
							body={(row) => {
								return row?.groups?.map((groupAssociation: any) => {
									return (
										<div key={groupAssociation.id} className="flex">
											<div>
												{convertUserInAccountGroupName(
													groupAssociation.name,
												)}
											</div>
										</div>
									);
								});
							}}
						/>
						<Column
							header="Aktionen"
							body={(row) => {
								return (
									<div>
										<Button
											onClick={() => {
												handleEditUserOnClick(row.user.id, row.groups);
											}}
											icon={"pi pi-pencil"}
										/>
										<Button
											className="ml-2"
											onClick={() => {
												handleDeleteUserOnClick(row.user.id);
											}}
											icon={"pi pi-trash"}
										/>
									</div>
								);
							}}
						/>
					</>
				)}
			</DataTable>
			{hasNext && (
				<div className="flex justify-content-center align-items-center">
					<Button
						type="button"
						className="p-button-secondary"
						disabled={!hasNext}
						onClick={loadNext}
					>
						Load more
					</Button>
				</div>
			)}
			<Dialog
				visible={!!editUserInAccountGroups}
				onHide={handleUserInAccountGroupDialogOnHide}
				className="w-25rem"
			>
				{editUserInAccountGroups && (
					<EditUserInAccountGroup
						accountId={accountId!}
						userId={editUserInAccountGroups.userId}
						groupsId={editUserInAccountGroups.groupsId}
						onUserEdited={handleUserInAccountGroupDialogOnHide}
					/>
				)}
			</Dialog>
			<Toast ref={toastRef} />
			<ConfirmDialog />
		</div>
	);
};
