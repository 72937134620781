/**
 * @generated SignedSource<<d7bd9ac18c9f738008762699741402b6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type flowAndCurrentElementContentConfigEditor_CurrentElement_TreeNodeFragment$data = {
  readonly id: string;
  readonly " $fragmentType": "flowAndCurrentElementContentConfigEditor_CurrentElement_TreeNodeFragment";
};
export type flowAndCurrentElementContentConfigEditor_CurrentElement_TreeNodeFragment$key = {
  readonly " $data"?: flowAndCurrentElementContentConfigEditor_CurrentElement_TreeNodeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"flowAndCurrentElementContentConfigEditor_CurrentElement_TreeNodeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "flowAndCurrentElementContentConfigEditor_CurrentElement_TreeNodeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "TreeNode",
  "abstractKey": null
};

(node as any).hash = "4292128b9f08c73635992d91f53bcdaf";

export default node;
