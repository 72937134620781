import { Paths } from "@routes/paths";

import { type RouteDefinition } from "@routes/route-definition";
import { AccountEditScreen } from "./account-edit.screen";
import { AccountEditBaseData } from "../account-edit-base-data";

export const AccountEditRoutes: RouteDefinition[] = [
	{
		path: Paths.accounts.withIdPlaceholder().edit.path,
		element: <AccountEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_AuthAdmin_Read",
			"UserInAccountPermission_AuthAdmin_Modify",
		],
	},
	{
		path: Paths.accounts.withIdPlaceholder().edit.basedata.path,
		element: <AccountEditBaseData />,
		requiredPermissions: ["AccountPermission_System_Root"],
	},
];
