import { PageButtonKindDropdownType } from "@components/page-button-kind-dropdown/page-button-kind-dropdown.types";

export const pageButtonKindOptions: PageButtonKindDropdownType[] = [
	{
		value: "confirm",
		label: "Bestätigen-Button",
	},
	{
		value: "link",
		label: "Link-Button",
	},
	{
		value: "textCopy",
		label: "Kopieren-Button",
	},
];
