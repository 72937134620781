/**
 * @generated SignedSource<<3cf725ccc1d357cba0524a0568703eb6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type editAccountGroupsButton_AccountGroupFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"editAccountGroupsForm_AccountGroupFragment">;
  readonly " $fragmentType": "editAccountGroupsButton_AccountGroupFragment";
};
export type editAccountGroupsButton_AccountGroupFragment$key = {
  readonly " $data"?: editAccountGroupsButton_AccountGroupFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"editAccountGroupsButton_AccountGroupFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "editAccountGroupsButton_AccountGroupFragment"
};

(node as any).hash = "1f00412b7ab555ea408f8212c2511f4c";

export default node;
