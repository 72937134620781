export class Spacing {
	constructor(private readonly px: number) {}

	rem(): string {
		return `${this.remValue()}rem`;
	}

	remValue(): number {
		return this.px / 16;
	}
}

export const spacing4: Spacing = new Spacing(4);

export const spacing6: Spacing = new Spacing(6);

export const spacing8: Spacing = new Spacing(8);

export const spacing12: Spacing = new Spacing(12);

export const spacing16: Spacing = new Spacing(16);

export const spacing20: Spacing = new Spacing(20);

export const spacing24: Spacing = new Spacing(24);

export const spacing32: Spacing = new Spacing(32);

export const spacing40: Spacing = new Spacing(40);
export const spacing64: Spacing = new Spacing(64);
export const spacing80: Spacing = new Spacing(80);
export const spacing96: Spacing = new Spacing(96);

export const defaultPaddings = "4px 8px";
