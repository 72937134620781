import { graphql } from "babel-plugin-relay/macro";

export const TREE_NODE_FRAGMENT = graphql`
	fragment contentConfigEditor_TreeNodeFragment on TreeNode {
		typeDefinition {
			... on AsyncContentTypeDefinition {
				contentKind
			}
			... on ELearningContentTypeDefinition {
				contentKind
			}
		}
		...startContentConfigEditor_TreeNodeFragment
		...passContentConfigEditor_TreeNodeFragment
		...RestartIfFailedContentConfigEditor_TreeNodeFragment
		...RestartIfPassedContentConfigEditor_TreeNodeFragment
		...flowAndCurrentElementContentConfigEditor_TreeNodeFragment
	}
`;
