/**
 * @generated SignedSource<<e42c5174fa27cc2c6340fcd9467f17d0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type DiscountActionUsageLimitationKind = "limited" | "unlimited";
export type DiscountActionValidUntilKind = "untilDateTime" | "untilForever";
export type DiscountTypeKind = "system" | "user";
import { FragmentRefs } from "relay-runtime";
export type discountActionForm_DiscountActionFragment$data = {
  readonly calcType: {
    readonly percentage?: any;
  };
  readonly discountType: {
    readonly kind: DiscountTypeKind;
  };
  readonly id: string;
  readonly title: string;
  readonly usageLimitation: {
    readonly kind: DiscountActionUsageLimitationKind;
    readonly maxAmountOfUsages?: {
      readonly max?: number;
    };
    readonly maxAmountOfUsagesPerAccount?: {
      readonly max?: number;
    };
    readonly onlyForAccountIds?: ReadonlyArray<string>;
    readonly onlyForProductIds?: ReadonlyArray<string>;
  };
  readonly validUntil: {
    readonly dateTime?: string;
    readonly kind: DiscountActionValidUntilKind;
  };
  readonly " $fragmentType": "discountActionForm_DiscountActionFragment";
};
export type discountActionForm_DiscountActionFragment$key = {
  readonly " $data"?: discountActionForm_DiscountActionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"discountActionForm_DiscountActionFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "kind",
  "storageKey": null
},
v1 = [
  {
    "kind": "InlineFragment",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "max",
        "storageKey": null
      }
    ],
    "type": "DiscountActionAmountLimited",
    "abstractKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "discountActionForm_DiscountActionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "calcType",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "percentage",
              "storageKey": null
            }
          ],
          "type": "DiscountActionPercentageCalcType",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "validUntil",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "dateTime",
              "storageKey": null
            }
          ],
          "type": "DiscountActionValidUntilDateTime",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "discountType",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "usageLimitation",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "maxAmountOfUsages",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "maxAmountOfUsagesPerAccount",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onlyForAccountIds",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "onlyForProductIds",
              "storageKey": null
            }
          ],
          "type": "DiscountActionUsageLimited",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountAction",
  "abstractKey": null
};
})();

(node as any).hash = "24e880ae7ed3f5baccf8d423364847ca";

export default node;
