import { useFormik } from "formik";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import * as Yup from "yup";
import {
	type AddExternalLicenseFormState,
	type AddExternalLicenseProps,
} from "./add-external-license-form.interface";
import { DefaultTextFieldComponent } from "../../../../components/DefaultTextInput";
import { ValidatedField } from "../../../../components/ValidatedField";

export const AddExternalLicenseForm = ({
	onSubmitCallback,
	addExternalLicenseDialogVisible,
	onHideToggle,
}: AddExternalLicenseProps) => {
	const formik = useFormik<AddExternalLicenseFormState>({
		enableReinitialize: false,
		initialValues: {
			name: "Neue Lizenz",
			code: "",
		},
		onSubmit: (values, { setSubmitting }) => {
			onSubmitCallback(values);
			setSubmitting(false);
			formik.resetForm();
		},
		validationSchema: Yup.object().shape({
			name: Yup.string().required("Name muss befüllt sein"),
			code: Yup.string().required("Code muss befüllt sein"),
			usageInformation: Yup.string().optional(),
			validUntil: Yup.date().optional(),
			daysOfValidityAfterIssuing: Yup.number().optional(),
		}),
	});

	return (
		<>
			<Dialog
				header={"Externe Lizenz"}
				visible={addExternalLicenseDialogVisible}
				maximizable
				modal
				style={{ width: "50vw" }}
				onHide={() => {
					onHideToggle(false);
				}}
			>
				<form onSubmit={formik.handleSubmit} className="p-fluid">
					<ValidatedField<AddExternalLicenseFormState, string>
						name={"name"}
						label={"Name"}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>
					<ValidatedField<AddExternalLicenseFormState, string>
						name={"code"}
						label={"Code"}
						component={DefaultTextFieldComponent}
						formikConfig={formik}
					/>

					<Button
						disabled={Object.entries(formik.touched).length === 0}
						type="submit"
						label="Anlegen"
						className="mt-2"
					/>

					<Button
						type="button"
						onClick={() => {
							onHideToggle(false);
						}}
						label="Zurück"
						className="p-button-secondary mt-2"
					/>
				</form>
			</Dialog>
		</>
	);
};
