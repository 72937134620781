import React, { useEffect } from "react";
import {
	readInlineData,
	usePaginationFragment,
	usePreloadedQuery,
	useQueryLoader,
} from "react-relay";
import { type searchablePublishedRootTable_LearnOpportunityV2Fragment$key } from "@relay/searchablePublishedRootTable_LearnOpportunityV2Fragment.graphql";
import { type searchablePublishedRootTable_Query } from "@relay/searchablePublishedRootTable_Query.graphql";
import { type searchablePublishedRootTable_QueryFragment$key } from "@relay/searchablePublishedRootTable_QueryFragment.graphql";
import { type searchablePublishedRootTable_QueryRefetch } from "@relay/searchablePublishedRootTable_QueryRefetch.graphql";
import {
	LEARN_OPPORTUNITY_V2,
	QUERY,
	QUERY_FRAGMENT,
} from "./searchable-published-root-table.graphql";
import {
	type SearchablePublishedRootTableSelection,
	type SearchablePublishedRootTableProps,
	type SearchablePublishedRootTableWithPreloadedQueryProps,
} from "./searchable-published-root-table.types";
import { type DataTableSelectionChangeParams } from "../../../components/data-table/data-table.interface";
import { SearchableTable } from "../../../components/searchable-table/searchable-table.component";

export const SearchablePublishedRootTableWithPreloadedQuery = ({
	selection,
	onChange,
	selectionMode = "single",
	queryRef,
}: SearchablePublishedRootTableWithPreloadedQueryProps) => {
	const query = usePreloadedQuery<searchablePublishedRootTable_Query>(QUERY, queryRef);

	const {
		data: {
			Admin: {
				LearnV2: { SearchLearnOpportunityV2: rootNodes },
			},
		},
		hasPrevious,
		hasNext,
		refetch,
		loadPrevious,
		loadNext,
	} = usePaginationFragment<
		searchablePublishedRootTable_QueryRefetch,
		searchablePublishedRootTable_QueryFragment$key
	>(QUERY_FRAGMENT, query);

	const handleLoadPrevious = () => {
		loadPrevious(20);
	};

	const handleLoadNext = () => {
		loadNext(20);
	};

	const handleOnSearch = (search: string) => {
		refetch({ titleMatchRegex: search });
	};

	const handleOnSelectionChange = (event: DataTableSelectionChangeParams<any>) => {
		onChange?.(event.value);
	};

	const values =
		rootNodes?.edges
			?.map((e) =>
				readInlineData<searchablePublishedRootTable_LearnOpportunityV2Fragment$key>(
					LEARN_OPPORTUNITY_V2,
					e!.node,
				),
			)
			.map((node) => ({ id: node.id, title: node.structureDefinition.title })) ?? [];

	return (
		<SearchableTable<SearchablePublishedRootTableSelection>
			selectionMode={selectionMode}
			selection={selection}
			dataKey="id"
			value={values}
			metaKeySelection={false}
			hasPrevious={hasPrevious}
			hasNext={hasNext}
			loadPrevious={handleLoadPrevious}
			loadNext={handleLoadNext}
			onSearch={handleOnSearch}
			onSelectionChange={handleOnSelectionChange}
		>
			{(Column) => (
				<>
					<Column header="Name" field="title" />
				</>
			)}
		</SearchableTable>
	);
};

export const SearchablePublishedRootTable = ({ ...props }: SearchablePublishedRootTableProps) => {
	const [queryReference, loadQuery] = useQueryLoader<searchablePublishedRootTable_Query>(QUERY);

	useEffect(() => {
		loadQuery({}, { fetchPolicy: "store-and-network" });
	}, [loadQuery]);

	return queryReference ? (
		<SearchablePublishedRootTableWithPreloadedQuery {...props} queryRef={queryReference} />
	) : null;
};
