export class Color {
	private readonly r: number;
	private readonly g: number;
	private readonly b: number;
	private readonly a: number;

	constructor(rgbHex: string | "transparent", a: number = 1) {
		try {
			if (rgbHex !== "transparent") {
				const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(rgbHex)!;

				this.r = parseInt(result[1], 16);
				this.g = parseInt(result[2], 16);
				this.b = parseInt(result[3], 16);
				this.a = a;
			} else {
				this.r = -1;
				this.g = -1;
				this.b = -1;
				this.a = -1;
			}
		} catch (e) {
			this.r = -1;
			this.g = -1;
			this.b = -1;
			this.a = -1;
		}
	}

	hexValue(): string {
		if (this.r === -1) {
			return "transparent";
		}

		function componentToHex(c: number) {
			const hex = c.toString(16);
			return hex.length === 1 ? "0" + hex : hex;
		}

		return "#" + componentToHex(this.r) + componentToHex(this.g) + componentToHex(this.b);
	}

	rgbaValue(): string {
		if (this.r === -1) {
			return "transparent";
		}

		return `rgba(${this.r.toString()}, ${this.g.toString()}, ${this.b.toString()}, ${this.a.toString()})`;
	}

	withAlpha(alpha: number): Color {
		return new Color(this.hexValue(), alpha);
	}
}

export const transparent: Color = new Color("transparent");

export const white: Color = new Color("#ffffff");
export const black: Color = new Color("#0A0A0A");
export const borderColorDark: Color = new Color("#CCCCCC");
export const borderColor: Color = new Color("#E5E5E5");
export const borderColorLight: Color = new Color("#F2F2F2");
export const backgroundColor: Color = new Color("#FAFAFA");
export const accentLight: Color = new Color("#F0F7F7");
export const accent: Color = new Color("#2D8575");
export const accent65: Color = new Color(accent.hexValue(), 0.65);
export const accent2: Color = new Color(accent.hexValue(), 0.02);

export const utilityGood: Color = new Color("#3DD598");
export const utilityGoodLight: Color = new Color(utilityGood.hexValue(), 0.08);

export const utilityBad: Color = new Color("#FC5959");
export const utilityBadLight: Color = new Color(utilityBad.hexValue(), 0.08);

export const textDefault = black.withAlpha(0.8);

export const textContrast = new Color("0A0A0A");
export const textSubtle = new Color(textContrast.hexValue(), 0.65);
export const textExtraSubtle = new Color(textContrast.hexValue(), 0.4);

export const chartOne = new Color("#6E80C9");
export const chartTwo = new Color("#36B4D8");
