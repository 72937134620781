import { Button } from "primereact/button";
import { readInlineData, useMutation, usePaginationFragment } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type externalLicensePoolsTable_CreateExternalLicensePoolMutation } from "@relay/externalLicensePoolsTable_CreateExternalLicensePoolMutation.graphql";
import { type externalLicensePoolsTable_ExternalLicensePoolFragment$key } from "@relay/externalLicensePoolsTable_ExternalLicensePoolFragment.graphql";
import { type externalLicensePoolsTable_ExternalLicensePoolsFragment$key } from "@relay/externalLicensePoolsTable_ExternalLicensePoolsFragment.graphql";
import { type externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch } from "@relay/externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch.graphql";
import { Paths } from "@routes/paths";
import {
	CREATE_EXTERNAL_LICENSE_POOL_MUTATION,
	EXTERNAL_LICENSE_POOL_FRAGMENT,
	EXTERNAL_LICENSE_POOLS_FRAGMENT,
} from "./external-license-pools-table.graphql";
import { type ExternalLicensePoolsTableProps } from "./external-license-pools-table.interface";
import { DataTable } from "../../../components/data-table";
import { Pagination } from "../../../components/Pagination";
import { DeleteExternalLicensePoolButton } from "../delete-external-license-pool-button/delete-external-license-pool-button.component";
import { EditExternalLicensePoolButton } from "../edit-external-license-pool-button/edit-external-license-pool-button.component";
import { ExternalLicensePoolFilters } from "../externa-license-pool-table-filters/external-license-pool-table-filters.component";
import { ViewExternalLicensePoolButton } from "../view-external-license-pool-button/view-external-license-pool-button.component";

export const ExternalLicensePoolsTable = ({
	externalLicensePoolsFragmentRef,
	externalLicensePoolsPerPage,
}: ExternalLicensePoolsTableProps) => {
	const {
		data: externalLicensePools,
		hasNext,
		loadNext,
		refetch,
	} = usePaginationFragment<
		externalLicensePoolsTable_ExternalLicensePoolsFragmentRefetch,
		externalLicensePoolsTable_ExternalLicensePoolsFragment$key
	>(EXTERNAL_LICENSE_POOLS_FRAGMENT, externalLicensePoolsFragmentRef);

	const externalLicensePoolsConnection =
		externalLicensePools.Admin.Billing.GetExternalLicensePools.edges?.map((edge) =>
			readInlineData<externalLicensePoolsTable_ExternalLicensePoolFragment$key>(
				EXTERNAL_LICENSE_POOL_FRAGMENT,
				edge?.node!,
			),
		);
	const entitiesConnection =
		[externalLicensePools.Admin.Billing.GetExternalLicensePools.__id] || [];

	const navigate = useNavigate();

	const [createExternalLicensePool] =
		useMutation<externalLicensePoolsTable_CreateExternalLicensePoolMutation>(
			CREATE_EXTERNAL_LICENSE_POOL_MUTATION,
		);

	const refetchFilters = (name?: string) =>
		refetch({
			name,
			first: externalLicensePoolsPerPage,
		});

	const onClickCreate = () => {
		createExternalLicensePool({
			variables: {
				input: {
					data: {
						name: "Neuer Lizenz-Pool",
						usageInformation: "Nutzungsinfo",
						validNumDaysAfterIssuing: 1,
						link: "",
					},
				},
				connections: entitiesConnection,
			},
			onCompleted: (result) => {
				const externalLicensePoolId =
					result.Admin.Billing.createExternalLicensePool?.edge.node.id!;
				navigate(Paths.externalLicensePools.withId(externalLicensePoolId).edit.path);
			},
		});
	};

	const canModify = useHasPermissions(["UserInAccountPermission_ExternalLicenseAdmin_Modify"]);

	return (
		<>
			<DataTable
				responsiveLayout="stack"
				emptyMessage={"Keine Externen Lizenz-Pools"}
				className="p-mb-2"
				header={
					<div className="flex flex-row">
						<ExternalLicensePoolFilters refetch={refetchFilters} />
						<div className="flex flex-grow-1" />
						{canModify && (
							<Button
								label="Neuer Pool"
								style={{ height: "50px" }}
								className="justify-content-center"
								onClick={onClickCreate}
							/>
						)}
					</div>
				}
				value={externalLicensePoolsConnection}
			>
				{(Column) => (
					<>
						<Column
							header={"ID"}
							body={(externalLicensePool) => (
								<div
									className="cursor-pointer"
									onClick={async () => {
										await navigator.clipboard.writeText(externalLicensePool.id);
									}}
								>
									<b>
										ID<i className="pi pi-copy"></i>
									</b>
								</div>
							)}
						/>
						<Column
							header={"Name"}
							body={(externalLicensePool) => (
								<div>{externalLicensePool.data.name ?? "-kein Name-"}</div>
							)}
						/>
						<Column
							header={"Gültigkeit (in Tagen)"}
							body={(externalLicensePool) => (
								<div>
									{externalLicensePool.data.validNumDaysAfterIssuing ?? "1"}
								</div>
							)}
						/>
						<Column
							header={"Link"}
							body={(externalLicensePool) => (
								<div>{externalLicensePool.data?.link ?? ""}</div>
							)}
						/>
						<Column
							header="Aktionen"
							style={{ width: "20%" }}
							body={(externalLicensePool) => (
								<div className="flex gap-2">
									<ViewExternalLicensePoolButton
										externalLicensePoolId={externalLicensePool.id}
									/>
									{canModify && (
										<>
											<EditExternalLicensePoolButton
												externalLicensePoolId={externalLicensePool.id}
											/>
											{externalLicensePool.canBeDeleted && (
												<DeleteExternalLicensePoolButton
													externalLicensePoolId={externalLicensePool.id}
													connections={entitiesConnection}
												/>
											)}
										</>
									)}
								</div>
							)}
						/>
					</>
				)}
			</DataTable>
			<Pagination hasNext={hasNext} loadNext={() => loadNext(externalLicensePoolsPerPage)} />
		</>
	);
};
