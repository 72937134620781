import { graphql } from "babel-plugin-relay/macro";

export const ORDERS_FRAGMENT = graphql`
	fragment ordersTable_OrdersFragment on Query
	@refetchable(queryName: "ordersTable_OrdersFragmentRefetch")
	@argumentDefinitions(
		username: { type: "String" }
		email: { type: "String" }
		accountId: { type: "ID" }
		userId: { type: "ID" }
		first: { type: "Int" }
		after: { type: "String" }
	) {
		Admin {
			Billing {
				Orders(
					first: $first
					after: $after
					username: $username
					email: $email
					accountId: $accountId
					userId: $userId
				) @connection(key: "OrdersTable_Orders") {
					edges {
						node {
							...ordersTable_OrderFragment
						}
					}
				}
			}
		}
	}
`;

export const ORDER_FRAGMENT = graphql`
	fragment ordersTable_OrderFragment on Order @inline {
		id
		createdAt
		status
		selectedPaymentMethod {
			selectedPaymentMethodType
		}
		...orderPriceDisplay_OrderFragment
		...paymentProviderLink_OrderFragment
	}
`;
