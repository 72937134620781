import graphql from "babel-plugin-relay/macro";

export const QUERY = graphql`
	query limitedCartDiscountEditScreen_LimitedCartDiscountQuery($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on LimitedCartDiscount {
				title
				...limitedCartDiscountForm_LimitedCartDiscountFragment
			}
		}
	}
`;
