import { ValidatedField } from "@thekeytechnology/framework-react-components";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import React from "react";
import { useFragment, useMutation } from "react-relay";
import { type setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment$key } from "@relay/setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment.graphql";
import { type setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation } from "@relay/setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation.graphql";
import { type setDirectFeedbackFlowConfigForm_TreeNodeFragment$key } from "@relay/setDirectFeedbackFlowConfigForm_TreeNodeFragment.graphql";
import { FLOW_CONFIG_TRANSLATIONS } from "src/translations/educational-offer";
import {
	DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_FRAGMENT,
	EDIT_DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION,
	TREE_NODE_FRAGMENT,
} from "./set-direct-feedback-flow-config-form.graphql";
import {
	type SetDirectFeedbackFlowConfigFormProps,
	type SetDirectFeedbackFlowConfigFormState,
} from "./set-direct-feedback-flow-config-form.types";

export const SetDirectFeedbackFlowConfigForm = ({
	configurationFragmentRef,
	treeNodeFragmentRef,
	onBack,
}: SetDirectFeedbackFlowConfigFormProps) => {
	const treeNode = useFragment<setDirectFeedbackFlowConfigForm_TreeNodeFragment$key>(
		TREE_NODE_FRAGMENT,
		treeNodeFragmentRef,
	);
	const configuration =
		useFragment<setDirectFeedbackFlowConfigForm_DirectFeedbackFlowELearningContentConfigFragment$key>(
			DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_FRAGMENT,
			configurationFragmentRef,
		);
	const [edit, isEditing] =
		useMutation<setDirectFeedbackFlowConfigForm_EditDirectFeedbackFlowELearningContentConfigMutation>(
			EDIT_DIRECT_FEEDBACK_FLOW_E_LEARNING_CONTENT_CONFIG_MUTATION,
		);

	const formik = useFormik<SetDirectFeedbackFlowConfigFormState>({
		enableReinitialize: true,
		initialValues: {
			minNumTriesTillShowAnswer: configuration.minNumTriesTillShowAnswer,
		},
		onSubmit: (values, { setSubmitting, setTouched }) => {
			edit({
				variables: {
					input: {
						contentId: treeNode.id,
						configId: configuration.id,
						minNumTriesTillShowAnswer: values.minNumTriesTillShowAnswer,
					},
				},
			});

			setSubmitting(false);
			setTouched({});
		},
	});

	return (
		<>
			<form onSubmit={formik.handleSubmit} className="p-fluid">
				<ValidatedField<SetDirectFeedbackFlowConfigFormState, number>
					name={"minNumTriesTillShowAnswer"}
					label={FLOW_CONFIG_TRANSLATIONS[configuration.configType]}
					component={({ fieldName, fieldValue, updateField }) => (
						<div>
							<InputNumber
								name={fieldName}
								step={1}
								value={fieldValue}
								onChange={(e) => {
									updateField(e.value ?? 3);
								}}
							/>
						</div>
					)}
					formikConfig={formik}
				/>

				<Button
					disabled={Object.entries(formik.touched).length === 0 || isEditing}
					type="submit"
					label="Speichern"
					className="mt-2"
				/>

				<Button
					type="button"
					onClick={() => {
						onBack!();
					}}
					label="Zurück"
					className="p-button-secondary mt-2"
				/>
			</form>
		</>
	);
};
