import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { toast } from "react-toastify";
import { RESET_STAGING_USER_PROGRESS_MUTATION } from "./reset-staging-user-progress-button.graphql";

export const ResetStagingUserProgressButton = () => {
	const [resetStagingUserProgress, isResettingStagingUserProgress] = useMutation(
		RESET_STAGING_USER_PROGRESS_MUTATION,
	);

	const handleOnClick = () => {
		resetStagingUserProgress({
			variables: {},
			onCompleted: () => {
				toast.success("Nutzer-Lernfortschritt zurückgesetzt");
			},
		});
	};

	return (
		<Button
			className={"mb-2"}
			label={"Staging Lern-Fortschritte resetten"}
			disabled={isResettingStagingUserProgress}
			onClick={handleOnClick}
		/>
	);
};
