import graphql from "babel-plugin-relay/macro";

// export const QUERY_FRAGMENT = graphql`
// 	fragment licenseProductForm_QueryFragment on Query {
// 		...selectLicenseDefinitionField_QueryFragment @arguments(excludeMicroLearnings: true)
// 	}
// `;

export const LICENSE_PRODUCT_FRAGMENT = graphql`
	fragment licenseProductForm_LicenseProductFragment on LicenseProduct {
		id
		title
		isHidden
		price {
			netPrice
			grossPrice
			taxRatePercentage
		}
		licenseDefinition {
			id
			data {
				name
			}
		}
		licenseDefinitionAmount
		costUnit
	}
`;

export const EDIT_LICENSE_PRODUCT_MUTATION = graphql`
	mutation licenseProductForm_EditLicenseProductMutation($input: EditLicenseProductInput!) {
		Admin {
			Billing {
				editLicenseProduct(input: $input) {
					product {
						...licenseProductForm_LicenseProductFragment
					}
				}
			}
		}
	}
`;
