import { Card } from "primereact/card";
import styled from "styled-components";

type OwnProps = {
	children: any;
};

export const BaseScreen = ({ children }: OwnProps) => {
	return (
		<div className="flex justify-content-center align-items-center flex-grow-1 pt-5 pb-5 surface-50">
			<ContentContainerCard>{children}</ContentContainerCard>
		</div>
	);
};

export const BaseScreenNoBackground = ({ children }: OwnProps) => {
	return (
		<div className="flex justify-content-center align-items-center flex-grow-1 pt-5 pb-5 surface-50">
			<ContentContainer>{children}</ContentContainer>
		</div>
	);
};

const ContentContainerCard = styled(Card)`
	max-width: 1920px;
	height: 100%;
	width: 100%;
`;

const ContentContainer = styled.div`
	max-width: 1024px;
	height: 100%;
	width: 100%;
`;
