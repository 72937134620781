import { UseMutationConfig } from "react-relay";
import {
	DispatchCondition,
	DispatchConditionInputType,
	DispatchConditionMutationConfig,
} from "@hooks/use-dispatch-conditions/use-dispatch-conditions.types";
import { DispatchConditionKind } from "@relay/dispatchConditionDropdown_MessageDispatchFragment.graphql";

export const createDispatchCondition = <INCLUDE_INPUT, EXCLUDE_INPUT>(
	kind: DispatchConditionKind,
	idInputType?: DispatchConditionInputType,
	includeMutation?: (
		input: INCLUDE_INPUT,
		config: UseMutationConfig<DispatchConditionMutationConfig>,
	) => void,
	excludeMutation?: (
		input: EXCLUDE_INPUT,
		config: UseMutationConfig<DispatchConditionMutationConfig>,
	) => void,
): DispatchCondition<INCLUDE_INPUT, EXCLUDE_INPUT> => {
	return {
		kind,
		idInputType,
		includeMutation,
		excludeMutation,
	};
};
