import { Button } from "primereact/button";
import { useMutation } from "react-relay";
import { type removeColumnFromMatrixElementButton_RemoveColumnFromMatrixElementMutation } from "@relay/removeColumnFromMatrixElementButton_RemoveColumnFromMatrixElementMutation.graphql";
import { REMOVE_COLUMN_FROM_MATRIX_ELEMENT_MUTATION } from "./remove-column-from-matrix-element-button.graphql";
import { type RemoveColumnFromMatrixElementButtonProps } from "./remove-column-from-matrix-element-button.types";

export const RemoveColumnFromMatrixElementButton = ({
	matrixElementId,
	idx,
}: RemoveColumnFromMatrixElementButtonProps) => {
	const [removeColumnFromMatrixElement] =
		useMutation<removeColumnFromMatrixElementButton_RemoveColumnFromMatrixElementMutation>(
			REMOVE_COLUMN_FROM_MATRIX_ELEMENT_MUTATION,
		);

	return (
		<Button
			type="button"
			className="m-1"
			icon="pi pi-trash"
			onClick={() => {
				removeColumnFromMatrixElement({
					variables: {
						input: {
							matrixElementId,
							idx,
						},
					},
				});
			}}
		/>
	);
};
