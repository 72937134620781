import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { Paths } from "@routes/paths";
import { type EditExternalLicensePoolButtonProps } from "./edit-external-license-pool-button.interface";

export const EditExternalLicensePoolButton = ({
	externalLicensePoolId,
}: EditExternalLicensePoolButtonProps) => {
	const navigate = useNavigate();

	return (
		<Button
			icon="pi pi-pencil"
			tooltip="Externen Lizenz-Pool bearbeiten"
			onClick={() => {
				navigate(Paths.externalLicensePools.withId(externalLicensePoolId).edit.path);
			}}
		/>
	);
};
