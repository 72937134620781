import { FieldDataObject } from "./select-complex-data-field.type";

export const getDeepValue = (path: string, obj?: FieldDataObject) =>
	path
		.split(".")
		.reduce(
			(o: FieldDataObject | undefined, key: string) =>
				o && typeof o[key] !== "undefined" ? (o[key] as FieldDataObject) : undefined,
			obj,
		);
