import { useFragment } from "react-relay";
import { type asyncElementForm_AsyncElementFragment$key } from "@relay/asyncElementForm_AsyncElementFragment.graphql";
import { ASYNC_ELEMENT_FRAGMENT } from "./async-element-form.graphql";
import { type AsyncElementFormProps } from "./async-element-form.types";
import { UploadAsyncElementForm } from "../upload-async-element-form";

export const AsyncElementForm = ({ asyncElementFragmentRef }: AsyncElementFormProps) => {
	const asyncElement = useFragment<asyncElementForm_AsyncElementFragment$key>(
		ASYNC_ELEMENT_FRAGMENT,
		asyncElementFragmentRef,
	);

	const Form = (() => {
		switch (asyncElement.kind) {
			case "Upload":
				return <UploadAsyncElementForm uploadAsyncElementFragmentRef={asyncElement} />;
			default:
				return null;
		}
	})();

	return (
		<div key={asyncElement.id}>
			<div className="flex align-items-center">
				<h2>Bearbeite Element</h2>
			</div>
			{Form}
		</div>
	);
};
