import { PathBase, PathWithId } from "@thekeytechnology/epic-ui";

export class OverviewPath extends PathBase {
	static readonly pathKey = "overview";
}

export class ContentsPath extends PathBase {
	static readonly pathKey = "contents";
}

export class AttachmentsPath extends PathBase {
	static readonly pathKey = "attachments";
}

export class ConfigPath extends PathBase {
	static readonly pathKey = "config";
}

export class RewardsPath extends PathBase {
	static readonly pathKey = "rewards";
}

export class NodePath extends PathWithId<typeof NodePath> {
	static readonly pathKey = "node";
	static readonly idKey = "nodeId";
	static readonly childPaths = [
		OverviewPath,
		ContentsPath,
		AttachmentsPath,
		ConfigPath,
		RewardsPath,
	];
}

export class EducationalOfferPath extends PathWithId<typeof EducationalOfferPath> {
	static readonly pathKey = "educationalOffer";
	static readonly pathName = "educational-offer";
	static readonly idKey = "rootId";
	static readonly childPaths = [
		NodePath,
		ContentsPath,
		OverviewPath,
		AttachmentsPath,
		ConfigPath,
		RewardsPath,
	];
}
