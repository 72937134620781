import { Button } from "primereact/button";
import { useState } from "react";
import { useMutation } from "react-relay";
import { type issueExternalLicenseToUserButton_IssueExternalLicenseMutation } from "@relay/issueExternalLicenseToUserButton_IssueExternalLicenseMutation.graphql";
import { ISSUE_EXTERNAL_LICENSE_TO_USER_MUTATION } from "./issue-external-license-to-user-button.graphql";
import { type IssueExternalLicenseToUserButtonProps } from "./issue-external-license-to-user-button.interface";
import { IssueExternalLicenseToUserForm } from "../issue-external-license-to-user-form/issue-external-license-to-user-form.component";
import { type IssueExternalLicenseToUserFormState } from "../issue-external-license-to-user-form/issue-external-license-to-user-form.interface";

export const IssueExternalLicenseToUserButton = ({
	externalLicenseId,
	externalLicensesConnection,
}: IssueExternalLicenseToUserButtonProps) => {
	const [displayMaximizable, setDisplayMaximizable] = useState(false);

	const [issueExternalLicense, isIssuingExternalLicense] =
		useMutation<issueExternalLicenseToUserButton_IssueExternalLicenseMutation>(
			ISSUE_EXTERNAL_LICENSE_TO_USER_MUTATION,
		);

	const handleIssueExternalLicenseFormOnSubmit = (values: IssueExternalLicenseToUserFormState) =>
		issueExternalLicense({
			variables: {
				input: {
					externalLicenseId,
					issuedToUser: values.issueTo,
				},
				connections: externalLicensesConnection,
			},
			onCompleted: () => {
				setDisplayMaximizable(false);
			},
		});
	return (
		<>
			<Button
				icon={"pi pi-user"}
				tooltip={"An Nutzer vergeben"}
				disabled={isIssuingExternalLicense}
				onClick={() => {
					setDisplayMaximizable(true);
				}}
			/>

			<IssueExternalLicenseToUserForm
				externalLicenseId={externalLicenseId}
				displayMaximizable={displayMaximizable}
				setDisplayMaximizable={setDisplayMaximizable}
				onSubmitCallback={(formValues: IssueExternalLicenseToUserFormState) => {
					handleIssueExternalLicenseFormOnSubmit(formValues);
				}}
			/>
		</>
	);
};
