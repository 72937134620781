import graphql from "babel-plugin-relay/macro";

export const USER_QUERY = graphql`
	query userEditScreen_Query($id: ID!, $skip: Boolean!) {
		node(id: $id) @skip(if: $skip) {
			... on User {
				...userEditScreen_UserFragment
			}
		}

		...ordersTable_OrdersFragment @arguments(userId: $id)
	}
`;

export const USER_FRAGMENT = graphql`
	fragment userEditScreen_UserFragment on User {
		activated
		email
		name
		id
		registeredAt
		isDeleted
		groupAssociations {
			account {
				name
			}
			group {
				id
				name
			}
		}
		extension {
			... on UserExtensionImpl {
				firstName
				lastName
				adsOptIn
				branch
				teamSize
				position
				avatar {
					id
					name
					url
				}
			}
		}
	}
`;

export const EDIT_USER_MUTATION = graphql`
	mutation userEditScreen_EditMutation($input: EditUserInput!) {
		Admin {
			Auth {
				editUser(input: $input) {
					user {
						...userEditScreen_UserFragment
					}
				}
			}
		}
	}
`;

export const TRIGGER_RESET_PASSWORD_MUTATION = graphql`
	mutation userEditScreen_TriggerResetPasswordMutation($input: TriggerResetPasswordInput!) {
		Admin {
			Auth {
				triggerResetPassword(input: $input) {
					__typename
				}
			}
		}
	}
`;

export const TRIGGER_RESEND_ACTIVATION_MUTATION = graphql`
	mutation userEditScreen_TriggerResendActivationMutation($input: TriggerResendActivationInput!) {
		Admin {
			Auth {
				triggerResendActivation(input: $input) {
					__typename
				}
			}
		}
	}
`;
