export const secondToTimestamp = (seconds: number) => {
	const numHours = Math.floor(((seconds % 31536000) % 86400) / 3600);
	const numMinutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
	const numSeconds = (((seconds % 31536000) % 86400) % 3600) % 60;
	return numHours + ":" + numMinutes + ":" + numSeconds;
};

export const timestampToSeconds = (timestamp: string): number => {
	const splitTimestamp = timestamp.split(":").map((value) => value.trim());

	switch (splitTimestamp.length) {
		case 1:
			return +splitTimestamp;
		case 2:
			return +splitTimestamp[0] * 60 + +splitTimestamp[1];
		case 3:
			return +splitTimestamp[0] * 3600 + +splitTimestamp[1] * 60 + +splitTimestamp[2];
		default:
			return 0;
	}
};
