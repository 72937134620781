import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { type rewardEditor_DeleteRewardMutation } from "@relay/rewardEditor_DeleteRewardMutation.graphql";
import { type rewardEditor_Query } from "@relay/rewardEditor_Query.graphql";
import { type rewardForm_RewardFragment$key } from "@relay/rewardForm_RewardFragment.graphql";
import { QUERY, REMOVE_REWARD_MUTATION, TREE_NODE_FRAGMENT } from "./reward-editor.graphql";
import { OrderListWithoutControls } from "./reward-editor.styles";
import { type RewardEditorProps } from "./reward-editor.types";
import { WarningUnsavedChangesDialog } from "../../../../components/WarningUnsavedChangesDialog";
import { selectArrayOfEditedForms } from "../../../../store/slices/CoreSlice";
import { useTypedSelector } from "../../../../store/store.redux";
import { CreateExternalLicenseRewardButton } from "../create-external-license-reward-button";
import { CreateIHKCertificateRewardButton } from "../create-ihk-certificate-reward-button/create-ihk-certificate-reward-button.component";
import { RewardForm } from "../reward-form";

export const RewardEditor = ({ treeNodeFragmentRef }: RewardEditorProps) => {
	const node = useFragment(TREE_NODE_FRAGMENT, treeNodeFragmentRef);

	const data = useLazyLoadQuery<rewardEditor_Query>(QUERY, {
		id: node.id,
		skip: !node,
	});

	const [removeReward, isRemovingReward] =
		useMutation<rewardEditor_DeleteRewardMutation>(REMOVE_REWARD_MUTATION);

	const [currentlyEditingReward, setCurrentlyEditingReward] = useState<
		rewardForm_RewardFragment$key | undefined
	>();
	const [showDialog, setShowDialog] = useState(false);

	const arrayOfEditedForm = useTypedSelector(selectArrayOfEditedForms);

	const handleOnBack = () => {
		if (arrayOfEditedForm.length > 0) {
			setShowDialog(true);
		} else {
			setCurrentlyEditingReward(undefined);
		}
	};

	const createOnDoubleClickHandler = (item: any) => () => {
		setCurrentlyEditingReward(item);
	};
	const createDeleteOnClickHanlder = (item: any) => () => {
		removeReward({
			variables: {
				input: {
					contentNodeId: node.id,
					rewardId: item.id,
				},
			},
		});
	};
	const createEditOnClickHandler = (item: any) => () => {
		setCurrentlyEditingReward(item);
	};

	const isInternalReward = (item: any) => {
		return item.kind === "GamificationPoints" || item.kind === "CrmTreeHeadUpdater";
	};

	return (
		<Card className="mb-2">
			<h2>Belohnungen</h2>
			{currentlyEditingReward ? (
				<>
					{showDialog && (
						<WarningUnsavedChangesDialog
							setShowDialog={setShowDialog}
							callback={setCurrentlyEditingReward}
							value={undefined}
						/>
					)}
					<RewardForm
						treeNodeFragmentRef={node}
						onBack={handleOnBack}
						rewardConfigurationFragmentRef={currentlyEditingReward}
					/>
				</>
			) : (
				<div>
					<div className="p-buttonset mb-2">
						<CreateExternalLicenseRewardButton
							treeNodeFragmentRef={node}
							externalLicensePoolConnectionFragmentRef={
								data.Admin.Billing.GetExternalLicensePools
							}
						/>
						<CreateIHKCertificateRewardButton treeNodeFragmentRef={node} />
					</div>

					<OrderListWithoutControls
						dragdrop={false}
						itemTemplate={(item) => {
							return (
								<div
									className="flex align-items-center"
									onDoubleClick={createOnDoubleClickHandler(item)}
								>
									{item.kind === "ExternalLicense" && (
										<span>
											Vergebe externe Lizenzen aus dem Pool:{" "}
											{item.data.pool.data.name}
										</span>
									)}
									{item.kind === "WordPackage" && (
										<span>
											Vergebenes Wortpaket: {item.data.wordPackage?.name}
										</span>
									)}
									{item.kind === "WordProficiencies" && (
										<span>Wordfertigkeiten verknüpft</span>
									)}
									{item.kind === "IHKCertificate" && <span>IHK Zertifikat</span>}
									{item.kind === "GamificationPoints" && (
										<span>Gamification Punkte</span>
									)}
									{item.kind === "CrmTreeHeadUpdater" && (
										<span>Hubspot-Lektionsstatus-Aktualisierung</span>
									)}

									{!isInternalReward(item) && (
										<>
											<Button
												disabled={isRemovingReward}
												className="ml-auto"
												icon="pi pi-trash"
												onClick={createDeleteOnClickHanlder(item)}
											/>

											<Button
												disabled={isRemovingReward}
												className="ml-2"
												icon="pi pi-pencil"
												onClick={createEditOnClickHandler(item)}
											/>
										</>
									)}
								</div>
							);
						}}
						value={[...(node.typeDefinition.rewards ?? [])]}
					/>
				</div>
			)}
		</Card>
	);
};
