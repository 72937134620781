import { graphql } from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { type ElementNode_ElementV2Fragment$key } from "../../../__generated__/ElementNode_ElementV2Fragment.graphql";
import { ELEMENT_TYPE_TRANSLATIONS } from "../../../translations/element-type";
import { htmlTagsInTextRemover } from "../../../util/htmlTagsInTextRemover";

const ELEMENT_FRAGMENT = graphql`
	fragment ElementNode_ElementV2Fragment on ElementV2 {
		id
		title
		elementType
	}
`;

type OwnProps = {
	elementFragmentRef: ElementNode_ElementV2Fragment$key;
};

export const ElementNode = ({ elementFragmentRef }: OwnProps) => {
	const element = useFragment<ElementNode_ElementV2Fragment$key>(
		ELEMENT_FRAGMENT,
		elementFragmentRef,
	);

	return (
		<div className="flex align-items-center small">
			{ELEMENT_TYPE_TRANSLATIONS[element.elementType]}: {htmlTagsInTextRemover(element.title)}
		</div>
	);
};
