/**
 * @generated SignedSource<<9fbc058624ef7c311420bfa3807b89a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type createMessageDispatchButton_MessageDefinitionFragment$data = {
  readonly id: string;
  readonly internalTitle: string;
  readonly " $fragmentType": "createMessageDispatchButton_MessageDefinitionFragment";
};
export type createMessageDispatchButton_MessageDefinitionFragment$key = {
  readonly " $data"?: createMessageDispatchButton_MessageDefinitionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"createMessageDispatchButton_MessageDefinitionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "createMessageDispatchButton_MessageDefinitionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "internalTitle",
      "storageKey": null
    }
  ],
  "type": "MessageDefinition",
  "abstractKey": null
};

(node as any).hash = "18509c8f0ea3af49a5e215e76d9e257e";

export default node;
