import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { DiscountActionEditRoutes } from "@screens/discount-action-edit";
import { DiscountActionsScreen } from "./discount-actions.screen";

export const DiscountActionsRoutes: RouteDefinition[] = [
	{
		path: Paths.discountActions.path,
		element: <DiscountActionsScreen />,
		requiredPermissions: ["UserInAccountPermission_DiscountAdmin_Read"],
	},
	...DiscountActionEditRoutes,
];
