import { arrayMove } from "@dnd-kit/sortable";
import { INDENTATION_WIDTH } from "./sortable-tree.consts";
import { type FlattenedItem, type TreeItem, type TreeItems } from "./sortable-tree.types";

export const iOS = /iPad|iPhone|iPod/.test(navigator.platform);

function getDragDepth(offset: number, indentationWidth: number) {
	return Math.round(offset / indentationWidth);
}

export function getProjection(
	items: FlattenedItem[],
	activeId: string,
	overId: string,
	dragOffset: number,
	indentationWidth = INDENTATION_WIDTH,
) {
	const overItemIndex = items.findIndex(({ id }) => id === overId);
	const activeItemIndex = items.findIndex(({ id }) => id === activeId);
	const activeItem = items[activeItemIndex];
	const newItems = arrayMove(items, activeItemIndex, overItemIndex);
	const previousItem = newItems[overItemIndex - 1];
	const nextItem = newItems[overItemIndex + 1];
	const dragDepth = getDragDepth(dragOffset, indentationWidth);
	const projectedDepth = activeItem.depth + dragDepth;
	let maxDepth = getMaxPossibleDragDepth({
		previousItem,
	});
	let minDepth = getMinPossibleDragDepth({ nextItem });
	let depth = projectedDepth;

	if (projectedDepth >= maxDepth) {
		depth = maxDepth;
	} else if (projectedDepth < minDepth) {
		depth = minDepth;
	}

	const targetIsBranch =
		previousItem && previousItem.data.typeDefinition?.definitionType !== "branch";
	if (targetIsBranch) {
		depth -= 1;
	}

	// We can't have 2 roots
	if (depth === 0) {
		depth = 1;
	}
	if (minDepth < 1) {
		minDepth = 1;
	}

	if (!previousItem) {
		minDepth = 0;
		maxDepth = 0;
		depth = 0;
	}

	let parentId = null;
	if (depth === 0 || !previousItem) {
		parentId = null;
	}
	if (depth === previousItem.depth) {
		parentId = previousItem.parentId;
	}
	if (depth > previousItem.depth) {
		parentId = previousItem.id;
	}
	if (parentId === null) {
		parentId = newItems
			.slice(0, overItemIndex)
			.reverse()
			.find((item) => item.depth === depth)?.parentId;
	}

	return { depth, maxDepth, minDepth, parentId: parentId ?? null };
}

function getMaxPossibleDragDepth({ previousItem }: { previousItem: FlattenedItem }) {
	if (previousItem) {
		return previousItem.depth + 1;
	}

	return 0;
}

function getMinPossibleDragDepth({ nextItem }: { nextItem: FlattenedItem }) {
	if (nextItem) {
		return nextItem.depth;
	}

	return 0;
}

function flatten(items: TreeItem[], parentId: string | null = null, depth = 0): FlattenedItem[] {
	return items.reduce<FlattenedItem[]>((acc, item, index) => {
		return [
			...acc,
			{ ...item, parentId, depth, index },
			...flatten(item.children || [], item.data.id, depth + 1),
		];
	}, []);
}

export function flattenTree(items: TreeItem[]): FlattenedItem[] {
	return flatten(items);
}

export function removeChildrenOf(items: FlattenedItem[], ids: string[]) {
	const excludeParentIds = [...ids];

	return items.filter((item) => {
		if (item.parentId && excludeParentIds.includes(item.parentId)) {
			if (item.children?.length) {
				excludeParentIds.push(item.id as string);
			}
			return false;
		}

		return true;
	});
}

export function findItem(items: TreeItem[], itemId: string) {
	return items.find(({ id }) => id === itemId);
}

export function findItemDeep(items: TreeItems, itemId: string): TreeItem | undefined {
	for (const item of items) {
		const { id, children } = item;

		if (id === itemId) {
			return item;
		}

		if (children.length) {
			const child = findItemDeep(children, itemId);

			if (child) {
				return child;
			}
		}
	}

	return undefined;
}

export function buildTree(flattenedItems: FlattenedItem[]): TreeItems {
	const root: TreeItem = { id: "root", children: [] };
	const nodes: Record<string, TreeItem> = { [root.id]: root };
	const items = flattenedItems.map((item) => ({ ...item, children: [] }));

	for (const item of items) {
		const { id, children } = item;
		const parentId = item.parentId ?? root.id;
		const parent = nodes[parentId] ?? findItem(items, parentId);

		nodes[id] = { id, children };
		parent.children.push(item);
	}

	return root.children;
}

function countChildren(items: TreeItem[], count = 0): number {
	return items.reduce((acc, { children }) => {
		if (children.length) {
			return countChildren(children, acc + 1);
		}

		return acc + 1;
	}, count);
}

export function getChildCount(items: TreeItems, id: string) {
	if (!id) {
		return 0;
	}

	const item = findItemDeep(items, id);

	return item ? countChildren(item.children) : 0;
}
