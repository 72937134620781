/**
 * @generated SignedSource<<187d6b3a61cc9d6c69e74b55a2f05328>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly " $fragmentSpreads": FragmentRefs<"minimizedDiscountCodeTable_DiscountCodeFragment">;
    };
  } | null> | null;
  readonly " $fragmentType": "minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment";
};
export type minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment$key = {
  readonly " $data"?: minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "minimizedDiscountCodeTable_DiscountCodesV2ConnectionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DiscountCodesV2Edge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DiscountCode",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "kind": "InlineDataFragmentSpread",
              "name": "minimizedDiscountCodeTable_DiscountCodeFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "code",
                  "storageKey": null
                }
              ],
              "args": null,
              "argumentDefinitions": []
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "DiscountCodesV2Connection",
  "abstractKey": null
};

(node as any).hash = "4c6af1d2c3dce8ca472578718329a002";

export default node;
