import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { toast } from "react-toastify";
import { type RemoveOrderItemProps } from "./remove-order-item-button.interface";
import { type RemoveOrderItemButton_OrderItemFragment$key } from "../../../../__generated__/RemoveOrderItemButton_OrderItemFragment.graphql";
import { type RemoveOrderItemButton_RemoveOrderItemMutation } from "../../../../__generated__/RemoveOrderItemButton_RemoveOrderItemMutation.graphql";

const ORDER_ITEM_FRAGMENT = graphql`
	fragment RemoveOrderItemButton_OrderItemFragment on OrderItem {
		id
	}
`;

const REMOVE_ORDER_ITEM_MUTATION = graphql`
	mutation RemoveOrderItemButton_RemoveOrderItemMutation($input: RemoveOrderItemInput!) {
		Admin {
			ElearningV2 {
				removeOrderItem(input: $input) {
					orderElement {
						orderItems {
							...OrderItemForm_OrderItemFragment
						}
					}
				}
			}
		}
	}
`;
export const RemoveOrderItemButton = ({
	orderElementId,
	orderItemFragmentRef,
}: RemoveOrderItemProps) => {
	const orderItem = useFragment<RemoveOrderItemButton_OrderItemFragment$key>(
		ORDER_ITEM_FRAGMENT,
		orderItemFragmentRef,
	);

	const [removeOrderItem, isRemovingOrderItem] =
		useMutation<RemoveOrderItemButton_RemoveOrderItemMutation>(REMOVE_ORDER_ITEM_MUTATION);

	return (
		<Button
			className="ml-2"
			icon="pi pi-trash"
			type="button"
			disabled={isRemovingOrderItem}
			onClick={() =>
				removeOrderItem({
					variables: {
						input: {
							orderElementId,
							orderItemId: orderItem.id,
						},
					},
					onCompleted: () => {
						toast.success("Reihenfolgeelement entfernt");
					},
					onError: () => {
						toast.success("Reihenfolgeelement konnte nicht entfernt werden");
					},
				})
			}
		/>
	);
};
