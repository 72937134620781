import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { DiscountCodeRoutes } from "@screens/discount-code";
import { DiscountActionEditScreen } from "./discount-action-edit.screen";

export const DiscountActionEditRoutes: RouteDefinition[] = [
	{
		path: Paths.discountActions.withIdPlaceholder().edit.path,
		element: <DiscountActionEditScreen />,
		requiredPermissions: ["UserInAccountPermission_DiscountAdmin_Modify"],
	},
	...DiscountCodeRoutes,
];
