/**
 * @generated SignedSource<<dcadcca786f45a758cd0cb0357fc2adb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AsyncElementKind = "Upload";
import { FragmentRefs } from "relay-runtime";
export type asyncElementForm_AsyncElementFragment$data = {
  readonly id: string;
  readonly kind: AsyncElementKind;
  readonly title: string;
  readonly " $fragmentSpreads": FragmentRefs<"uploadAsyncElementForm_UploadAsyncElementFragment">;
  readonly " $fragmentType": "asyncElementForm_AsyncElementFragment";
};
export type asyncElementForm_AsyncElementFragment$key = {
  readonly " $data"?: asyncElementForm_AsyncElementFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"asyncElementForm_AsyncElementFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "asyncElementForm_AsyncElementFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "uploadAsyncElementForm_UploadAsyncElementFragment"
    }
  ],
  "type": "AsyncElement",
  "abstractKey": "__isAsyncElement"
};

(node as any).hash = "bfeb7d5ee348d426336f681cb1bcf338";

export default node;
