import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment selectMultipleAccountsField_QueryFragment on Query
	@refetchable(queryName: "selectMultipleAccountsField_Refetch")
	@argumentDefinitions(
		first: { type: "Int" }
		after: { type: "String", defaultValue: null }
		nameOpt: { type: "String", defaultValue: null }
	) {
		Admin {
			Auth {
				SelectAccounts(first: $first, after: $after, nameOpt: $nameOpt, ids: [])
					@connection(key: "selectMultipleAccountsField_SelectAccounts") {
					edges {
						node {
							...selectMultipleAccountsField_AccountFragment
						}
					}
				}
			}
		}
	}
`;

export const ACCOUNT_FRAGMENT = graphql`
	fragment selectMultipleAccountsField_AccountFragment on Account @inline {
		id
		name
	}
`;
