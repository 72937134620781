import { PathBase, PathWithId } from "@thekeytechnology/epic-ui";

export class ExternalLicenseEditPath extends PathBase {
	static readonly pathKey = "edit";
}

export class ExternalLicensesPath extends PathWithId<typeof ExternalLicensesPath> {
	static readonly pathKey = "externalLicenses";
	static readonly pathName = "external-licenses";
	static readonly idKey = "externalLicenseId";
	static readonly childPaths = [ExternalLicenseEditPath];
}
