import React, { useRef } from "react";
import graphql from "babel-plugin-relay/macro";
import { Button } from "primereact/button";
import { useFragment } from "react-relay";
import { Toast } from "primereact/toast";
import { DownloadCodesCsvButton_DiscountActionFragment$key } from "@relay/DownloadCodesCsvButton_DiscountActionFragment.graphql";

const DISCOUNT_ACTION_FRAGMENT = graphql`
	fragment DownloadCodesCsvButton_DiscountActionFragment on DiscountAction {
		discountCodesCSV
	}
`;

interface OwnProps {
	className?: string;
	discountActionFragmentRef: DownloadCodesCsvButton_DiscountActionFragment$key;
}

export const DownloadCodesCsvButton = ({ className, discountActionFragmentRef }: OwnProps) => {
	const discountAction = useFragment<DownloadCodesCsvButton_DiscountActionFragment$key>(
		DISCOUNT_ACTION_FRAGMENT,
		discountActionFragmentRef,
	);
	const toast = useRef<any>(null);
	const downloadCsv = () => {
		if (discountAction.discountCodesCSV) {
			const link = document.createElement("a");
			link.href = "data:text/csv;charset=utf-8," + discountAction.discountCodesCSV;
			link.download = "Codes.csv";
			link.click();
		} else {
			toast.current?.show({
				severity: "success",
				life: 3000,
				summary: "CSV erstellt",
				detail: "Dein CSV wurde erfolgreich erstellt und heruntergeladen",
			});
		}
	};

	return (
		<>
			<Toast ref={toast} />
			<Button
				label="CSV"
				icon="pi pi-download"
				className={"h-3rem " + className}
				onClick={downloadCsv}
			/>
		</>
	);
};
