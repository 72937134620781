import { graphql } from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment reportsTable_QueryFragment on Query
	@refetchable(queryName: "reportsTable_QueryFragmentRefetch")
	@argumentDefinitions(
		filterByReferenceNumberOpt: { type: "String" }
		first: { type: "Int" }
		after: { type: "String" }
	) {
		Admin {
			Reports {
				Reports(first: $first, after: $after, referenceNumber: $filterByReferenceNumberOpt)
					@connection(key: "ReportsTable_Reports") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...reportsTable_DSAReportInlineFragment
						}
					}
				}
			}
		}
	}
`;

export const DSA_REPORT_INLINE_FRAGMENT = graphql`
	fragment reportsTable_DSAReportInlineFragment on DSAReport @inline {
		id
		createdAt
		referenceNumber
		data {
			url
			email
			firstName
			lastName
			extra
			problemType
			reportType
		}
	}
`;
