import { EpicIcons } from "@thekeytechnology/epic-ui";
import { Toast } from "primereact/toast";
import React, { useContext, useRef } from "react";
import { useMutation } from "react-relay";
import { Button, ButtonVariant } from "@components/button";
import { createMessageDefinitionButton_CreateMessageDefinitionMutation } from "@relay/createMessageDefinitionButton_CreateMessageDefinitionMutation.graphql";
import { MessageDefinitionsContext } from "@screens/message-definitions/message-definitions.context";
import { CREATE_MESSAGE_DEFINITION_MUTATION } from "@screens/message-definitions/parts/message-definitions-table/create-message-definition-button/create-message-definition-button.graphql";

import { buttonWrapperClass } from "@screens/message-definitions/parts/message-definitions-table/create-message-definition-button/create-message-definition-button.styles";

export const CreateMessageDefinitionButton = () => {
	const toast = useRef<Toast>(null);
	const { connectionId } = useContext(MessageDefinitionsContext);

	const [createMessageDefinition] =
		useMutation<createMessageDefinitionButton_CreateMessageDefinitionMutation>(
			CREATE_MESSAGE_DEFINITION_MUTATION,
		);

	const handleOnCreateClick = () => {
		createMessageDefinition({
			variables: {
				input: {
					internalTitle: "Neue Nachrichten-Vorlage",
				},
				connections: [connectionId],
			},
			onCompleted: () => {
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Nachrichten-Vorlage erstellt",
					detail: "Nachrichten-Vorlage wurde erstellt",
				});
			},
			onError: () => {
				toast.current?.show({
					severity: "error",
					life: 3000,
					summary: "Nachrichten-Vorlage nicht erstellt",
					detail: "Nachrichten-Vorlage konnte nicht erstellt werden",
				});
			},
		});
	};

	return (
		<>
			<Toast ref={toast}></Toast>
			<div className={buttonWrapperClass}>
				<Button
					icon={EpicIcons.FILE_EDIT}
					variant={ButtonVariant.Strong}
					label={"Nachrichten-Vorlage definieren"}
					onClick={handleOnCreateClick}
				/>
			</div>
		</>
	);
};
