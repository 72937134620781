import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { ExternalLicensePoolScreen } from "./external-license-pool.screen";

export const ExternalLicensePoolRoutes: RouteDefinition[] = [
	{
		path: Paths.externalLicensePools.withIdPlaceholder().path,
		element: <ExternalLicensePoolScreen />,
		requiredPermissions: ["UserInAccountPermission_ExternalLicenseAdmin_Read"],
	},
];
