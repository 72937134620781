import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { OrderEditScreen } from "./order-edit.screen";

export const OrderEditRoutes: RouteDefinition[] = [
	{
		path: Paths.orders.withIdPlaceholder().edit.path,
		element: <OrderEditScreen />,
		requiredPermissions: [
			"UserInAccountPermission_OrderAdmin_Read",
			"UserInAccountPermission_OrderAdmin_Modify",
		],
	},
];
