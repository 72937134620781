import graphql from "babel-plugin-relay/macro";

export const CREATE_PERCENTAGE_DISCOUNT_ACTION_MUTATION = graphql`
	mutation createPercentageDiscountActionButton_CreatePercentageDiscountActionMutation(
		$input: CreatePercentageDiscountActionInput!
		$connections: [ID!]!
	) {
		Admin {
			Billing {
				createPercentageDiscountAction(input: $input) {
					edge @appendEdge(connections: $connections) {
						node {
							...DiscountActionsTable_DiscountActionFragment
						}
					}
				}
			}
		}
	}
`;
