import React, { Suspense } from "react";

/**
 * Wraps component with suspense component.
 * @param Component
 */
export function withSuspense<T>(Component: React.FC<T>) {
	const SuspendedComponent = (props: T) => (
		<Suspense fallback={<div>Lade...</div>}>
			{/* @ts-expect-error */}
			<Component {...props} />
		</Suspense>
	);
	SuspendedComponent.displayName = `Suspended ${Component.displayName}`;
	return SuspendedComponent;
}
