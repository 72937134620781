/**
 * @generated SignedSource<<b674afb20ecea2e176fd507709faefed>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CreateBranchRootInput = {
  clientMutationId?: string | null;
  title: string;
};
export type createCourseLearnableButton_AcademiesCreateBranchRootMutation$variables = {
  input: CreateBranchRootInput;
};
export type createCourseLearnableButton_AcademiesCreateBranchRootMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly createBranchRoot: {
        readonly tree: {
          readonly nodes: ReadonlyArray<{
            readonly __typename: "TreeNode";
          }>;
        };
      } | null;
    };
  };
};
export type createCourseLearnableButton_AcademiesCreateBranchRootMutation = {
  response: createCourseLearnableButton_AcademiesCreateBranchRootMutation$data;
  variables: createCourseLearnableButton_AcademiesCreateBranchRootMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "createCourseLearnableButton_AcademiesCreateBranchRootMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateBranchRootPayload",
                "kind": "LinkedField",
                "name": "createBranchRoot",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tree",
                    "kind": "LinkedField",
                    "name": "tree",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TreeNode",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "createCourseLearnableButton_AcademiesCreateBranchRootMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "CreateBranchRootPayload",
                "kind": "LinkedField",
                "name": "createBranchRoot",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tree",
                    "kind": "LinkedField",
                    "name": "tree",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TreeNode",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "11e200183fb1f82a9d29a7fefb7aaf7b",
    "id": null,
    "metadata": {},
    "name": "createCourseLearnableButton_AcademiesCreateBranchRootMutation",
    "operationKind": "mutation",
    "text": "mutation createCourseLearnableButton_AcademiesCreateBranchRootMutation(\n  $input: CreateBranchRootInput!\n) {\n  Admin {\n    Tree {\n      createBranchRoot(input: $input) {\n        tree {\n          nodes {\n            __typename\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "85eb8a4aec2601416852192fe9ed4084";

export default node;
