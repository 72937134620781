/**
 * @generated SignedSource<<d423717946be534634597f34b5033bf7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AddOrderItemInput = {
  clientMutationId?: string | null;
  orderElementId: string;
  text: string;
};
export type AddOrderItemButton_AddOrderItemMutation$variables = {
  input: AddOrderItemInput;
};
export type AddOrderItemButton_AddOrderItemMutation$data = {
  readonly Admin: {
    readonly ElearningV2: {
      readonly addOrderItem: {
        readonly orderElement: {
          readonly " $fragmentSpreads": FragmentRefs<"OrderElementForm_OrderElementFragment">;
        };
      } | null;
    };
  };
};
export type AddOrderItemButton_AddOrderItemMutation = {
  response: AddOrderItemButton_AddOrderItemMutation$data;
  variables: AddOrderItemButton_AddOrderItemMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddOrderItemButton_AddOrderItemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOrderItemPayload",
                "kind": "LinkedField",
                "name": "addOrderItem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderElement",
                    "kind": "LinkedField",
                    "name": "orderElement",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "OrderElementForm_OrderElementFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddOrderItemButton_AddOrderItemMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ElearningV2AdminMutationSchema",
            "kind": "LinkedField",
            "name": "ElearningV2",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "AddOrderItemPayload",
                "kind": "LinkedField",
                "name": "addOrderItem",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrderElement",
                    "kind": "LinkedField",
                    "name": "orderElement",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "answeringTypeText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "OrderItem",
                        "kind": "LinkedField",
                        "name": "orderItems",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "text",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1e4dbdb4f0793b76621bad356bf367d4",
    "id": null,
    "metadata": {},
    "name": "AddOrderItemButton_AddOrderItemMutation",
    "operationKind": "mutation",
    "text": "mutation AddOrderItemButton_AddOrderItemMutation(\n  $input: AddOrderItemInput!\n) {\n  Admin {\n    ElearningV2 {\n      addOrderItem(input: $input) {\n        orderElement {\n          ...OrderElementForm_OrderElementFragment\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment EditOrderItemButton_OrderItemFragment on OrderItem {\n  id\n  text\n}\n\nfragment OrderElementForm_OrderElementFragment on OrderElement {\n  id\n  title\n  answeringTypeText\n  orderItems {\n    id\n    ...OrderItemForm_OrderItemFragment\n  }\n}\n\nfragment OrderItemForm_OrderItemFragment on OrderItem {\n  id\n  text\n  ...EditOrderItemButton_OrderItemFragment\n  ...RemoveOrderItemButton_OrderItemFragment\n}\n\nfragment RemoveOrderItemButton_OrderItemFragment on OrderItem {\n  id\n}\n"
  }
};
})();

(node as any).hash = "c705df97498b180fdfca8770b91c4d4e";

export default node;
