import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { MessageDispatchEditRoutes } from "@screens/message-dispatch-edit";
import { MessageDispatchesScreen } from "@screens/message-dispatches/message-dispatches.screen";

export const MessagesRoutes: RouteDefinition[] = [
	{
		path: Paths.messagesDispatches.path,
		element: <MessageDispatchesScreen />,
		requiredPermissions: ["UserInAccountPermission_MessageDispatchAdmin_Read"],
	},
	...MessageDispatchEditRoutes,
];
