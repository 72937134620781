import styled from "styled-components";
import tw from "twin.macro";

export const Wrapper = styled.div<{ isDragging?: boolean }>`
	opacity: 1;
	position: relative;
	z-index: 1;

	${(props) => {
		if (props.isDragging) {
			return `
        opacity: 0.5;
      `;
		}
	}}
`;

export const TreeItem = styled.div<{ isDragging?: boolean }>`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding: 0.375rem 0.25rem;
	background-color: #ffffff;
	border-radius: 0.1875rem;

	&:hover {
		background-color: #e9ecef;
	}

	${(props) => {
		if (props.isDragging) {
			return `    
        position: relative;
        padding: 0;
        height: 0.5rem;
        border-color: #2196f3;
        background-color: #56a1f8;
				
				&:hover {
					background-color: #56a1f8;
				}


        &:before {
					position: absolute;
					left: -0.5rem;
					top: -0.25rem;
					display: block;
					content: "";
					width: 0.75rem;
					height: 0.75rem;
					border-radius: 50%;
					border: 1px solid #2196f3;
					background-color: #ffffff;
				}

				> * {
					/* Items are hidden using height and opacity to retain focus */
					opacity: 0;
					height: 0;

          box-shadow: none;
          background-color: transparent;
				}
    `;
		}
	}}
`;

export const TreeItemContent = tw.div`
  tw-flex
  tw-flex-1
  tw-flex-row
  tw-items-center
`;

export const Handle = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	min-width: 1.25rem;
	max-width: 1.25rem;
	min-height: 2rem;
	max-height: 2rem;
`;

export const Collapse = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	min-width: 2rem;
	max-width: 2rem;
	min-height: 2rem;
	max-height: 2rem;

	i {
		transition: transform 250ms ease;
	}

	&.collapsed i {
		transform: rotate(-90deg);
	}
`;

export const Label = styled.button<{ active?: boolean }>`
	display: flex;
	flex-direction: row;
	gap: 0.5rem;
	align-items: center;
	flex: 1;
	width: 100%;
	min-height: 2rem;
	margin-left: 0.25rem;
	font-size: 1rem;
	text-align: left;
	border: 0;
	background-color: transparent;

	${(props) => {
		if (props.active) {
			return `
        color: #56a1f8;
      `;
		}
	}}
`;

export const RemoveButton = tw.button`
	tw-flex
	tw-items-center
	tw-justify-center
	tw-text-center
	tw-min-w-[2rem]
	tw-max-w-[2rem]
	tw-min-h-[2rem]
	tw-max-h-[2rem]
	tw-bg-transparent
	tw-border-0
`;

export const ChildCount = tw.div`
	tw-absolute
	tw--top-[0.625rem]
	tw--right-[0.625rem]
	tw-flex
	tw-items-center
	tw-justify-center
	tw-w-[1.5rem]
	tw-h-[1.5rem]
	tw-rounded-[50%]
	tw-bg-[#2196f3]
	tw-text-white
	tw-text-xs
	tw-font-semibold
`;
