import graphql from "babel-plugin-relay/macro";

export const QUERY_FRAGMENT = graphql`
	fragment messageDispatchesTable_QueryFragment on Query
	@refetchable(queryName: "messageDispatchesTable_Refetch")
	@argumentDefinitions(first: { type: "Int" }, after: { type: "String" }) {
		Admin {
			Message {
				MessageDispatches(first: $first, after: $after)
					@connection(key: "messageDispatchesTable_MessageDispatches") {
					pageInfo {
						endCursor
						hasPreviousPage
						hasNextPage
						startCursor
					}
					edges {
						node {
							...messageDispatchesTable_MessageDispatchFragment
						}
					}
				}
			}
		}
	}
`;

export const MESSAGE_DISPATCH_FRAGMENT = graphql`
	fragment messageDispatchesTable_MessageDispatchFragment on MessageDispatch @inline {
		id
		createdBy {
			name
		}
		createdAt
		sendAt
		dispatchedAt
		messageDefinition {
			internalTitle
		}
		...editMessageDispatchButton_MessageDispatchFragment
		...deleteMessageDispatchButton_MessageDispatchFragment
	}
`;
