import { ProgressSpinner } from "primereact/progressspinner";
import React, { Suspense } from "react";
import { useLazyLoadQuery } from "react-relay";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { ORDERS_QUERY, ordersPerPage } from "./orders.graphql";
import {
	type ordersScreen_Query,
	type ordersScreen_Query$data,
} from "../../__generated__/ordersScreen_Query.graphql";
import { NoAccess } from "../../components/no-access";
import { OrdersTable } from "../../features/orders/orders-table/orders-table.component";
export const OrdersScreen = () => {
	const orders: ordersScreen_Query$data = useLazyLoadQuery<ordersScreen_Query>(ORDERS_QUERY, {
		numItemsPerPage: ordersPerPage,
	});

	const canRead = useHasPermissions(["UserInAccountPermission_OrderAdmin_Read"]);

	const canModify = useHasPermissions(["UserInAccountPermission_OrderAdmin_Modify"]);

	if (!canRead) return <NoAccess />;

	return (
		<Suspense fallback={<ProgressSpinner />}>
			<h2 className="mb-3 text-center">Bestellungen</h2>
			<OrdersTable
				ordersFragmentRef={orders}
				ordersPerPage={ordersPerPage}
				enableFilter={true}
				canModifyOrders={canModify}
			/>
		</Suspense>
	);
};
