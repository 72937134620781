import { Button, ButtonType } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import { useLazyLoadQuery } from "react-relay";
import * as Yup from "yup";
import { ValidatedField } from "@components/ValidatedField";
import { SelectMultipleAccountsField } from "@features/accounts/select-multiple-accounts-field/select-multiple-accounts-field.component";
import { SelectMultipleAccountsFieldState } from "@features/accounts/select-multiple-accounts-field/select-multiple-accounts-field.types";
import { inputFieldClass } from "@features/accounts/select-multiple-accounts-form/select-multiple-accounts-form.styles";
import { selectMultipleAccountsForm_AccountsQuery } from "@relay/selectMultipleAccountsForm_AccountsQuery.graphql";
import { QUERY } from "./select-multiple-accounts-form.graphql";
import {
	SelectMultipleAccountsFormProps,
	SelectMultipleAccountsFormState,
} from "./select-multiple-accounts-form.types";

export const SelectMultipleAccountsForm = ({
	onAccountSelected,
	disabled,
}: SelectMultipleAccountsFormProps) => {
	const data = useLazyLoadQuery<selectMultipleAccountsForm_AccountsQuery>(
		QUERY,
		{},
		{ fetchPolicy: "store-or-network" },
	);

	const formik = useFormik<SelectMultipleAccountsFormState>({
		initialValues: {},
		validationSchema: Yup.object().shape({
			accounts: Yup.array()
				.of(
					Yup.object().shape({
						id: Yup.string(),
						name: Yup.string(),
					}),
				)
				.min(1, "Ein Account wird benötigt.")
				.required("Bitte wähle mindestens einen Account aus."),
		}),
		onSubmit: (data, { setSubmitting }) => {
			onAccountSelected(data.accounts!.map((account) => account.id!));
			setSubmitting(false);
		},
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<ValidatedField<SelectMultipleAccountsFormState, SelectMultipleAccountsFieldState>
				className={inputFieldClass}
				name={"accounts"}
				label={"Wähle einen Account aus"}
				formikConfig={formik}
				component={(renderConfig) => (
					<SelectMultipleAccountsField {...renderConfig} queryFragmentRef={data} />
				)}
			/>
			<Button
				disabled={!formik.isValid || disabled}
				label="Account hinzufügen"
				type={ButtonType.Submit}
			/>
		</form>
	);
};
