/**
 * @generated SignedSource<<597e3d439222ae5bbb2aca141c53d999>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditDefaultFileAttachmentInput = {
  attachmentId: string;
  clientMutationId?: string | null;
  descriptionOpt?: string | null;
  fileId: string;
  nodeId: string;
  titleOpt?: string | null;
};
export type AttachmentEditor_EditDefaultFileAttachmentMutation$variables = {
  input: EditDefaultFileAttachmentInput;
};
export type AttachmentEditor_EditDefaultFileAttachmentMutation$data = {
  readonly Admin: {
    readonly Tree: {
      readonly EditDefaultFileAttachment: {
        readonly node: {
          readonly attachments: ReadonlyArray<{
            readonly " $fragmentSpreads": FragmentRefs<"DefaultFileAttachmentForm_AttachmentV2Fragment">;
          }>;
        };
      } | null;
    };
  };
};
export type AttachmentEditor_EditDefaultFileAttachmentMutation = {
  response: AttachmentEditor_EditDefaultFileAttachmentMutation$data;
  variables: AttachmentEditor_EditDefaultFileAttachmentMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AttachmentEditor_EditDefaultFileAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDefaultFileAttachmentPayload",
                "kind": "LinkedField",
                "name": "EditDefaultFileAttachment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": true,
                        "selections": [
                          {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "DefaultFileAttachmentForm_AttachmentV2Fragment"
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AttachmentEditor_EditDefaultFileAttachmentMutation",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "AdminMutationType",
        "kind": "LinkedField",
        "name": "Admin",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TreeAdminMutationSchema",
            "kind": "LinkedField",
            "name": "Tree",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "EditDefaultFileAttachmentPayload",
                "kind": "LinkedField",
                "name": "EditDefaultFileAttachment",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TreeNode",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "attachments",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isAttachmentV2"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "File",
                                "kind": "LinkedField",
                                "name": "file",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "title",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "description",
                                "storageKey": null
                              }
                            ],
                            "type": "DefaultFileAttachment",
                            "abstractKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f914b32d361af9f5dc82d83b9d906b91",
    "id": null,
    "metadata": {},
    "name": "AttachmentEditor_EditDefaultFileAttachmentMutation",
    "operationKind": "mutation",
    "text": "mutation AttachmentEditor_EditDefaultFileAttachmentMutation(\n  $input: EditDefaultFileAttachmentInput!\n) {\n  Admin {\n    Tree {\n      EditDefaultFileAttachment(input: $input) {\n        node {\n          attachments {\n            __typename\n            ...DefaultFileAttachmentForm_AttachmentV2Fragment\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment DefaultFileAttachmentForm_AttachmentV2Fragment on AttachmentV2 {\n  __isAttachmentV2: __typename\n  ... on DefaultFileAttachment {\n    file {\n      id\n      name\n    }\n    title\n    description\n  }\n  kind\n}\n"
  }
};
})();

(node as any).hash = "0faea1f4d8b6f52f432408b05d7ffd0e";

export default node;
