import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay";
import { type OrderItemFormProps } from "./order-item-form.interface";
import { type OrderItemForm_OrderItemFragment$key } from "../../../../__generated__/OrderItemForm_OrderItemFragment.graphql";
import { EditOrderItemButton } from "../edit-order-item-button";
import { RemoveOrderItemButton } from "../remove-order-item-button/RemoveOrderItemButton.component";

export const ORDER_ITEM_FRAGMENT = graphql`
	fragment OrderItemForm_OrderItemFragment on OrderItem {
		id
		text
		...EditOrderItemButton_OrderItemFragment
		...RemoveOrderItemButton_OrderItemFragment
	}
`;

export const OrderItemForm = ({ orderElementId, orderItemFragmentRef }: OrderItemFormProps) => {
	const orderItem = useFragment<OrderItemForm_OrderItemFragment$key>(
		ORDER_ITEM_FRAGMENT,
		orderItemFragmentRef,
	);

	return (
		<div className="flex align-items-center h-1rem mt-1 mb-1">
			<div className="mr-auto">{orderItem.text}</div>
			<div className="m-2">
				<EditOrderItemButton
					orderElementId={orderElementId}
					orderItemFragmentRef={orderItem}
				/>
				<RemoveOrderItemButton
					orderElementId={orderElementId}
					orderItemFragmentRef={orderItem}
				/>
			</div>
		</div>
	);
};
