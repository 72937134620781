/**
 * @generated SignedSource<<9cb226bdcb874addd7c9314a853cd131>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type OrderStatus = "HasBillingDetails" | "HasCart" | "HasPaymentDetails" | "PaymentReceived" | "Purchased" | "Transient";
export type SelectedPaymentMethodType = "EmployerInvoice" | "MonthlyPayment" | "OneTimePayment";
import { FragmentRefs } from "relay-runtime";
export type ordersTable_OrderFragment$data = {
  readonly createdAt: string;
  readonly id: string;
  readonly selectedPaymentMethod: {
    readonly selectedPaymentMethodType: SelectedPaymentMethodType;
  } | null;
  readonly status: OrderStatus;
  readonly " $fragmentSpreads": FragmentRefs<"orderPriceDisplay_OrderFragment" | "paymentProviderLink_OrderFragment">;
  readonly " $fragmentType": "ordersTable_OrderFragment";
};
export type ordersTable_OrderFragment$key = {
  readonly " $data"?: ordersTable_OrderFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ordersTable_OrderFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "ordersTable_OrderFragment"
};

(node as any).hash = "cd4c0f22ab1b5aceb44150d2a7bf438e";

export default node;
