import { Button, PathParams, InputSwitch } from "@thekeytechnology/epic-ui";
import { useFormik } from "formik";
import moment from "moment";
import { Calendar } from "primereact/calendar";
import { Divider } from "primereact/divider";
import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useParams } from "react-router-dom";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { messageDefinitionForm_EditMessageDefinitionMutation } from "@relay/messageDefinitionForm_EditMessageDefinitionMutation.graphql";
import { messageDefinitionForm_MessageDefinitionFragment$key } from "@relay/messageDefinitionForm_MessageDefinitionFragment.graphql";
import { messageDefinitionForm_Query } from "@relay/messageDefinitionForm_Query.graphql";
import { DesktopNotificationForm } from "@screens/message-definition-edit/parts/desktop-notification-form";
import {
	DesktopNotificationFormRef,
	DesktopNotificationFormState,
} from "@screens/message-definition-edit/parts/desktop-notification-form/desktop-notification-form.types";
import { InAppNotificationForm } from "@screens/message-definition-edit/parts/in-app-notification-form";
import {
	InAppNotificationFormRef,
	InAppNotificationFormState,
} from "@screens/message-definition-edit/parts/in-app-notification-form/in-app-notification-form.types";
import {
	EDIT_MESSAGE_DEFINITION_MUTATION,
	MESSAGE_DEFINITION_FRAGMENT,
	QUERY,
} from "@screens/message-definition-edit/parts/message-definition-form/message-definition-form.graphql";
import { togglableClass } from "@screens/message-definition-edit/parts/message-definition-form/message-definition-form.styles";
import { MessagePagesEditor } from "@screens/message-definition-edit/parts/message-pages-editor/message-pages-editor.component";
import { MessageDefinitionsPath } from "@screens/message-definitions";
import { H1Span, H2Span } from "@themes/font-tags";
import { MessageDefinitionFormState } from "./message-definition-form.types";

export const MessageDefinitionForm = () => {
	const toast = useRef<Toast>(null);
	const { messageDefinitionId } = useParams<PathParams<typeof MessageDefinitionsPath>>();

	const query = useLazyLoadQuery<messageDefinitionForm_Query>(QUERY, {
		id: messageDefinitionId ?? "",
	});

	const [editMessageDefinition] =
		useMutation<messageDefinitionForm_EditMessageDefinitionMutation>(
			EDIT_MESSAGE_DEFINITION_MUTATION,
		);

	const data = useFragment<messageDefinitionForm_MessageDefinitionFragment$key>(
		MESSAGE_DEFINITION_FRAGMENT,
		query,
	);

	const messageDefinition = data.node;

	const [showInAppNotification, setShowInAppNotification] = useState(
		messageDefinition?.inAppNotification != null,
	);
	const [showDesktopNotification, setShowDesktopNotification] = useState(
		messageDefinition?.desktopNotification != null,
	);

	const handleOnSave = async () => {
		await formik.setFieldValue("inAppNotification", messageDefinition?.inAppNotification);
		await inAppNotificationFormRef.current?.submit();

		await formik.setFieldValue("desktopNotification", messageDefinition?.desktopNotification);
		await desktopNotificationFormRef.current?.submit();

		await formik.submitForm();
	};

	const handleEdit = () => {
		if (!messageDefinitionId) return;
		formik.setSubmitting(true);
		editMessageDefinition({
			variables: {
				input: {
					messageDefinitionId: messageDefinitionId,
					newInternalTitle: formik.values.internalTitle,
					newShowUntil: formik.values.showUntil,
					newDesktopNotification: showDesktopNotification
						? {
								title: formik.values.desktopNotification.title,
								content: formik.values.desktopNotification.content,
								icon: formik.values.desktopNotification.icon.id,
								url: formik.values.desktopNotification.url,
						  }
						: null,
					newInAppNotification: showInAppNotification
						? {
								title: formik.values.inAppNotification.title,
								content: formik.values.inAppNotification.content,
						  }
						: null,
				},
			},
			onCompleted: () => {
				formik.setSubmitting(false);
				toast.current?.show({
					severity: "success",
					life: 3000,
					summary: "Nachrichten-Vorlage gespeichert",
					detail: "Die Nachrichten-Vorlage wurde erfolgreich gespeichert",
				});
			},
			onError: () => {
				toast.current?.show({
					severity: "error",
					life: 3000,
					summary: "Nachrichten-Vorlage nicht gespeichert",
					detail: "Das Nachrichten-Vorlage konnte nicht gespeichert werden",
				});
				formik.setSubmitting(false);
			},
		});
	};

	const initialData = {
		internalTitle: messageDefinition?.internalTitle ?? "",
		showUntil: messageDefinition?.showUntil ?? "",
		desktopNotification: {
			title: messageDefinition?.desktopNotification?.title ?? "",
			content: messageDefinition?.desktopNotification?.content ?? "",
			url: messageDefinition?.desktopNotification?.url ?? "",
			icon: {
				id: messageDefinition?.desktopNotification?.icon?.id ?? "",
			},
		},
		inAppNotification: {
			title: messageDefinition?.inAppNotification?.title ?? "",
			content: messageDefinition?.inAppNotification?.content ?? "",
		},
	};
	const inAppNotificationFormRef = useRef<InAppNotificationFormRef>(null);
	const desktopNotificationFormRef = useRef<DesktopNotificationFormRef>(null);

	const formik = useFormik<MessageDefinitionFormState>({
		initialValues: {
			internalTitle: initialData?.internalTitle,
			showUntil: initialData?.showUntil,
			desktopNotification: initialData.desktopNotification,
			inAppNotification: initialData.inAppNotification,
		},
		onSubmit: async (values, { setSubmitting }) => {
			handleEdit();
			setSubmitting(false);
		},
	});

	const handleInAppNotificationFormOnSubmit = async (values: InAppNotificationFormState) => {
		void formik.setFieldValue("inAppNotification", values);
	};

	const handleDesktopNotificationFormOnSubmit = async (values: DesktopNotificationFormState) => {
		void formik.setFieldValue("desktopNotification", values);
	};

	const handleOnShowDesktopNotificationChange = (checked: boolean) =>
		setShowDesktopNotification(checked);

	const handleOnShowInAppNotificationChange = (checked: boolean) =>
		setShowInAppNotification(checked);

	return (
		<>
			<Toast ref={toast}></Toast>
			<H1Span>Nachrichten-Vorlage bearbeiten</H1Span>
			<ValidatedField<MessageDefinitionFormState, string>
				name={"internalTitle"}
				label={"Interner Titel"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
			/>
			<ValidatedField<MessageDefinitionFormState, string>
				name={"showUntil"}
				label={"Anzeigen bis"}
				component={({ fieldName, fieldValue, updateField }) => (
					<Calendar
						name={fieldName}
						dateFormat={"dd.mm.yy"}
						showTime
						showButtonBar
						value={
							fieldValue
								? moment(fieldValue.replace("[UTC]", "")).toDate()
								: undefined
						}
						onChange={(e) => {
							updateField(
								e.value ? moment(e.value as Date).toISOString(true) : undefined,
							);
						}}
					/>
				)}
				formikConfig={formik}
			/>
			<Divider />
			<div className={togglableClass}>
				<InputSwitch
					checked={showInAppNotification}
					onChange={handleOnShowInAppNotificationChange}
				/>
				<H2Span>In-App-Benachrichtigung</H2Span>
			</div>
			{showInAppNotification && (
				<InAppNotificationForm
					ref={inAppNotificationFormRef}
					messageDefinitionRef={messageDefinition}
					onSubmit={handleInAppNotificationFormOnSubmit}
				/>
			)}
			<Divider />
			<div className={togglableClass}>
				<InputSwitch
					checked={showDesktopNotification}
					onChange={handleOnShowDesktopNotificationChange}
				/>
				<H2Span>Desktop-Benachrichtigung</H2Span>
			</div>
			{showDesktopNotification && (
				<DesktopNotificationForm
					ref={desktopNotificationFormRef}
					messageDefinitionRef={messageDefinition}
					onSubmit={handleDesktopNotificationFormOnSubmit}
				/>
			)}
			<Divider />
			<Button
				disabled={formik.isSubmitting}
				label="Nachrichten-Vorlage speichern"
				onClick={handleOnSave}
			/>
			<Divider />
			<MessagePagesEditor messageDefinitionFragmentRef={messageDefinition} />
		</>
	);
};
