import { Button } from "primereact/button";
import { OrderList } from "primereact/orderlist";
import { useState } from "react";
import { type AnswerOptionV2Input } from "@relay/CreateMultipleChoiceElementButton_CreateMultipleChoiceElementV2Mutation.graphql";
import { AnswerOptionDisplay } from "./AnswerOptionDisplay";
import { EditAnswerOptionForm } from "./EditAnswerOptionForm";

type OwnProps = {
	answerOptions: AnswerOptionV2Input[];
	onUpdate: (answerOptions?: AnswerOptionV2Input[]) => void;
	onChange?: () => void;
	canUploadFiles: boolean;
	canDeleteFiles: boolean;
};

export const AnswerOptionsField = ({
	answerOptions,
	onUpdate,
	onChange,
	canUploadFiles,
	canDeleteFiles,
}: OwnProps) => {
	const [answerOptionIndexUnderEditing, setAnswerOptionIndexUnderEditing] = useState<number>();
	const answerOptionUnderEditing =
		answerOptionIndexUnderEditing !== undefined
			? answerOptions[answerOptionIndexUnderEditing]
			: undefined;

	return (
		<div>
			<div className="flex align-items-center">
				<div className="p-field flex-grow-1 flex-column mr-2">
					{answerOptionUnderEditing ? (
						<EditAnswerOptionForm
							answerOption={answerOptionUnderEditing}
							onUpdate={(value) => {
								onUpdate(
									answerOptions.map((ao, i) =>
										i === answerOptionIndexUnderEditing ? value : ao,
									),
								);
								onChange?.();
							}}
							onBack={() => {
								setAnswerOptionIndexUnderEditing(undefined);
							}}
							canUploadFiles={canUploadFiles}
							canDeleteFiles={canDeleteFiles}
						/>
					) : (
						<>
							<Button
								type="button"
								className="mb-2"
								label={"Neue Antwortmöglichkeit"}
								onClick={() => {
									onUpdate([
										...answerOptions,
										{ answer: "Neue Antwort", isCorrect: false },
									]);
								}}
							/>
							<OrderList
								value={answerOptions}
								onChange={(e) => {
									onUpdate(e.value);
									onChange?.();
								}}
								itemTemplate={(item) => {
									const index = answerOptions.indexOf(item);

									return (
										<AnswerOptionDisplay
											answerOption={item}
											index={index}
											onDelete={() => {
												onUpdate(
													[...answerOptions].filter(
														(ao, i) => i !== index,
													),
												);
												onChange?.();
											}}
											onEdit={() => {
												setAnswerOptionIndexUnderEditing(index);
											}}
										/>
									);
								}}
							/>
						</>
					)}
				</div>
			</div>
		</div>
	);
};
