/**
 * @generated SignedSource<<a354644ef55d8d81955eb36f54921f60>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type resetStagingUserProgressButton_resetStagingUserProgressMutation$variables = {};
export type resetStagingUserProgressButton_resetStagingUserProgressMutation$data = {
  readonly Admin: {
    readonly Staging: {
      readonly cleanUpUserInputRelatedToStaging: {
        readonly clientMutationId: string | null;
      } | null;
    };
  };
};
export type resetStagingUserProgressButton_resetStagingUserProgressMutation = {
  response: resetStagingUserProgressButton_resetStagingUserProgressMutation$data;
  variables: resetStagingUserProgressButton_resetStagingUserProgressMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AdminMutationType",
    "kind": "LinkedField",
    "name": "Admin",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "StagingAdminMutationSchema",
        "kind": "LinkedField",
        "name": "Staging",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "input",
                "value": {}
              }
            ],
            "concreteType": "CleanUpUserInputRelatedToStagingPayload",
            "kind": "LinkedField",
            "name": "cleanUpUserInputRelatedToStaging",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "clientMutationId",
                "storageKey": null
              }
            ],
            "storageKey": "cleanUpUserInputRelatedToStaging(input:{})"
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "resetStagingUserProgressButton_resetStagingUserProgressMutation",
    "selections": (v0/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "resetStagingUserProgressButton_resetStagingUserProgressMutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "fd2cf51dbe7a3bed420eeefb572017dc",
    "id": null,
    "metadata": {},
    "name": "resetStagingUserProgressButton_resetStagingUserProgressMutation",
    "operationKind": "mutation",
    "text": "mutation resetStagingUserProgressButton_resetStagingUserProgressMutation {\n  Admin {\n    Staging {\n      cleanUpUserInputRelatedToStaging(input: {}) {\n        clientMutationId\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ff988e424684838774204075add3885";

export default node;
