import { Paths } from "@routes/paths";
import { type RouteDefinition } from "@routes/route-definition";
import { SettingsScreen } from "./settings.screen";

export const SettingsRoutes: RouteDefinition[] = [
	{
		path: Paths.settings.path,
		element: <SettingsScreen />,
		requiredPermissions: "onlyOwnerOfRoot",
	},
];
