/**
 * @generated SignedSource<<86d7c538d320e4ec5fd0950111032eaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type CustomerType = "Business" | "Private";
import { FragmentRefs } from "relay-runtime";
export type billingDetailsForm_BillingDetailsFragment$data = {
  readonly customerType: CustomerType;
  readonly " $fragmentSpreads": FragmentRefs<"businessBillingDetailsForm_BillingDetailsFragment" | "privateBillingDetailsForm_BillingDetailsFragment">;
  readonly " $fragmentType": "billingDetailsForm_BillingDetailsFragment";
};
export type billingDetailsForm_BillingDetailsFragment$key = {
  readonly " $data"?: billingDetailsForm_BillingDetailsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"billingDetailsForm_BillingDetailsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "billingDetailsForm_BillingDetailsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "customerType",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "privateBillingDetailsForm_BillingDetailsFragment"
        }
      ],
      "type": "PrivateBillingDetails",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "businessBillingDetailsForm_BillingDetailsFragment"
        }
      ],
      "type": "BusinessBillingDetails",
      "abstractKey": null
    }
  ],
  "type": "BillingDetails",
  "abstractKey": "__isBillingDetails"
};

(node as any).hash = "85f369c47caaae9fcb118ccee94034e3";

export default node;
