import { type PathParams } from "@thekeytechnology/epic-ui";
import { useFragment, useLazyLoadQuery, useMutation } from "react-relay";
import { useNavigate, useParams } from "react-router-dom";
import { NoAccess } from "@components/no-access";
import { useHasPermissions } from "@hooks/use-has-permissions";
import { type emailTemplateEdit_EmailTemplateFragment$key } from "@relay/emailTemplateEdit_EmailTemplateFragment.graphql";
import { type emailTemplateEdit_Query } from "@relay/emailTemplateEdit_Query.graphql";
import { type emailTemplateEdit_UpdatePersonalizedEmailTemplateMutation } from "@relay/emailTemplateEdit_UpdatePersonalizedEmailTemplateMutation.graphql";
import { Paths } from "@routes/paths";
import { type EmailTemplatesPath } from "@screens/email-templates";
import {
	EMAIL_TEMPLATE_FRAGMENT,
	QUERY,
	UPDATE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION,
} from "./email-template-edit.graphql";
import { EditEmailTemplateForm } from "./parts/edit-email-template-form";
import { BaseScreen } from "../BaseScreen";

export const EmailTemplateEditScreen = () => {
	const navigate = useNavigate();
	const { emailTemplateId } = useParams<PathParams<typeof EmailTemplatesPath>>();
	const query = useLazyLoadQuery<emailTemplateEdit_Query>(QUERY, {
		id: emailTemplateId!,
	});

	const emailTemplate = useFragment<emailTemplateEdit_EmailTemplateFragment$key>(
		EMAIL_TEMPLATE_FRAGMENT,
		query.node,
	)!;
	const [updatePersonalizedEmailTemplate, isUpdatingPersonalizedEmailTemplate] =
		useMutation<emailTemplateEdit_UpdatePersonalizedEmailTemplateMutation>(
			UPDATE_PERSONALIZED_EMAIL_TEMPLATE_MUTATION,
		);

	const canRead = useHasPermissions(["UserInAccountPermission_Email_Templates"]);

	if (!canRead) {
		return <NoAccess />;
	}

	return (
		<BaseScreen>
			<h1 className="mb-3">Bearbeite Email-Vorlage</h1>

			<EditEmailTemplateForm
				initialValues={{
					subject: emailTemplate.template.subject,
					preview: emailTemplate.template.previewText,
					body: emailTemplate.template.body,
				}}
				variables={emailTemplate.template.variables}
				loading={isUpdatingPersonalizedEmailTemplate}
				onSubmit={(values) => {
					const data = {
						key: emailTemplate.template.key,
						subject: values.subject!,
						previewText: values.preview!,
						body: values.body!,
						variables: [...emailTemplate.template.variables],
					};
					if (emailTemplate) {
						updatePersonalizedEmailTemplate({
							variables: {
								input: {
									id: emailTemplate.id,
									data,
								},
							},
							onCompleted: () => {
								navigate(Paths.emailTemplates.path);
							},
						});
					}
				}}
			/>
		</BaseScreen>
	);
};
