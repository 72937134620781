/**
 * @generated SignedSource<<012fa33e9e2f0c7245b4661fe49d0af8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsTable_ProductFragment$data = {
  readonly " $fragmentSpreads": FragmentRefs<"ProductActionColumn_ProductFragment" | "ProductIdColumn_ProductFragment" | "ProductIsHiddenColumn_ProductFragment" | "ProductPriceColumn_ProductFragment" | "ProductTitleColumn_ProductFragment">;
  readonly " $fragmentType": "productsTable_ProductFragment";
};
export type productsTable_ProductFragment$key = {
  readonly " $data"?: productsTable_ProductFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_ProductFragment">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "productsTable_ProductFragment"
};

(node as any).hash = "5928e4fd291d26e848c05ad9e43c2d12";

export default node;
