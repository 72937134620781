import { useFormik } from "formik";
import { Button } from "primereact/button";
import { useFragment, useMutation } from "react-relay";
import { DefaultTextFieldComponent } from "@components/DefaultTextInput";
import { ValidatedField } from "@components/ValidatedField";
import { type editWrongAnswerInMatrixElementCellForm_EditWrongAnswerInMatrixElementCellMutation } from "@relay/editWrongAnswerInMatrixElementCellForm_EditWrongAnswerInMatrixElementCellMutation.graphql";
import {
	ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT,
	EDIT_WRONG_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION,
} from "./edit-wrong-answer-in-matrix-element-cell-form.graphql";
import {
	type EditWrongAnswerInMatrixElementCellFormFormState,
	type EditWrongAnswerInMatrixElementCellFormProps,
} from "./edit-wrong-answer-in-matrix-element-cell-form.types";
import { RemoveWrongAnswerFromMatrixElementCellButton } from "../remove-wrong-answer-from-matrix-element-cell-button";

export const EditWrongAnswerInMatrixElementCellForm = ({
	answerMatrixElementCellFragmentRef,
	matrixElementId,
	answerId,
	content,
}: EditWrongAnswerInMatrixElementCellFormProps) => {
	const answerCell = useFragment(
		ANSWER_MATRIX_ELEMENT_CELL_FRAGMENT,
		answerMatrixElementCellFragmentRef,
	);
	const [editWrongAnswerInMatrixElement] =
		useMutation<editWrongAnswerInMatrixElementCellForm_EditWrongAnswerInMatrixElementCellMutation>(
			EDIT_WRONG_ANSWER_IN_MATRIX_ELEMENT_CELL_MUTATION,
		);

	const formik = useFormik<EditWrongAnswerInMatrixElementCellFormFormState>({
		initialValues: {
			content,
		},
		enableReinitialize: true,
		onSubmit: (values) => {
			editWrongAnswerInMatrixElement({
				variables: {
					input: {
						matrixElementId,
						matrixCellId: answerCell.id,
						wrongAnswerId: answerId,
						wrongAnswerContent: values.content,
					},
				},
			});
		},
	});

	return (
		<form
			onSubmit={formik.handleSubmit}
			className="p-fluid flex flex-nowrap align-items-center"
		>
			<ValidatedField<EditWrongAnswerInMatrixElementCellFormFormState, string>
				name={"content"}
				component={DefaultTextFieldComponent}
				formikConfig={formik}
				className="m-1"
			/>
			<Button type="submit" icon="pi pi-save" className="m-1" />
			<RemoveWrongAnswerFromMatrixElementCellButton
				matrixElementId={matrixElementId}
				wrongAnswerId={answerId}
				matrixCellId={answerCell.id}
			/>
		</form>
	);
};
