import { Calendar } from "primereact/calendar";
import moment from "moment-timezone";
import React from "react";

interface Props {
	name: string;
	value: string | undefined;
	onUpdate: (newValue: string | undefined) => void;
	disabled: boolean;
}

export function ConfigDatePicker({ name, value, onUpdate, disabled }: Props) {
	return (
		<Calendar
			name={name}
			showTime
			visible={true}
			hourFormat="24"
			dateFormat={"dd.mm.yy"}
			disabled={disabled}
			value={value ? moment(value.replace("[UTC]", "")).toDate() : undefined}
			onChange={(e) =>
				onUpdate(e.value ? moment(e.value as Date).format("YYYY-MM-DDTHH:mmZ") : undefined)
			}
		/>
	);
}
