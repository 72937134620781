/**
 * @generated SignedSource<<5dca84a05e02e1341fcbb1a6ffbf45bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type productsTable_QueryFragment$data = {
  readonly Admin: {
    readonly Billing: {
      readonly SearchProducts: {
        readonly __id: string;
        readonly edges: ReadonlyArray<{
          readonly cursor: string;
          readonly node: {
            readonly " $fragmentSpreads": FragmentRefs<"productsTable_ProductFragment">;
          };
        } | null> | null;
        readonly pageInfo: {
          readonly endCursor: string | null;
          readonly hasNextPage: boolean;
          readonly hasPreviousPage: boolean;
          readonly startCursor: string | null;
        };
      };
    };
  };
  readonly " $fragmentType": "productsTable_QueryFragment";
};
export type productsTable_QueryFragment$key = {
  readonly " $data"?: productsTable_QueryFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"productsTable_QueryFragment">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "Admin",
  "Billing",
  "SearchProducts"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "before"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "isHiddenOpt"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "last"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "titleOpt"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": null,
        "cursor": null,
        "direction": "bidirectional",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": {
          "count": "last",
          "cursor": "before"
        },
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./productsTable_QueryFragmentRefetch.graphql')
    }
  },
  "name": "productsTable_QueryFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AdminQueryType",
      "kind": "LinkedField",
      "name": "Admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "BillingAdminSchema",
          "kind": "LinkedField",
          "name": "Billing",
          "plural": false,
          "selections": [
            {
              "alias": "SearchProducts",
              "args": [
                {
                  "kind": "Variable",
                  "name": "isHiddenOpt",
                  "variableName": "isHiddenOpt"
                },
                {
                  "kind": "Variable",
                  "name": "titleOpt",
                  "variableName": "titleOpt"
                }
              ],
              "concreteType": "ProductsConnection",
              "kind": "LinkedField",
              "name": "__productsTable_SearchProducts_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasPreviousPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "startCursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ProductsEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "productsTable_ProductFragment",
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ProductIdColumn_ProductFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ProductTitleColumn_ProductFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ProductIsHiddenColumn_ProductFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ProductPriceColumn_ProductFragment"
                            },
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ProductActionColumn_ProductFragment"
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "ClientExtension",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__id",
                      "storageKey": null
                    }
                  ]
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "3a6235a9014ae11c11608d7dd75d7c65";

export default node;
